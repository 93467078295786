import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Button, Box, Typography, Dialog } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { useEffect, useState } from "react";
import PopDialog from "./Dialog";

const SubmissionTableData = (props) => {
  // console.log(props);

  const { id } = useParams();
  const [answers, setAnswers] = useState({});
  const [allStudentAns, setAllStudentAns] = useState([]);

  // const answersArray = Object?.entries(answers?.answers)?.map(([key, value]) => ({
  //   questionId: key,
  //   ...value,
  // }));

  // console.log(answers);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    props.getLead(id);
    props.getLeads();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }
  const leadData = props?.lead?.students?.map((item) => {
    return {
      email: item?.email,
      points: props.leadActionType == "LEAD_UPDATE_REQUEST" ? "Loading..." : item?.totalPoints,
      createTime: formatDate(item?.createdAt),
      lastSubmittedTime: formatDate(item?.lastSubmittedTime),
      actions: (
        <Box>
          {/* Create a button to get the response on click */}
          <Button
            variant="outlined"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => {
              handleClick(item);
            }}
          >
            {" "}
            View
          </Button>
        </Box>
      ),
    };
  });

  function handleClick(item) {
    const answersArray = Object.entries(item?.answers)?.map(([key, value]) => ({
      questionId: key,
      ...value,
    }));
    setAnswers({ answers: answersArray, respondentEmail: item.respondentEmail });
    // setAnswers({ answers: answersArray, respondentEmail: item.respondentEmail, createTime, lastSubmittedTime, responseId });
    handleClickOpen();
  }

  const convertResponsesToArray = (responses, questions) => {
    // console.log("questions", questions);
    const result = [];
    responses?.forEach((response) => {
      const answers = [];
      for (const questionId in response.answers) {
        answers.push({
          questionId: questionId,
          textAnswers: response.answers[questionId].textAnswers,
        });
      }
      result.push({
        respondentEmail: response.respondentEmail,
        answers: answers,
        items: questions,
        createTime: response.createTime,
        lastSubmittedTime: response.lastSubmittedTime,
        formId: props.lead.formID,
      });
    });
    // console.log("result", result);
    return result;
  };

  const pointsMap = {};

  // props.lead?.points.map((point) => {
  //   // pointsMap[point.questionId] = parseInt(point.points);
  //   console.log(point);
  // });

  const pointsByQuestionOption = props.lead?.points?.map((item) => ({
    [item.option]: item.points,
  }));

  const calculateTotalPoints = (pointsArray, responsesArray) => {
    // console.log("responseArray", responsesArray);
    const result = [];

    responsesArray?.forEach((response) => {
      const email = response.respondentEmail;
      const answers = response.answers;
      const items = response.items;
      const createTime = response.createTime;
      const lastSubmittedTime = response.lastSubmittedTime;
      const formID = response.formId;

      let totalPoints = 0;
      let isDQ;

      answers?.forEach((answer) => {
        const option = answer.textAnswers?.answers[0].value;
        // console.log("option", option);

        pointsArray?.forEach((pointsItem) => {
          const pointsKey = Object.keys(pointsItem)[0];
          const pointsValue = pointsItem[pointsKey];

          if (pointsKey == option && !isNaN(pointsValue)) {
            totalPoints += parseInt(pointsValue);
          }
        });
      });

      result.push({
        email: email || "N/A",
        totalPoints,
        items: items,
        answers: answers,
        createdAt: createTime,
        lastSubmittedTime: lastSubmittedTime,
        formId: formID,
      });
    });
    // console.log("resultlast", result);
    return result;
  };

  function findOrCreateEntry(email, item) {
    const emailExists = props.leads.some((obj) => obj?.googleForm && obj?.googleForm?.email === email);

    if (!emailExists) {
      console.log(`Create new entry for email: ${email}`);
      props.createLead({
        googleForm: item,
      });
    } else {
      console.log(`Email ${email} already exists, no action needed`);
      return;
    }
  }

  const calculatePoints = () => {
    const data = convertResponsesToArray(props.lead?.responses, props?.lead?.questions);
    // console.log("data", data);

    const totalPoints = calculateTotalPoints(pointsByQuestionOption, data);

    // totalPoints?.forEach((item) => {
    //   console.log(item);
    //   // props.createLead({
    //   //   googleForm: item,
    //   // });
    // });

    // console.log(totalPoints);
    totalPoints.forEach((item) => {
      findOrCreateEntry(item?.email, item);
    });

    props.updateLead(
      {
        students: totalPoints,
        formId: props.lead.formID,
      },
      id,
    );

    // console.log(totalPoints);
    // console.log(data);
    // console.log(pointsByQuestionOption);
  };

  // console.log(allStudentAns);

  const tableColumns = [
    // { id: "leadName", label: "Lead Name" },
    // { id: "leadScore", label: "Lead Score" },
    // { id: "leadStage", label: "Lead Stage" },
    // { id: "assignedTo", label: "Assigned To" },
    // { id: "lastAction", label: "Last Action" },
    // { id: "origin", label: "Origin" },
    // { id: "city", label: "City" },
    // { id: "contacts", label: "Contacts" },
    { id: "email", label: "Emails" },
    { id: "points", label: "Points" },
    { id: "createTime", label: "Create Time" },
    { id: "lastSubmittedTime", label: "Last Submitted Time" },
    { id: "actions", label: "Actions" },
  ];

  const tableData = leadData;

  const history = useHistory();

  return (
    <Box sx={{ marginTop: "100px", mx: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
        <Button variant="contained" sx={{ mb: 2 }} onClick={calculatePoints}>
          Refresh
        </Button>
      </Box>
      <Typography variant="h4" sx={{ mb: 2, color: "primary.main" }}>
        {props.lead?.title}
      </Typography>
      {props.leadActionType == "LEAD_READ_REQUEST" ? (
        <Typography variant="h4" component="div" color="primary.main" my={0}>
          Loading...
        </Typography>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",

                      color: "black",
                      [`@media(max-width:780px)`]: { fontSize: "0.7rem" },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow key={index}>
                  {tableColumns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PopDialog open={open} handleClose={handleClose} answers={answers} />
    </Box>
  );
};
let variableList = {
  leadReducer: {
    lead: {},
    leads: [],
  },
};

const getLead = curdActions.getLead;
const getLeads = curdActions.getLeads;
const updateLead = curdActions.updateLead;
const createLead = curdActions.createLead;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getLead,
    updateLead,
    createLead,
    getLeads,
  },
};
let addditionalREducer = ["leadReducer"];

// export default SingleForm;
const SubmissionTableDataContainer = CRUDFunction(SubmissionTableData, " ", actionOptions, variableList, addditionalREducer, null);
export default SubmissionTableDataContainer;
// export default SubmissionTableData;
