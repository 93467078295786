import React from "react";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import InputForm from "../../../Components/Common/Form/InputForm";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { repeaterDefaultValueIndex, repeaterLoader } from "../../StudentProfile/ApplicationForm/Forms/repeaterLoader";

const EnglishProficiency = ({
  setModal,
  goBack,
  eControl,
  handleSubmit,
  setRegistrationFormFiles,
  nextStep,
  setStudentData,
  isEdit,
  proceedData: sData,
}) => {
  const handleCancel = () => {
    setModal(false);
  };
  const [file, setFile] = useState("");
  const [showOtherField, setShowOtherField] = useState("");

  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data, { uploadTestResult: file }]);
    nextStep();
  };
  const {
    control,
    // handleSubmit,
    formState: { errors },
  } = useForm(

    
   
    {
      defaultValues: {
        englishProficiency: [
          {
            englishProficiency: "",
            score: "",
            uploadTestResult: "",
            otherEnglishProficiency: "",
            dateOfTest: "",
            speaking: "",
            listening: "",
            writing: "",
            reading: "",
          },
        ],
      },
    },
  );

  const uploadResult = (e) => {
    setFile(e.target.files[0].name);
    setRegistrationFormFiles((prevData) => {
      return { ...prevData, [e.target.name]: e.target.files[0] };
    });
  };
  const { fields, append } = useFieldArray({
    control,
    name: "englishProficiency",
  });

  const englishProficiencyOptions = [
    { label: "IELTS Academic", level: 1 },
    { label: "IELTS UKVI ", level: 2 },
    { label: "TOFEL", level: 3 },
    { label: "GCE O'Levels", level: 4 },
    { label: "PTE", level: 5 },
    { label: "PTE", level: 6 },
    { label: "DUOLINGO", level: 7 },
    { label: "Other", level: 8 },
  ];

  const addOtherField = (data) => {
    if (data?.label === "Other") {
      setShowOtherField(data?.label);
    } else {
      setShowOtherField("");
    }
  };

  return (
    <Box mt={2}>
      {/* <InputForm
        config={{
          noPaper: true,
          saveText: "Next",
          cancelButton: true,
          backButton: true,
        }}
        handleSave={nextStep}
        handleClose={handleCancel}
        handleBack={backStep}
        formFieldSchema={englishProficiencyForm}
      /> */}

      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <Box>
          <Stack>
            {fields.map((field, index) => {
              return (
                <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb:'25px' }} spacing={2}>
                  <Controller
                    defaultValue={
                      ""
                    }
                    control={eControl}
                    name={`englishProficiency.${index}.englishProficiency`}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={englishProficiencyOptions}
                        onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                        renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="English Proficiency" />}
                        {...field}
                      />
                    )}
                  />
                  {showOtherField === "Other" && (
                    <Controller
                      defaultValue={
                        ""
                      }
                      control={eControl}
                      name={`englishProficiency.${index}.otherEnglishProficiency`}
                      render={({ field }) => (
                        <TextField
                          id="outlined-basic"
                          type="text"
                          label="Other English Proficiency"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  )}
                  <Stack direction={"row"} spacing={2}>
                    <Controller
                      control={eControl}
                      defaultValue={""}
                      name={`englishProficiency.${index}.speaking`}
                      render={({ field }) => (
                        <TextField
                          label="Speaking"
                          error={Boolean(errors.speaking)}
                          helperText={errors.speaking?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                    <Controller
                      control={eControl}
                      defaultValue={""}
                      name={`englishProficiency.${index}.listening`}
                      render={({ field }) => (
                        <TextField
                          label="Listening"
                          error={Boolean(errors.listening)}
                          helperText={errors.listening?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                    <Controller
                      control={eControl}
                      defaultValue={""}
                      name={`englishProficiency.${index}.writing`}
                      render={({ field }) => (
                        <TextField
                          label="Writing"
                          error={Boolean(errors.writing)}
                          helperText={errors.writing?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={eControl}
                      defaultValue={""}
                      name={`englishProficiency.${index}.reading`}
                      render={({ field }) => (
                        <TextField
                          label="Reading"
                          error={Boolean(errors.reading)}
                          helperText={errors.reading?.message}
                          id="outlined-basic"
                          type="text"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    defaultValue={""}
                    control={eControl}
                    name={`englishProficiency.${index}.score`}
                    render={({ field }) => (
                      <TextField
                        label="Score"
                        error={Boolean(errors.score)}
                        helperText={errors.score?.message}
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                  {/* <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "englishProficiency", "englishProficiency", index, sData) : ""}
                    control={eControl}
                    name={`englishProficiency.${index}.uploadResult`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.uploadResult)}
                        helperText="Upload Result"
                        id="outlined-basic"
                        type="file"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  /> */}
                  <TextField type="file" name={`englishProficiency.${index}.uploadTestResult`} variant="outlined" onChange={uploadResult} />
                  <Controller
                    defaultValue={""}
                    control={eControl}
                    name={`englishProficiency.${index}.dateOfTest`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.dateOfTest)}
                        helperText="Date Of Test"
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={() => append({ englishProficiency: "", score: "", uploadResult: "", dateOfTest: "" })}>
              Add More
            </Button>
          </Box>
        </Box>
        <Box
          mt={2}
          sx={{
            width: "70%",
            position: "absolute",
            height: "7%",
            bottom: "3%",
          }}
        >
          <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white", zIndex: 1 }}>
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default EnglishProficiency;
