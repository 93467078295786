import React from "react";
import { Box, SvgIcon, Card, Typography, Tooltip, Button } from "@mui/material";
import { Visibility, ArrowForward } from "@mui/icons-material";
import ReactApexChart from "react-apexcharts";

const ApplicationProgress = () => {
  const chartOptions = {
    labels: ["Incomplete", "Submitted", "Final Review", "Enrolled", "Inactive", "Arrived"],
    colors: ["#ff9800", "#4caf50", "#9c27b0", "#e91e63", "#ff5722", "#607d8b"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [20, 15, 5, 30, 8, 12];

  const transitionTimes = ["10 minutes", "15 minutes", "7 minutes", "12 minutes", "8 minutes", "9 minutes"];

  return (
    <Card sx={{ marginTop: "3rem", padding: "2rem", marginBottom: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Application Progress Tracker
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box display="flex" alignItems="center" justifyContent="center" width={400}>
          <ReactApexChart options={chartOptions} series={chartSeries} type="radialBar" width={300} height={300} />
        </Box>

        {chartSeries.map((value, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip title={transitionTimes[index]} arrow>
                  <SvgIcon
                    component={ArrowForward}
                    sx={{
                      fontSize: 40,
                      color: "primary.main",
                      marginX: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-2rem",
                    }}
                  />
                </Tooltip>
              </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ marginRight: "1rem" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={100}
                height={100}
                borderRadius="50%"
                bgcolor={chartOptions.colors[index]}
              >
                <SvgIcon component={Visibility} sx={{ fontSize: 50, color: "#fff" }} />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" marginTop="0.5rem"></Box>
              <Typography variant="subtitle1" sx={{ color: "black", marginTop: "1rem" }}>
                {chartOptions.labels[index]}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <Typography variant="subtitle1" sx={{ color: "black", marginTop: "1rem", marginLeft: "5rem" }}>
        All :[100]
      </Typography>
    </Card>
  );
};

export default ApplicationProgress;
