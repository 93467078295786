import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, useMediaQuery } from "@mui/material";
import React from "react";

const TaskDropdown = ({ personName, handleChange, users }) => {
  // console.log("users", users);

  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      {
        isMobile ? (
          <FormControl>
            <InputLabel id="demo-multiple-name-label">Role</InputLabel>
            <Select
              fullWidth
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={personName}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Name" />}
            >
              {users?.map((name, index) => (
                <MenuItem key={index} value={name?.name}>
                  {name?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl
            sx={{
              width: "420px",
              "@media (max-width: 1440px)": {
                width: "350px",
              },
              "@media (max-width: 1024px)": {
                width: "250px",
              },
            }}
          >
            <InputLabel id="demo-multiple-name-label">Role</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={personName}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Name" />}
            >
              {users?.map((name, index) => (
                <MenuItem key={index} value={name?.name}>
                  {name?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
    </>
  );
};

export default TaskDropdown;
