import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
// import { guid } from "../../utils/UUID";
import { generateProjectId } from "../../utils/generateTaskId";
import { getLoggedInUser } from "../../utils/loggedInUser";
import CollaboratorDropdown from "./CollaboratorDropdown";
const AddProjectModal = ({ createTask, handleClose1, users }) => {
  //component for calling create task db function for generic tasks
  //state  for setting project name
  const [projectName, setProjectName] = useState("");
  const [userName, setUserName] = useState([]);

  const user = getLoggedInUser();

  const handleProject = () => {
    if (projectName === "") {
      alert("Project name cannot be empty!");
      return;
    }
    const projectId = generateProjectId(projectName);
    createTask({
      projectTitle: projectName,
      projectId: projectId,
      addColaborator: [userName],
      createdBy: { id: user._id, name: user.name, email: user.email },
    });
    // Clear form inputs
    setProjectName("");
    handleClose1();
  };

  return (
    <>
      <Box>
        <Typography variant="h3" sx={{ color: "rgb(63,64,65)", textAlign: "center" }}>
          New Project
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: "5px", color: "rgb(63,64,65)", fontWeight: "bold" }}>
          Project Name
        </Typography>
        <Box
          sx={{
            mt: "5px",
          }}
        >
          <TextField value={projectName} onChange={(e) => setProjectName(e.target.value)} fullWidth variant="filled" />
        </Box>
        {/* <CollaboratorDropdown users={users} userName={userName} setUserName={setUserName} /> */}
        <Box sx={{ mt: "15px", display: "flex", justifyContent: "flex-end", justifyItems: "end" }}>
          <Button onClick={handleProject} variant="contained" size="medium">
            Create Project
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddProjectModal;
