import React, { useState } from "react";
import { studentFileDownloadPath } from "../../../../../utils/studentFileDownloadUtil";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, Checkbox, Tooltip, Typography } from "@mui/material";
import UploadIcon from "../../../../../Images/UploadIcon";
import CommentIcon from "@mui/icons-material/Comment";

const DocumentViewTable = ({
  data,
  fileUpload,
  setSelectedFile,
  openFile,
  onUpdate,
  stdId,
  allData,
  verify,
  user,
  setRevertData,
  openFileForCounselor,
  student,
  selectFileCategoryFilesView,
}) => {
  // console.log(allData)

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thTdStyle = {
    padding: "8px 8px",
    textAlign: "left",
  };

  const trEvenStyle = {
    backgroundColor: "white",
  };

  const trOddStyle = {
    backgroundColor: "white",
  };

  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      thTdStyle: {
        display: "block",
        width: "90%",
        boxSizing: "border-box",
        textAlign: "center",
      },
    },
  };

  const headingContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  };

  const ContainterTopStyle = {
    // paddingLeft: "1.5rem",
    // paddingRight: "2rem",
    width: "100%",
  };

  const checkList = [
    {
      id: 1,
      title: "SAT",
      required: true,
      fieldName: "sat",
    },
    {
      id: 2,
      title: "IELTS",
      fieldName: "ielts",
    },
    {
      id: 3,
      title: "Teacher's recommendations",
      required: true,
      fieldName: "teacherRecommendation",
    },
    {
      id: 4,
      title: "Counselor's recommendations",
      required: true,
      fieldName: "counselorRecommendation",
    },
    {
      id: 5,
      title: "School reports",
      fieldName: "schoolReports",
    },
    {
      id: 6,
      title: "Predicted grades",
      required: true,
      fieldName: "predictedGrades",
    },
    {
      id: 7,
      title: "Internship/ECA's certificate",
      fieldName: "internship",
    },
    {
      id: 8,
      title: "O Levels certificate",
      fieldName: "olevelCertificate",
    },
    {
      id: 9,
      title: "AS Levels certificate",
      required: true,
      fieldName: "aslevelCertificate",
    },
    {
      id: 10,
      title: "Other Documents",
      fieldName: "otherDocuments",
    },
    {
      id: 11,
      title: "Passport",
      fieldName: "passport",
      required: true,
    },
    // {
    //   id: 11,
    //   title: "Passport (Colour copy with 6 monthes validity)",
    //   fieldName: "passport",
    // },
    // {
    //   id: 12,
    //   title: "Work experience / Internship letter in case of gap year in study",
    //   fieldName: "workExperience",
    // },
    // {
    //   id: 13,
    //   title: "Extra Curriculum",
    //   fieldName: "extraCurriculum",
    // },
    // {
    //   id: 14,
    //   title: "Affidavit of Support",
    //   fieldName: "affidavit",
    // },
    // {
    //   id: 15,
    //   title: "Bank Letter / Statement",
    //   required: true,
    //   fieldName: "bankLetter",
    // },

    // {
    //   id: 18,
    //   title: "Other Documents",
    //   fieldName: "otherDocuments",
    // },
  ];

  const documents = data;

  const resultObject = {};

  if (documents !== undefined) {
    for (const [key, value] of Object?.entries(documents)) {
      // Join the array values into a single string
      const formattedValue = Array.isArray(value) ? value?.join(", ") : value;

      // Assign the formatted value to the result object
      resultObject[key] = formattedValue;
    }
  }

  const appData = allData?.appData;

  const ReplaceFile = (fieldName, existingFileName, newFile) => {
    if (!appData) {
      return;
    }
    const files = appData.documentUpload[fieldName];
    // Find the index of the existing file
    const fileIndex = files.findIndex((file) => file === existingFileName);
    if (fileIndex !== -1) {
      // Update the existing array with the new file
      files[fileIndex] = newFile.name;
      setSelectedFile(newFile.name);
      // Assuming props.fileUploads function is available
      fileUpload(newFile, "/uploadsingle?stdId=" + allData.stdId);
      onUpdate({ appData }, allData?._id);
    }
  };

  // const uploadFile = (file, fieldName) => {
  //   if (!appData) return;
  //   if (!appData.documentUpload || !appData.documentUpload[fieldName]) {
  //     const upAppData = { ...appData };
  //     if (!upAppData.documentUpload) {
  //       upAppData.documentUpload = {};
  //     }
  //     upAppData.documentUpload[fieldName] = [file.name];
  //     onUpdate({ appData: upAppData }, allData?._id);
  //   }
  //   fileUpload(file, "/uploadsingle?stdId=" + allData.stdId);
  // };

  const uploadFile = (file, fieldName) => {
    if (!appData) return;
    const upAppData = { ...appData };
    if (!upAppData.documentUpload) {
      upAppData.documentUpload = {};
    }
    if (!upAppData.documentUpload[fieldName]) {
      upAppData.documentUpload[fieldName] = [];
    }
    upAppData.documentUpload[fieldName].push(file.name);
    onUpdate({ appData: upAppData }, allData?._id);
    fileUpload(file, "/uploadsingle?stdId=" + allData.stdId);
  };

  const AddFile = (file, fieldName) => {
    console.log("File to upload:", file);
    console.log("Field name:", fieldName);

    if (!appData) return;

    const upAppData = { ...appData };

    if (upAppData.documentUpload[fieldName]) {
      // If the fieldName exists within docCheck, update it
      if (!upAppData.documentUpload[fieldName].includes(file.name)) {
        upAppData.documentUpload[fieldName] = [...upAppData.documentUpload[fieldName], file.name];
      }
    }

    onUpdate({ appData: upAppData }, allData?._id);
    fileUpload(file, "/uploadsingle?stdId=" + allData.stdId);
  };

  return (
    <>
      {/* For App Officer View */}
      {user?.role !== "Counselor" && (
        <div style={ContainterTopStyle}>
          <div style={{ overflowY: "scroll", overflowX: "scroll" }}>
            <table style={tableStyle}>
              <thead style={{ backgroundColor: "rgb(25, 104, 173)", width: "120%" }}>
                <tr>
                  <th style={{ ...thTdStyle, color: "white", padding: "0 0 0 8px" }}>Documents</th>
                  <th style={{ ...thTdStyle, color: "white" }}>File Name</th>
                  <th style={{ ...thTdStyle, color: "white" }}>View</th>
                  <th style={{ ...thTdStyle, color: "white" }}>Verify</th>
                  {student.counselor?.name === student?.appOfficer?.name ? (
                    <th style={{ ...thTdStyle, color: "white" }}>Replace</th>
                  ) : (
                    <th style={{ ...thTdStyle, color: "white" }}>Revert</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {checkList?.map((value, index) => (
                  <>
                    {data && data[value?.fieldName]?.length > 0 ? (
                      data[value?.fieldName]?.map((file, fileIndex) => (
                        <tr
                          key={`${value.fieldName}-${fileIndex}`}
                          style={{
                            ...(index % 2 === 0 ? trEvenStyle : trOddStyle),
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle, padding: "0 0 0 8px" }}>
                            {fileIndex === 0 && value?.title}
                          </td>
                          <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <span style={{ fontWeight: "bold" }}>{file ? `${fileIndex + 1}. ` : ""}</span>
                            <span>{file}</span>
                            {/* Displaying multiple files if they exist */}
                            {/* {data?.[value?.fieldName] && data[value.fieldName].map((file, index) => ( */}
                            {/* <div key={index}> */}
                            {/* <span style={{ fontWeight: "bold" }}>{`${index + 1}. `}</span> */}
                            {/* <span>{file}</span> */}
                            {/* <br />  */}
                            {/* </div> */}
                            {/* ))} */}
                            {/* {!data?.[value?.fieldName] && "N/A"}  */}
                          </td>
                          <td data-label="Status" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <Tooltip title={allData?.docCheck?.[file]?.isViewed ? "Document Viewed" : "View Document"} placement="top">
                              <button
                                style={{
                                  cursor: "pointer",
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                  opacity: `${data?.[value?.fieldName] ? "1" : ".4"}`,
                                  color: allData?.docCheck?.[file]?.isViewed ? "green" : "rgb(25,104,173)",
                                }}
                                onClick={() => selectFileCategoryFilesView(file, file)}
                              >
                                <VisibilityIcon />
                              </button>
                            </Tooltip>
                          </td>
                          <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <Tooltip
                              title={
                                !allData?.docCheck?.[file]?.isViewed
                                  ? "View Document Then Verify "
                                  : allData?.docCheck?.[file]?.verify
                                    ? "Document Verified"
                                    : "Verify Document"
                              }
                              placement="top"
                            >
                              <span>
                                <Checkbox
                                  onChange={(e) => verify(e?.target?.checked, file)}
                                  disabled={!allData?.docCheck?.[file]?.isViewed}
                                  defaultChecked={allData?.docCheck?.[file]?.verify}
                                />
                              </span>
                            </Tooltip>
                          </td>
                          {student.counselor?.name === student?.appOfficer?.name ? (
                            <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Tooltip
                                  title={!allData?.docCheck?.[file]?.verify ? "Update Document" : "Unverify Then Update"}
                                  placement="top"
                                >
                                  <Box
                                    component="label"
                                    sx={{
                                      cursor: allData?.docCheck?.[file]?.verify ? "default" : "pointer",
                                      opacity: allData?.docCheck?.[file]?.verify ? ".4" : "1",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      hidden
                                      onChange={(e) => ReplaceFile(value.fieldName, file, e.target.files[0])}
                                      // onChange={(e) => AddFile(e.target.files[0], value?.fieldName)}
                                      disabled={allData?.docCheck?.[file]?.verify}
                                    />
                                    <UploadIcon />
                                  </Box>
                                </Tooltip>
                              </button>
                            </td>
                          ) : (
                            <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <button
                                disabled={!allData?.docCheck?.[file]?.isViewed || allData?.docCheck?.[file]?.verify}
                                style={{
                                  cursor: allData?.docCheck?.[file]?.verify || !allData?.docCheck?.[file]?.isViewed ? "default" : "pointer",
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Tooltip
                                  title={
                                    allData?.docCheck?.[file]?.verify
                                      ? "Unverify Document Then Revert"
                                      : !allData?.docCheck?.[file]?.isViewed
                                        ? "View Document Then Revert"
                                        : allData?.docCheck?.[file]?.revert
                                          ? "Document Reverted"
                                          : "Revert Document"
                                  }
                                  placement="top"
                                >
                                  <ReplayIcon
                                    color="primary"
                                    onClick={() =>
                                      setRevertData({
                                        revert: true,
                                        docName: file,
                                      })
                                    }
                                    sx={{
                                      rotate: allData?.docCheck?.[file]?.revert ? "270deg" : "none",
                                      color: allData?.docCheck?.[file]?.verify
                                        ? "Grey"
                                        : allData?.docCheck?.[file]?.revert
                                          ? "red"
                                          : "none",
                                    }}
                                  />
                                </Tooltip>
                              </button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr
                        style={{
                          ...(index % 2 === 0 ? trEvenStyle : trOddStyle),
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle, padding: "0 0 0 8px" }}>
                          {value?.title}
                        </td>
                        <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                          <span>N/A</span>
                          {/* Displaying multiple files if they exist */}
                          {/* {data?.[value?.fieldName] && data[value.fieldName].map((file, index) => ( */}
                          {/* <div key={index}> */}
                          {/* <span style={{ fontWeight: "bold" }}>{`${index + 1}. `}</span> */}
                          {/* <span>{file}</span> */}
                          {/* <br />  */}
                          {/* </div> */}
                          {/* ))} */}
                          {/* {!data?.[value?.fieldName] && "N/A"}  */}
                        </td>
                        <td data-label="Status" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                          <Tooltip title={"Upload Document"} placement="top">
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                outline: "none",
                                opacity: ".4",
                              }}
                            >
                              <VisibilityIcon />
                            </button>
                          </Tooltip>
                        </td>
                        <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                          <Tooltip title={"Upload Document Then verify"} placement="top">
                            <span>
                              <Checkbox disabled />
                            </span>
                          </Tooltip>
                        </td>
                        {student.counselor?.name === student?.appOfficer?.name ? (
                          <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                outline: "none",
                              }}
                            >
                              <Tooltip title={"Upload Document"} placement="top">
                                <Box component="label">
                                  <input type="file" hidden onChange={(e) => uploadFile(e.target.files[0], value.fieldName)} />
                                  <UploadIcon />
                                </Box>
                              </Tooltip>
                            </button>
                          </td>
                        ) : (
                          <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                outline: "none",
                              }}
                            >
                              <Tooltip title={"Request for document"} placement="top">
                                <ReplayIcon
                                  color="primary"
                                  sx={{
                                    rotate: allData?.docCheck?.[value?.fieldName]?.revert ? "270deg" : "none",
                                    color: allData?.docCheck?.[value?.fieldName]?.revert ? "red" : "",
                                  }}
                                  onClick={() =>
                                    setRevertData({
                                      revert: true,
                                      docName: value?.fieldName,
                                    })
                                  }
                                />
                              </Tooltip>
                            </button>
                          </td>
                        )}
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* For Counselor View */}
      {user?.role === "Counselor" &&
        (checkList?.some((value) => data[value.fieldName]?.some((file) => allData?.docCheck?.[file]?.revert === true)) ||
          checkList?.some((value) => allData?.docCheck?.[value?.fieldName]?.revert === true) ? (
          <div style={ContainterTopStyle}>
            <div style={{ overflowY: "scroll", overflowX: "scroll" }}>
              <table style={tableStyle}>
                <thead style={{ backgroundColor: "rgb(25, 104, 173)", width: "120%" }}>
                  <tr>
                    <th style={{ ...thTdStyle, color: "white", padding: "0 0 0 8px" }}>Documents</th>
                    <th style={{ ...thTdStyle, color: "white" }}>File Name</th>
                    <th style={{ ...thTdStyle, color: "white" }}>View</th>
                    <th style={{ ...thTdStyle, color: "white" }}>Replace</th>
                    <th style={{ ...thTdStyle, color: "white" }}>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {checkList?.map((value, index) => (
                    <>
                      {data[value.fieldName]?.length > 0 ? (
                        data[value.fieldName]?.map((file, fileIndex) => (
                          <tr
                            key={`${value?.fieldName}-${fileIndex}`}
                            style={{
                              ...(index % 2 === 0 ? trEvenStyle : trOddStyle),
                              borderBottom: "1px solid #ccc",
                              background: allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? "#E4F1FF" : "",
                            }}
                          >
                            <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle, padding: "0 0 0 8px" }}>
                              {fileIndex === 0 && value.title}
                            </td>
                            <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <span style={{ fontWeight: "bold" }}>{file ? `${fileIndex + 1}. ` : ""}</span>
                              <span>{file}</span>
                              {/* Displaying multiple files if they exist */}
                              {/* {data?.[value?.fieldName] && data[value.fieldName].map((file, index) => ( */}
                              {/* <div key={index}> */}
                              {/* <span style={{ fontWeight: "bold" }}>{`${index + 1}. `}</span> */}
                              {/* <span>{file}</span> */}
                              {/* <br />  */}
                              {/* </div> */}
                              {/* ))} */}
                              {/* {!data?.[value?.fieldName] && "N/A"}  */}
                            </td>
                            <td data-label="Status" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <button
                                style={{
                                  cursor: allData?.docCheck?.[file]?.revert ? "pointer" : "default",
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                  opacity: `${allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? "1" : ".4"}`,
                                }}
                                disabled={allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? false : true}
                                onClick={() => selectFileCategoryFilesView(file, file, true)}
                              >
                                <VisibilityIcon color="primary" />
                              </button>
                            </td>
                            <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <button
                                style={{
                                  cursor: "pointer",
                                  background: "none",
                                  border: "none",
                                  outline: "none",
                                  opacity: `${allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? "1" : ".4"}`,
                                }}
                              >
                                <Tooltip title={allData?.docCheck?.[file]?.revert ? "Replace Document" : ""} placement="top">
                                  <Box
                                    component="label"
                                    sx={{
                                      cursor: allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? "pointer" : "default",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      hidden
                                      disabled={allData?.docCheck?.[file]?.revert && user?.role === "Counselor" ? false : true}
                                      onChange={(e) => ReplaceFile(value.fieldName, file, e.target.files[0])}
                                    />
                                    <UploadIcon />
                                  </Box>
                                </Tooltip>
                              </button>
                            </td>
                            <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                              <Tooltip title={allData?.docCheck?.[file]?.comment} placement="top">
                                <div>
                                  <CommentIcon />
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          style={{
                            ...(index % 2 === 0 ? trEvenStyle : trOddStyle),
                            borderBottom: "1px solid #ccc",
                            background: allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? "#E4F1FF" : "",
                          }}
                        >
                          <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle, padding: "0 0 0 8px" }}>
                            {value?.title}
                          </td>
                          <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <span>N/A</span>
                          </td>
                          <td data-label="Status" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <button
                              style={{
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                outline: "none",
                                opacity: `${allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? "1" : ".4"}`,
                              }}
                            >
                              <VisibilityIcon color="primary" />
                            </button>
                          </td>
                          <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <button
                              disabled={allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? false : true}
                              style={{
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                outline: "none",
                                opacity: `${allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? "1" : ".4"}`,
                              }}
                            >
                              <Box
                                component="label"
                                sx={{
                                  cursor:
                                    allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? "pointer" : "default",
                                }}
                              >
                                <input
                                  type="file"
                                  hidden
                                  disabled={allData?.docCheck?.[value?.fieldName]?.revert && user?.role === "Counselor" ? false : true}
                                  onChange={(e) => uploadFile(e.target.files[0], value.fieldName)}
                                />
                                <UploadIcon />
                              </Box>
                            </button>
                          </td>
                          <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                            <Tooltip title={allData?.docCheck?.[value?.fieldName]?.comment} placement="top">
                              <div>
                                <CommentIcon />
                              </div>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <ApplicationInProgressComp />
        ))}
    </>
  );
};
export default DocumentViewTable;

const ApplicationInProgressComp = ({ msg }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      {msg ? (
        <Typography>{msg}</Typography>
      ) : (
        <>
          <Typography variant="h3">Application in Progress</Typography>
          {/* <Typography variant="h3">Pending :</Typography> */}
        </>
      )}
    </Box>
  );
};
