import React, { useState, useEffect, useContext } from "react";
import { getLoggedInUser } from "../../utils/loggedInUser";
import socketIOClient from "socket.io-client"; //import from socket io library
import { getServerEndpointNoProtocol } from "../../utils/serverEndpoint"; // based on environment code ka setup karay ga kai yh local main hai ya production main hai.
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { SocketContext } from "../../SocketContext";
const SocketIoClient = (props) => {
  const { socketIOUser, setSocketIOUser } = useContext(SocketContext);
  let socketIO = null;
  useEffect(() => {
    let user = getLoggedInUser();
    if (user && !socketIO) {
      socketIO = socketIOClient(getServerEndpointNoProtocol());

      socketIO.emit("room", {id:user._id,email:user.email});
      socketIO.on("notification_received", (id) => {
        props.getNotification(id);
      });
      // console.log(user.email);
      socketIO.emit("join_chat", user.email);
      socketIO.on("chat_received", (data) => {
        console.log("chat from user", data);
      });
      // console.log(socketIO);
      setSocketIOUser(socketIO);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (props.loginActionType === "") {
    } else if (props.loginActionType === "") {
    }
  }, [props.loginActionType]);
};
let variableList = { loginReducer: { login: null } };
const getNotification = curdActions.getNotification;
let actionOptions = {
  create: false,
  read: false,
  update: false,
  delete: false,
  others: { getNotification },
};
const socketIoClientContainer = CRUDFunction(SocketIoClient, "socketIo", actionOptions, variableList, ["loginReducer"]);
export default socketIoClientContainer;
// export { socketIoClientContainer as default, SocketIoClient };
