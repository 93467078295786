import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, StepButton, StepLabel, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { UploadFile } from "@mui/icons-material";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import { calculateActiveStep } from "./StatusCaclulator";
import { decisionOptions } from "./Templates/DecisionTemplate";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";

const steps = ["Awaiting Decision", "Decision Received"];
// const steps = ["Awaiting Decision", "Decision Received", "Approved"];

const Decision = ({ setValue, setDisabledTab, disabledTab, onUpdate, data, student, studentTimelineUpdate }) => {
  const [activeStep, setActiveStep] = useState(calculateActiveStep(data, "decision", ["completed"]));
  // const nextStep = () => {
  //   setActiveStep(activeStep + 1);
  // };
  const [decisionFileObj, setDecisionFileObj] = useState(null);

  const [decision, setDecision] = useState({
    selectedDecision: "",
    uploadDecision: "",
  });

  const [comment, setComment] = useState("");

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setDecision((prevData) => ({ ...prevData, uploadDecision: e.target.files[0].name }));
      setDecisionFileObj(e.target.files[0]);
    }
  };

  const completed = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        decision: {
          universityDecision: {
            decisionType: decision.selectedDecision,
            file: decision.uploadDecision,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
      { file: decisionFileObj },
    );
    studentTimelineUpdate("Decision submitted", "Submitted");
    // setActiveStep(1);
  };

  const nextTab = () => {
    setDisabledTab((prevData) => ({ ...prevData, tab3: false }));
    setValue(3);
  };
  const approved = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        decision: {
          approved: {
            approvedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    studentTimelineUpdate("Decision received", "Received");
    // setActiveStep(2);
    // nextTab();
  };

  const [files, setFiles] = useState("");

  const inputRef = useRef();

  const handleChange = (event) => {
    const data = event.target.value;
    setDecision((prevData) => ({ ...prevData, selectedDecision: data }));
  };

  const FirstStepData = ({ data }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          px: 10,
          pt: 2,
          "@media (max-width: 950px)": {
            p: "0px !important",
          },
        }}
      >
        {/* <Typography variant="subtitle1">Assigned to: {student?.appOfficer?.name}</Typography> */}
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          variant="subtitle1"
        >
          {" "}
          Submitted By{" "}
          <span
            style={{
              fontWeight: "500 ",
            }}
          >
            {data?.decision?.universityDecision?.submittedBy?.name}
          </span>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          variant="subtitle1"
        >
          Decision Type
          <span
            style={{
              fontWeight: "500 ",
            }}
          >
            {decisionOptions[data?.decision?.universityDecision?.decisionType]
              ? decisionOptions[data.decision.universityDecision.decisionType]
              : ""}
          </span>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          variant="subtitle1"
        >
          {" "}
          Proof
          <Link
            download
            href={studentFileDownloadPath(data.stdId, data.decision?.universityDecision?.file)}
          >
            {" "}
            {data.decision?.universityDecision?.file}
          </Link>
        </Typography>
      </Box>
    );
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack
            spacing={2}
            alignItems="flex-start"
            sx={{
              px: 4,
            }}
          >
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Decision</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={decision.selectedDecision}
                label="Select Decision"
                onChange={handleChange}
              >
                <MenuItem value="unCon">Unconditional</MenuItem>
                <MenuItem value="con">Conditional</MenuItem>
                <MenuItem value="rej">Reject</MenuItem>
                <MenuItem value="furDoc">Request For Additional Documents / Information</MenuItem>
              </Select>
            </FormControl>
            {decision.selectedDecision === "furDoc" && (
              <Box>
                <Typography variant="subtitle2">Additional Documents</Typography>
                <TextField
                  inputProps={{
                    multiple: true,
                  }}
                  type="file"
                />
              </Box>
            )}
            {decision.selectedDecision === "rej" && (
              <Box sx={{ width: "100%" }}>
                <Typography variant="subtitle2">Comments</Typography>
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Enter Comments"
                  sx={{ width: "100%" }}
                  multiline
                  rows={4}
                  defaultValue=""
                  onChange={(e) => setComment(e.target.value)}
                />
              </Box>
            )}
            <Box>
              <Typography variant="subtitle2">Upload Decision</Typography>
              <TextField type="file" onChange={onFileChange} />
            </Box>
            <Button
              variant="contained"
              onClick={completed}
              sx={{
                position: "absolute",
                right: "2%",
                bottom: "0%",
              }}
            >
              Submit
            </Button>
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2} alignItems="flex-start">
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}

            <Stack spacing={2}>
              <FirstStepData data={data} />
              <Button variant="contained" onClick={approved}>
                Confirm
              </Button>
              {/* <Button variant="outlined">Revert</Button> */}
            </Stack>
          </Stack>
        );
      default:
        return;
    }
  };
  const approvedDateObj = new Date(data.decision?.approved?.date);
  const approvedDate = approvedDateObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });

  const handleData = (step) => {
    switch (step) {
      case 0:
        return <FirstStepData data={data} />;
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1"> Approved By: {data?.decision?.approved?.approvedBy?.name}</Typography>
            <Typography variant="subtitle1"> Date: {approvedDate}</Typography>
          </Box>
        );
      case 2:
        return <Box></Box>;
      default:
        return "";
    }
  };

  const [stepNumber, setStepNumber] = useState(0);
  useEffect(() => {
    setActiveStep(calculateActiveStep(data, "decision", ["completed"]));
  }, [{ data }]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, idx) => (
          <Step key={label} disabled={data.stage > 2 ? false : true}>
            <StepLabel>{label}</StepLabel>
            {/* <StepButton onClick={() => setStepNumber(idx)}>{label}</StepButton> */}
          </Step>
        ))}
      </Stepper>
      {data.stage > 2 ? <Box mt={4}>{handleData(stepNumber)}</Box> : <Box mt={4}>{handleSteps(activeStep)}</Box>}
    </Box>
  );
};

export default Decision;
