import PropTypes from "prop-types";
import React, { useState } from "react";
import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, SvgIcon, Box } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.primary.main, alpha(theme.palette.primary.main, 0.25)],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "40px",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    yaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    xaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}K` : `${value}`),
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const VerticalChart = (props) => {
  const [chartData] = useState({
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "PRODUCT B",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "PRODUCT C",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "PRODUCT D",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: ["01/01/2011 GMT", "01/02/2011 GMT", "01/03/2011 GMT", "01/04/2011 GMT", "01/05/2011 GMT", "01/06/2011 GMT"],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });
  const { chartSeries } = props;
  const chartOptions = useChartOptions();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Card sx={{ marginTop: "2rem", flex: 1 }}>
          <CardHeader
            action={
              <Button
                color="inherit"
                size="small"
                startIcon={
                  <SvgIcon fontSize="small">
                    <ArrowPathIcon />
                  </SvgIcon>
                }
              >
                Sync
              </Button>
            }
            title="Sales"
          />
          <CardContent>
            <ReactApexChart height={350} options={chartOptions} series={chartSeries} type="bar" width="100%" />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              color="inherit"
              endIcon={
                <SvgIcon fontSize="small">
                  <ArrowRightIcon />
                </SvgIcon>
              }
              size="small"
            >
              Overview
            </Button>
          </CardActions>
        </Card>
        <Box flex={1} ml={2} mt={4}>
          <Box id="chart">
            <Card>
              <ReactApexChart height={500} options={chartData.options} series={chartData.series} type="line" width="100%" />
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

VerticalChart.propTypes = {
  chartSeries: PropTypes.array.isRequired,
  sx: PropTypes.object,
};

export default VerticalChart;
