import React from "react";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import { TableBody, Typography } from "@mui/material";
import Profile from "./StudentStatus";
import EditSvg from "./EditSvg";

const StudentData = ({ student }) => {

// console.log(student?.registrationData)

  return (
    <>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead
            sx={{
              backgroundColor: "rgba(25, 104, 173, 1)",
              "@media (max-width: 768px)": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "25px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                About {student?.registrationData?.namePrefix?.label} {student?.registrationData?.firstName}{" "}
                {student?.registrationData?.lastName}
              </Typography>
              {/* <div style={{ marginLeft: "40px", marginTop: "3px" }}>
                <EditSvg />
              </div> */}
            </div>
          </TableHead>
          <TableBody>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Address Line 1
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.line1 || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Address Line 2
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.line2 || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Country
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.country?.label || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>City</Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.city?.label || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Date of birth
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.dob || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>Email</Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.infoEmail || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Dialing Code
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.dialingCode?.label || student?.registrationData?.dialingCode ||  "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Home Number
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.homeNo || "N/A"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid rgba(217, 217, 217, 1)" }}>
              <Typography sx={{ ml: "30px", mt: "15px", fontSize: "18px", fontWeight: "500", color: "rgba(0, 0, 0, 1)" }}>
                Parents Number
              </Typography>
              <Typography sx={{ ml: "30px", mb: "8px", fontSize: "16px", opacity: "0.9" }}>
                {student?.registrationData?.parentsNumber || student?.registrationData?.cellNo || "N/A"}
              </Typography>
            </div>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StudentData;
