import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "../../Images/AddIcon";
import React from "react";

const AddTaskButton = ({ handleOpen1 }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          backgroundColor: "rgb(25, 104, 173)",
          // ml: "300px",
          height: "55px",
          borderRadius: "1rem",
          transition: "width 0.3s ease",
          // "@media (max-width: 1440px)": {
          //   ml: "90px",
          // },
          // "@media (max-width: 1024px)": {
          //   ml: "20px",
          // },
        }}
      >
        <IconButton color="inherit" sx={{ margin: "0px 15px 0px 15px" }} onClick={handleOpen1}>
          <AddIcon />
          <Typography
            sx={{
              color: "white",
              fontWeight: "300px",
              marginLeft: "10px",
              mb: "2px",
            }}
          >
            Add Project
          </Typography>
        </IconButton>
      </Box>
    </>
  );
};

export default AddTaskButton;
