import { Box, Divider, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { studentFileDownloadPath } from "../../../utils/studentFileDownloadUtil";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const TestingData = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  console.log(student);
  return (
    <Stack
      sx={{
        //  display: "grid",
        // gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
        gap: "1rem",
        ["@media (max-width: 775px)"]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box>
        <DataWrapper heading={`Testing`}>
          {student?.registrationData.englishProficiency &&
            student?.registrationData.englishProficiency.map((item, index) => {
              return (
                <>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      English Proficiency:{" "}
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {item?.englishProficiency?.label ? item?.englishProficiency?.label : item?.englishProficiency || "N/A"}
                      </span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="englishProficiency"
                        text={item?.englishProficiency?.label ? item?.englishProficiency?.label : item?.englishProficiency || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      English Proficiency (Other):{" "}
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {item?.otherEnglishProficiency ? item?.otherEnglishProficiency : item?.otherEnglishProficiency || "N/A"}
                      </span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="otherEnglishProficiency"
                        text={item?.otherEnglishProficiency ? item?.otherEnglishProficiency : item?.otherEnglishProficiency || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Score: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.score || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="score"
                        text={item?.score || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Speaking: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.speaking || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="speaking"
                        text={item?.speaking || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Listening: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.listening || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="listening"
                        text={item?.listening || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Writing: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.writing || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="writing"
                        text={item?.writing || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Reading: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.reading || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="reading"
                        text={item?.reading || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    Date Of Test: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.dateOfTest || "N/A"}</span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="dateOfTest"
                        text={item?.dateOfTest || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      // borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    Upload Result:{" "}
                    {student.registrationData?.englishProficiency ? (
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {student.registrationData?.englishProficiency &&
                        student.registrationData?.[`englishProficiency.${index}.uploadTestResult`] ? (
                          <Link
                            target="_blank"
                            rel="noopener"
                            download
                            href={studentFileDownloadPath(
                              student?.registeration,
                              student.registrationData?.[`englishProficiency.${index}.uploadTestResult`],
                            )}
                          >
                            {student.registrationData?.[`englishProficiency.${index}.uploadTestResult`]}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    ) : (
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {appData && appData[`englishProficiency.${index}.uploadResult`] ? (
                          <Link
                            target="_blank"
                            rel="noopener"
                            download
                            href={studentFileDownloadPath(student?.registeration, appData[`englishProficiency.${index}.uploadResult`])}
                          >
                            {" "}
                            {appData[`englishProficiency.${index}.uploadResult`]}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    )}
                  </Typography>
                  {index < student?.registrationData?.englishProficiency?.length - 1 && (
                    <Divider style={{ backgroundColor: "black", height: "2px", marginBottom: "20px" }} />
                  )}
                </>
              );
            })}
        </DataWrapper>
      </Box>
    </Stack>
  );
};

export default TestingData;
