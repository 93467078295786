import { Box, Typography } from "@mui/material";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const Intake = ({ appData, student, confirm, data, copy, submitted, setRevertData, user, appCheck }) => {
  return (
    <DataWrapper heading={"Intake"}>
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Country Preference: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.countryPreference?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="countryPreference"
            text={appData?.countryPreference?.label || "N/A"}
          />
        )}
      </Typography> */}
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          {/* Intake: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.intake?.label || "N/A"}</span> */}
          Intake: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.intake?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="intake"
            text={appData?.intake?.label || "N/A"}
          />
        )}
      </Typography>
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Intake (Other):{" "}
          <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.otherIntake || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="otherIntake"
            text={student?.registrationData?.otherIntake || "N/A"}
          />
        )}
      </Typography> */}
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Year: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.year || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="year"
            text={appData?.year || "N/A"}
          />
        )}
      </Typography>
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Plan of action: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.planOfAction?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="planOfAction"
            text={appData?.planOfAction?.label || "N/A"}
          />
        )}
      </Typography> */}
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          National Rankings: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.nationalRankings || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="nationalRankings"
            text={appData?.nationalRankings || "N/A"}
          />
        )}
      </Typography> */}
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Tuition Fee: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.tuitionFee || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="tuitionFee"
            text={appData?.tuitionFee || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Application Fee: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.applicationFee || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="applicationFee"
            text={appData?.applicationFee || "N/A"}
          />
        )}
      </Typography>
    </DataWrapper>
  );
};

export default Intake;
