import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import WidgetContainer from "./WidgetContainer";
import { useMemo } from "react";

const ColumnContainer = ({
  column,
  addWidget,
  widgetsArray,
  dropWidgets,
  edit,
  allWidgets,
  open,
  setOpen,
  columnId,
  openDrawer,
  onDeleteLayout,
}) => {
  const widgetsId = useMemo(() => widgetsArray.map((widget) => widget.widget), [widgetsArray]);

  // const [open, setOpen] = useState(false);

  // const [columnId, setColumnId] = useState("");

  const { setNodeRef, listeners, attributes, transform, transition } = useSortable({
    id: column.id,
    data: {
      type: "column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  // const openDrawer = (id) => {
  //   setOpen(true);
  //   setColumnId(id);
  // };

  const widgets = [
    {
      id: "1",
      // img: "/assets/status-w.png",
      title: "Chart",
      name: "Total Leads",
    },
    // {
    //   id: "9",
    //   // img: "/assets/status-w.png",
    //   title: "Counter",
    //   name: "Total Applications",
    // },
    // {
    //   id: "10",
    //   // img: "/assets/status-w.png",
    //   title:"Counter",
    //   name: "Escalated",
    // },
    // {
    //   id: "11",
    //   // img: "/assets/status-w.png",
    //   title: "Counter",
    //   name: "Accepted Applications",
    // },
    {
      id: "2",
      // img: "/assets/status-w.png",
      title: "Chart",
      name: "Total Queries",
    },
    {
      id: "3",
      // img: "/assets/status-w.png",
      title: "Chart",
      name: "Registration",
    },
    {
      id: "4",
      // img: "/assets/status-w.png",
      title: "Graph",
      name: "Processing",
    },
    // {
    //   id: "5",
    //   // img: "/assets/status-w.png",
    //   title: "Graph",
    //   name: "Country Unipreference",
    // },
    // {
    //   id: "12",
    //   // img: "/assets/status-w.png",
    //   title: "Graph",
    //   name: "Uni Preference",
    // },
    // {
    //   id: "6",
    //   // img: "/assets/status-w.png",
    //   title: "Graph",
    //   name: "Graduation",
    // },
    // {
    //   id: "7",
    //   // img: "/assets/status-w.png",
    //   title: "Graph",
    //   name: "Application Offers",
    // },
    {
      id: "8",
      // img: "/assets/status-w.png",
      title: "Table",
      name: "Notification",
    },
  ];

  return (
    <Box
      ref={setNodeRef}
      style={style}
      sx={{
        width: "100%",
      }}
      key={column.id}
    >
      {edit && (
        <Box
          {...attributes}
          {...listeners}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "grab",
            border: "1px solid #000",
            p: 0.2,
          }}
        >
          <span>{column.title}</span>
          <Box
            sx={{
              display: "flex",
              gap: ".5rem",
            }}
          >
            <Tooltip title={"Add Column"} placement="top">
              <Box
                onClick={() => openDrawer(column.id)}
                sx={{
                  cursor: "pointer",
                  // width: "fit-content",
                  color: "primary.main",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    fontSize: ".8rem",
                    gap: ".5rem",
                  }}
                >
                  {/* Add Widget */}
                  <span
                    style={{
                      width: "1.2rem",
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </span>
              </Box>
            </Tooltip>
            <Tooltip title={"Delete Column"} placement="top">
              <Box
                onClick={() =>
                  onDeleteLayout({
                    type: "column",
                    id: column.id,
                  })
                }
                sx={{
                  cursor: "pointer",
                  // width: "fit-content",
                  color: "primary.main",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    fontSize: ".8rem",
                    gap: ".5rem",
                  }}
                >
                  {/* Delete Column */}
                  <span
                    style={{
                      width: "1.2rem",
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </span>
                </span>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )}
      <Box
        className={edit ? "animation" : ""}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <SortableContext items={widgetsId}>
          {widgetsArray?.map((widget) => (
            <Box key={widget.id}>
              <WidgetContainer dropWidgets={dropWidgets} widget={widget} onDeleteLayout={onDeleteLayout} edit={edit} />
            </Box>
          ))}
        </SortableContext>
      </Box>
      {open && (
        <Box
          sx={{
            // width: "30rem",
            width: "15rem",
            backgroundColor: "white",
            position: "fixed",
            overflowY: "scroll",
            top: "70px",
            right: 0,
            zIndex: 9999999,
            bottom: 0,
            p: 2,
          }}
        >
          <Box
            sx={{
              position: "fixed",
              zIndex: 99999999,
              backgroundColor: "white",
              width: "50%",
            }}
          >
            <Button
              sx={{
                pt: ".4rem",
              }}
              onClick={() => setOpen(false)}
            >
              <span
                style={{
                  width: "1.2rem",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
            </Button>
            <Typography
              sx={{
                fontSize: ".9rem",
                fontWeight: 600,
              }}
            >
              {" "}
              Click to add widget
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              mt: 12,
            }}
          >
            {widgets.map((widget) => (
              <Box
                key={widget.id}
                onClick={() => addWidget(columnId, widget.id, column.rowId)}
                sx={{
                  opacity: allWidgets.find((w) => w.widget === widget.id) ? 0.3 : 1,
                  cursor: allWidgets.find((w) => w.widget === widget.id) ? "not-allowed" : "pointer",
                }}
              >
                <Paper
                  sx={{
                    width: "12rem",
                    height: "5rem",
                  }}
                  elevation={3}
                >
                  <Typography
                    sx={{
                      fontSize: ".9rem",
                      fontWeight: 600,
                      textAlign: "center",
                      paddingBottom: "1rem",
                    }}
                  >
                    {widget.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: ".9rem",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {widget.title}
                  </Typography>
                </Paper>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ColumnContainer;
