import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getLoggedInUser } from "../../../utils/loggedInUser";

const OtherForm = ({ control, handleSubmit, goBack, nextStep, handleClose, setEnquiryData, setFormFile, enquiryData, createEnquiry, proceedData }) => {
  console.log("ed", proceedData);
  const methods = useForm({
    defaultValues: {
      comment: "",
      referredBy: "",
    },
  });
  const user = getLoggedInUser();

  const onSend = (data) => {
    const finalEnquiryData = enquiryData.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    // console.log("data", data);

    createEnquiry({
      ...finalEnquiryData,
      ...data,
      purpose: "Created",
      content: `${user?.name} generated this query`,
      assignTo: { id: user._id, name: user.name, email: user.email },
      QueryScore: proceedData?.leadScore,
      QueryCampaign: proceedData?.leadcampaign,
      CommentSheet: proceedData?.data?.commentSheet,
    });
    // console.log("hello");
    nextStep();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
        <Controller
          control={control}
          name="referredBy"
          render={({ field }) => (
            <TextField id="outlined-basic" label="Referred By" type="text" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <TextField label="Comments" id="outlined-basic" type="text" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        />
        <Box
          mt={2}
          sx={{
            width: "70%",
            position: "absolute",
            height: "7%",
            bottom: "3%",
          }}
        >
          <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
};

export default OtherForm;
