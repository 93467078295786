export const dialingCodeOptions = [
  { label: "Afghanistan (+93)", level: 0 },
  { label: "Albania (+355)", level: 1 },
  { label: "Algeria (+213)", level: 2 },
  { label: "American Samoa (+1-684)", level: 3 },
  { label: "Andorra (+376)", level: 4 },
  { label: "Angola (+244)", level: 5 },
  { label: "Anguilla (+1-264)", level: 6 },
  { label: "Antarctica (+672)", level: 7 },
  { label: "Antigua and Barbuda (+1-268)", level: 8 },
  { label: "Argentina (+54)", level: 9 },
  { label: "Armenia (+374)", level: 10 },
  { label: "Aruba (+297)", level: 11 },
  { label: "Australia (+61)", level: 12 },
  { label: "Austria (+43)", level: 13 },
  { label: "Azerbaijan (+994)", level: 14 },
  { label: "Bahamas (+1-242)", level: 15 },
  { label: "Bahrain (+973)", level: 16 },
  { label: "Bangladesh (+880)", level: 17 },
  { label: "Barbados (+1-246)", level: 18 },
  { label: "Belarus (+375)", level: 19 },
  { label: "Belgium (+32)", level: 20 },
  { label: "Belize (+501)", level: 21 },
  { label: "Benin (+229)", level: 22 },
  { label: "Bermuda (+1-441)", level: 23 },
  { label: "Bhutan (+975)", level: 24 },
  { label: "Bolivia (+591)", level: 25 },
  { label: "Bosnia and Herzegovina (+387)", level: 26 },
  { label: "Botswana (+267)", level: 27 },
  { label: "Brazil (+55)", level: 28 },
  { label: "British Indian Ocean Territory (+246)", level: 29 },
  { label: "British Virgin Islands (+1-284)", level: 30 },
  { label: "Brunei (+673)", level: 31 },
  { label: "Bulgaria (+359)", level: 32 },
  { label: "Burkina Faso (+226)", level: 33 },
  { label: "Burundi (+257)", level: 34 },
  { label: "Cambodia (+855)", level: 35 },
  { label: "Cameroon (+237)", level: 36 },
  { label: "Canada (+1)", level: 37 },
  { label: "Cape Verde (+238)", level: 38 },
  { label: "Cayman Islands (+1-345)", level: 39 },
  { label: "Central African Republic (+236)", level: 40 },
  { label: "Chad (+235)", level: 41 },
  { label: "Chile (+56)", level: 42 },
  { label: "China (+86)", level: 43 },
  { label: "Christmas Island (+61)", level: 44 },
  { label: "Cocos Islands (+61)", level: 45 },
  { label: "Colombia (+57)", level: 46 },
  { label: "Comoros (+269)", level: 47 },
  { label: "Cook Islands (+682)", level: 48 },
  { label: "Costa Rica (+506)", level: 49 },
  { label: "Croatia (+385)", level: 50 },
  { label: "Cuba (+53)", level: 51 },
  { label: "Curacao (+599)", level: 52 },
  { label: "Cyprus (+357)", level: 53 },
  { label: "Czech Republic (+420)", level: 54 },
  { label: "Democratic Republic of the Congo (+243)", level: 55 },
  { label: "Denmark (+45)", level: 56 },
  { label: "Djibouti (+253)", level: 57 },
  { label: "Dominica (+1-767)", level: 58 },
  { label: "Dominican Republic (+1-809, +1-829, +1-849)", level: 59 },
  { label: "East Timor (+670)", level: 60 },
  { label: "Ecuador (+593)", level: 61 },
  { label: "Egypt (+20)", level: 62 },
  { label: "El Salvador (+503)", level: 63 },
  { label: "Equatorial Guinea (+240)", level: 64 },
  { label: "Eritrea (+291)", level: 65 },
  { label: "Estonia (+372)", level: 66 },
  { label: "Ethiopia (+251)", level: 67 },
  { label: "Falkland Islands (+500)", level: 68 },
  { label: "Faroe Islands (+298)", level: 69 },
  { label: "Fiji (+679)", level: 70 },
  { label: "Finland (+358)", level: 71 },
  { label: "France (+33)", level: 72 },
  { label: "French Guiana (+594)", level: 73 },
  { label: "French Polynesia (+689)", level: 74 },
  { label: "Gabon (+241)", level: 75 },
  { label: "Gambia (+220)", level: 76 },
  { label: "Georgia (+995)", level: 77 },
  { label: "Germany (+49)", level: 78 },
  { label: "Ghana (+233)", level: 79 },
  { label: "Gibraltar (+350)", level: 80 },
  { label: "Greece (+30)", level: 81 },
  { label: "Greenland (+299)", level: 82 },
  { label: "Grenada (+1-473)", level: 83 },
  { label: "Guadeloupe (+590)", level: 84 },
  { label: "Guam (+1-671)", level: 85 },
  { label: "Guatemala (+502)", level: 86 },
  { label: "Guernsey (+44-1481)", level: 87 },
  { label: "Guinea (+224)", level: 88 },
  { label: "Guinea-Bissau (+245)", level: 89 },
  { label: "Guyana (+592)", level: 90 },
  { label: "Haiti (+509)", level: 91 },
  { label: "Honduras (+504)", level: 92 },
  { label: "Hong Kong (+852)", level: 93 },
  { label: "Hungary (+36)", level: 94 },
  { label: "Iceland (+354)", level: 95 },
  { label: "India (+91)", level: 96 },
  { label: "Indonesia (+62)", level: 97 },
  { label: "Iran (+98)", level: 98 },
  { label: "Iraq (+964)", level: 99 },
  { label: "Ireland (+353)", level: 100 },
  { label: "Pakistan (+92)", level: 101 },
];
