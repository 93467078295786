import React from "react";
import {
  Button,
  Typography,
  TextField,
  Stepper,
  StepLabel,
  Grid,
  Autocomplete,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { useForm, FormProvider, useFormContext, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { branchesOptions } from "../../../Data/BranchesOptions";

const Personal = ({ control, handleSubmit, goBack, nextStep, handleClose, setEnquiryData, setFormFile, enquiryEditData }) => {
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [country, setCountry] = useState("");
  const [uploadRefusalLetter, setUploadRefusalLetter] = useState("");
  const [visaRefusal, setVisaRefusal] = useState(enquiryEditData?.enquiryData?.visaRefusal);
  const [showOtherField, setShowOtherField] = useState("");
  const uploadLetter = (e) => {
    let file = e.target.files[0];
    setUploadRefusalLetter(file.name);
    setFormFile((prevData) => {
      return { ...prevData, uploadRefusalLetter: file };
    });
  };

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data?.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.iso2,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg==");

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`https://api.countrystatecity.in/v1/countries/${countryOfBirth || country}/cities`, requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCities(
          data?.map((item) => ({
            label: item.name,
            value: item.name,
            level: item.iso2,
          })),
        ),
      )
      .catch((error) => console.log("error", error));
  }, [countryOfBirth, country]);
  const StudentName = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();
    const namePrefixOptions = [
      { label: "Mr", level: 1 },
      { label: "Ms", level: 2 },
      { label: "Mrs", level: 3 },
    ];
    return (
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="namePrefix"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.namePrefix : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={namePrefixOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Name Prefix" />}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="firstName"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.firstName : ""}
              render={({ field }) => (
                <TextField
                  label="First Name"
                  error={Boolean(errors?.firstName)}
                  helperText={errors?.firstName?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                  sx={{ marginLeft: 2 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="middleName"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.middleName : ""}
              render={({ field }) => (
                <TextField
                  label="Middle Name"
                  error={Boolean(errors?.middleName)}
                  helperText={errors?.middleName?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                  sx={{ marginLeft: 2 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="lastName"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.lastName : ""}
              render={({ field }) => (
                <TextField
                  label="Last Name"
                  error={Boolean(errors?.lastName)}
                  helperText={errors?.lastName?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                  sx={{ marginLeft: 2 }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography fontSize="14px">Date Of Birth</Typography>
          <Controller
            control={control}
            name="dob"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.dob : ""}
            render={({ field }) => (
              <TextField
                error={Boolean(errors?.dob)}
                helperText={errors?.dob?.message}
                id="outlined-basic"
                type="date"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Box>
        <Controller
          control={control}
          name="infoEmail"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.infoEmail : ""}
          render={({ field }) => (
            <TextField
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
              label="Email"
              id="outlined-basic"
              type="email"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="branch"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.branch : ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={branchesOptions}
              onChange={(event, data) => onChange(data)}
              getOptionLabel={(option) => option.text || option} // Handle case when option.text is undefined
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Branch" />}
              {...field}
            />
          )}
        />
      </Stack>
    );
  };

  const PhoneNumbers = () => {
    const dialingCodeOptions = [
      { label: "Afghanistan (+93)", level: 0 },
      { label: "Albania (+355)", level: 1 },
      { label: "Algeria (+213)", level: 2 },
      { label: "American Samoa (+1-684)", level: 3 },
      { label: "Andorra (+376)", level: 4 },
      { label: "Angola (+244)", level: 5 },
      { label: "Anguilla (+1-264)", level: 6 },
      { label: "Antarctica (+672)", level: 7 },
      { label: "Antigua and Barbuda (+1-268)", level: 8 },
      { label: "Argentina (+54)", level: 9 },
      { label: "Armenia (+374)", level: 10 },
      { label: "Aruba (+297)", level: 11 },
      { label: "Australia (+61)", level: 12 },
      { label: "Austria (+43)", level: 13 },
      { label: "Azerbaijan (+994)", level: 14 },
      { label: "Bahamas (+1-242)", level: 15 },
      { label: "Bahrain (+973)", level: 16 },
      { label: "Bangladesh (+880)", level: 17 },
      { label: "Barbados (+1-246)", level: 18 },
      { label: "Belarus (+375)", level: 19 },
      { label: "Belgium (+32)", level: 20 },
      { label: "Belize (+501)", level: 21 },
      { label: "Benin (+229)", level: 22 },
      { label: "Bermuda (+1-441)", level: 23 },
      { label: "Bhutan (+975)", level: 24 },
      { label: "Bolivia (+591)", level: 25 },
      { label: "Bosnia and Herzegovina (+387)", level: 26 },
      { label: "Botswana (+267)", level: 27 },
      { label: "Brazil (+55)", level: 28 },
      { label: "British Indian Ocean Territory (+246)", level: 29 },
      { label: "British Virgin Islands (+1-284)", level: 30 },
      { label: "Brunei (+673)", level: 31 },
      { label: "Bulgaria (+359)", level: 32 },
      { label: "Burkina Faso (+226)", level: 33 },
      { label: "Burundi (+257)", level: 34 },
      { label: "Cambodia (+855)", level: 35 },
      { label: "Cameroon (+237)", level: 36 },
      { label: "Canada (+1)", level: 37 },
      { label: "Cape Verde (+238)", level: 38 },
      { label: "Cayman Islands (+1-345)", level: 39 },
      { label: "Central African Republic (+236)", level: 40 },
      { label: "Chad (+235)", level: 41 },
      { label: "Chile (+56)", level: 42 },
      { label: "China (+86)", level: 43 },
      { label: "Christmas Island (+61)", level: 44 },
      { label: "Cocos Islands (+61)", level: 45 },
      { label: "Colombia (+57)", level: 46 },
      { label: "Comoros (+269)", level: 47 },
      { label: "Cook Islands (+682)", level: 48 },
      { label: "Costa Rica (+506)", level: 49 },
      { label: "Croatia (+385)", level: 50 },
      { label: "Cuba (+53)", level: 51 },
      { label: "Curacao (+599)", level: 52 },
      { label: "Cyprus (+357)", level: 53 },
      { label: "Czech Republic (+420)", level: 54 },
      { label: "Democratic Republic of the Congo (+243)", level: 55 },
      { label: "Denmark (+45)", level: 56 },
      { label: "Djibouti (+253)", level: 57 },
      { label: "Dominica (+1-767)", level: 58 },
      { label: "Dominican Republic (+1-809, +1-829, +1-849)", level: 59 },
      { label: "East Timor (+670)", level: 60 },
      { label: "Ecuador (+593)", level: 61 },
      { label: "Egypt (+20)", level: 62 },
      { label: "El Salvador (+503)", level: 63 },
      { label: "Equatorial Guinea (+240)", level: 64 },
      { label: "Eritrea (+291)", level: 65 },
      { label: "Estonia (+372)", level: 66 },
      { label: "Ethiopia (+251)", level: 67 },
      { label: "Falkland Islands (+500)", level: 68 },
      { label: "Faroe Islands (+298)", level: 69 },
      { label: "Fiji (+679)", level: 70 },
      { label: "Finland (+358)", level: 71 },
      { label: "France (+33)", level: 72 },
      { label: "French Guiana (+594)", level: 73 },
      { label: "French Polynesia (+689)", level: 74 },
      { label: "Gabon (+241)", level: 75 },
      { label: "Gambia (+220)", level: 76 },
      { label: "Georgia (+995)", level: 77 },
      { label: "Germany (+49)", level: 78 },
      { label: "Ghana (+233)", level: 79 },
      { label: "Gibraltar (+350)", level: 80 },
      { label: "Greece (+30)", level: 81 },
      { label: "Greenland (+299)", level: 82 },
      { label: "Grenada (+1-473)", level: 83 },
      { label: "Guadeloupe (+590)", level: 84 },
      { label: "Guam (+1-671)", level: 85 },
      { label: "Guatemala (+502)", level: 86 },
      { label: "Guernsey (+44-1481)", level: 87 },
      { label: "Guinea (+224)", level: 88 },
      { label: "Guinea-Bissau (+245)", level: 89 },
      { label: "Guyana (+592)", level: 90 },
      { label: "Haiti (+509)", level: 91 },
      { label: "Honduras (+504)", level: 92 },
      { label: "Hong Kong (+852)", level: 93 },
      { label: "Hungary (+36)", level: 94 },
      { label: "Iceland (+354)", level: 95 },
      { label: "India (+91)", level: 96 },
      { label: "Indonesia (+62)", level: 97 },
      { label: "Iran (+98)", level: 98 },
      { label: "Iraq (+964)", level: 99 },
      { label: "Ireland (+353)", level: 100 },
      { label: "Pakistan (+92)", level: 101 },
    ];
    const {
      // control,
      formState: { errors },
    } = useFormContext();
    return (
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="dialingCode"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.dialingCode : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dialingCodeOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Dialing Code" />}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              control={control}
              name="cellNo"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.cellNo : ""}
              render={({ field }) => (
                <TextField
                  label="Cell Number"
                  error={Boolean(errors?.cellNo)}
                  helperText={errors?.cellNo?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  {...field}
                  sx={{ width: "97.3%", marginLeft: 2 }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          control={control}
          name="homeNo"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.homeNo : ""}
          render={({ field }) => (
            <TextField
              label="Home Number"
              error={Boolean(errors?.homeNo)}
              helperText={errors?.homeNo?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
      </Stack>
    );
  };

  const Address = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    function selectedCountry(data) {
      setCountry(data.level);
    }

    return (
      <Stack spacing={2}>
        <Controller
          control={control}
          name="line1"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.line1 : ""}
          render={({ field }) => (
            <TextField
              label="Line 1"
              error={Boolean(errors?.line1)}
              helperText={errors?.line1?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="line2"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.line2 : ""}
          render={({ field }) => (
            <TextField
              label="Line 2"
              error={Boolean(errors?.line2)}
              helperText={errors?.line2?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Stack direction="row" spacing={2}>
          <Controller
            control={control}
            name="country"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.country : ""}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCountries}
                onChange={(_, data) => (onChange(data), selectedCountry(data))}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country" />}
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.city : ""}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCities}
                onChange={(_, data) => onChange(data)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="City" />}
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Stack>
      </Stack>
    );
  };

  const PassportInfo = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();
    const dualNationality = [
      { label: "Citizen or National", level: 1 },
      { label: "Dual citizen", level: 2 },
      { label: "Permanent resident", level: 3 },
      { label: "Refugee Or asylee", level: 4 },
    ];
    const [dualNationalityState, setDualNationalityState] = useState(false);

    function showMoreField(data) {
      if (data.label === "Dual citizen") {
        setDualNationalityState(true);
      } else {
        setDualNationalityState(false);
      }
    }

    function selectedCountry(data) {
      setCountryOfBirth(data.level);
    }

    return (
      <Stack spacing={2}>
        <Controller
          control={control}
          name="countryOfCitizenship"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.countryOfCitizenship : ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listOfCountries}
              onChange={(_, data) => onChange(data)}
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country Of Citizenship" />}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="passportNo"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.passportNo : ""}
          render={({ field }) => (
            <TextField
              label="Passport Number"
              error={Boolean(errors.passportNo)}
              helperText={errors.passportNo?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="birthCountry"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.birthCountry : ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listOfCountries}
              onChange={(_, data) => (onChange(data), selectedCountry(data))}
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Birth Country" />}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="birthCity"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.birthCity : ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listOfCities}
              onChange={(_, data) => (onChange(data), selectedCountry(data))}
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="City of birth" />}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="dualNationality"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.dualNationality : ""}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={dualNationality}
              onChange={(_, data) => (onChange(data), showMoreField(data))}
              renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Select your citizenship status" />}
              {...field}
            />
          )}
        />
        {dualNationalityState && (
          <Controller
            control={control}
            name="secondCountry"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.secondCountry : ""}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listOfCountries}
                onChange={(_, data) => onChange(data)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Second Country" />}
                {...field}
                sx={{ zIndex: "2" }}
              />
            )}
          />
        )}
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
      </Stack>
    );
  };

  const VisaRefusal = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();
    const visaTypes = [
      { label: "Visit Visa", level: 1 },
      { label: "Student Visa", level: 2 },
      { label: "Other", level: 3 },
    ];
    const handleChange = (event) => {
      setVisaRefusal(event.target.value);
    };

    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setShowOtherField(data?.label);
      } else {
        setShowOtherField("");
      }
    };

    return (
      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Visa Refusal</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={visaRefusal}
            label="Visa Refusal"
            onChange={handleChange}
          >
            <MenuItem value="yes">YES</MenuItem>
            <MenuItem value="no">NO</MenuItem>
          </Select>
        </FormControl>
        {visaRefusal === "yes" && (
          <Stack spacing={2}>
            <Controller
              control={control}
              name="visaCountryName"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.visaCountryName : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listOfCountries}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Country Name" />}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="typeOfVisa"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.typeOfVisa : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={visaTypes}
                  onChange={(_, data) => (onChange(data), addOtherField(data))}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Type Of Visa" />}
                  {...field}
                />
              )}
            />
            {showOtherField === "Other" && (
              <Controller
                control={control}
                name="otherTypeOfVisa"
                defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.otherTypeOfVisa : ""}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors.otherTypeOfVisa)}
                    helperText={errors.otherTypeOfVisa?.message}
                    id="outlined-basic"
                    type="text"
                    label="Other Type Of Visa"
                    variant="outlined"
                    {...field}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            )}
            <Box sx={{ width: "100%" }}>
              <Typography>Date Of Visa Refusal</Typography>
              <Controller
                control={control}
                name="dateOfVisaRefusal"
                defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.dateOfVisaRefusal : ""}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors.dateOfVisaRefusal)}
                    helperText={errors.dateOfVisaRefusal?.message}
                    id="outlined-basic"
                    type="date"
                    variant="outlined"
                    {...field}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="reasonOfVisaRefusal"
              defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.reasonOfVisaRefusal : ""}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.reasonOfVisaRefusal)}
                  helperText={errors.reasonOfVisaRefusal?.message}
                  id="outlined-basic"
                  type="text"
                  variant="outlined"
                  label="Reason Of Visa Refusal"
                  {...field}
                />
              )}
            />

            <Box sx={{ width: "100%" }}>
              <Typography sx={{ pb: 1 }}>Upload Refusal Letter</Typography>
              <Button
                sx={{
                  zIndex: 2,
                  py: 1,
                  width: "100%",
                }}
                component="label"
                variant="outlined"
                color="inherit"
              >
                {/* {uploadRefusalLetter ? uploadRefusalLetter : "Upload Refusal Letter"} */}
                {uploadRefusalLetter ? uploadRefusalLetter : "Upload Refusal Letter"}
                <input type="file" hidden onChange={uploadLetter} />
              </Button>
            </Box>
          </Stack>
        )}
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
        <Typography></Typography>
      </Stack>
    );
  };
  const Guardian = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    return (
      <Stack spacing={2}>
        <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
          <Controller
            control={control}
            name="guardianFirstName"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianFirstName : ""}
            render={({ field }) => (
              <TextField
                label="First Name"
                error={Boolean(errors.guardianFirstName)}
                helperText={errors.guardianFirstName?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="guardianMiddleName"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianMiddleName : ""}
            render={({ field }) => (
              <TextField
                label="Middle Name"
                error={Boolean(errors.guardianMiddleName)}
                helperText={errors.guardianMiddleName?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="guardianLastName"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianLastName : ""}
            render={({ field }) => (
              <TextField
                label="Last Name"
                error={Boolean(errors.guardianLastName)}
                helperText={errors.guardianLastName?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Controller
            control={control}
            name="profession"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.profession : ""}
            render={({ field }) => (
              <TextField
                label="Profession"
                error={Boolean(errors.profession)}
                helperText={errors.profession?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="monthlyIncome"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.monthlyIncome : ""}
            render={({ field }) => (
              <TextField
                label="Monthly Income"
                error={Boolean(errors.monthlyIncome)}
                helperText={errors.monthlyIncome?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="guardianAddress"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianAddress : ""}
          render={({ field }) => (
            <TextField
              label="Address"
              error={Boolean(errors.guardianAddress)}
              helperText={errors.guardianAddress?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
              sx={{ width: "100%" }}
            />
          )}
        />
        <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
          <Controller
            control={control}
            name="guardianTelephoneNo"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianTelephoneNo : ""}
            render={({ field }) => (
              <TextField
                label="Telephone No"
                error={Boolean(errors.guardianTelephoneNo)}
                helperText={errors.guardianTelephoneNo?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="guardianCellNo"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianCellNo : ""}
            render={({ field }) => (
              <TextField
                label="Cell No"
                error={Boolean(errors.guardianCellNo)}
                helperText={errors.guardianCellNo?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="guardianAlternateNo"
            defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianAlternateNo : ""}
            render={({ field }) => (
              <TextField
                label="Alternate No"
                error={Boolean(errors.guardianAlternateNo)}
                helperText={errors.guardianAlternateNo?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="guardianEmail"
          defaultValue={enquiryEditData?.enquiryData ? enquiryEditData?.enquiryData?.guardianEmail : ""}
          render={({ field }) => (
            <TextField
              label="E-mail"
              error={Boolean(errors.guardianEmail)}
              helperText={errors.guardianEmail?.message}
              id="outlined-basic"
              type="email"
              variant="outlined"
              {...field}
              sx={{ width: "100%" }}
            />
          )}
        />
      </Stack>
    );
  };

  const methods = useForm({
    defaultValues: {
      namePrefix: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      infoEmail: "",
      branch: "",
      dialingCode: "",
      cellNo: "",
      homeNo: "",
      line1: "",
      line2: "",
      country: "",
      countryOfCitizenship: "",
      passportNo: "",
      birthCountry: "",
      dualNationality: "",
      visaCountryName: "",
      typeOfVisa: "",
      otherTypeOfVisa: "",
      dateOfVisaRefusal: "",
      reasonOfVisaRefusal: "",
      uploadRefusalLetter: "",
      secondCountry: "",
      birthCity: "",
      city: "",
      guardianFirstName: "",
      guardianMiddleName: "",
      guardianLastName: "",
      profession: "",
      monthlyIncome: "",
      guardianAddress: "",
      guardianTelephoneNo: "",
      guardianCellNo: "",
      guardianAlternateNo: "",
      guardianEmail: "",
    },
  });
  const nextForm = () => {
    setActiveSteps(activeSteps + 1);
  };
  const onSubmit = (data) => {
    if (activeSteps === steps.length - 1) {
      setEnquiryData((prevData) => [...prevData, data, { uploadRefusalLetter: uploadRefusalLetter }, { visaRefusal: visaRefusal }]);
      // onSubmit(data);
    } else {
      nextForm();
    }
    if (activeSteps === steps.length - 1) {
      nextStep();
    }
  };

  const [activeSteps, setActiveSteps] = useState(0);

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return <StudentName />;
      case 1:
        return <PhoneNumbers />;
      case 2:
        return <Guardian />;
      case 3:
        return <Address />;
      case 4:
        return <PassportInfo />;
      case 5:
        return <VisaRefusal />;

      default:
        return nextStep();
    }
  };

  const steps = ["Student Name", "Contact Details", "Guardian", "Address", "Geography and Nationality", "Visa Refusal"];
  return (
    <Box mt={2} sx={{ width: "100%", display: "flex", gap: 4 }}>
      <Stepper activeStep={activeSteps} orientation="vertical">
        {steps.map((label, idx) => (
          <StepLabel key={label}>
            <Typography fontSize="1rem" fontWeight={activeSteps === idx ? "bolder" : ""}>
              {label}
            </Typography>
          </StepLabel>
        ))}
      </Stepper>
      <Box
        sx={{
          width: "100%",
          mt: "-1.3rem",
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
            {handleSteps(activeSteps)}
            <Box
              mt={2}
              sx={{
                width: "70%",
                position: "absolute",
                height: "7%",
                bottom: "3%",
                left: "2%",
                zIndex: 1,
              }}
            >
              <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
                {activeSteps > 0 && (
                  <Button variant="outlined" onClick={() => setActiveSteps(activeSteps - 1)}>
                    Back
                  </Button>
                )}
                {activeSteps < 1 && (
                  <Button variant="outlined" onClick={goBack}>
                    Back
                  </Button>
                )}

                <Button variant="contained" type="submit">
                  Next
                </Button>
                {/* {activeSteps === steps.length - 1 ? "Submit" : "Next"} */}

                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default Personal;
