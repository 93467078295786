import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";

function PrivateRoute(props) {
  const location = useLocation();
  const history = useHistory();

  const getPrivateRoutes = async () => {
    props.getPrivateRoute("GoogleUser");
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("access_token");
    console.log(token);

    if (token) {
      localStorage.setItem("access_token", token);
      getPrivateRoutes(token);
    }
  }, [location]);

  useEffect(() => {
    if (props.actionType === "PRIVATEROUTE_READ_SUCCESS") {
      console.log(props.privateRoute);
      localStorage.setItem("user", JSON.stringify(props.privateRoute));
      history.push("/");
    } else if (props.actionType === "PRIVATEROUTE_READ_FAILURE") {
      localStorage.removeItem("access_token");
      history.push("/login");
    }
  }, [props.actionType]);

  return (
    <div>
      <h1>""</h1>
    </div>
  );
}

const privateRouteContainer = CRUDFunction(PrivateRoute, "privateRoute", null, null, null, "user", null);
export default privateRouteContainer;
