import React from 'react'

const NotificationIcon = ({color}) => {
  return (
    <div>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.8309 8.16748C4.8309 4.75362 7.60454 1.97998 11.0184 1.97998C14.4231 1.97998 17.2059 4.76278 17.2059 8.16748V10.8166C17.2059 11.0136 17.2505 11.3019 17.335 11.6099C17.4184 11.9142 17.5262 12.1847 17.6243 12.3564L18.6754 14.1021C19.5055 15.4856 18.842 17.2878 17.3127 17.7941C13.2208 19.161 8.80576 19.1608 4.71387 17.7937L4.71132 17.7929C3.93912 17.5315 3.36 16.9911 3.10108 16.2918C2.84185 15.5917 2.93003 14.8037 3.35265 14.1014L4.40537 12.3529C4.40561 12.3525 4.40584 12.3522 4.40607 12.3518C4.50645 12.183 4.61641 11.9135 4.70126 11.6079C4.78637 11.3013 4.8309 11.0134 4.8309 10.8166V8.16748ZM11.0184 3.35498C8.36393 3.35498 6.2059 5.51301 6.2059 8.16748V10.8166C6.2059 11.1791 6.13126 11.597 6.02616 11.9756C5.92101 12.3545 5.77003 12.7491 5.58643 13.057L5.58489 13.0596L4.53082 14.8103C4.53078 14.8103 4.53085 14.8102 4.53082 14.8103C4.3027 15.1895 4.28997 15.5428 4.39052 15.8144C4.49128 16.0865 4.73222 16.348 5.15092 16.49C8.95998 17.7623 13.069 17.7622 16.878 16.4896L16.8801 16.4889C17.5683 16.2614 17.8759 15.4428 17.4967 14.81C17.4966 14.8099 17.4968 14.8102 17.4967 14.81L16.4361 13.0486C16.26 12.7434 16.1126 12.3515 16.0089 11.9736C15.9055 11.5964 15.8309 11.1789 15.8309 10.8166V8.16748C15.8309 5.52218 13.6637 3.35498 11.0184 3.35498Z" fill="#434343"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.68242 2.68247C9.04858 1.74813 9.95764 1.09082 11.0184 1.09082C12.0791 1.09082 12.9881 1.74813 13.3543 2.68247C13.4499 2.92637 13.3985 3.20339 13.2219 3.39686C13.0453 3.59032 12.7741 3.6666 12.5225 3.59356C12.2682 3.51973 12.0116 3.46402 11.7515 3.43249L11.7489 3.43218C10.9565 3.33313 10.2078 3.39192 9.51443 3.59349C9.26285 3.66663 8.99156 3.59041 8.81487 3.39694C8.63819 3.20348 8.58682 2.92641 8.68242 2.68247Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.95581 17.4716C8.95581 18.0331 9.18938 18.5571 9.56111 18.9288C9.93284 19.3006 10.4568 19.5341 11.0183 19.5341C12.1511 19.5341 13.0808 18.6045 13.0808 17.4716H14.4558C14.4558 19.3638 12.9105 20.9091 11.0183 20.9091C10.0765 20.9091 9.20711 20.5194 8.58884 19.9011C7.97057 19.2829 7.58081 18.4135 7.58081 17.4716H8.95581Z" fill={color}/>
</svg>

        </div>
  )
}

export default NotificationIcon