export const alevelSubjectList = [
  { label: "Accounting", value: "Accounting_-_9706", level: 1 },
  { label: "Afrikaans - Language (AS Level only)", value: "Afrikaans_-_Language_(AS_Level_only)_-_8679", level: 2 },
  { label: "Arabic", value: "Arabic_-_9680", level: 3 },
  { label: "Arabic - Language (AS Level only)", value: "Arabic_-_Language_(AS_Level_only)_-_8680", level: 4 },
  { label: "Art & Design ", value: "Art_&_Design_-_9479", level: 5 },
  { label: "Biblical Studies New", value: "Biblical_Studies_(9484)_New", level: 6 },
  { label: "Biology", value: "Biology_-_9700", level: 7 },
  { label: "Business", value: "Business_(9609)", level: 8 },
  { label: "Chemistry", value: "Chemistry_-_9701", level: 9 },
  {
    label: "Chinese - Language & Literature (A Level only) New",
    value: "Chinese_-_Language_&_Literature_(A_Level_only)_-_9868_New",
    level: 10,
  },

  { label: "Chinese - Language (AS Level only)", value: "Chinese_-_Language_(AS_Level_only)_-_8681", level: 11 },
  { label: "Chinese (A Level only)", value: "Chinese_(A_Level_only)_-_9715", level: 12 },
  { label: "Chinese Language (AS Level) New", value: "Chinese_Language_(AS_Level)_-_8238_New", level: 13 },
  { label: "Classical Studies", value: "Classical_Studies_-_9274", level: 14 },
  { label: "Computer Science", value: "Computer_Science_-_9618", level: 15 },
  { label: "Design & Technology", value: "Design_&_Technology_-_9705", level: 16 },
  { label: "Design & Textiles", value: "Design_&_Textiles_-_9631", level: 17 },
  { label: "Digital Media & Design", value: "Digital_Media_&_Design_-_9481", level: 18 },
  { label: "Divinity (A Level only)", value: "Divinity_(A_Level_only)_-_9011", level: 19 },
  { label: "Divinity (AS Level only)", value: "Divinity_(AS_Level_only)_-_8041", level: 20 },
  { label: "Drama", value: "Drama_-_9482", level: 21 },
  { label: "Economics", value: "Economics_-_9708", level: 22 },
  {
    label: "English - Language and Literature (AS Level only)",
    value: "English_-_Language_and_Literature_(AS_Level_only)_-_8695",
    level: 23,
  },
  { label: "English - Literature", value: "English_-_Literature_-_9695", level: 24 },
  { label: "English General Paper (AS Level only)", value: "English_General_Paper_(AS_Level_only)_-_8021", level: 25 },
  { label: "English Language", value: "English_Language_-_9093", level: 26 },
  { label: "Environmental Management (AS only)", value: "Environmental_Management_(AS_only)_-_8291", level: 27 },
  { label: "French - Language (AS Level only)", value: "French_-_Language_(AS_Level_only)_-_8682", level: 28 },
  { label: "French (A Level only)", value: "French_(A_Level_only)_-_9716", level: 29 },
  { label: "French Language & Literature", value: "French_Language_&_Literature_-_9898", level: 30 },
  { label: "French Language (AS Level)", value: "French_Language_(AS_Level)_-_8028", level: 31 },
  { label: "Geography", value: "Geography_-_9696", level: 32 },
  { label: "German", value: "German_-_9897", level: 33 },
  { label: "German - Language (AS Level only)", value: "German_-_Language_(AS_Level_only)_-_8683", level: 34 },
  { label: "German - Language (AS Level)", value: "German_-_Language_(AS_Level)_-_8027", level: 35 },
  { label: "German (A Level only)", value: "German_(A_Level_only)_-_9717", level: 36 },
  { label: "Global Perspectives & Research", value: "Global_Perspectives_&_Research_-_9239", level: 37 },
  { label: "Hindi - Language (AS Level only)", value: "Hindi_-_Language_(AS_Level_only)_-_8687", level: 38 },
  { label: "Hindi - Literature (AS Level only)", value: "Hindi_-_Literature_(AS_Level_only)_-_8675", level: 39 },
  { label: "Hindi (A Level only)", value: "Hindi_(A_Level_only)_-_9687", level: 40 },
  { label: "Hinduism", value: "Hinduism_-_9487", level: 41 },
  { label: "History", value: "History_-_9489", level: 42 },
  { label: "Information Technology", value: "Information_Technology_-_9626", level: 43 },
  { label: "Islamic Studies", value: "Islamic_Studies_-_9488", level: 44 },
  { label: "Japanese Language (AS Level only)", value: "Japanese_Language_(AS_Level_only)_-_8281", level: 45 },
  { label: "Law", value: "Law_-_9084", level: 46 },
  { label: "Marine Science", value: "Marine_Science_-_9693", level: 47 },
  { label: "Mathematics", value: "Mathematics_-_9709", level: 48 },
  { label: "Mathematics - Further", value: "Mathematics_-_Further_-_9231", level: 49 },
  { label: "Media Studies", value: "Media_Studies_-_9607", level: 50 },
  { label: "Music", value: "Music_-_9483", level: 51 },
  { label: "Physical Education", value: "Physical_Education_-_9396", level: 52 },
  { label: "Physics", value: "Physics_-_9702", level: 53 },
  { label: "Portuguese - Language (AS Level only)", value: "Portuguese_-_Language_(AS_Level_only)_-_8684", level: 54 },
  { label: "Portuguese (A Level only)", value: "Portuguese_(A_Level_only)_-_9718", level: 55 },
  { label: "Psychology", value: "Psychology_-_9990", level: 56 },
  { label: "Sociology", value: "Sociology_-_9699", level: 57 },
  { label: "Spanish - First Language (AS Level only)", value: "Spanish_-_First_Language_(AS_Level_only)_-_8665", level: 58 },
  {
    label: "Spanish - Language & Literature (A Level only) New",
    value: "Spanish_-_Language_&_Literature_(A_Level_only)_-_9844_New",
    level: 59,
  },
  { label: "Spanish - Language (AS Level only)", value: "Spanish_-_Language_(AS_Level_only)_-_8685", level: 60 },
  { label: "Spanish - Literature (AS Level only)", value: "Spanish_-_Literature_(AS_Level_only)_-_8673", level: 61 },
  { label: "Spanish (A Level only)", value: "Spanish_(A_Level_only)_-_9719", level: 62 },
  { label: "Spanish Language (AS Level) New", value: "Spanish_Language_(AS_Level)_-_8022_New", level: 63 },
  {
    label: "Sport & Physical Education (AS Level only) New",
    value: "Sport_&_Physical_Education_(AS_Level_only)_(8386)_New",
    level: 64,
  },
  { label: "Tamil", value: "Tamil_-_9689", level: 65 },
  { label: "Tamil - Language", value: "Tamil_-_Language_-_8689", level: 66 },
  { label: "Thinking Skills ", value: "Thinking_Skills_-_9694", level: 67 },
  { label: "Travel & Tourism", value: "Travel_&_Tourism_-_9395", level: 68 },
  { label: "Urdu - Language (AS Level only)", value: "Urdu_-_Language_(AS_Level_only)_-_8686", level: 69 },
  { label: "Urdu - Pakistan only (A Level only)", value: "Urdu_-_Pakistan_only_(A_Level_only)_-_9686", level: 70 },
  { label: "Urdu (A Level only)", value: "Urdu_(A_Level_only)_-_9676", level: 71 },
  { label: "Other", value: "Other", level: 72 },
];

export const olevelSubjectList = [
  { label: "Accounting", value: "Accounting_-_7707", level: 1 },
  { label: "Additional Mathematics", value: "Additional_Mathematics_-_4037", level: 2 },
  { label: "Agriculture", value: "Agriculture_-_5038", level: 3 },
  { label: "Arabic", value: "Arabic_-_3180", level: 4 },
  { label: "Art & Design", value: "Art_&_Design_-_6090", level: 5 },
  { label: "Bangladesh Studies", value: "Bangladesh_Studies_-_7094", level: 6 },
  { label: "Bengali", value: "Bengali_-_3204", level: 7 },
  { label: "Biblical Studies New", value: "Biblical_Studies_-_2035_New", level: 8 },
  { label: "Biology", value: "Biology_-_5090", level: 9 },
  { label: "Business Studies", value: "Business_Studies_-_7115", level: 10 },
  { label: "Chemistry", value: "Chemistry_-_5070", level: 11 },
  { label: "Commerce", value: "Commerce_-_7100", level: 12 },
  { label: "Computer Science", value: "Computer_Science_-_2210", level: 13 },
  { label: "Design & Technology", value: "Design_&_Technology_-_6043", level: 14 },
  { label: "Economics", value: "Economics_-_2281", level: 15 },
  { label: "English Language", value: "English_Language_-_1123", level: 16 },
  { label: "Environmental Management", value: "Environmental_Management_-_5014", level: 17 },
  { label: "Fashion & Textiles", value: "Fashion_&_Textiles_-_6130", level: 18 },
  { label: "Food & Nutrition", value: "Food_&_Nutrition_-_6065", level: 19 },
  { label: "French", value: "French_-_3015", level: 20 },
  { label: "Geography", value: "Geography_-_2217", level: 21 },
  { label: "Global Perspectives", value: "Global_Perspectives_-_2069", level: 22 },
  { label: "Hinduism", value: "Hinduism_-_2055", level: 23 },
  { label: "History", value: "History_-_2147", level: 24 },
  { label: "Islamic Studies", value: "Islamic_Studies_-_2068", level: 25 },
  { label: "Islamiyat", value: "Islamiyat_-_2058", level: 26 },
  { label: "Literature in English", value: "Literature_in_English_-_2010", level: 27 },
  { label: "Marine Science", value: "Marine_Science_-_5180", level: 28 },
  { label: "Mathematics (Syllabus D)", value: "Mathematics_(Syllabus_D)_-_4024", level: 29 },
  { label: "Pakistan Studies", value: "Pakistan_Studies_-_2059", level: 30 },
  { label: "Physics", value: "Physics_-_5054", level: 31 },
  { label: "Religious Studies", value: "Religious_Studies_-_2048", level: 32 },
  { label: "Science", value: "Science_-_Combined_-_5129", level: 33 },
  { label: "Setswana", value: "Setswana_-_3158", level: 34 },
  { label: "Sinhala", value: "Sinhala_-_3205", level: 35 },
  { label: "Sociology", value: "Sociology_-_2251", level: 36 },
  { label: "Statistics", value: "Statistics_-_4040", level: 37 },
  { label: "Tamil", value: "Tamil_-_3226", level: 38 },
  { label: "Travel & Tourism", value: "Travel_&_Tourism_-_7096", level: 39 },
  { label: "Urdu Language", value: "Urdu_-_First_Language_-_3247", level: 40 },
  { label: "Urdu Language", value: "Urdu_-_Second_Language_-_3248", level: 41 },
  { label: "Other", value: "Other", level: 42 },
  { label: "Mathematics", value: "Mathematics_-_9709", level: 48 },
];

export const hsscSubjectList = [
  { label: "Pre-Medical", value: "Pre-Medical", level: 1 },
  { label: "Pre-Engineering", value: "Pre-Engineering", level: 2 },
  { label: "Commerce", value: "Commerce", level: 3 },
  { label: "Computer Science", value: "Computer_Science", level: 4 },
  { label: "General Science", value: "General_Science", level: 5 },
  { label: "Humanities", value: "Humanities", level: 6 },
  { label: "Fine Arts", value: "Fine_Arts", level: 7 },
  { label: "Home Economics", value: "Home_Economics", level: 8 },
  { label: "Islamic Studies", value: "Islamic_Studies", level: 9 },
  { label: "Accounting and Finance", value: "Accounting_and_Finance", level: 10 },
  { label: "Statistics", value: "Statistics", level: 11 },
  { label: "Economics", value: "Economics", level: 12 },
  { label: "Psychology", value: "Psychology", level: 13 },
  { label: "Sociology", value: "Sociology", level: 14 },
  { label: "Political Science", value: "Political_Science", level: 15 },
  { label: "Urdu", value: "Urdu", level: 16 },
  { label: "English", value: "English", level: 17 },
  { label: "Physics", value: "Physics", level: 18 },
  { label: "Chemistry", value: "Chemistry", level: 19 },
  { label: "Biology", value: "Biology", level: 20 },
  { label: "Mathematics", value: "Mathematics", level: 21 },
  { label: "Business Studies", value: "Business_Studies", level: 22 },
  { label: "Law", value: "Law", level: 23 },
  { label: "Geography", value: "Geography", level: 24 },
  { label: "History", value: "History", level: 25 },
  { label: "Other", value: "Other", level: 26 },
];
export const sccSubjectList = [
  { label: "English", value: "English", level: 1 },
  { label: "Urdu", value: "Urdu", level: 2 },
  { label: "Pakistan Studies", value: "Pakistan_Studies", level: 3 },
  { label: "Islamic Studies", value: "Islamic_Studies", level: 4 },
  { label: "Mathematics", value: "Mathematics", level: 5 },
  { label: "General Science", value: "General_Science", level: 6 },
  { label: "Biology", value: "Biology", level: 7 },
  { label: "Physics", value: "Physics", level: 8 },
  { label: "Chemistry", value: "Chemistry", level: 9 },
  { label: "Computer Science", value: "Computer_Science", level: 10 },
  { label: "Home Economics", value: "Home_Economics", level: 11 },
  { label: "Arts and Drawing", value: "Arts_and_Drawing", level: 12 },
  { label: "Agriculture", value: "Agriculture", level: 13 },
  { label: "Physical Education", value: "Physical_Education", level: 14 },
  { label: "Health and Physical Education", value: "Health_and_Physical_Education", level: 15 },
  { label: "Geography", value: "Geography", level: 16 },
  { label: "History", value: "History", level: 17 },
  { label: "Civics", value: "Civics", level: 18 },
  { label: "Arabic", value: "Arabic", level: 19 },
  { label: "Other", value: "Other", level: 19 },
];

export const otherFormSubjectList = [
  { label: "Accounting", value: "accounting", level: 1 },
  { label: "History", value: "history", level: 2 },
  { label: "Modern languages", value: "Modern languages", level: 3 },
  { label: "Classical languages", value: "Classical languages", level: 4 },
  {
    label: "Maths and further maths",
    value: "Maths and further maths",
    level: 5,
  },
  { label: "Physics", value: "Physics", level: 6 },
  { label: "Biology", value: "Biology", level: 7 },
  { label: "Chemistry", value: "Chemistry", level: 8 },
  { label: "Other", value: "Other", level: 9 },
];

export const universitySubjects = [
  { label: "Accounting", value: "Accounting", level: 1 },
  { label: "Acoustical Engineering", value: "Acoustical_Engineering", level: 2 },
  { label: "Actuarial Science", value: "Actuarial_Science", level: 3 },
  { label: "Addiction Studies", value: "Addiction_Studies", level: 4 },
  { label: "Aerospace Engineering", value: "Aerospace_Engineering", level: 5 },
  { label: "African American Studies", value: "African_American_Studies", level: 6 },
  { label: "African Studies", value: "African_Studies", level: 7 },
  { label: "Agribusiness", value: "Agribusiness", level: 8 },
  { label: "Agricultural Economics", value: "Agricultural_Economics", level: 9 },
  { label: "Agricultural Engineering", value: "Agricultural_Engineering", level: 10 },
  { label: "Agricultural Science", value: "Agricultural_Science", level: 11 },
  { label: "Agriculture", value: "Agriculture", level: 12 },
  { label: "Agroecology", value: "Agroecology", level: 13 },
  { label: "American Studies", value: "American_Studies", level: 14 },
  { label: "Animal Science", value: "Animal_Science", level: 15 },
  { label: "Anthropology", value: "Anthropology", level: 16 },
  { label: "Applied Linguistics", value: "Applied_Linguistics", level: 17 },
  { label: "Applied Mathematics", value: "Applied_Mathematics", level: 18 },
  { label: "Applied Physics", value: "Applied_Physics", level: 19 },
  { label: "Applied Psychology", value: "Applied_Psychology", level: 20 },
  { label: "Archaeology", value: "Archaeology", level: 21 },
  { label: "Architecture", value: "Architecture", level: 22 },
  { label: "Art and Design", value: "Art_and_Design", level: 23 },
  { label: "Art History", value: "Art_History", level: 24 },
  { label: "Artificial Intelligence", value: "Artificial_Intelligence", level: 25 },
  { label: "Asian Studies", value: "Asian_Studies", level: 26 },
  { label: "Astronomy", value: "Astronomy", level: 27 },
  { label: "Atmospheric Science", value: "Atmospheric_Science", level: 28 },
  { label: "Atmospheric Sciences", value: "Atmospheric_Sciences", level: 29 },
  { label: "Audio Engineering", value: "Audio_Engineering", level: 30 },
  { label: "Automotive Engineering", value: "Automotive_Engineering", level: 31 },
  { label: "Aviation", value: "Aviation", level: 32 },
  { label: "Behavioral Neuroscience", value: "Behavioral_Neuroscience", level: 33 },
  { label: "Behavioral Science", value: "Behavioral_Science", level: 34 },
  { label: "Biblical Studies", value: "Biblical_Studies", level: 35 },
  { label: "Biochemistry", value: "Biochemistry", level: 36 },
  { label: "Biodiversity Conservation", value: "Biodiversity_Conservation", level: 37 },
  { label: "Bioengineering", value: "Bioengineering", level: 38 },
  { label: "Bioinformatics", value: "Bioinformatics", level: 39 },
  { label: "Biological Sciences", value: "Biological_Sciences", level: 40 },
  { label: "Biomedical Engineering", value: "Biomedical_Engineering", level: 41 },
  { label: "Biomedical Sciences", value: "Biomedical_Sciences", level: 42 },
  { label: "Biostatistics", value: "Biostatistics", level: 43 },
  { label: "Botany", value: "Botany", level: 44 },
  { label: "Building and Construction", value: "Building_and_Construction", level: 45 },
  { label: "Business Administration", value: "Business_Administration", level: 46 },
  { label: "Business Analytics", value: "Business_Analytics", level: 47 },
  { label: "Business Ethics", value: "Business_Ethics", level: 48 },
  { label: "Business Law", value: "Business_Law", level: 49 },
  { label: "Canadian Studies", value: "Canadian_Studies", level: 50 },
  { label: "Cancer Biology", value: "Cancer_Biology", level: 51 },
  { label: "Caribbean Studies", value: "Caribbean_Studies", level: 52 },
  { label: "Cell Biology", value: "Cell_Biology", level: 53 },
  { label: "Chemical Engineering", value: "Chemical_Engineering", level: 54 },
  { label: "Chemistry", value: "Chemistry", level: 55 },
  { label: "Child Development", value: "Child_Development", level: 56 },
  { label: "Chinese Studies", value: "Chinese_Studies", level: 57 },
  { label: "Christian Studies", value: "Christian_Studies", level: 58 },
  { label: "Cinema and Media Studies", value: "Cinema_and_Media_Studies", level: 59 },
  { label: "Civil Engineering", value: "Civil_Engineering", level: 60 },
  { label: "Classical Studies", value: "Classical_Studies", level: 61 },
  { label: "Classics", value: "Classics", level: 62 },
  { label: "Cognitive Science", value: "Cognitive_Science", level: 63 },
  { label: "Communication", value: "Communication", level: 64 },
  { label: "Communication Disorders", value: "Communication_Disorders", level: 65 },
  { label: "Communication Studies", value: "Communication_Studies", level: 66 },
  { label: "Comparative Literature", value: "Comparative_Literature", level: 67 },
  { label: "Computer Engineering", value: "Computer_Engineering", level: 68 },
  { label: "Computer Science", value: "Computer_Science", level: 69 },
  { label: "Conflict Resolution", value: "Conflict_Resolution", level: 70 },
  { label: "Conservation Biology", value: "Conservation_Biology", level: 71 },
  { label: "Construction Management", value: "Construction_Management", level: 72 },
  { label: "Counseling Psychology", value: "Counseling_Psychology", level: 73 },
  { label: "Creative Writing", value: "Creative_Writing", level: 74 },
  { label: "Criminal Justice", value: "Criminal_Justice", level: 75 },
  { label: "Criminology", value: "Criminology", level: 76 },
  { label: "Culinary Arts", value: "Culinary_Arts", level: 77 },
  { label: "Cultural Studies", value: "Cultural_Studies", level: 78 },
  { label: "Cybersecurity", value: "Cybersecurity", level: 79 },
  { label: "Dance", value: "Dance", level: 80 },
  { label: "Data Science", value: "Data_Science", level: 81 },
  { label: "Dental Hygiene", value: "Dental_Hygiene", level: 82 },
  { label: "Dental Science", value: "Dental_Science", level: 83 },
  { label: "Dentistry", value: "Dentistry", level: 84 },
  { label: "Design", value: "Design", level: 85 },
  { label: "Development Studies", value: "Development_Studies", level: 86 },
  { label: "Diagnostic Radiography", value: "Diagnostic_Radiography", level: 87 },
  { label: "Dietetics", value: "Dietetics", level: 88 },
  { label: "Dietetics and Nutrition", value: "Dietetics_and_Nutrition", level: 89 },
  { label: "Digital Arts", value: "Digital_Arts", level: 90 },
  { label: "Digital Media", value: "Digital_Media", level: 91 },
  { label: "Diplomacy", value: "Diplomacy", level: 92 },
  { label: "Disaster Management", value: "Disaster_Management", level: 93 },
  { label: "Early Childhood Education", value: "Early_Childhood_Education", level: 94 },
  { label: "Earth Science", value: "Earth_Science", level: 95 },
  { label: "Earth Sciences", value: "Earth_Sciences", level: 96 },
  { label: "East Asian Studies", value: "East_Asian_Studies", level: 97 },
  { label: "Ecology", value: "Ecology", level: 98 },
  { label: "Econometrics", value: "Econometrics", level: 99 },
  { label: "Economics", value: "Economics", level: 100 },
  { label: "Education", value: "Education", level: 101 },
  { label: "Educational Leadership", value: "Educational_Leadership", level: 102 },
  { label: "Electrical and Electronic Engineering", value: "Electrical_and_Electronic_Engineering", level: 103 },
  { label: "Electrical Engineering", value: "Electrical_Engineering", level: 104 },
  { label: "Elementary Education", value: "Elementary_Education", level: 105 },
  { label: "Emergency Management", value: "Emergency_Management", level: 106 },
  { label: "Energy Science and Engineering", value: "Energy_Science_and_Engineering", level: 107 },
  { label: "Engineering", value: "Engineering", level: 108 },
  { label: "English", value: "English", level: 109 },
  { label: "English Language and Literature", value: "English_Language_and_Literature", level: 110 },
  { label: "Entomology", value: "Entomology", level: 111 },
  { label: "Entrepreneurship", value: "Entrepreneurship", level: 112 },
  { label: "Environmental Engineering", value: "Environmental_Engineering", level: 113 },
  { label: "Environmental Health", value: "Environmental_Health", level: 114 },
  { label: "Environmental Science", value: "Environmental_Science", level: 115 },
  { label: "Environmental Studies", value: "Environmental_Studies", level: 116 },
  { label: "Epidemiology", value: "Epidemiology", level: 117 },
  { label: "Ethics", value: "Ethics", level: 118 },
  { label: "European Studies", value: "European_Studies", level: 119 },
  { label: "Exercise and Sports Science", value: "Exercise_and_Sports_Science", level: 120 },
  { label: "Exercise Science", value: "Exercise_Science", level: 121 },
  { label: "Fashion and Textile Design", value: "Fashion_and_Textile_Design", level: 122 },
  { label: "Fashion Design", value: "Fashion_Design", level: 123 },
  { label: "Film Studies", value: "Film_Studies", level: 124 },
  { label: "Finance", value: "Finance", level: 125 },
  { label: "Fine Arts", value: "Fine_Arts", level: 126 },
  { label: "Fire Science", value: "Fire_Science", level: 127 },
  { label: "Fish and Wildlife Management", value: "Fish_and_Wildlife_Management", level: 128 },
  { label: "Fisheries and Aquaculture", value: "Fisheries_and_Aquaculture", level: 129 },
  { label: "Food Science", value: "Food_Science", level: 130 },
  { label: "Food Science and Technology", value: "Food_Science_and_Technology", level: 131 },
  { label: "Forensic Science", value: "Forensic_Science", level: 132 },
  { label: "Forestry", value: "Forestry", level: 133 },
  { label: "French", value: "French", level: 134 },
  { label: "French Studies", value: "French_Studies", level: 135 },
  { label: "Gender and Women's Studies", value: "Gender_and_Women's_Studies", level: 136 },
  { label: "Gender Studies", value: "Gender_Studies", level: 137 },
  { label: "Genetics", value: "Genetics", level: 138 },
  { label: "Geography", value: "Geography", level: 139 },
  { label: "Geology", value: "Geology", level: 140 },
  { label: "Geosciences", value: "Geosciences", level: 141 },
  { label: "German", value: "German", level: 142 },
  { label: "German Studies", value: "German_Studies", level: 143 },
  { label: "Gerontology", value: "Gerontology", level: 144 },
  { label: "Global Studies", value: "Global_Studies", level: 145 },
  { label: "Government and Politics", value: "Government_and_Politics", level: 146 },
  { label: "Graphic Design", value: "Graphic_Design", level: 147 },
  { label: "Greek and Roman Studies", value: "Greek_and_Roman_Studies", level: 148 },
  { label: "Health Administration", value: "Health_Administration", level: 149 },
  { label: "Health Education", value: "Health_Education", level: 150 },
  { label: "Health Informatics", value: "Health_Informatics", level: 151 },
  { label: "Health Sciences", value: "Health_Sciences", level: 152 },
  { label: "Health Services Management", value: "Health_Services_Management", level: 153 },
  { label: "Healthcare Management", value: "Healthcare_Management", level: 154 },
  { label: "Hindu Studies", value: "Hindu_Studies", level: 155 },
  { label: "History", value: "History", level: 156 },
  { label: "Horticulture", value: "Horticulture", level: 157 },
  { label: "Hospitality Management", value: "Hospitality_Management", level: 158 },
  { label: "Human Biology", value: "Human_Biology", level: 159 },
  { label: "Human Development", value: "Human_Development", level: 160 },
  { label: "Human Resource Management", value: "Human_Resource_Management", level: 161 },
  { label: "Human Resources Management", value: "Human_Resources_Management", level: 162 },
  { label: "Human Rights", value: "Human_Rights", level: 163 },
  { label: "Human Services", value: "Human_Services", level: 164 },
  { label: "Humanities", value: "Humanities", level: 165 },
  { label: "Hydrology", value: "Hydrology", level: 166 },
  { label: "Indigenous Studies", value: "Indigenous_Studies", level: 167 },
  { label: "Industrial and Manufacturing Engineering", value: "Industrial_and_Manufacturing_Engineering", level: 168 },
  { label: "Industrial Design", value: "Industrial_Design", level: 169 },
  { label: "Industrial Engineering", value: "Industrial_Engineering", level: 170 },
  { label: "Information Science", value: "Information_Science", level: 171 },
  { label: "Information Systems", value: "Information_Systems", level: 172 },
  { label: "Information Technology", value: "Information_Technology", level: 173 },
  { label: "Innovation and Entrepreneurship", value: "Innovation_and_Entrepreneurship", level: 174 },
  { label: "Insurance and Risk Management", value: "Insurance_and_Risk_Management", level: 175 },
  { label: "Integrated Marketing Communications", value: "Integrated_Marketing_Communications", level: 176 },
  { label: "Intercultural Studies", value: "Intercultural_Studies", level: 177 },
  { label: "Interior Design", value: "Interior_Design", level: 178 },
  { label: "International Business", value: "International_Business", level: 179 },
  { label: "International Development", value: "International_Development", level: 180 },
  { label: "International Relations", value: "International_Relations", level: 181 },
  { label: "Islamic Studies", value: "Islamic_Studies", level: 182 },
  { label: "Italian", value: "Italian", level: 183 },
  { label: "Italian Studies", value: "Italian_Studies", level: 184 },
  { label: "Japanese", value: "Japanese", level: 185 },
  { label: "Japanese Studies", value: "Japanese_Studies", level: 186 },
  { label: "Journalism", value: "Journalism", level: 187 },
  { label: "Judaic Studies", value: "Judaic_Studies", level: 188 },
  { label: "Kinesiology", value: "Kinesiology", level: 189 },
  { label: "Korean Studies", value: "Korean_Studies", level: 190 },
  { label: "Labor Studies", value: "Labor_Studies", level: 191 },
  { label: "Landscape Architecture", value: "Landscape_Architecture", level: 192 },
  { label: "Language and Literature", value: "Language_and_Literature", level: 193 },
  { label: "Latin American Studies", value: "Latin_American_Studies", level: 194 },
  { label: "Law", value: "Law", level: 195 },
  { label: "Leadership", value: "Leadership", level: 196 },
  { label: "Leadership Studies", value: "Leadership_Studies", level: 197 },
  { label: "Learning Sciences", value: "Learning_Sciences", level: 198 },
  { label: "Legal Studies", value: "Legal_Studies", level: 199 },
  { label: "Leisure Studies", value: "Leisure_Studies", level: 200 },
  { label: "Liberal Arts", value: "Liberal_Arts", level: 201 },
  { label: "Library Science", value: "Library_Science", level: 202 },
  { label: "Linguistics", value: "Linguistics", level: 203 },
  { label: "Literary Studies", value: "Literary_Studies", level: 204 },
  { label: "Management", value: "Management", level: 205 },
  { label: "Management Information Systems", value: "Management_Information_Systems", level: 206 },
  { label: "Manufacturing Engineering", value: "Manufacturing_Engineering", level: 207 },
  { label: "Marine Biology", value: "Marine_Biology", level: 208 },
  { label: "Marine Science", value: "Marine_Science", level: 209 },
  { label: "Marketing", value: "Marketing", level: 210 },
  { label: "Materials Science", value: "Materials_Science", level: 211 },
  { label: "Materials Science and Engineering", value: "Materials_Science_and_Engineering", level: 212 },
  { label: "Mathematics", value: "Mathematics", level: 213 },
  { label: "Mechanical Design", value: "Mechanical_Design", level: 214 },
  { label: "Mechanical Engineering", value: "Mechanical_Engineering", level: 215 },
  { label: "Media Studies", value: "Media_Studies", level: 216 },
  { label: "Medical Anthropology", value: "Medical_Anthropology", level: 217 },
  { label: "Medical Laboratory Science", value: "Medical_Laboratory_Science", level: 218 },
  { label: "Medical Physics", value: "Medical_Physics", level: 219 },
  { label: "Medical Science", value: "Medical_Science", level: 220 },
  { label: "Medical Technology", value: "Medical_Technology", level: 221 },
  { label: "Medicine", value: "Medicine", level: 222 },
  { label: "Medieval Studies", value: "Medieval_Studies", level: 223 },
  { label: "Mental Health Counseling", value: "Mental_Health_Counseling", level: 224 },
  { label: "Meteorology", value: "Meteorology", level: 225 },
  { label: "Microbiology", value: "Microbiology", level: 226 },
  { label: "Middle Eastern Studies", value: "Middle_Eastern_Studies", level: 227 },
  { label: "Military History", value: "Military_History", level: 228 },
  { label: "Military Science", value: "Military_Science", level: 229 },
  { label: "Mining Engineering", value: "Mining_Engineering", level: 230 },
  { label: "Modern Languages", value: "Modern_Languages", level: 231 },
  { label: "Molecular Biology", value: "Molecular_Biology", level: 232 },
  { label: "Multimedia Design", value: "Multimedia_Design", level: 233 },
  { label: "Music", value: "Music", level: 234 },
  { label: "Music Education", value: "Music_Education", level: 235 },
  { label: "Music Performance", value: "Music_Performance", level: 236 },
  { label: "Music Technology", value: "Music_Technology", level: 237 },
  { label: "Nanotechnology", value: "Nanotechnology", level: 238 },
  { label: "National Security Studies", value: "National_Security_Studies", level: 239 },
  { label: "Natural Resource Management", value: "Natural_Resource_Management", level: 240 },
  { label: "Neuroscience", value: "Neuroscience", level: 241 },
  { label: "Nonprofit Management", value: "Nonprofit_Management", level: 242 },
  { label: "Nuclear Engineering", value: "Nuclear_Engineering", level: 243 },
  { label: "Nuclear Medicine Technology", value: "Nuclear_Medicine_Technology", level: 244 },
  { label: "Nursing", value: "Nursing", level: 245 },
  { label: "Nutrition", value: "Nutrition", level: 246 },
  { label: "Nutrition Science", value: "Nutrition_Science", level: 247 },
  { label: "Nutritional Sciences", value: "Nutritional_Sciences", level: 248 },
  { label: "Occupational Health and Safety", value: "Occupational_Health_and_Safety", level: 249 },
  { label: "Occupational Therapy", value: "Occupational_Therapy", level: 250 },
  { label: "Ocean Engineering", value: "Ocean_Engineering", level: 251 },
  { label: "Oceanography", value: "Oceanography", level: 252 },
  { label: "Operations Management", value: "Operations_Management", level: 253 },
  { label: "Optometry", value: "Optometry", level: 254 },
  { label: "Organizational Behavior", value: "Organizational_Behavior", level: 255 },
  { label: "Organizational Psychology", value: "Organizational_Psychology", level: 256 },
  { label: "Outdoor Education", value: "Outdoor_Education", level: 257 },
  { label: "Packaging Engineering", value: "Packaging_Engineering", level: 258 },
  { label: "Paleontology", value: "Paleontology", level: 259 },
  { label: "Paralegal Studies", value: "Paralegal_Studies", level: 260 },
  { label: "Peace and Conflict Studies", value: "Peace_and_Conflict_Studies", level: 261 },
  { label: "Peace Studies", value: "Peace_Studies", level: 262 },
  { label: "Performing Arts", value: "Performing_Arts", level: 263 },
  { label: "Petroleum Engineering", value: "Petroleum_Engineering", level: 264 },
  { label: "Pharmaceutical Engineering", value: "Pharmaceutical_Engineering", level: 265 },
  { label: "Pharmaceutical Sciences", value: "Pharmaceutical_Sciences", level: 266 },
  { label: "Pharmacology", value: "Pharmacology", level: 267 },
  { label: "Pharmacy", value: "Pharmacy", level: 268 },
  { label: "Philosophy", value: "Philosophy", level: 269 },
  { label: "Philosophy and Religion", value: "Philosophy_and_Religion", level: 270 },
  { label: "Photography", value: "Photography", level: 271 },
  { label: "Physical Education", value: "Physical_Education", level: 272 },
  { label: "Physical Sciences", value: "Physical_Sciences", level: 273 },
  { label: "Physical Therapy", value: "Physical_Therapy", level: 274 },
  { label: "Physician Assistant Studies", value: "Physician_Assistant_Studies", level: 275 },
  { label: "Physics", value: "Physics", level: 276 },
  { label: "Physiology", value: "Physiology", level: 277 },
  { label: "Plant Biology", value: "Plant_Biology", level: 278 },
  { label: "Plant Science", value: "Plant_Science", level: 279 },
  { label: "Political Science", value: "Political_Science", level: 280 },
  { label: "Polymer Science", value: "Polymer_Science", level: 281 },
  { label: "Population Studies", value: "Population_Studies", level: 282 },
  { label: "Portuguese", value: "Portuguese", level: 283 },
  { label: "Portuguese Studies", value: "Portuguese_Studies", level: 284 },
  { label: "Precision Agriculture", value: "Precision_Agriculture", level: 285 },
  { label: "Product Design", value: "Product_Design", level: 286 },
  { label: "Professional Counseling", value: "Professional_Counseling", level: 287 },
  { label: "Professional Writing", value: "Professional_Writing", level: 288 },
  { label: "Project Engineering", value: "Project_Engineering", level: 289 },
  { label: "Project Management", value: "Project_Management", level: 290 },
  { label: "Psychology", value: "Psychology", level: 291 },
  { label: "Psychology and Counseling", value: "Psychology_and_Counseling", level: 292 },
  { label: "Public Administration", value: "Public_Administration", level: 293 },
  { label: "Public Health", value: "Public_Health", level: 294 },
  { label: "Public Policy", value: "Public_Policy", level: 295 },
  { label: "Public Relations", value: "Public_Relations", level: 296 },
  { label: "Publishing", value: "Publishing", level: 297 },
  { label: "Pure Mathematics", value: "Pure_Mathematics", level: 298 },
  { label: "Quality Management", value: "Quality_Management", level: 299 },
  { label: "Radiation Therapy", value: "Radiation_Therapy", level: 300 },
  { label: "Radio and Television Broadcasting", value: "Radio_and_Television_Broadcasting", level: 301 },
  { label: "Radiography", value: "Radiography", level: 302 },
  { label: "Real Estate", value: "Real_Estate", level: 303 },
  { label: "Recreation and Leisure Studies", value: "Recreation_and_Leisure_Studies", level: 304 },
  { label: "Recreation Management", value: "Recreation_Management", level: 305 },
  { label: "Rehabilitation Counseling", value: "Rehabilitation_Counseling", level: 306 },
  { label: "Religious Education", value: "Religious_Education", level: 307 },
  { label: "Religious Studies", value: "Religious_Studies", level: 308 },
  { label: "Renaissance Studies", value: "Renaissance_Studies", level: 309 },
  { label: "Respiratory Therapy", value: "Respiratory_Therapy", level: 310 },
  { label: "Retail Management", value: "Retail_Management", level: 311 },
  { label: "Risk Analysis and Management", value: "Risk_Analysis_and_Management", level: 312 },
  { label: "Risk Management", value: "Risk_Management", level: 313 },
  { label: "Robotics", value: "Robotics", level: 314 },
  { label: "Russian", value: "Russian", level: 315 },
  { label: "Russian Studies", value: "Russian_Studies", level: 316 },
  { label: "Sales", value: "Sales", level: 317 },
  { label: "Sales and Marketing", value: "Sales_and_Marketing", level: 318 },
  { label: "Science and Technology Studies", value: "Science_and_Technology_Studies", level: 319 },
  { label: "Science Education", value: "Science_Education", level: 320 },
  { label: "Secondary Education", value: "Secondary_Education", level: 321 },
  { label: "Social Policy", value: "Social_Policy", level: 322 },
  { label: "Social Science", value: "Social_Science", level: 323 },
  { label: "Social Sciences Education", value: "Social_Sciences_Education", level: 324 },
  { label: "Social Work", value: "Social_Work", level: 325 },
  { label: "Sociology", value: "Sociology", level: 326 },
  { label: "Software Development", value: "Software_Development", level: 327 },
  { label: "Software Engineering", value: "Software_Engineering", level: 328 },
  { label: "Soil Science", value: "Soil_Science", level: 329 },
  { label: "Sound Engineering", value: "Sound_Engineering", level: 330 },
  { label: "Spanish", value: "Spanish", level: 331 },
  { label: "Spanish Studies", value: "Spanish_Studies", level: 332 },
  { label: "Special Education", value: "Special_Education", level: 333 },
  { label: "Speech and Language Pathology", value: "Speech_and_Language_Pathology", level: 334 },
  { label: "Speech-Language Pathology", value: "Speech-Language_Pathology", level: 335 },
  { label: "Sports Management", value: "Sports_Management", level: 336 },
  { label: "Sports Science", value: "Sports_Science", level: 337 },
  { label: "Statistics", value: "Statistics", level: 338 },
  { label: "Strategic Communication", value: "Strategic_Communication", level: 339 },
  { label: "Strategic Management", value: "Strategic_Management", level: 340 },
  { label: "Supply Chain Management", value: "Supply_Chain_Management", level: 341 },
  { label: "Sustainability Studies", value: "Sustainability_Studies", level: 342 },
  { label: "Sustainable Development", value: "Sustainable_Development", level: 343 },
  { label: "Sustainable Energy", value: "Sustainable_Energy", level: 344 },
  { label: "Systems Engineering", value: "Systems_Engineering", level: 345 },
  { label: "Tax Law", value: "Tax_Law", level: 346 },
  { label: "Taxation", value: "Taxation", level: 347 },
  { label: "Teacher Education", value: "Teacher_Education", level: 348 },
  { label: "Teaching English as a Second Language (TESL)", value: "Teaching_English_as_a_Second_Language_(TESL)", level: 349 },
  { label: "Technical Writing", value: "Technical_Writing", level: 350 },
  { label: "Technology Management", value: "Technology_Management", level: 351 },
  { label: "Telecommunications Engineering", value: "Telecommunications_Engineering", level: 352 },
  { label: "Textile Engineering", value: "Textile_Engineering", level: 353 },
  { label: "Theater Arts", value: "Theater_Arts", level: 354 },
  { label: "Theatre", value: "Theatre", level: 355 },
  { label: "Theatre Studies", value: "Theatre_Studies", level: 356 },
  { label: "Theology", value: "Theology", level: 357 },
  { label: "Theoretical Physics", value: "Theoretical_Physics", level: 358 },
  { label: "Tourism and Hospitality", value: "Tourism_and_Hospitality", level: 359 },
  { label: "Tourism Management", value: "Tourism_Management", level: 360 },
  { label: "Translation", value: "Translation", level: 361 },
  { label: "Translation Studies", value: "Translation_Studies", level: 362 },
  { label: "Transportation Engineering", value: "Transportation_Engineering", level: 363 },
  { label: "Transportation Planning", value: "Transportation_Planning", level: 364 },
  { label: "Travel and Tourism", value: "Travel_and_Tourism", level: 365 },
  { label: "Urban Design", value: "Urban_Design", level: 366 },
  { label: "Urban Planning", value: "Urban_Planning", level: 367 },
  { label: "Urban Studies", value: "Urban_Studies", level: 368 },
  { label: "User Experience Design", value: "User_Experience_Design", level: 369 },
  { label: "Veterinary Medicine", value: "Veterinary_Medicine", level: 370 },
  { label: "Veterinary Science", value: "Veterinary_Science", level: 371 },
  { label: "Video Game Design", value: "Video_Game_Design", level: 372 },
  { label: "Visual Arts", value: "Visual_Arts", level: 373 },
  { label: "Water Resources Engineering", value: "Water_Resources_Engineering", level: 374 },
  { label: "Web Design", value: "Web_Design", level: 375 },
  { label: "Wildlife and Conservation Biology", value: "Wildlife_and_Conservation_Biology", level: 376 },
  { label: "Wildlife Management", value: "Wildlife_Management", level: 377 },
  { label: "Women's Health", value: "Women's_Health", level: 378 },
  { label: "Women's Studies", value: "Women's_Studies", level: 379 },
  { label: "Writing and Rhetoric", value: "Writing_and_Rhetoric", level: 380 },
  { label: "Writing Studies", value: "Writing_Studies", level: 381 },
  { label: "Youth Development", value: "Youth_Development", level: 382 },
  { label: "Zoological Medicine", value: "Zoological_Medicine", level: 383 },
  { label: "Zoology", value: "Zoology", level: 384 },
  { label: "Other", value: "Other", level: 385 },
];
