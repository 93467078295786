import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const Employment = ({ appData, student, confirm, data, copy, submitted, setRevertData, user, appCheck }) => {
  return (
    <Box>
      <DataWrapper heading={"Employment"}>
        {student?.registrationData?.professionalExperience?.map((item, index) => {
          return (
            <Box>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Company Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.companyName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="companyName"
                    text={item?.companyName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Designation: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.designation || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="designation"
                    text={item?.designation || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  From: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.from || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="from"
                    text={item?.from || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  To: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.to || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="to"
                    text={item?.to || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Main Responsibilities:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.mainResponsibilities || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="mainResponsibilities"
                    text={item?.mainResponsibilities || "N/A"}
                  />
                )}
              </Typography>
              {index < student?.registrationData?.professionalExperience?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
            </Box>
          );
        })}
      </DataWrapper>
    </Box>
  );
};

export default Employment;
