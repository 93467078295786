import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import { Avatar, Card, CardContent, Stack, SvgIcon, Grid, MenuItem, Select } from "@mui/material";

const Performance = () => {
  const [selectedRole, setSelectedRole] = useState("Counselor");

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Performance Metrics
      </Typography>

      <Card>
        <Box sx={{ marginBottom: 2, padding: "1rem" }}>
          <Select value={selectedRole} onChange={handleRoleChange} variant="outlined">
            <MenuItem value="Counselor">Counselor</MenuItem>
            <MenuItem value="ApplicationOfficer">Application Officer</MenuItem>
          </Select>
        </Box>

        {selectedRole === "Counselor" && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ padding: "1rem" }}>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Total Number of Applications Handled
                      </Typography>
                      <Typography variant="h4">150</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="success" fontSize="small">
                        <ArrowUpIcon />
                      </SvgIcon>
                      <Typography color="success.main" variant="body2">
                        15%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Average Time to Decide (in days)
                      </Typography>
                      <Typography variant="h4">7</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="error" fontSize="small">
                        <ArrowDownIcon />
                      </SvgIcon>
                      <Typography color="error.main" variant="body2">
                        -10%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Number of Escalated Applications
                      </Typography>
                      <Typography variant="h4">5</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="success" fontSize="small">
                        <ArrowUpIcon />
                      </SvgIcon>
                      <Typography color="success.main" variant="body2">
                        25%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {selectedRole === "ApplicationOfficer" && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Total Number of Applications Handled
                      </Typography>
                      <Typography variant="h4">200</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="success" fontSize="small">
                        <ArrowUpIcon />
                      </SvgIcon>
                      <Typography color="success.main" variant="body2">
                        20%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Average Time to Decide (in days)
                      </Typography>
                      <Typography variant="h4">5</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="success" fontSize="small">
                        <ArrowUpIcon />
                      </SvgIcon>
                      <Typography color="success.main" variant="body2">
                        10%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                      <Typography color="text.secondary" variant="overline">
                        Number of Escalated Applications
                      </Typography>
                      <Typography variant="h4">2</Typography>
                    </Stack>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                        height: 56,
                        width: 56,
                      }}
                    ></Avatar>
                  </Stack>

                  <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SvgIcon color="error" fontSize="small">
                        <ArrowDownIcon />
                      </SvgIcon>
                      <Typography color="error.main" variant="body2">
                        -20%
                      </Typography>
                    </Stack>
                    <Typography color="text.secondary" variant="caption">
                      Since last month
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
};

export default Performance;
