import React from 'react'
import SidemenuBar from './SidemenuBar'

const Mynav = ( {openNav , onCloseNav}) => {
  return (
    <div>
      <SidemenuBar openNav onCloseNav />
    </div>
  )
}

export default Mynav
