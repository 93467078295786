import React from "react";
import Typography from "@mui/material/Typography";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TaskSidebar from "./TaskSidebar";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Checkbox, Tooltip } from "@mui/material";

const TaskCard = ({ title, id, task, onTaskUpdate, student, users, assignedTo, handleDeleteTask, isDone }) => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar state
  const toggleSidebar = () => {
    setIsSidebarOpen(true);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: id,
    data: {
      type: "tasks",
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <>
      <Drawer
        //    hideBackdrop
        anchor="right" // You can change the anchor to "right" or "top" as needed
        open={isSidebarOpen}
        onClose={closeSidebar}
        BackdropProps={{
          invisible: true,
        }}
      >
        <Box>
          <TaskSidebar
            task={task}
            student={student}
            users={users}
            onTaskUpdate={onTaskUpdate}
            closeSidebar={closeSidebar}
            handleDeleteTask={handleDeleteTask} />
        </Box>
      </Drawer>
      <Box
        onDoubleClick={toggleSidebar}
        sx={{
          border: "1px solid rgb(237,234,233)",
          borderRadius: "10px",
          marginTop: "25px",
          padding: "10px",
          backgroundColor: "rgb(255,255,255)",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {task.isDone === true ?
            <Tooltip title="" placement="top">
              <span>
                <Checkbox
                  disabled
                  defaultChecked
                  sx={{
                    color: "rgba(25, 104, 173, 1) !important",
                    marginTop: '-10px',
                  }}
                />
              </span>
            </Tooltip>
            :
            <Checkbox
              disabled
              sx={{
                color: "rgb(145,145,146)",
                marginTop: '-10px',
              }}
            />
          }
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              color: "rgb(145,145,146)",
              wordBreak: "break-all",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{
            color: "rgb(145,145,146)",
            marginLeft: "20px",
            marginTop: "20px",
          }}
        >
          Assignee : {assignedTo?.name}
        </Typography>
      </Box>
    </>
  );
};

export default TaskCard;
