import { Box, Typography } from "@mui/material";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const IntendedCourse = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  return (
    <DataWrapper heading={"Intended Course"}>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Intended Course: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.courseTitle?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="courseTitle"
            text={student?.courseTitle?.label || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Intended Course (Other): <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.otherLevelOfStudy || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="otherLevelOfStudy"
            text={student?.otherLevelOfStudy || "N/A"}
          />
        )}
      </Typography>
    </DataWrapper>
  );
};

export default IntendedCourse;
