import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { curdActions } from "../../reduxCURD/actions";
import { CRUDFunction } from "../../reduxCURD/container";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Submission = ({ props }) => {
  const formTitle = "Feedback Form";
  const formDescription = "Feedback from students.";
  const creationDate = "May 25, 2023";
  const formStatus = "Active";
  const numberOfSubmissions = 10;
  const lastSubmissionDate = "May 28, 2023";

  useEffect(() => {
    props.getLeads();
    // props.getSubmissions()
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        // mt: "10px",
      }}
    >
      {props.leads
        ?.filter((item) => !item?.manualForm && !item?.googleForm)
        .map((lead) => {
          return (
            <Link>
              <Card
                sx={{
                  width: 400,
                  height:230,
                  marginTop: "5px",
                  marginLeft: "1.2rem",
                  [`@media(max-width:483px)`]: {
                    width: 330,
                    [`@media(max-width:416px)`]: { width: 310 },
                    [`@media(max-width:390px)`]: { width: 290 },
                  },
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography variant="h5" component="div" color="primary.main" my={0}>
                        {lead.title}
                      </Typography>
                      <Typography variant="subtitle2" color="primary.main" my={1}>
                        {lead.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" color="primary.main" my={1}>
                        {formatDate(lead.createdAt)}
                      </Typography>
                      <Typography variant="subtitle2" color="primary.main" my={1}>
                        {formStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      marginTop: "10px",
                      bgcolor: "lightGray",
                      width: "23px",
                      height: "23px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: ".9rem",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    0
                  </Box>
                  <Box my={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="primary.main">
                          {formatDate(lead.createdAt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Link to={`/submissions/${lead._id}`} key={lead._id}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <a
                              style={{
                                color: "white",
                                backgroundColor: "#1968AD",
                                width: "75px",
                                height: "28px",
                                marginRight: "30px",
                                marginBottom: "30px",
                                fontSize: "12px",
                                fontWeight: 300,
                                letterSpacing: "1.25px",
                                fontStyle: "normal",
                                textAlign: "center",
                                paddingTop: "5px",
                              }}
                            >
                              View
                            </a>
                          </Box>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          );
        })}
    </Box>
  );
};

// let variableList = {
//   leadReducer: {
//     // lead: {},
//     leads: [],
//   },
// };

// const getLead = curdActions.getLead;
// // const updateLead = curdActions.updateLead;
// const getLeads = curdActions.getLeads;

// let actionOptions = {
//   create: true,
//   update: true,
//   delete: true,
//   read: true,
//   others: {
//     // getLead,
//     // updateLead,
//     getLeads,
//   },
// };
// let addditionalREducer = ["leadReducer"];

// // export default Submission;
// const submissionContainer = CRUDFunction(Submission, "submission", actionOptions, variableList, addditionalREducer, null);
// export default submissionContainer;
export default Submission;
