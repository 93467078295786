import React from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Divider, Stack, Typography } from "@mui/material";

const Qualification = ({ singleEnquiry }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Postgraduate"}>
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Institution:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.postgraduateInstitution || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Degree Title:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.postgraduateDegreeTitle || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.postgraduateGrades || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Completion Year:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.postgraduateCompletionYear || "N/A"}</span>
              </span>
            </Typography>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Undergraduate"}>
          <Box>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Institution:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.undergraduateInstitution || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Degree Title:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.undergraduateDegreeTitle || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.undergraduateGrades || "N/A"}</span>
              </span>
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Completion Year:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.undergraduateCompletionYear || "N/A"}</span>
              </span>
            </Typography>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width={"100%"}>
          <DataWrapper heading={`A Level`}>
            {singleEnquiry?.alevelForm &&
              singleEnquiry?.alevelForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                        width: "100%",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.alevelSubjectList?.label ? item?.alevelSubjectList?.label : item?.alevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherAlevelSubjectList ? item?.otherAlevelSubjectList : item?.otherAlevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelAwardingBody || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry?.alevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>

        <Stack width={"100%"}>
          <DataWrapper heading={`AS Level`} >
            {singleEnquiry?.aSlevelForm &&
              singleEnquiry.aSlevelForm.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.aSlevelSubjectList?.label ? item?.aSlevelSubjectList?.label : item?.aSlevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherASlevelSubjectList ? item?.otherASlevelSubjectList : item?.otherASlevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelAwardingBody || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry?.aSlevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width={"100%"}>
          <DataWrapper heading={`O Level`}>
            {singleEnquiry?.olevelForm &&
              singleEnquiry?.olevelForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.olevelSubjectList?.label ? item?.olevelSubjectList?.label : item?.olevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherOlevelSubjectList ? item?.otherOlevelSubjectList : item?.otherOlevelSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelAwardingBody || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry?.olevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>

        <Stack width={"100%"}>
          <DataWrapper heading={`HSSC`}>
            {singleEnquiry?.hsscForm &&
              singleEnquiry?.hsscForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.hsscSubjectList?.label ? item?.hsscSubjectList?.label : item?.hsscSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherHsscSubjectList ? item?.otherHsscSubjectList : item?.otherHsscSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry.hsscForm.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width="100%">
          <DataWrapper heading={`SSC`}>
            {singleEnquiry?.sccForm &&
              singleEnquiry?.sccForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.sccSubjectList?.label ? item?.sccSubjectList?.label : item?.sccSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherSccSubjectList ? item?.otherSccSubjectList : item?.otherSccSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry?.sccForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>

        <Stack width="100%">
          <DataWrapper heading={`Other Education`}>
            {singleEnquiry?.otherForm &&
              singleEnquiry?.otherForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormInstitution || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormDegreeTitle || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormGrades || "N/A"}</span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherFormSubjectList?.label ? item?.otherFormSubjectList?.label : item?.otherFormSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherOtherFormSubjectList ? item?.otherOtherFormSubjectList : item?.otherOtherFormSubjectList || "N/A"}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormCompletionYear || "N/A"}</span>
                      </span>
                    </Typography>
                    {index < singleEnquiry?.otherForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>
      </Box>
    </Box>
  );
};

export default Qualification;
