import React, { useState } from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Personal = ({ singleEnquiry, EmailComment, singleEnquiryID, CallSchedule }) => {
  // console.log(singleEnquiryID);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [message, setMessage] = useState("");
  const [purpose, setPurpose] = useState("");

  function getCurrentTime() {
    const now = new Date();
    const formatTime12Hour = (date) => {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      return `${hours}:${minutes}`;
    };
    const formattedTime = formatTime12Hour(now);
    return formattedTime;
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  const onSendMessage = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();
    const data = {
      time: time,
      date: date,
      purpose: "Email",
      content: message,
      mode: "Email",
      emailDone: purpose,
    };
    EmailComment(data, singleEnquiryID?._id);
    setMessage("");
    handleClose();
  };

  // Modal For Call Purpose
  const [OpenCallModal, setOpenCallModal] = useState(false);
  const CallModalOpen = () => setOpenCallModal(true);
  const CallModalClose = () => setOpenCallModal(false);

  const [CallMessage, setCallMessage] = useState("");
  const [CallPurpose, setCallPurpose] = useState();

  const onCallMessage = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();
    const data = {
      time: time,
      date: date,
      mode: "Call",
      purpose: "Call",
      emailDone: CallPurpose,
      content: CallMessage,
    };
    CallSchedule(data, singleEnquiryID?._id);
    setMessage("");
    CallModalClose();
  };

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Student Name"}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <Typography variant="h6">Student Name</Typography> */}
            <Box
              sx={{
                // mr: 2,
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  alignItems: "center",

                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Name Prefix:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.namePrefix?.label || singleEnquiry?.namePrefix || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  alignItems: "center",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  First Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.firstName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Middle Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.middleName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Last Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.lastName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Date Of Birth: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.dob || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Email:
                  <a href={`mailto:${singleEnquiry?.infoEmail}`} className="link-1">
                    <span style={{ fontWeight: "lighter", marginLeft: "5px", color: "rgb(25,104,173)" }} onClick={handleOpen}>
                      {singleEnquiry?.infoEmail || "N/A"}
                    </span>
                  </a>
                </span>
                {/* <span>
                  Email: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.infoEmail || "N/A"}</span>
                </span> */}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Contact Details"}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <Typography variant="h6">Contact Details</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Dialing Code:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.dialingCode?.label || singleEnquiry?.dialingCode || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Mobile Number: <span style={{ fontWeight: "lighter", marginLeft: "5px", color: "rgb(25,104,173)", cursor: "pointer" }} onClick={CallModalOpen}>{singleEnquiry?.cellNo || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Home No: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.homeNo || "N/A"}</span>
                </span>
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Guardian Details"}>
          <Box>
            {/* <Typography variant="h6">Guardian Details</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  First Name : <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianFirstName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Middle Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianMiddleName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Last Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianLastName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Profession: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.profession || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Monthly Income: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.monthlyIncome || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianAddress || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Telephone No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianTelephoneNo || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Cell No: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianCellNo || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Alternate No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianAlternateNo || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Email:
                  <a href={`mailto:${singleEnquiry?.guardianEmail}`} className="link-1">
                    <span style={{ fontWeight: "lighter", marginLeft: "5px", color: "Black" }}>
                      {singleEnquiry?.guardianEmail || "N/A"}
                    </span>
                  </a>
                </span>
                {/* <span>
                  Email: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.guardianEmail || "N/A"}</span>
                </span> */}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Address"}>
          <Box>
            {/* <Typography variant="h6">Address</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address Line 1: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.line1 || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address Line 2: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.line2 || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.country?.label || singleEnquiry?.country || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  City:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.city?.label || singleEnquiry?.city || "N/A"}
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Geography And Nationality"}>
          <Box>
            {/* <Typography variant="h6">Geography And Nationality</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Country Of Citizenship:
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.countryOfCitizenship?.label || singleEnquiry?.countryOfCitizenship || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Birth City:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.birthCity?.label || singleEnquiry?.birthCity || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Birth Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.birthCountry?.label || singleEnquiry?.birthCountry || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Dual Nationality:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.dualNationality?.label || singleEnquiry?.dualNationality || "N/A"}
                  </span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Passport No: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.passportNo || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Second Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.secondCountry?.label || singleEnquiry?.secondCountry || "N/A"}
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Visa Refusal"}>
          <Box>
            {/* <Typography variant="h6">Visa Refusal</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Date Of Visa Refusal:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.dateOfVisaRefusal || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Reason Of Visa Refusal:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.reasonOfVisaRefusal || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Type Of Visa:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.typeOfVisa?.label || singleEnquiry?.typeOfVisa || "N/A"}
                  </span>
                </span>
              </Typography>
              {singleEnquiry?.typeOfVisa?.label === "Other" && (
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Type Of Visa (Other):{" "}
                    <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.otherTypeOfVisa || "N/A"}</span>
                  </span>
                </Typography>
              )}

              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Visa Country Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {singleEnquiry?.visaCountryName?.label || singleEnquiry?.visaCountryName || "N/A"}
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>

      {/* Modal For Define Purpose for Email  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Email Attended Response
          </Typography>
          <TextField
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="Response"
            placeholder="Enter Response"
            variant="outlined"
            sx={{ marginBottom: "15px", marginTop: "10px" }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Attend</InputLabel>
            <Select
              value={purpose}
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setPurpose(e.target.value)}
              input={<OutlinedInput label="Attend" />}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSendMessage}
          >
            Add Response
          </Button>
        </Box>
      </Modal>

      {/* Modal For Define Purpose for Call  */}
      <Modal
        open={OpenCallModal}
        onClose={CallModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Call Attended Response
          </Typography>
          <TextField
            fullWidth
            value={CallMessage}
            onChange={(e) => setCallMessage(e.target.value)}
            label="Response"
            placeholder="Enter Response"
            variant="outlined"
            sx={{ marginBottom: "15px", marginTop: "10px" }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-name-label">Attend</InputLabel>
            <Select
              value={CallPurpose}
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setCallPurpose(e.target.value)}
              input={<OutlinedInput label="Attend" />}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onCallMessage}
          >
            Add Response
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Personal;
