import React from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Typography } from "@mui/material";

const Comments = ({ singleEnquiry }) => {
  return (
    <DataWrapper heading={"Comment"}>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Referred By: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.referredBy || "N/A"}</span>
        </span>
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Comment: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.comment || "N/A"}</span>
        </span>
      </Typography>
    </DataWrapper>
  );
};

export default Comments;
