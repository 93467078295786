import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";

const ConfirmationModal = ({ title, body, open, setOpen, yes, setAppId, confirmDelete }) => {
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAppId(null);
  };

  const onYes = () => {
    yes(true);
    setOpen(false);
    if (confirmDelete) {
      confirmDelete();
    }
  };

  return (
    <Box>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">Cancel</Button>
          <Button onClick={onYes} variant="contained" >Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmationModal;
