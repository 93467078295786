import React, { useState } from "react";
import { Grid, Box, Card, CardHeader, ListItem, ListItemIcon, ListItemText, InputBase, IconButton,Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";



const NotificationWidget = ({ notifications }) => {
  // console.log({notifications});
  return (
    <Grid container item xs={12} sm={12}>
      <Grid item xs={12} sm={12} md={12}>
        <NotificationCard  title="Recent Notifications" notifications={notifications} />
      </Grid>
    </Grid>
  );
};

export default NotificationWidget;

function NotificationCard({ title, notifications }) {
  const [searchTerm, setSearchTerm] = useState("");
  // console.log("title is :", title);
  // console.log("notifications is :", notifications);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredNotifications = notifications
    ?.map((obj) => {
      return { ...obj, date: new Date(obj.createdAt) };
    })
    .sort((a, b) => Number(b. date) - Number(a.date))
    .filter(
      (noti) =>
        noti?.sendTo?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        noti?.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
        noti?.message.toLowerCase().includes(searchTerm.toLowerCase()),
    );

  return (
    <Card>
      <CardHeader sx={{ display:"flex",
      [`@media(max-width:640px)`]:{
        display:"flex",flexDirection:"column", gapX:"10px"
      }}}
        title={
          <React.Fragment>
           <Typography variant="h6" component="div" sx={{    [`@media(max-width:640px)`]:{
       mb:6
      }
          
          }}> {/* Add margin bottom for space */}
        {title}
      </Typography>
            <SearchBar handleSearch={handleSearch} />
          </React.Fragment>
        }
      />
      <Box sx={{ mx: 3, my: 2 }} dir="ltr" style={{ height: "280px", overflow: "auto" }} spacing={3}>
        {filteredNotifications.map((noti) => {
          return (
            <NotificationItem
              key={noti._id}
              userName={noti?.sendTo?.name}
              comment={noti?.subject}
              message={noti?.message}
              time={noti?.createdAt}
              url={noti?.url?.path}
              url2={noti?.url?.applicationId}
            />
          );
        })}
      </Box>
    </Card>
  );
}

function NotificationItem({ userName, comment, message, time ,url, url2 }) {
  const formattedDate = new Date(time).toLocaleString();

  return (
    <Link to={{
      pathname: url,
      search: url2 ? `?${url2}` : "",
    }}>  
    <Grid container item xs={12} sm={12}>
      <Grid item xs={12} sm={2}>
    
        <ListItem disableGutters>
          <ListItemText primary={<span style={{ fontWeight: 700 , color:"black"}}>{userName}</span>} />
        </ListItem>
      </Grid>
      <Grid item xs={12} sm={8}>
        <ListItem disableGutters>
          <ListItemText primary={<span style={{ fontWeight: 700 , color:"black"}}>{comment}</span>} secondary={message} />
        </ListItem>
      </Grid>
      <Grid item xs={12} sm={2}>
        <ListItem disableGutters>
        
            <ListItemText secondary={formattedDate} />
         
        </ListItem>
        
      </Grid>
    </Grid>
      </Link>
  );
}

function SearchBar({ handleSearch }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: "-1.5rem", justifyContent: "end", flexWrap:"wrap" }}>
      <InputBase placeholder="Search..." onChange={handleSearch} />
      
    </Box>
  );
}
