import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Badge, Box, Button, Checkbox, Modal, Tooltip } from "@mui/material";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";

const DocumentCheckListItem = ({ data, setAllFiles, allFiles, setDatabaseFiles, selectedFiles = [], student }) => {
  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [file, setFile] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const name = data?.name;
  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // const files = Object.keys(event.target.files).map((key) => event.target.files[key].name);
      setFile([...file, [...event.target.files[0].name]]);
      let newFiles = allFiles[name] ? [...allFiles[name], ...event.target.files] : [event.target.files[0]];
      setAllFiles({ ...allFiles, ...{ [name]: newFiles } });
      setDatabaseFiles((prevData) => {
        let newFilesNames = prevData[name] ? [...prevData[name], ...[event.target.files[0].name]] : [event.target.files[0].name];

        return {
          ...prevData,
          ...{ [name]: newFilesNames },
        };
      });
    }
  };

  const getPrevDoc = (data) => {
    setDatabaseFiles((prevData) => {
      let newState = { ...prevData };
      !newState[name] && (newState[name] = []);
      if (data.target.checked) newState[name] = [...newState[name], data.target.name];
      else newState[name] = [...newState[name].filter((v) => v !== data.target.name)];
      return newState;
    });
  };
  const openFile = (doc) => {
    const url = studentFileDownloadPath(student?.registeration, doc);
    handleOpen();
    setFileUrl(url);
  };
  return (
    <Box>
      <Box>
        <Accordion sx={{ background: "#F5F5F5" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box mr={3}>
              {file.length > 0 && (
                <Badge badgeContent={file.length} color="secondary" sx={{ "& .MuiBadge-badge": { fontSize: 7, height: 15, minWidth: 5 } }}>
                  <AttachFileIcon fontSize="1rem" />
                </Badge>
              )}
            </Box>
            <Typography>
              {data.title} {data.required && "*"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                mb: 2,
                px: 3,
              }}
            >
              {data?.prevDocs?.map((doc) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={getPrevDoc} name={doc} checked={selectedFiles && selectedFiles.find((v) => v === doc)} />{" "}
                    <button
                      style={{
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                      onClick={() => openFile(doc)}
                    >
                      <Tooltip title={"View"}>
                        <VisibilityIcon color="primary" />
                      </Tooltip>
                    </button>{" "}
                    <Typography>{doc}</Typography>{" "}
                  </Box>
                );
              })}
            </Box>
            <Stack
              direction="row"
              spacing={10}
              sx={{
                px: 4,
              }}
            >
              <Button variant="contained" component="label">
                Upload Document
                <input type="file" hidden onChange={onFileChange} />
              </Button>
            </Stack>
            <Box
              sx={{
                px: 4,
                pt: 2,
              }}
            >
              {file.map((item, id) => {
                return (
                  <Stack key={id} direction="row" alignItems="center" justifyContent="space-between" spacing={2} my={2}>
                    <Typography>
                      {id + 1} - {item}
                    </Typography>{" "}
                    {/* <Stack direction="row">
                      <IconButton aria-label="view" color="primary">
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="delete" color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Stack> */}
                  </Stack>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <iframe
          src={`${fileUrl}`}
          style={{
            background: "white",
          }}
          frameborder="0"
          height="80%"
          width="80%"
          title="pdf Viewer"
        ></iframe>
      </Modal>
    </Box>
  );
};

export default DocumentCheckListItem;
