import React, { useEffect, useRef } from "react";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Grid, Typography } from "@mui/material";
import TimelineSvg from "./TimelineSvg";
import Timeline from "./TimelineData";

const TimelineDescription = ({ student }) => {
  const ref = useRef(null);
  // components/MessageList.tsx
  // useEffect(() => {
  //   if (student?.timeline?.length) {
  //     ref.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [student?.timeline?.length]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={
          {
            // mt: '15px',
          }
        }
      >
        {student?.timeline?.map((x) => (
          <>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  opacity: "0.6",
                }}
              >
                {x.date}
              </Typography>

              <TimelineSeparator
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: "10px",
                }}
              >
                <TimelineSvg />
              </TimelineSeparator>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  opacity: "0.6",
                }}
              >
                {x.time}
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                {x.name}
              </Typography>

              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  opacity: "0.6",
                  mt: "13px",
                }}
              >
                {x.status}
              </Typography>

              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  opacity: "0.6",
                  mt: "13px",
                }}
              >
                {x.content}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
      <div ref={ref} />
    </>
  );
};

export default TimelineDescription;
