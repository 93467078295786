import React, { Component } from "react";
import InputForm from "../../Components/Common/Form/InputForm";
import LoginAuth from "../../Components/GoogleWorkSpace/loginAuth";
import { CRUDFunction } from "../../reduxCURD/container";
import { Button, Box, Typography, Grid, Link, Divider, Checkbox, FormControlLabel } from "@mui/material";
import { color } from "@mui/system";
const loginForm = {
  email: {
    name: "email",
    type: "email",
    label: "Email",
    value: "",
    required: true,
  },
  password: {
    name: "password",
    type: "password",
    label: "Password",
    value: "",
    required: true,
  },
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: null,
    };
    this.onLogin = this.onLogin.bind(this);
    this.getFormState = this.getFormState.bind(this);
    // console.log(props);
  }
  onLogin() {
    if (this.state.fields) {
      let email = this.state.fields["email"];
      let pass = this.state.fields["password"];

      this.props.createLogin({
        email: email,
        password: pass,
      });
      this.setState({
        loading: true,
        errorMessage: "",
      });
    }
  }
  getFormState(fields) {
    this.setState({
      fields: fields,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actionType === "LOGIN_CREATE_FAILURE" && this.props.actionType !== prevProps.actionType) {
      this.setState({
        loading: false,
        errorMessage: "Invalid Email or Password",
      });
    } else if (this.props.actionType === "LOGIN_CREATE_SUCCESS" && this.props.actionType !== prevProps.actionType && this.props.login) {
      this.setState({
        loading: false,
        errorMessage: "",
      });
      this.props.login.token && localStorage.setItem("access_token", this.props.login.token);
      this.props.login.result && localStorage.setItem("user", JSON.stringify(this.props.login.result));
      this.props.history.push("/");
      window.location.reload();
    }
  }
  render() {
    return (
      <Grid
        container
        sx={{
          p: 0,
          m: 0,


        }}
      >
        {/* <Grid
          item
          xs={6}
          sx={{
            height: "100vh",
            backgroundImage:
              "url('https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=')",
            backgroundSize: "cover",
          }}
        >
          <h2 style={{ margin: "40px" }}>LOGO</h2>
          <h3 style={{ marginTop: "550px", marginLeft: "30px" }}>Welcome!</h3>
          <p style={{ marginLeft: "30px" }}>Login to access account</p>
        </Grid> */}

        <Grid item md={6} xs={12} sx={{ height: "100vh", position: { xs: "absolute", md: "relative" }, overflowY: "hidden" }}>
          <Box>
            <img style={{ width: "6rem", marginLeft: "2rem", marginTop: "1rem" }} src="/assets/Success_Factor_Logo.png" alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "93vh",
              justifyContent: "center",
              alignItems: "center",
              // position: "absolute",
              width: { xs: "99%", sm: "calc(85%-50px)", md: "calc(35%-50px)" },
              marginLeft: { xs: 0, sm: "3rem", md: 0 },
              [`@media (min-width: 700px) and (max-width: 900px)`]: {
                marginLeft: "7rem",
              },
            }}
          >
            <InputForm
              config={{
                noFooter: true,
                getState: this.getFormState,
                headerValue: "Login",
                customFooter: (
                  <Box>
                    <Link href="#" sx={{ fontSize: "12px", marginLeft: 20, textDecoration: "none" }}>
                      {"Forgot Password?"}
                    </Link>
                    <Divider />
                    <FormControlLabel control={<Checkbox color="success" />} label="Remember Me" />
                    <Divider />
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1" gutterBottom component="div" sx={{ color: "red" }}>
                        {this.state.errorMessage}
                      </Typography>
                      <Button
                        sx={{ marginTop: 4, marginBottom: 2, width: "100%", borderRadius: "20px" }}
                        variant="contained"
                        onClick={this.onLogin}
                      >
                        Login
                      </Button>

                      {/* <Button sx={{ marginTop: 4, marginBottom: 2, width: "100%", borderRadius: "20px" }}></Button> */}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <LoginAuth />
                    </Box>
                  </Box>
                ),
              }}
              formFieldSchema={loginForm}
              handleClose={this.props.handleCancel}
              handleSave={this.props.handleSave}
            />

          </Box>
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          sx={{
            height: "100vh",
            backgroundImage: "url('/assets/SF login image.jpg')",
            backgroundSize: "cover",
          }}
        ></Grid>
      </Grid>
    );
  }
}
const loginContainer = CRUDFunction(Login, "login");
export default loginContainer;
