import { Box, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import ColumnContainer from "./ColumnContainer";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ApplicationDecisionStatusWidget from "./Widgets/RegistrationWidget";

const RowContainer = ({
  columns,
  dropWidgets,
  addWidget,
  widgets,
  columnsId,
  row,
  createNewColumn,
  edit,
  allWidgets,
  open,
  setOpen,
  columnId,
  openDrawer,
  onDeleteLayout,
  decisionStatus,
}) => {
  const { setNodeRef, listeners, attributes, transform, transition } = useSortable({
    id: row.id,
    data: {
      type: "row",
      row,
    },
  });

  // const [open, setOpen] = useState(false);
  // const [columnId, setColumnId] = useState("");
  // const openDrawer = (id) => {
  //   setOpen(true);
  //   setColumnId(id);
  // };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  return (
    <Box ref={setNodeRef} style={style}>
      <Box
        {...attributes}
        {...listeners}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {edit && (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Box
              onClick={() => createNewColumn(row.id)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "primary.main",
              }}
            >
              <span
                style={{
                  width: "1.4rem",
                  height: "1.5rem",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              </span>
              <span
                style={{
                  fontSize: ".8rem",
                }}
              >
                Add Column
              </span>
            </Box>
            <Box
              onClick={() =>
                onDeleteLayout({
                  type: "row",
                  id: row.id,
                })
              }
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "primary.main",
              }}
            >
              <span
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </span>
              <span
                style={{
                  fontSize: ".8rem",
                }}
              >
                Delete Row
              </span>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          [`@media(max-width:650px)`]: {
            flexDirection: "column",
          },
        }}
      >
        <SortableContext items={columnsId}>
          {columns?.map((column) => (
            <ColumnContainer
              key={column.id}
              edit={edit}
              column={column}
              addWidget={addWidget}
              widgetsArray={widgets.filter((widget) => widget.columnId === column.id)}
              dropWidgets={dropWidgets}
              allWidgets={allWidgets}
              open={open}
              setOpen={setOpen}
              columnId={columnId}
              openDrawer={openDrawer}
              onDeleteLayout={onDeleteLayout}
            />
          ))}
        </SortableContext>
      </Box>
    </Box>
  );
};

export default RowContainer;
