import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { Box, Button, Select, TextField, Typography, MenuItem } from "@mui/material";
import { getLoggedInUser } from "../../../utils/loggedInUser";
const TimelineData = ({ allLeadData, setComment }) => {
  // console.log(allLeadData);
  const [message, setMessage] = useState("");
  const [purpose, setPurpose] = useState("General");

  function incrementDateByOneDay(timestamp) {
    const dateObject = new Date(timestamp);

    dateObject.setDate(dateObject.getDate());

    const updatedDate = dateObject.toISOString().split("T")[0];

    return updatedDate;
  }

  function formatTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);

    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedTime = `${formattedHours}:${formattedMinutes}`;

    return formattedTime;
  }

  function convertTo12HourFormat(time24) {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24?.split(":")?.map(Number);

    // Determine if it's AM or PM
    const period = hours < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    let formattedHours = hours % 12;
    if (formattedHours === 0) {
      formattedHours = 12; // 12 AM or 12 PM
    }

    // Create the formatted time string
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")}`;

    return formattedTime;
  }

  function convertDateFormat(inputDate) {
    const parts = inputDate.split("-");
    if (parts.length !== 3) {
      return "Invalid Date";
    }

    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Ensure the components are valid numbers
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return "Invalid Date";
    }

    // Convert to the desired format (dd-mm-yyyy)
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  // const schedules = allLeadData?.scheduleCall
  //   ? Object.entries(allLeadData.scheduleCall).map(([key, item]) => {
  //       return {
  //         time: convertTo12HourFormat(item?.time),
  //         date: convertDateFormat(item?.date),
  //         purpose: item?.mode,
  //         content: item?.scheduleComment,
  //       };
  //     })
  //   : [];

  // const comments = allLeadData?.comments?.map((item) => {
  //   return {
  //     time: item?.time,
  //     date: item?.date,
  //     purpose: item?.purpose,
  //     content: item?.content,
  //   };
  // });

  // const messages = (comments || []).concat(schedules || []);
  // const messages = [...(schedules || []), ...(comments || [])];
  // const messages = [...(comments || []), ...(schedules || [])];

  const messages = allLeadData?.commentSheet?.map((item) => {
    return {
      time: item?.time,
      date: item?.date,
      purpose: item?.purpose,
      content: item?.content,
    };
  });

  // console.log(allLeadData?.commentSheet);

  const messagesWithDefaultEntry = [
    ...messages,
    {
      purpose: allLeadData?.manualForm?.purpose,
      content: allLeadData?.manualForm?.content,
      time: formatTimeFromTimestamp(allLeadData?.createdAt),
      date: incrementDateByOneDay(allLeadData?.createdAt),
    },
  ];

  // messagesWithDefaultEntry?.sort((a, b) => {
  //   const dateA = new Date(`${a?.date} ${a?.time}`);
  //   const dateB = new Date(`${b?.date} ${b?.time}`);
  //   return dateB - dateA;
  // });

  messagesWithDefaultEntry?.sort((a, b) => {
    const dateA = parseDateTime(a?.date, a?.time);
    const dateB = parseDateTime(b?.date, b?.time);

    if (!dateA && !dateB) {
      return 0;
    } else if (!dateA) {
      return 1;
    } else if (!dateB) {
      return -1;
    } else if (dateA.getTime() === dateB.getTime()) {
      // Preserve the original order for messages with the same timestamp
      return messages?.indexOf(b) - messages?.indexOf(a);
    } else {
      return dateB - dateA;
    }
  });

  function parseDateTime(date, time) {
    if (!date || !time) {
      return null; // Handle missing date or time
    }

    const [day, month, year] = date.split("-").map(Number);
    const [hours, minutes] = time.split(":").map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
      return null;
    }

    return new Date(year, month - 1, day, hours, minutes);
  }

  function getCurrentTime() {
    const now = new Date();

    const formatTime12Hour = (date) => {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";

      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }

      return `${hours}:${minutes}`;
    };

    const formattedTime = formatTime12Hour(now);

    return formattedTime;
  }

  function getCurrentDate() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  }

  const onSendMessage = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();

    const data = {
      time: time,
      date: date,
      purpose: purpose,
      content: message,
    };
    setComment(data, allLeadData?._id);
    setMessage("");
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1, p: 2, width: "100%" }}>
        {messagesWithDefaultEntry?.length > 0 ? (
          <Timeline
            sx={{
              mb: "2rem",
            }}
          >
            {messagesWithDefaultEntry?.map((msg, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent color="textSecondary">
                  <Typography color="textSecondary">
                    <span>{msg?.date || "N/A"}</span> <span style={{ fontSize: "0.9rem", marginLeft: "2px" }}>{msg?.time || "N/A"}</span>
                  </Typography>

                  <Typography color="textSecondary">{msg?.purpose}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent sx={{}}>
                  <Typography>{msg?.content || "No comment"}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        ) : (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <h1>Nothing to show</h1>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "start",
          position: "fixed",
          top: "75%",
          left: "50%",
          transform: "translateX(-50%)",
          background: "white",
          mx: "auto",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Comment"
          placeholder="Enter Comment"
          variant="outlined"
          sx={{
            width: "30rem",
          }}
        />
        <Select value={purpose} onChange={(e) => setPurpose(e.target.value)}>
          <MenuItem value="General">General</MenuItem>
          <MenuItem value="Online Meeting">Online Meeting</MenuItem>
          <MenuItem value="Call">Call</MenuItem>
          <MenuItem value="Email">Email</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          onClick={onSendMessage}
          sx={{
            height: "3rem",
            width: "5rem",
          }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default TimelineData;
