import React, { useState } from "react";
import { Box, TextField, Button, Grid } from "@mui/material";
import { getLoggedInUser } from "../../../../../utils/loggedInUser";
const StudentDescription = ({ updateStudent, student }) => {
  const loggedInUser = getLoggedInUser();
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear();

  const currentTime = new Date();

  let hours = currentTime.getHours();
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const meridiem = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  const formattedTime = `${hours}:${minutes}:${meridiem}`;

  const formattedDate = `${month}/${day}/${year}`;
  // console.log(student);
  const [commentValue, setCommentValue] = useState("");
  const sendComment = () => {
    updateStudent(
      {
        timeline: {
          content: commentValue,
          date: formattedDate,
          time: formattedTime,
          name: loggedInUser?.name,
          status: "General",
        },
      },
      student?._id,
    );
    setCommentValue("");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <TextField
              onChange={(e) => setCommentValue(e.target.value)}
              sx={{ width: "100%" }}
              value={commentValue}
              placeholder="Insert text here ..."
              multiline
              rows={4}
              maxRows={4}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", my: "20px" }}>
              <Button
                onClick={sendComment}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(25, 104, 173, 1)",
                  padding: "5px 25px",
                  justifyContent: "center",
                  border: "2px solid rgba(25, 104, 173, 0)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "rgba(25, 104, 173, 1)",
                    border: "2px solid rgba(25, 104, 173, 1)",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* <Box style={{ display: "flex", flexDirection: "column" }}>
                <TextField sx={{ width: "100%", pt: '25px' }}
                    placeholder="Insert text here ..."
                    multiline
                    rows={4}
                    maxRows={4}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '25px', }}>
                    <Button
                        sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: 'rgba(25, 104, 173, 1)',
                            padding: '5px 25px',
                            justifyContent: 'center',
                            color: 'white',
                            "&:hover": {
                                backgroundColor: 'white',
                                color: 'rgba(25, 104, 173, 1)',
                                border: '2px solid rgba(25, 104, 173, 1)'
                            }
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Box> */}
    </>
  );
};

export default StudentDescription;
