import { Box, Button, Stack } from "@mui/material";
import React from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider, Controller } from "react-hook-form";

const commentsForm = {
  comments: {
    name: "comments",
    type: "text",
    label: "Comments",
    value: "",
    required: true,
  },
};

const Comments = ({ setModal, goBack, control, handleSubmit, nextStep, setStudentData, isEdit, proceedData: sData }) => {
  const handleCancel = () => {
    setModal(false);
  };
  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data]);
    nextStep();
  };

  const methods = useForm({
    defaultValues: {
      comments: "",
    },
  });

  return (
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Controller
            name="comments"
            control={control}
            defaultValue={sData?.enquiryData?.comment}
            render={({ field }) => (
              <TextField
                // error={Boolean(errors.uploadResult)}
                label="Comments"
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>

      {/* <InputForm
        config={{
          noPaper: true,
          saveText: "Next",
          cancelButton: true,
          backButton: true,
          headerValue: "Student Coordinator Comments",
        }}
        handleSave={nextStep}
        handleClose={handleCancel}
        handleBack={backStep}
        formFieldSchema={commentsForm}
      /> */}
    </Box>
  );
};

export default Comments;
