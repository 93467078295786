import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Button, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import AgreementForm from "./AgreementForm";
import Notification from "../../Components/Common/Notification";
import DeleteIcon from "@mui/icons-material/Delete";

const AllAgreements = () => {
  const [showForm, setShowForm] = useState(false);
  const [agreementData, setAgreementData] = useState([]);
  const transformedData = agreementData?.map((item, index) => ({
    id: item.id,
    university: item.universityName,
    agreement: item.universityName,
    validTill: item.expirationDate,
    view: "view",
    download: "download",
    delete: "delete",
  }));

  const handleDelete = (id) => {
    const newRows = agreementData.filter((row) => row.id !== id);
    setAgreementData([...newRows]);
    setDeleteOpen(true);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "university",
      headerName: "University",
      width: 150,
    },
    {
      field: "agreement",
      headerName: "Agreement",
      width: 150,
    },
    {
      field: "validTill",
      headerName: "Valid Till",
      width: 200,
    },
    {
      field: "view",
      headerName: "View",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="outlined"
            onClick={(event) => {
              handleViewButton(event, cellValues);
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "Download",
      headerName: "Download",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="outlined"
            onClick={(event) => {
              handleDownloadButton(event, cellValues);
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const rows = transformedData;

  const handleViewButton = (event, cellValues) => {
    const { id } = cellValues;
    // console.log(id);/
  };
  const handleDownloadButton = (event, cellValues) => {
    // console.log(cellValues);/
  };
  const [successOpen, setSuccessOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Notification open={successOpen} setOpen={setSuccessOpen} text="Data Submitted Successfully" />
      <Notification open={deleteOpen} setOpen={setDeleteOpen} variant="error" text="Data removed from the table" />
      <Stack mb={2} direction="row" justifyContent="space-between">
        <Typography></Typography>
        <Button variant="contained" onClick={() => setShowForm(true)}>
          New Agreement
        </Button>
      </Stack>
      <DataGrid
        checkboxSelection={false}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      {showForm && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <AgreementForm
            setAgreementData={setAgreementData}
            agreementData={agreementData}
            setSuccessOpen={setSuccessOpen}
            setShowForm={setShowForm}
          />
        </Box>
      )}
    </Box>
  );
};

export default AllAgreements;
