import { Box, Typography } from "@mui/material";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const UniversitiesPreference = ({ appData, confirm, data, copy, submitted, setRevertData, user, appCheck }) => {
  return (
    <DataWrapper heading={"Universities Preference"}>
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Institution: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.institution?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="institution"
            text={appData?.institution?.label || "N/A"}
          />
        )}
      </Typography> */}
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Course Title: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.courseTitle?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="courseTitle"
            text={appData?.courseTitle?.label || "N/A"}
          />
        )}
      </Typography> */}
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Other Course: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.otherCourse || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="otherCourse"
            text={appData?.otherCourse || "N/A"}
          />
        )}
      </Typography> */}
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          University: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.university?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="university"
            text={appData?.university?.label || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Course: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.course?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="course"
            text={appData?.course?.label || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Course Link: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.courseLink || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="courseLink"
            text={appData?.courseLink || "N/A"}
          />
        )}
      </Typography>
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Level Of Study: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.levelOfStudy?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="levelOfStudy"
            text={appData?.levelOfStudy?.label || "N/A"}
          />
        )}
      </Typography> */}
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Level Of Study (Other): <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.otherLevelOfStudy || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="otherLevelOfStudy"
            text={appData?.otherLevelOfStudy || "N/A"}
          />
        )}
      </Typography> */}
      {/* <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Point of Entry: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.pointOfEntry?.label || "N/A"}</span>
        </span>

        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="pointOfEntry"
            text={appData?.pointOfEntry?.label || "N/A"}
          />
        )}
      </Typography> */}
    </DataWrapper>
  );
};

export default UniversitiesPreference;
