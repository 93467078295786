import Autocomplete from "@mui/material/Autocomplete";
const { Box, Paper, TextField, MenuItem } = require("@mui/material");

export const InputFormContainer = (props) => {
  return (
    <Box
      component="form"
      sx={
        props.configs && props.configs.sx
          ? props.configs.sx
          : {
              p: props.configs && !props.configs.noPadding ? 2 : 0,

              "& .MuiTextField-root": {
                m: !props.noMargin && props.fieldWidth ? 1 : 0,
                mb: props.fieldWidth ? 0 : 1,
                width: props.fieldWidth ? props.fieldWidth : "100%",
                boxShadow: "0px 0px 5px 0px rgb(136, 136, 136, 0.3)",
              },
            }
      }
    >
      {props.noPaper && props.children}
      {!props.noPaper && (
        <Paper sx={{ p: 2, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}> {props.children}</Paper>
      )}
    </Box>
  );
};

export const InputFormField = (props) => {
  let options = null;
  if (props.options) {
    options = props.options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      );
    });
  }
  if (props.type === "autocomplete") {
    return (
      <Autocomplete
        disablePortal
        autoHighlight={true}
        id="combo-box-demo"
        options={props.options}
        renderInput={(params) => (
          <TextField
            {...params}
            key={props.id ? props.id : props.name}
            id={props.muiId ? props.muiId : props.name}
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            required={props.required}
            label={props.label}
            variant="outlined"
            onChange={props.onInputChange}
            InputLabelProps={props.InputLabelProps}
            size={props.size}
            // InputProps={props.InputProps}
            style={props.style}
            {...props.queryStyle}
          />
        )}
      />
    );
  }
  return (
    <TextField
      key={props.id ? props.id : props.name}
      id={props.muiId ? props.muiId : props.name}
      type={props.type}
      name={props.name}
      value={props.value}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      required={props.required}
      label={props.label}
      variant="outlined"
      error={!props.valid && props.invalidMessage}
      select={props.type === "select"}
      helperText={!props.valid ? props.invalidMessage : props.helperText ? props.helperText : ""}
      onChange={props.onInputChange}
      InputLabelProps={props.InputLabelProps}
      size={props.size}
      InputProps={props.InputProps}
      style={props.style}
      {...props.queryStyle}
    >
      {options}
    </TextField>
  );
};
