import React from 'react';

const TimelineSvg = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <circle cx="12.5" cy="12" r="12" fill="#434343" />
                <circle cx="12.5" cy="12" r="7.5" fill="white" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="1" height="82" viewBox="0 0 1 82" fill="none">
                <path opacity="0.8" d="M0.5 1L0.499997 81" stroke="#434343" stroke-linecap="round" stroke-dasharray="2 2" />
            </svg>
        </>
    )
}

export default TimelineSvg;
