import React, { useState } from "react";
import { Container, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Mail, Inbox, Send, Drafts, Delete } from "@mui/icons-material";

const GmailUI = () => {
  const [showMessages, setShowMessages] = useState(false);

  const handleClick = () => {
    setShowMessages(true);
  };

  return (
    <Container sx={{ marginRight: "40rem" }}>
      <Grid container spacing={2}>
        {/* Sidebar */}
        <Grid item xs={3}>
          <Paper elevation={2} style={{ padding: "16px" }}>
            <Typography variant="h6">Gmail</Typography>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <Inbox />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <Send />
                </ListItemIcon>
                <ListItemText primary="Sent" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <Drafts />
                </ListItemIcon>
                <ListItemText primary="Drafts" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText primary="Trash" />
              </ListItem>
            </List>
            <Divider />
            <Typography variant="subtitle2">Labels</Typography>
            <List>
              <ListItem button>
                <ListItemText primary="Work" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Personal" />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={9}>
          <Paper elevation={2} style={{ padding: "16px" }}>
            <Typography variant="h6">Inbox</Typography>
          </Paper>

          {showMessages ? (
            <Paper elevation={2} style={{ padding: "16px", margin: "16px" }}>
              {/* Messages Screen */}
              <Typography variant="h6">Message 1</Typography>
              <Typography variant="body1">This is a sample message.</Typography>
            </Paper>
          ) : (
            <Paper elevation={2} style={{ padding: "16px", margin: "16px", cursor: "pointer" }} onClick={handleClick}>
              <Typography variant="h6">Messages</Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default GmailUI;
