import _ from "lodash";
let cart = [];

export function addToCart(item) {
  let newItem = item;
  newItem.uuid = guid();
  cart.push(item);
}

export function resetCart() {
  cart = [];
}

export function removeFromCart(id) {
  _.remove(cart, { uuid: id });
}

export function getCart() {
  return cart;
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}
