import MenuItem from "@mui/material/MenuItem";
import { getLoggedInUser } from "../../utils/loggedInUser";

let loginUser = getLoggedInUser();

export function loadUserOptions(conditionMethod, users, handleOptionClick) {
  if (!users || !conditionMethod) return null;
  let roleUsers = users.filter((user) => conditionMethod(user));
  let opts = roleUsers.map((user) => (
    <MenuItem value={user._id} key={user._id} onClick={() => handleOptionClick(user)}>
      {user.name}
    </MenuItem>
  ));

  if (loginUser && loginUser.role === "Counselor") {
    return (
      <MenuItem value={"self-assign"} key={"self-assign"} onClick={() => handleOptionClick(loginUser)}>
        {loginUser.name}
      </MenuItem>
    );
  } else {
    return opts;
  }
}

