import { CALL_API } from '../middleware/api'


export function fileUploads(file, uploadFolderPath, apiName) {
    if (!apiName) { apiName = "fileresource" };
    let apiPath = apiName + (uploadFolderPath ? uploadFolderPath : "/upload");
    let formData = new FormData();

    formData.append('file', file);

    const options = {
        headers: {
            processData: 'false',
            contentType: 'false',
            dataType: 'json',
            Accept: '*/*',
        },
        method: 'POST',
        body: formData
    };
    return {
        [CALL_API]: {
            endpoint: apiPath,
            authenticated: true,
            types: ["FILES_UPLOAD_REQUEST", "FILES_UPLOAD_SUCCESS", "FILES_UPLOAD_FAILURE"],
            config: options
        }
    }


}