import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Modal, StepButton, StepLabel } from "@mui/material";
import { Stack } from "@mui/system";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import { calculateActiveStep } from "./StatusCaclulator";
import { formatDate } from "../../../../utils/formatData";

import ViewProfile from "../../ViewProfile";

// const steps = ["Incomplete", "Completed", "Approved"];
const steps = ["Incomplete", "Completed"];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DataCollection = ({ setValue, setDisabledTab, disabledTab, onUpdate, data, student, studentTimelineUpdate }) => {
  const [activeStep, setActiveStep] = useState(calculateActiveStep(data, "dataCollection", ["completed"]));
  const [confirmModal, setConfirmModal] = useState(false);
  const loggedInUser = getLoggedInUser();

  const completed = () => {
    // setActiveStep(1);
    onUpdate(
      {
        dataCollection: {},
      },
      data._id,
    );
    studentTimelineUpdate("Application confirmed", "Confirmed");
  };
  // const nextTab = () => {
  //   setDisabledTab((prevData) => ({ ...prevData, tab1: false, tab0: false }));
  //   setValue(1);
  // };

  const approved = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        dataCollection: {
          approved: {
            approvedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    studentTimelineUpdate("Application approved", "Approved");
    // setActiveStep(2);
    // nextTab();
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <ViewProfile student={student} onUpdate={onUpdate} data={data} review={true} appCheck={true} user={loggedInUser} />
            {confirmModal && (
              <Modal
                open={true}
                onClick={() => setConfirmModal(false)}
                // sx={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
              >
                <Box sx={style}>
                  <Typography variant="h5">Are you sure you want to submit?</Typography>

                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem", marginTop: "1rem" }}>
                    <Button variant="outlined" onClick={() => setConfirmModal(false)}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={completed}>
                      Sure
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
            <Stack
              spacing={2}
              alignItems="flex-start"
              sx={{
                position: "absolute",
                right: "2%",
                bottom: "0%",
              }}
            >
              {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}
              <Button variant="contained" onClick={() => setConfirmModal(true)}>
                Confirm
              </Button>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Stack spacing={2} alignItems="flex-start">
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={approved}>
                Approve
              </Button>
              {/* <Button variant="outlined">Revert</Button> */}
            </Stack>
          </Stack>
        );
      default:
        return;
    }
  };

  // const createdAtObj = new Date(data?.createdAt);
  // const createdAt = createdAtObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });
  // const completionDateObj = new Date(data?.dataCollection?.completionDate);
  // const completionDate = completionDateObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });

  const handleData = (step) => {
    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              px: 10,
              pt: 2,
              "@media (max-width: 950px)": {
                p: "0px !important",
              },
            }}
          >
            {/* <Typography variant="subtitle1">Assigned to: {student?.appOfficer?.name}</Typography> */}
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Application filled by
              <span
                style={{
                  fontWeight: "500",
                }}
              >
                {data.user.name}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Date
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {formatDate(data?.createdAt)}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Time
              <span
                style={{
                  fontWeight: "500",
                }}
              >
                {formatDate(data?.createdAt, true)}
              </span>
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1">
              Application Approved by: {data?.dataCollection?.approved?.approvedBy?.name || "N.A"}{" "}
            </Typography>
            {/* <Typography variant="subtitle1">Date: {completionDate}</Typography> */}
          </Box>
        );
      case 2:
        return <Box></Box>;
      default:
        return "";
    }
  };

  const [stepNumber, setStepNumber] = useState(0);
  useEffect(() => {
    // set step based on  data.dataCollection
    // completion boolean false = 0 else 1
    // approved then show all relevant data and steps are on
    setActiveStep(calculateActiveStep(data, "dataCollection", ["completed"]));
  }, [{ data }]);

  // useEffect(() => {

  // }, [data])

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, idx) => (
          <Step key={label} disabled={data?.stage > 0 ? false : true}>
            <StepLabel>{label}</StepLabel>
            {/* <StepButton onClick={() => setStepNumber(idx)}>{label}</StepButton> */}
          </Step>
        ))}
      </Stepper>
      {data?.stage > 0 ? <Box mt={4}>{handleData(stepNumber)}</Box> : <Box mt={4}>{handleSteps(activeStep)}</Box>}
    </Box>
  );
};

export default DataCollection;
