import React from 'react'

const SearchIcon = () => {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_14_1547)">
<path d="M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6163 24 12C24 5.38373 18.6172 0 12 0ZM12 22.141C6.40898 22.141 1.85902 17.592 1.85902 12C1.85902 6.40805 6.40898 1.85902 12 1.85902C17.591 1.85902 22.141 6.40805 22.141 12C22.141 17.592 17.592 22.141 12 22.141Z" fill="white"/>
<path d="M16.6475 10.9869H12.9295V7.26883C12.9295 6.75573 12.514 6.33929 11.9999 6.33929C11.4859 6.33929 11.0704 6.75573 11.0704 7.26883V10.9869H7.35238C6.83835 10.9869 6.42285 11.4033 6.42285 11.9164C6.42285 12.4295 6.83835 12.8459 7.35238 12.8459H11.0704V16.564C11.0704 17.077 11.4859 17.4935 11.9999 17.4935C12.514 17.4935 12.9295 17.077 12.9295 16.564V12.8459H16.6475C17.1615 12.8459 17.577 12.4295 17.577 11.9164C17.577 11.4033 17.1615 10.9869 16.6475 10.9869Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_14_1547">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default SearchIcon