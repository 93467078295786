import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
// import DashboardBranchSelect from "./DashboardBranchSelect";
// import StatusWidget from "./Widgets/StatusWidgets";
import NotificationWidget from "./Widgets/NotificationWidget";
import QueryWidget from "./Widgets/QueryWidget";
import RegistrationWidget from "./Widgets/RegistrationWidget";
import ApplicationResponseWidget from "./Widgets/ApplicationResponseWidget";
import PreferrenceWidget from "./Widgets/PreferrenceWidget";
import UniPreference from "./Widgets/UniPreference";
// import DashboardUserSelect from "./DashboardUserSelect";
import ProcessingWidget from "./Widgets/ProcessingWidget";
import GraduationStatus from "./Widgets/GraduationStatus";
import ApplicationOffers from "./Widgets/ApplicationOffers";
// import Droppable from "./DandD/Droppable";
import { useState } from "react";
// import ColumnContainer from "./ColumnContainer";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMemo } from "react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay } from "@dnd-kit/core";
import LeadWidget from "../../Components/Common/AppWidgetSummary/LeadWidget";
import RowContainer from "./RowContainer";
import { curdActions } from "../../reduxCURD/actions";
import { CRUDFunction } from "../../reduxCURD/container";
import { getLoggedInUser } from "../../utils/loggedInUser";
import EditIcon from "@mui/icons-material/Edit";
import AccountDetails from "./AccountDetails";
import DashBoardFilters from "./DashBoardFilters";

const Widgets = ({
  statusData,
  decisionStatus,
  appStagesData,
  appResponseData,
  notificationsData,
  countryPrefs,
  institutesPrefs,
  sessionData,
  levelOfStudy,
  userValue,
  userOptionsList,
  branchOptions,
  setSelectedBranch,
  selectedBranch,
  loadDashboardData,
  setUserDashboard,
  updateUser,
  getUser,
  userData,
  users,
  DashboardFilters,
  setDashboardFilters,
  getDashboards,
  dashboardData,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [rows, setRows] = useState([]);
  const rowsId = useMemo(() => rows?.map((row) => row?.id), [rows]);
  // console.log("dashboard", dashboardData);
  const [columns, setColumns] = useState([]);
  const columnsId = useMemo(() => columns?.map((column) => column?.id), [columns]);

  const [activeColumn, setActiveColumn] = useState(null);
  const [activeWidget, setActiveWidget] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [deleteWidget, setDeleteWidget] = useState(false);

  const [widgets, setWidgets] = useState([]);

  const [edit, setEdit] = useState(false);

  // console.log("widgets", widgets);
  // console.log("columns", columns);

  const saveLayout = () => {
    // localStorage.setItem("columns", JSON.stringify(columns));
    // localStorage.setItem("widgets", JSON.stringify(widgets));
    // localStorage.setItem("rows", JSON.stringify(rows));
    const user = getLoggedInUser();
    // console.log("user", user);
    const updatedUser = {
      ...user,
      layout: {
        columns,
        widgets,
        rows,
      },
    };
    updateUser(updatedUser);
  };

  const editLayout = () => {
    setEdit(true);
  };

  useEffect(() => {
    if (userData) {
      const { layout } = userData;
      if (layout) {
        setColumns(layout.columns);
        setWidgets(layout.widgets);
        setRows(layout.rows);
      }
    }
  }, [userData]);

  // useEffect(() => {
  //   const columns = localStorage.getItem("columns");
  //   const widgets = localStorage.getItem("widgets");
  //   const rows = localStorage.getItem("rows");

  //   if (columns) {
  //     setColumns(JSON.parse(columns));
  //   }
  //   if (widgets) {
  //     setWidgets(JSON.parse(widgets));
  //   }
  //   if (rows) {
  //     setRows(JSON.parse(rows));
  //   }
  // }, []);
  function generateUniqueID() {
    const randomID = Math.floor(Math.random() * 10000);

    const formattedID = String(randomID).padStart(4, "0");

    return formattedID;
  }
  const createNewColumn = (rowId) => {
    const columnToAdd = {
      id: `column-${generateUniqueID()}`,
      title: `Column ${columns.length + 1}`,
      rowId,
    };

    setColumns([...columns, columnToAdd]);
  };

  const createNewRow = () => {
    const rowToAdd = {
      id: `row-${generateUniqueID()}`,
      title: `Row ${generateUniqueID()}`,
    };

    setRows([...rows, rowToAdd]);
  };

  const addWidget = (columnId, widgetId, rowId) => {
    const newWidget = {
      id: `widget-${generateUniqueID()}`,
      columnId: columnId,
      widget: widgetId,
      rowId,
    };

    const isDuplicate = widgets.find((widget) => widget.widget === widgetId);
    if (isDuplicate) return;

    setWidgets([...widgets, newWidget]);
  };

  function handleDragStart(event) {
    setDeleteWidget(true);

    if (event.active.data.current.type === "row") {
      setActiveRow(event.active.data.current.row);
      return;
    }
    if (event.active.data.current.type === "column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }
    if (event.active.data.current.type === "widget") {
      setActiveWidget(event.active.data.current.widget);
      return;
    }
  }

  const handleDragEnd = (event) => {
    setActiveColumn(null);
    setActiveWidget(null);
    setActiveRow(null);
    setDeleteWidget(false);

    const { active, over } = event;

    if (!over) return;

    const activeColumnId = active.id;
    const overColumnId = over.id;

    if (activeColumnId === overColumnId) return;

    setColumns((columns) => {
      const oldColumnIndex = columns?.findIndex((column) => column?.id === activeColumnId);
      const newColumnIndex = columns?.findIndex((column) => column?.id === overColumnId);

      const updatedColumns = arrayMove(columns, oldColumnIndex, newColumnIndex);

      return updatedColumns;
    });

    setRows((rows) => {
      const oldRowIndex = rows?.findIndex((row) => row?.id === activeColumnId);
      const newRowIndex = rows?.findIndex((row) => row?.id === overColumnId);

      const updatedRows = arrayMove(rows, oldRowIndex, newRowIndex);

      return updatedRows;
    });

    // if (over && over.id === "delete" && active) {
    //   setColumns((columns) => {
    //     const oldColumnIndex = columns?.findIndex((column) => column?.id === activeColumnId);

    //     const updatedColumns = columns?.filter((column) => column?.id !== activeColumnId);

    //     return updatedColumns;
    //   });
    //   setWidgets((widget) => {
    //     const oldColumnIndex = widget?.findIndex((w) => w?.widget === activeColumnId);

    //     const updatedColumns = widget?.filter((w) => w?.widget !== activeColumnId);

    //     return updatedColumns;
    //   });
    //   setRows((r) => {
    //     const oldColumnIndex = r?.findIndex((row) => row?.id === activeColumnId);

    //     const updatedColumns = r?.filter((row) => row?.id !== activeColumnId);

    //     return updatedColumns;
    //   });
    // }
  };

  const onDeleteLayout = (data) => {
    if (data.type === "row") {
      const newRows = rows.filter((item) => item.id !== data.id);
      const columnsInRow = columns.filter((item) => item.rowId !== data.id);
      const widgetsInColumns = widgets.filter((item) => item.rowId !== data.id);
      setRows(newRows);
      setColumns(columnsInRow);
      setWidgets(widgetsInColumns);
    } else if (data.type === "column") {
      const newColumns = columns.filter((item) => item.id !== data.id);
      const widgetsInColumns = widgets.filter((item) => item.columnId !== data.id);
      setColumns(newColumns);
      setWidgets(widgetsInColumns);
    } else if (data.type === "widget") {
      const newWidgets = widgets.filter((item) => item.id !== data.id);
      setWidgets(newWidgets);
    }
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveWidget = active.data.current.type === "widget";
    const isOverWidget = over.data.current.type === "widget";

    if (!isActiveWidget) return;

    if (isActiveWidget && isOverWidget) {
      setWidgets((w) => {
        const oldWidgetIndex = w.findIndex((widget) => widget.widget === activeId);
        const newWidgetIndex = w.findIndex((widget) => widget.widget === overId);

        w[oldWidgetIndex].columnId = w[newWidgetIndex].columnId;

        const updatedWidgets = arrayMove(w, oldWidgetIndex, newWidgetIndex);

        return updatedWidgets;
      });
    }

    const isOverColumn = over.data.current.type === "column";

    if (isActiveWidget && isOverColumn) {
      setWidgets((w) => {
        const oldWidgetIndex = w.findIndex((widget) => widget.widget === activeId);
        const newWidgetIndex = w.findIndex((widget) => widget.widget === overId);

        w[oldWidgetIndex].columnId = overId;

        const updatedWidgets = arrayMove(w, oldWidgetIndex, oldWidgetIndex);

        return updatedWidgets;
      });
    }
  };

  const [open, setOpen] = useState(false);
  const [columnId, setColumnId] = useState("");
  const openDrawer = (id) => {
    setOpen(true);
    setColumnId(id);
  };
  // console.log("dashboard", dashboardData);

  const dropWidgets = {
    1: <LeadWidget title={"Total Leads"} leadData={dashboardData?.dashboardData?.leadData} />,
    // 9: <LeadWidget title={"Total Applications"} total={statusData?.totalApplications.toString()} />,
    // // 10: <LeadWidget title={"Escalated"} total={statusData?.escalatedApps.toString()} />,
    // 11: <LeadWidget title={"Accepted Applications"} total={statusData?.acceptance.toString()} />,
    2: <QueryWidget title={"Total Query"} queryData={dashboardData?.dashboardData?.queryData} />,
    3: <RegistrationWidget registrationData={dashboardData?.dashboardData?.registrationData} />,
    4: <ProcessingWidget processingData={dashboardData?.dashboardData?.processingData} />,
    // 5: <PreferrenceWidget countryPrefs={countryPrefs} uniPrefs={institutesPrefs} />,
    // 12: <UniPreference countryPrefs={countryPrefs} uniPrefs={institutesPrefs} />,
    // 6: <GraduationStatus data={levelOfStudy} />,
    // 7: <ApplicationOffers appResponseData={appResponseData} />,
    8: <NotificationWidget notifications={notificationsData} />,
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      autoScroll={true}
    >
      <Box>
        <Box sx={{ px: 2 }}>
          <Box
            sx={{
              p: 2,
              textAlign: "start",
              backgroundColor: "whitesmoke",
              borderRadius: "1rem",
            }}
          >
            <AccountDetails />
          </Box>
          <DashBoardFilters
            getDashboards={getDashboards}
            users={users}
            DashboardFilters={DashboardFilters}
            setDashboardFilters={(fil) => setDashboardFilters(fil)}
          />
          {/* <Button onClick={() => createNewColumn()} variant="contained">
            Add Column
          </Button> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
              mt: 2,
              mb: 2,
            }}
          >
            {edit && (
              <Button onClick={() => createNewRow()} variant="contained">
                Add Row
              </Button>
            )}
            {edit && (
              <Button variant="contained" onClick={saveLayout}>
                Save Layout
              </Button>
            )}
            {edit ? (
              <Button variant="outlined" onClick={() => setEdit(false)}>
                Done
              </Button>
            ) : (
              // <Button variant="outlined" onClick={editLayout}>
              //   Edit Layout
              // </Button>
              <EditIcon onClick={editLayout} color="primary" sx={{ cursor: "pointer" }} />
            )}

            {/* {startSort ? (
          <Button variant="contained" onClick={() => setStartSort(false)}>
            Done
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setStartSort(true)}>
            Edit
          </Button>
        )} */}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <SortableContext items={rowsId}>
              {rows?.map((row) => (
                <Box
                  // className={edit ? "animation" : ""}
                  key={row.id}
                  sx={{
                    // border: "1px solid black",
                    p: 2,
                    // display: "flex",
                    // flexDirection: "column",
                    backgroundColor: edit ? "rgba(0,0,0,0.09)" : "transparent",
                    borderRadius: "1rem",
                  }}
                >
                  <RowContainer
                    columns={columns?.filter((column) => column?.rowId === row?.id)}
                    widgets={widgets}
                    dropWidgets={dropWidgets}
                    addWidget={addWidget}
                    columnsId={columnsId}
                    decisionStatus={decisionStatus}
                    row={row}
                    edit={edit}
                    createNewColumn={createNewColumn}
                    allWidgets={widgets}
                    open={open}
                    setOpen={setOpen}
                    columnId={columnId}
                    openDrawer={openDrawer}
                    onDeleteLayout={onDeleteLayout}
                  />
                </Box>
              ))}
            </SortableContext>
          </Box>
        </Box>
        {/* {deleteWidget && (
          <Box
            sx={{
              position: "fixed",
              bottom: "2rem",
              right: "2rem",
              backgroundColor: "red",
              color: "white",
              p: 3,
              borderRadius: "1rem",
            }}
          >
            <Droppable id={"delete"}>Delete</Droppable>
          </Box>
        )} */}
      </Box>
      {/* <DragOverlay>{activeColumn && <ColumnContainer column={activeColumn} />}</DragOverlay> */}
    </DndContext>
    // <Box
    //   sx={{
    //     pl: 3,
    //     pr: 3,
    //     py: 12,
    //   }}
    // >
    //   <Grid container spacing={3}>
    //     <DashboardBranchSelect
    //       selectedBranch={selectedBranch}
    //       onBranchSelect={(e) => {
    //         setSelectedBranch(e.target.value);
    //         loadDashboardData(true);
    //       }}
    //       branchOptions={branchOptions}
    //     />

    //     <DashboardUserSelect
    //       userOptionsList={userOptionsList}
    //       selectedUser={userValue}
    //       onUserSelect={(e) => {
    //         setUserDashboard(e.target.value);
    //         loadDashboardData(false, e.target.value);
    //       }}
    //     />

    //     <StatusWidget {...statusData} />

    //     <ApplicationStagesStatus appStagesData={appStagesData} />

    //     <ApplicationDecisionStatusWidget applicationStatusPieChart={decisionStatus} />
    //     {/* <ApplicationResponseWidget appResponseData={appResponseData} /> */}

    //     <ApplicationSession sessionData={sessionData} />

    //     <PreferrenceWidget countryPrefs={countryPrefs} uniPrefs={institutesPrefs} />

    //     <GraduationStatus data={levelOfStudy} />

    //     <ApplicationOffers appResponseData={appResponseData} />

    //     <NotificationWidget notifications={notificationsData} />
    //   </Grid>
    // </Box>
  );
};

export default Widgets;
