import React, { useState } from 'react';
import { Paper, Typography, Box, Card,  CardContent, } from '@mui/material';
import { Inbox, Send } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from "./CustomDialog"

const Draft = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveDraft = () => {
    handleCloseDialog();
  };

  return (
    <>
      <Paper elevation={2} style={{ padding: '16px' }}>
        <Typography variant="h6">Draft</Typography>
      </Paper>

      <Card
        elevation={2}
        style={{ padding: '16px', margin: '16px', cursor: 'pointer', position: 'relative' }}
        onClick={handleOpenDialog}
      >
        <Typography variant="h6">Message 1</Typography>
        <Typography variant="body1">This is a draft message</Typography>
      </Card>

      <CustomDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveDraft}
      />
    </>
  );
};

export default Draft;
