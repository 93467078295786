import React, { useState, useEffect } from "react";
import { getLoggedInUser } from "../../utils/loggedInUser";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Divider, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, Tooltip } from "@mui/material";
import { borderRadius, Stack } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import { Link } from "react-router-dom";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import EscalateIcon from "../../Images/EscalateIcon";
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';

const NewStudentCard = ({ data, location, updateStudent, getStudents, isProcessingRoute }) => {
  // console.log({ data })
  const [userBranch, setUserBranch] = useState(null);
  const [StatusModal, setStatusModal] = useState(false);
  const [StatusProcessModal, setStatusProcessModal] = useState(false);

  // Modal For Registration Status
  const StatusModalOpen = () => setStatusModal(true);

  const StatusModalClose = () => {
    setStatusModal(false);
  };

  // For InProcess Status
  const handleProcess = async () => {
    await updateStudent({ isProcess: true, isClosed: false }, data?._id);
    getStudents();
    StatusModalClose();
  };
  // For Closed Status
  const handleClose = async () => {
    await updateStudent({ isClosed: true, isProcess: false }, data?._id);
    getStudents();
    StatusModalClose();
  };

  // Modal For Processing Status
  const StatusModalOpen1 = () => setStatusProcessModal(true);

  const StatusModalClose1 = () => {
    setStatusProcessModal(false);
  };

  // For InProcess Status
  const handleProcessing = async () => {
    await updateStudent({ inProcess: true, ProcessClosed: false }, data?._id);
    getStudents();
    StatusModalClose1();
  };
  // For ProcessClosed Status
  const handleClosing = async () => {
    await updateStudent({ ProcessClosed: true, inProcess: false }, data?._id);
    getStudents();
    StatusModalClose1();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const user = getLoggedInUser();
    setUserBranch(user.branch);
  }, []);

  let name = "";
  if (data?.registrationData?.firstName) name += data.registrationData.firstName;
  if (data?.registrationData?.middleName) name += " " + data.registrationData.middleName;
  if (data?.registrationData?.lastName) name += " " + data.registrationData.lastName;
  return (
    <>
      <Card
        sx={{
          width: 345,
          overflow: "initial",
          [`@media (max-width: 455px)`]: {
            width: 300,
          },

          [`@media (max-width: 390px)`]: {
            width: 270,
          },
        }}
      >
        {/* <CardMedia
        sx={{ height: 140 }}
        image="https://images.unsplash.com/photo-1531545514256-b1400bc00f31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
        title="green iguana"
      /> */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            sx={{
              zIndex: 1,
              width: 75,
              height: 75,
              position: "relative",
              marginTop: "-40px",
              overflow: "hidden",
            }}
            src=""
          />
        </Box>

        <CardContent>
          <Stack spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}>
            <Stack>
              <Typography variant="h5" component="div" sx={{ textAlign: "center", fontSize: 14, color: "#1968AD" }}>
                {name}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}
            >
              Reg #: {data?.registeration}
            </Typography>
          </Box>

          <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" color="#1968AD">
              Counselor(s)
            </Typography>
            <span>{data?.counselor?.name}</span>
          </Stack>

          <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" color="#1968AD">
              Branch{" "}
            </Typography>
            <span style={{ color: "gray", fontSize: "0.8rem" }}>
              {data?.registrationData?.branch?.text || data?.registrationData?.branch}
            </span>
          </Stack>

          <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" color="#1968AD">
              Application Officer
            </Typography>
            <span>{data?.appOfficer?.name}</span>
          </Stack>

          <Stack mt={2} spacing={2} color="gray">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="#1968AD">
                Applications
              </Typography>
              <Box
                sx={{
                  width: "23px",
                  height: "23px",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: ".9rem",
                  fontWeight: "bold",
                }}
              >
                {data?.appPackStats?.applicationCounter || 0}
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="#1968AD">
                Escalate
              </Typography>
              <Box>
                {data?.appPackStats?.escalatedValue == "Low Priorty" ? (
                  <EscalateIcon color={"blue"} />
                ) : data?.appPackStats?.escalatedValue == "High Priority" ? (
                  <EscalateIcon color={"red"} />
                ) : data?.appPackStats?.escalatedValue == "Medium Priority" ? (
                  <EscalateIcon color={"green"} />
                ) : null}
              </Box>
            </Stack>

            {!isProcessingRoute ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" color="#1968AD">
                  Status
                </Typography>
                <Tooltip
                  title={
                    data?.isProcess === true
                      ? "In Process"
                      : data?.isClosed === true
                      ? "Registration Closed"
                      : data?.isProcessing === true
                      ? "Moved to Processing"
                      : "Not Started"
                  }
                  placement="top"
                >
                  <FilterTiltShiftIcon
                    onClick={() => {
                      StatusModalOpen();
                    }}
                    sx={{
                      marginRight: "5px",
                      color:
                        data?.isProcess === true
                          ? "Orange"
                          : data?.isClosed === true
                          ? "Red"
                          : data?.isProcessing === true
                          ? "Green"
                          : "Grey",
                    }}
                  />
                </Tooltip>
              </Stack>
            ) : (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" color="#1968AD">
                  Status
                </Typography>
                <Tooltip
                  title={data?.inProcess === true ? "In Process" : data?.ProcessClosed === true ? "Processing Closed" : "Not Started"}
                  placement="top"
                >
                  <FilterTiltShiftIcon
                    onClick={() => {
                      StatusModalOpen1();
                    }}
                    sx={{
                      marginRight: "5px",
                      color: data?.inProcess === true ? "Orange" : data?.ProcessClosed === true ? "Red" : "Grey",
                    }}
                  />
                </Tooltip>
              </Stack>
            )}
            {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>Conditional Offer Letters</Typography>
            <Box
              sx={{
                bgcolor: "lightGray",
                width: "23px",
                height: "23px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
              }}
            >
              {data.noOfConditionalOfferLetters || 0}
            </Box>
          </Stack> */}
            {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>Deposits Paid</Typography>
            <Box
              sx={{
                bgcolor: "lightGray",
                width: "23px",
                height: "23px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
              }}
            >
              {data.depositsPaid || 0}
            </Box>
          </Stack> */}
            {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>1-20 Issued</Typography>
            <Box
              sx={{
                bgcolor: "lightGray",
                width: "23px",
                height: "23px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
              }}
            >
              1
            </Box>
          </Stack> */}
          </Stack>
        </CardContent>

        {/* <CardActions>
        <Button size="medium">Action</Button>
        <Button size="medium">Action</Button>
      </CardActions> */}
        <Link to={location == "/processing" ? `/processing/${data?.registeration}` : `/students/${data?.registeration}`}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <a
              style={{
                color: "white",
                backgroundColor: "#1968AD",
                width: "75px",
                height: "28px",
                marginRight: "30px",
                marginBottom: "30px",
                fontSize: "12px",
                fontWeight: 300,
                letterSpacing: "1.25px",
                fontStyle: "normal",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              View
            </a>
          </Box>
        </Link>
      </Card>

      {/* Status Modal For Registration */}
      <Modal open={StatusModal} onClose={StatusModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Registration Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Registration Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Registration Status" />}
            >
              <MenuItem onClick={handleProcess}>In Process</MenuItem>
              <MenuItem onClick={handleClose}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>

      {/* Status Modal For Processing */}
      <Modal
        open={StatusProcessModal}
        onClose={StatusModalClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Processing Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Processing Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Processing Status" />}
            >
              <MenuItem onClick={handleProcessing}>In Process</MenuItem>
              <MenuItem onClick={handleClosing}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default NewStudentCard;
