import React from "react";
import { ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";

function NotificationMessage({ onClick, isRead, message, comment, time }) {
  const formattedDate = new Date(time).toLocaleString();
  const unreadStyle = {
    fontWeight: "bold",
  };

  return (
    <ListItem button onClick={onClick}>
       <ListItemIcon
        sx={{
          alignSelf: { xs: "flex-start", sm:"center"  }, 
          marginTop:{xs:"30px" , sm:"10px"}
        }}
      >
        <NotificationsIcon />
      </ListItemIcon>

      <ListItem   sx={{ width: "100%" }}>
      <ListItemText primary={<span style={isRead ? null : unreadStyle}>{comment}</span>} secondary={message} />
      </ListItem>

     
      <ListItemText secondary={formattedDate} sx={{ mt: 10 }} />
      

    </ListItem>
  );
}

export default NotificationMessage;
