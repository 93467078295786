import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Button, Drawer, Typography, Avatar, SvgIcon } from "@mui/material";

// hooks
import useResponsive from "./hooks/useResponsive";
// components

import NavSection from "./nav-section";
//
import navConfig from "./config";
import Scrollbar from "../../Common/scrollbar/Scrollbar";
import { getLoggedInUser } from "../../../utils/loggedInUser";
import SvgColor from "./svg-color/SvgColor";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "xl");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  let user = getLoggedInUser();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>{/* <img src={``} height="81px" width="60px" /> */}</Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        {/* <Link underline="none"> */}
        <StyledAccount>
          <Avatar src={user && user.photoURL} alt="photoURL" />

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {user && user.name}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {user && user.role}
            </Typography>
          </Box>
        </StyledAccount>
        {/* </Link> */}
      </Box>
      <NavSection data={navConfig} />
    </Scrollbar>
  );

  return (
    user && (
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { xl: user ? NAV_WIDTH : 0 },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: "background.default",
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    )
  );
}
