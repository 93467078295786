// component
import SvgColor from "./svg-color";

import { DashboardRounded } from "@mui/icons-material";
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color: "white" }} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("icons"),
    exact: true,
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer"],
  },
  {
    title: "Leads",
    path: "/leads",
    icon: icon("leader"),
    permissions: ["Marketing", "Management", "Branch Manager", "Administrator", "Counselor"],
  },
  {
    title: "Query",
    path: "/query",
    icon: icon("leader"),
    permissions: ["Marketing", "Management", "Branch Manager", "Administrator", "Counselor"],
  },
  {
    title: "Registration ",
    path: "/students",
    icon: icon("Vector"),
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor"],
  },
  {
    title: "Application Processing",
    path: "/processing",
    icon: icon("process"),
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },
  {
    title: "Tasks",
    path: "/tasks",
    icon: icon("tasks-solid"),
    permissions: ["Administrator", "Management", "Branch Manager", "Counselor", "Application Officer"],
  },
  {
    title: "Reporting",
    path: "/report",
    icon: icon("settings"),
    permissions: ["Administrator", "Management"],
  },

  // {
  //   title: "Forms",
  //   path: "/forms",
  //   name: "lead",
  //   icon: icon("leader"),
  // },
  // {
  //   title: "Submissions",
  //   path: "/submission",
  //   name: "lead",
  //   icon: icon("leader"),
  // },
  // {
  //   title: "Forms",
  //   path: "/forms",
  //   name: "lead",
  //   icon: icon("ic_user"),
  // },

  {
    title: "User",
    path: "/user",
    icon: icon("settings"),
    name: "user",
  },

  // {
  //   title: "Profile",
  //   path: "/profile",
  //   icon: icon("settings"),
  //   name: "user",
  // },

  {
    title: "Agreements",
    path: "/agreements",
    icon: icon("contract"),
    name: "finance",
  },
  {
    title: "Chat",
    path: "/chat",
    icon: icon("chat"),
    name: "chat",
  },
  // {
  //   title: "Emails",
  //   path: "/email",
  //   icon: icon("ic_user"),
  //   name: "chat",
  // },
  {
    title: "Report",
    path: "/finance",
    icon: icon("contract"),
    name: "finance",
  },
  // {
  //   title: "Invoice",
  //   path: "/invoice",
  //   icon: icon("contract"),
  //   name: "finance",
  // },
];

export default navConfig;
