import React, { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext, Controller, useFieldArray } from "react-hook-form";
import { Autocomplete, Box, Stack, Button, Typography, StepLabel, Stepper, TextField } from "@mui/material";
import { alevelSubjectList, hsscSubjectList, olevelSubjectList, otherFormSubjectList, sccSubjectList } from "../../../Data/subjectLists";
import { repeaterDefaultValueIndex, repeaterLoader } from "../../StudentProfile/ApplicationForm/Forms/repeaterLoader";

const Education = ({ setModal, goBack, control, handleSubmit, nextStep, setStudentData, isEdit, existingStudentData: sData }) => {
  const [otherAlevelSubjectList, setOtherAlevelSubjectList] = useState(
    isEdit ? sData.registrationData?.alevelForm?.[0]?.alevelSubjectList : "",
  );
  const [otherASlevelSubjectList, setOtherASlevelSubjectList] = useState(
    isEdit ? sData.registrationData?.aSlevelForm?.[0]?.aSlevelSubjectList : "",
  );
  const [otherOlevelSubjectList, setOtherOlevelSubjectList] = useState(
    isEdit ? sData.registrationData?.olevelForm?.[0]?.olevelSubjectList : "",
  );
  const [otherHsscSubjectList, setOtherHsscSubjectList] = useState(isEdit ? sData.registrationData?.hsscForm?.[0]?.hsscSubjectList : "");
  const [otherSccSubjectList, setOtherSccSubjectList] = useState(isEdit ? sData.registrationData?.sccForm?.[0]?.sccSubjectList : "");
  const [otherOtherFormSubjectList, otherOtherOtherFormSubjectList] = useState(
    isEdit ? sData.registrationData?.otherForm?.[0]?.otherFormSubjectList : "",
  );

  // console.log(sData.registrationData)

  const methods = useForm(
    isEdit
      ? {
          defaultValues: {
            postgraduateInstitution: "",
            postgraduateDegreeTitle: "",
            postgraduateGrades: "",
            postgraduateCompletionYear: "",
            undergraduateInstitution: "",
            undergraduateDegreeTitle: "",
            undergraduateGrades: "",
            undergraduateCompletionYear: "",

            alevelForm: repeaterLoader(!isEdit, "alevelForm", sData, {
              alevelInstitution: "",
              alevelGrades: "",
              alevelCompletionYear: "",
              alevelSubjectList: "",
              otherAlevelSubjectList: "",
              alevelAwardingBody: "",
            }),

            aSlevelForm: repeaterLoader(!isEdit, "aSlevelForm", sData, {
              aSlevelInstitution: "",
              aSlevelGrades: "",
              aSlevelDegreeTitle: "",
              aSlevelCompletionYear: "",
              aSlevelSubjectList: "",
              otherASlevelSubjectList: "",
              aSlevelAwardingBody: "",
            }),

            olevelForm: repeaterLoader(!isEdit, "olevelForm", sData, {
              olevelInstitution: "",
              olevelGrades: "",
              olevelDegreeTitle: "",
              olevelCompletionYear: "",
              olevelSubjectList: "",
              otherOlevelSubjectList: "",
              olevelAwardingBody: "",
            }),

            hsscForm: repeaterLoader(!isEdit, "hsscForm", sData, {
              hsscInstitution: "",
              hsscGrades: "",
              hsscDegreeTitle: "",
              hsscCompletionYear: "",
              hsscSubjectList: "",
              otherHsscSubjectList: "",
            }),

            sccForm: repeaterLoader(!isEdit, "sccForm", sData, {
              sccInstitution: "",
              sccGrades: "",
              sccDegreeTitle: "",
              sccCompletionYear: "",
              sccSubjectList: "",
              otherSccSubjectList: "",
            }),
            otherForm: repeaterLoader(!isEdit, "otherForm", sData, {
              otherFormInstitution: "",
              otherFormGrades: "",
              otherFormDegreeTitle: "",
              otherFormCompletionYear: "",
              otherFormSubjectList: "",
              otherOtherFormSubjectList: "",
            }),
          },
        }
      : {
          defaultValues: {
            postgraduateInstitution: "",
            postgraduateDegreeTitle: "",
            postgraduateGrades: "",
            postgraduateCompletionYear: "",
            undergraduateInstitution: "",
            undergraduateDegreeTitle: "",
            undergraduateGrades: "",
            undergraduateCompletionYear: "",
            alevelForm: [
              {
                alevelInstitution: "",
                alevelGrades: "",
                alevelCompletionYear: "",
                alevelSubjectList: "",
                alevelAwardingBody: "",
                otherAlevelSubjectList: "",
              },
            ],
            aSlevelForm: [
              {
                aSlevelInstitution: "",
                aSlevelGrades: "",
                aSlevelDegreeTitle: "",
                aSlevelCompletionYear: "",
                aSlevelSubjectList: "",
                otherASlevelSubjectList: "",
                aSlevelAwardingBody: "",
              },
            ],
            olevelForm: [
              {
                olevelInstitution: "",
                olevelGrades: "",
                olevelDegreeTitle: "",
                olevelCompletionYear: "",
                olevelSubjectList: "",
                otherOlevelSubjectList: "",
                olevelAwardingBody: "",
              },
            ],
            hsscForm: [
              {
                hsscInstitution: "",
                hsscGrades: "",
                hsscDegreeTitle: "",
                hsscCompletionYear: "",
                otherHsscSubjectList: "",
                hsscSubjectList: "",
              },
            ],
            sccForm: [
              {
                sccInstitution: "",
                sccGrades: "",
                sccDegreeTitle: "",
                sccCompletionYear: "",
                otherSccSubjectList: "",
                sccSubjectList: "",
              },
            ],
            otherForm: [
              {
                otherFormInstitution: "",
                otherFormGrades: "",
                otherFormDegreeTitle: "",
                otherFormCompletionYear: "",
                otherOtherFormSubjectList: "",
                otherFormSubjectList: "",
              },
            ],
          },
        },
  );

  const PostgraduateForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    return (
      <Stack spacing={2}>
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.postgraduateInstitution : ""}
          control={control}
          name="postgraduateInstitution"
          render={({ field }) => (
            <TextField
              label="Institution"
              error={Boolean(errors.postgraduateInstitution)}
              helperText={errors.postgraduateInstitution?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.postgraduateDegreeTitle : ""}
          control={control}
          name="postgraduateDegreeTitle"
          render={({ field }) => (
            <TextField
              label="Degree Title"
              error={Boolean(errors.postgraduateDegreeTitle)}
              helperText={errors.postgraduateDegreeTitle?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.postgraduateGrades : ""}
          control={control}
          name="postgraduateGrades"
          render={({ field }) => (
            <TextField
              label="Grades"
              error={Boolean(errors.postgraduateGrades)}
              helperText={errors.postgraduateGrades?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Box>
          <Typography>Completion Year</Typography>
          <Controller
            defaultValue={isEdit ? sData?.registrationData?.postgraduateCompletionYear : ""}
            control={control}
            name="postgraduateCompletionYear"
            render={({ field }) => (
              <TextField
                error={Boolean(errors.postgraduateCompletionYear)}
                helperText={errors.postgraduateCompletionYear?.message}
                id="outlined-basic"
                type="date"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Box>
      </Stack>
    );
  };

  const UndergraduateForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    return (
      <Stack spacing={2}>
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.undergraduateInstitution : ""}
          control={control}
          name="undergraduateInstitution"
          render={({ field }) => (
            <TextField
              label="Institution"
              error={Boolean(errors.undergraduateInstitution)}
              helperText={errors.undergraduateInstitution?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.undergraduateDegreeTitle : ""}
          control={control}
          name="undergraduateDegreeTitle"
          render={({ field }) => (
            <TextField
              label="Degree Title"
              error={Boolean(errors.undergraduateDegreeTitle)}
              helperText={errors.undergraduateDegreeTitle?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Controller
          defaultValue={isEdit ? sData?.registrationData?.undergraduateGrades : ""}
          control={control}
          name="undergraduateGrades"
          render={({ field }) => (
            <TextField
              label="Grades"
              error={Boolean(errors.undergraduateGrades)}
              helperText={errors.undergraduateGrades?.message}
              id="outlined-basic"
              type="text"
              variant="outlined"
              {...field}
            />
          )}
        />
        <Box>
          <Typography>Completion Year</Typography>
          <Controller
            defaultValue={isEdit ? sData?.registrationData?.undergraduateCompletionYear : ""}
            control={control}
            name="undergraduateCompletionYear"
            render={({ field }) => (
              <TextField
                error={Boolean(errors.undergraduateCompletionYear)}
                helperText={errors.undergraduateCompletionYear?.message}
                id="outlined-basic"
                type="date"
                variant="outlined"
                {...field}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Box>
      </Stack>
    );
  };

  const AlevelForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "alevelForm",
    });

    function AddMoreAlevelFields() {
      append({
        alevelInstitution: "",
        alevelGrades: "",
        alevelDegreeTitle: "",
        alevelCompletionYear: "",
        alevelSubjectList: "",
        alevelAwardingBody: "",
        otherAlevelSubjectList: "",
      });
    }

    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setOtherAlevelSubjectList(data?.label);
      } else {
        setOtherAlevelSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelInstitution", index, sData) : ""}
                  control={control}
                  name={`alevelForm.${index}.alevelInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.alevelInstitution)}
                      helperText={errors.alevelInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`alevelForm.${index}.alevelDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.alevelDegreeTitle)}
                      helperText={errors.alevelDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelSubjectList", index, sData) : ""}
                  control={control}
                  name={`alevelForm.${index}.alevelSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={alevelSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherAlevelSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "otherAlevelSubjectList", index, sData) : ""}
                    control={control}
                    name={`alevelForm.${index}.otherAlevelSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other Alevel Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelGrades", index, sData) : ""}
                  control={control}
                  name={`alevelForm.${index}.alevelGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.alevelGrades)}
                      helperText={errors.alevelGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelCompletionYear", index, sData) : ""}
                    control={control}
                    name={`alevelForm.${index}.alevelCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.alevelCompletionYear)}
                        helperText={errors.alevelCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "alevelForm", "alevelAwardingBody", index, sData) : ""}
                  control={control}
                  name={`alevelForm.${index}.alevelAwardingBody`}
                  render={({ field }) => (
                    <TextField
                      error={Boolean(errors.alevelAwardingBody)}
                      helperText={errors.alevelAwardingBody?.message}
                      id="outlined-basic"
                      type="text"
                      label="Awarding Body"
                      variant="outlined"
                      {...field}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreAlevelFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const ASLevelForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "aSlevelForm",
    });

    function AddMoreAlevelFields() {
      append({
        aSlevelInstitution: "",
        aSlevelGrades: "",
        aSlevelDegreeTitle: "",
        aSlevelCompletionYear: "",
        aSlevelSubjectList: "",
        aSlevelAwardingBody: "",
        otherASlevelSubjectList: "",
      });
    }
    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setOtherASlevelSubjectList(data?.label);
      } else {
        setOtherASlevelSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelInstitution", index, sData) : ""}
                  control={control}
                  name={`aSlevelForm.${index}.aSlevelInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.aSlevelInstitution)}
                      helperText={errors.aSlevelInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`aSlevelForm.${index}.aSlevelDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.aSlevelDegreeTitle)}
                      helperText={errors.aSlevelDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelSubjectList", index, sData) : ""}
                  control={control}
                  name={`aSlevelForm.${index}.aSlevelSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={alevelSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherASlevelSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "otherASlevelSubjectList", index, sData) : ""}
                    control={control}
                    name={`aSlevelForm.${index}.otherASlevelSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other ASlevel Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelGrades", index, sData) : ""}
                  control={control}
                  name={`aSlevelForm.${index}.aSlevelGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.aSlevelGrades)}
                      helperText={errors.aSlevelGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelCompletionYear", index, sData) : ""}
                    control={control}
                    name={`aSlevelForm.${index}.aSlevelCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.aSlevelCompletionYear)}
                        helperText={errors.aSlevelCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "aSlevelForm", "aSlevelAwardingBody", index, sData) : ""}
                  control={control}
                  name={`aSlevelForm.${index}.aSlevelAwardingBody`}
                  render={({ field }) => (
                    <TextField
                      error={Boolean(errors.aSlevelAwardingBody)}
                      helperText={errors.aSlevelAwardingBody?.message}
                      id="outlined-basic"
                      type="text"
                      label="Awarding Body"
                      variant="outlined"
                      {...field}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreAlevelFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const OlevelForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "olevelForm",
    });

    function AddMoreOlevelFields() {
      append({
        olevelInstitution: "",
        olevelGrades: "",
        olevelDegreeTitle: "",
        olevelCompletionYear: "",
        olevelSubjectList: "",
        olevelAwardingBody: "",
        otherOlevelSubjectList: "",
      });
    }
    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setOtherOlevelSubjectList(data?.label);
      } else {
        setOtherOlevelSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelInstitution", index, sData) : ""}
                  control={control}
                  name={`olevelForm.${index}.olevelInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.olevelInstitution)}
                      helperText={errors.olevelInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`olevelForm.${index}.olevelDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.olevelDegreeTitle)}
                      helperText={errors.olevelDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelSubjectList", index, sData) : ""}
                  control={control}
                  name={`olevelForm.${index}.olevelSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={olevelSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherOlevelSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "otherOlevelSubjectList", index, sData) : ""}
                    control={control}
                    name={`olevelForm.${index}.otherOlevelSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other O'level Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelGrades", index, sData) : ""}
                  control={control}
                  name={`olevelForm.${index}.olevelGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.olevelGrades)}
                      helperText={errors.olevelGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelCompletionYear", index, sData) : ""}
                    control={control}
                    name={`olevelForm.${index}.olevelCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.olevelCompletionYear)}
                        helperText={errors.olevelCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "olevelForm", "olevelAwardingBody", index, sData) : ""}
                  control={control}
                  name={`olevelForm.${index}.olevelAwardingBody`}
                  render={({ field }) => (
                    <TextField
                      error={Boolean(errors.olevelAwardingBody)}
                      helperText={errors.olevelAwardingBody?.message}
                      id="outlined-basic"
                      type="text"
                      label="Awarding Body"
                      variant="outlined"
                      {...field}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreOlevelFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const HsscForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "hsscForm",
    });

    function AddMoreHsscFields() {
      append({
        hsscInstitution: "",
        hsscDegreeTitle: "",
        hsscSubjectList: "",
        hsscGrades: "",
        hsscCompletionYear: "",
        otherHsscSubjectList: "",
      });
    }
    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setOtherHsscSubjectList(data?.label);
      } else {
        setOtherHsscSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "hsscInstitution", index, sData) : ""}
                  control={control}
                  name={`hsscForm.${index}.hsscInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.hsscInstitution)}
                      helperText={errors.hsscInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "hsscDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`hsscForm.${index}.hsscDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.hsscDegreeTitle)}
                      helperText={errors.hsscDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "hsscSubjectList", index, sData) : ""}
                  control={control}
                  name={`hsscForm.${index}.hsscSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={hsscSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherHsscSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "otherHsscSubjectList", index, sData) : ""}
                    control={control}
                    name={`hsscForm.${index}.otherHsscSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other HSSC Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "hsscGrades", index, sData) : ""}
                  control={control}
                  name={`hsscForm.${index}.hsscGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.hsscGrades)}
                      helperText={errors.hsscGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "hsscForm", "hsscCompletionYear", index, sData) : ""}
                    control={control}
                    name={`hsscForm.${index}.hsscCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.hsscCompletionYear)}
                        helperText={errors.hsscCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreHsscFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const SccForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "sccForm",
    });

    function AddMoreHsscFields() {
      append({
        sccInstitution: "",
        sccGrades: "",
        sccDegreeTitle: "",
        sccCompletionYear: "",
        sccSubjectList: "",
        otherSccSubjectList: "",
      });
    }
    const addOtherField = (data) => {
      if (data?.label === "Other") {
        setOtherSccSubjectList(data?.label);
      } else {
        setOtherSccSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "sccInstitution", index, sData) : ""}
                  control={control}
                  name={`sccForm.${index}.sccInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.sccInstitution)}
                      helperText={errors.sccInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "sccDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`sccForm.${index}.sccDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.sccDegreeTitle)}
                      helperText={errors.sccDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "sccSubjectList", index, sData) : ""}
                  control={control}
                  name={`sccForm.${index}.sccSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={sccSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherSccSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "otherSccSubjectList", index, sData) : ""}
                    control={control}
                    name={`sccForm.${index}.otherSccSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other SSC Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "sccGrades", index, sData) : ""}
                  control={control}
                  name={`sccForm.${index}.sccGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.sccGrades)}
                      helperText={errors.sccGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "sccForm", "sccCompletionYear", index, sData) : ""}
                    control={control}
                    name={`sccForm.${index}.sccCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.sccCompletionYear)}
                        helperText={errors.sccCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreHsscFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const OtherForm = () => {
    const {
      // control,
      formState: { errors },
    } = useFormContext();

    const { fields, append } = useFieldArray({
      // control,
      name: "otherForm",
    });

    function AddMoreOtherFields() {
      append({
        otherFormInstitution: "",
        otherFormDegreeTitle: "",
        otherFormSubjectList: "",
        otherFormGrades: "",
        otherOtherFormSubjectList: "",
        otherFormCompletionYear: "",
      });
    }
    const addOtherField = (data) => {
      if (data?.label === "Other") {
        otherOtherOtherFormSubjectList(data?.label);
      } else {
        otherOtherOtherFormSubjectList("");
      }
    };
    return (
      <Box>
        <Stack>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb: "25px" }} spacing={2}>
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherFormInstitution", index, sData) : ""}
                  control={control}
                  name={`otherForm.${index}.otherFormInstitution`}
                  render={({ field }) => (
                    <TextField
                      label="Institution"
                      error={Boolean(errors.otherFormInstitution)}
                      helperText={errors.otherFormInstitution?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherFormDegreeTitle", index, sData) : ""}
                  control={control}
                  name={`otherForm.${index}.otherFormDegreeTitle`}
                  render={({ field }) => (
                    <TextField
                      label="Degree Title"
                      error={Boolean(errors.otherFormDegreeTitle)}
                      helperText={errors.otherFormDegreeTitle?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherFormSubjectList", index, sData) : ""}
                  control={control}
                  name={`otherForm.${index}.otherFormSubjectList`}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={otherFormSubjectList}
                      onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                      renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Subject List" />}
                      {...field}
                    />
                  )}
                />
                {otherOtherFormSubjectList === "Other" && (
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherOtherFormSubjectList", index, sData) : ""}
                    control={control}
                    name={`otherForm.${index}.otherOtherFormSubjectList`}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                        type="text"
                        label="Other Subject"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
                <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherFormGrades", index, sData) : ""}
                  control={control}
                  name={`otherForm.${index}.otherFormGrades`}
                  render={({ field }) => (
                    <TextField
                      label="Grades"
                      error={Boolean(errors.otherFormGrades)}
                      helperText={errors.otherFormGrades?.message}
                      id="outlined-basic"
                      type="text"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Box>
                  <Typography>Completion Year</Typography>
                  <Controller
                    defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "otherForm", "otherFormCompletionYear", index, sData) : ""}
                    control={control}
                    name={`otherForm.${index}.otherFormCompletionYear`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.otherFormCompletionYear)}
                        helperText={errors.otherFormCompletionYear?.message}
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Box>
              </Stack>
            );
          })}
        </Stack>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={AddMoreOtherFields}>
            Add More
          </Button>
        </Box>
      </Box>
    );
  };

  const [activeSteps, setActiveSteps] = useState(0);
  const nextForm = () => {
    setActiveSteps(activeSteps + 1);
  };
  const onSubmit = (data) => {
    if (activeSteps === steps.length - 1) {
      setStudentData((prevData) => [...prevData, data]);
    } else {
      nextForm();
    }
    if (activeSteps === steps.length - 1) {
      nextStep();
    }
  };
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return <PostgraduateForm />;
      case 1:
        return <UndergraduateForm />;
      case 2:
        return <AlevelForm />;
      case 3:
        return <ASLevelForm />;
      case 4:
        return <OlevelForm />;
      case 5:
        return <HsscForm />;
      case 6:
        return <SccForm />;
      case 7:
        return <OtherForm />;
      default:
        return nextStep();
    }
  };
  const handleCancel = () => {
    setModal(false);
  };
  const steps = ["Postgraduate", "Undergraduate", "ALevel", "ASLevel", "OLevel", "HSSC", "SSC", "Other Qualifications"];

  return (
    <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
      <Stepper activeStep={activeSteps} orientation="vertical">
        {steps.map((label, idx) => (
          <StepLabel key={label}>
            <Typography fontSize="1rem" fontWeight={activeSteps === idx ? "bolder" : ""}>
              {idx + 1} - {label}
            </Typography>
          </StepLabel>
        ))}
      </Stepper>
      <Box
        sx={{
          width: "100%",
          mt: "-1.3rem",
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
            {handleSteps(activeSteps)}
            <Box
              mt={2}
              sx={{
                width: "70%",
                position: "absolute",
                height: "7%",
                bottom: "3%",
                left: "2%",
              }}
            >
              <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
                {activeSteps > 0 && (
                  <Button variant="outlined" onClick={() => setActiveSteps(activeSteps - 1)}>
                    Back
                  </Button>
                )}
                {activeSteps < 1 && (
                  <Button variant="outlined" onClick={goBack}>
                    Back
                  </Button>
                )}
                <Button variant="contained" type="submit">
                  Next
                </Button>
                {/* {activeSteps === steps.length - 1 ? "Submit" : "Next"} */}

                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default Education;
