import React from "react";
import { InputFormContainer, InputFormField } from "./InputFormHtml";
import { Stack, Typography, Box } from "@mui/material";
import Button from "@mui/material/Button";
export const renderFormFields = (fields, fieldState, actions, configs) => {
  let fieldsRender = [];
  for (let fKey in fields) {
    if (fields.hasOwnProperty(fKey)) {
      let fieldObj = fields[fKey];
      let value = fieldState[fKey] ? fieldState[fKey] : fieldObj && fieldObj.value ? fieldObj.value : "";
      fieldsRender.push(
        <InputFormField
          key={fieldObj.name}
          name={fieldObj.name}
          value={value}
          defaultDate={fieldObj.defaultDate}
          disabled={fieldObj.disabled}
          label={fieldObj.label}
          variant={fieldObj.muiVariant}
          valid={fieldObj.valid}
          type={fieldObj.type}
          required={fieldObj.required}
          options={fieldObj.options}
          invalidMessage={fieldObj.invalidMessage}
          helperText={fieldObj.helperText}
          onInputChange={actions.handleInputChange}
          InputLabelProps={fieldObj.InputLabelProps}
          size={fieldObj.size}
          InputProps={fieldObj.InputProps}
          style={fieldObj.style}
          queryStyle={fieldObj.queryStyle}
        />,
      );
    }
  }
  return (
    <InputFormContainer
      fieldWidth={configs && configs.fieldWidth}
      configs={configs && { ...configs }}
      noMargin={configs && configs.noMargin}
      noPaper={configs && configs.noPaper}
    >
      {configs && configs.customHeader ? configs.customHeader : renderFormHeader(actions, configs)}
      {configs && configs.customFormHeader}
      {fieldsRender}
      {(!configs || !configs.noFooter) && renderFooter(actions, configs)}
      {configs && configs.customFooter && configs.customFooter}
    </InputFormContainer>
  );
};

const renderFooter = (actions, configs = {}) => {
  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end">
      {configs.backButton && (
        <Button
          variant="outlined"
          size="medium"
          onClick={(e) => {
            actions.handleBack();
          }}
        >
          {configs.cancelText ? configs.cancelText : "Back"}
        </Button>
      )}
      <Button
        variant="contained"
        size="medium"
        onClick={(e) => {
          actions.handleSave();
        }}
      >
        {configs.saveText ? configs.saveText : "Save"}
      </Button>

      {configs.cancelButton && (
        <Button
          variant="outlined"
          size="medium"
          onClick={(e) => {
            actions.handleCancel();
          }}
        >
          {configs.cancelText ? configs.cancelText : "Cancel"}
        </Button>
      )}
    </Stack>
  );
};

const renderFormHeader = (actions, configs) => {
  if (configs && configs.headerValue) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 3 }}>
        <Typography component="h1" variant="h5">
          {configs.headerValue}
        </Typography>
      </Box>
    );
  } else {
    return "";
  }
};
