import React, { useState, useEffect } from "react";
import { IconButton, Popover, List, ListItem, ListItemText, Typography, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationMessage from "./NotificationMessage";
import { CRUDFunction } from "../../reduxCURD/container";
import { withRouter } from "react-router-dom";
import NotificationIcon from "../../Images/NotificationIcon";

function NotificationPop(props) {
  // console.log("ahsan", props);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(10);
  const [notifications, setNotifications] = useState(props.notifications || []);
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchNotifications = async (offset) => {
    const fetchedNotifications = props.getNotifications(`?status=new&type=web&limit=20&offset=${offset}`);
    // console.log("fetchedNotifications:", fetchedNotifications);

    // if (fetchedNotifications && fetchedNotifications.notifications && fetchedNotifications.notifications.length < 20) {
    //   setHasMore(false);
    // }
    // setNotifications((prevNotifications) => [...prevNotifications, fetchedNotifications]);
  };

  useEffect(() => {
    (() => {
      fetchNotifications(offset);
    })();
  }, []);

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + 20);
    fetchNotifications(offset);
  };

  const [count, setCount] = useState(props.notifications ? props.notifications.length : 0);
  const setUnreadCount = (allNotis) => {
    setCount(allNotis.filter((noti) => noti.status !== "read").length);
  };

  // New useEffect for handling actionType changes
  useEffect(() => {
    if (props.actionType === "NOTIFICATIONS_READ_SUCCESS") {
      setNotifications(props.notifications);
      setUnreadCount(props.notifications);
    } else if (props.actionType === "NOTIFICATION_READ_SUCCESS" || props.actionType === "NOTIFICATION_UPDATE_SUCCESS") {
      const updatedNotifications = notifications.map((notification) => {
        if (notification._id === props.notification._id) {
          return props.notification;
        }
        return notification;
      });

      if (!notifications.find((notification) => notification._id === props.notification._id)) {
        updatedNotifications.push(props.notification);
      }

      setNotifications(updatedNotifications);
      setUnreadCount(updatedNotifications);
    }
  }, [props.actionType]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = (notification) => {
    // if (!isRead[index]) {
    //   setCount((prevCount) => prevCount - 1);
    // }
    // const newIsRead = [...isRead];
    // newIsRead[index] = true;
    // setIsRead(newIsRead);
    // console.log(notification);
    if (notification.url && notification.url.path) {
      props.history.push(notification.url.path);
      props.history.replace({
        search: notification.url.applicationId,
      });
    }
    if (notification.status !== "read") props.updateNotification({ status: "read" }, "readNotification/" + notification._id);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notifications-popover" : undefined;
  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          mr: 1,
        }}
      >
        {count > 0 ? (
          <div style={{ position: "relative" }}>
            <NotificationIcon />
            <div
              style={{
                position: "absolute",
                right: -8,
                top: -8,
                backgroundColor: "#F00",
                color: "white",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                textAlign: "center",
                fontSize: "0.8rem",
                lineHeight: "20px",
              }}
            >
              {count}
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "0.5rem",
            }}
          >
            <NotificationIcon color={"black"} />
          </div>
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "16px" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Notifications
          </Typography>

          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {notifications
              ?.map((obj) => {
                return { ...obj, date: new Date(obj.createdAt) };
              })
              .sort((a, b) => Number(b.date) - Number(a.date))
              .map((notification, index) => (
                <NotificationMessage
                  key={index}
                  index={index}
                  onClick={() => handleClickNotification(notification)}
                  isRead={notification.status === "read"}
                  message={notification.message}
                  comment={notification.subject}
                  time={notification.createdAt}
                />
              ))}

            {hasMore && false && (
              <Button variant="contained" color="primary">
                <span
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={handleLoadMore}
                >
                  Load More{" "}
                </span>{" "}
              </Button>
            )}
          </List>
        </div>
      </Popover>
    </div>
  );
}

const NotificationPopContainer = CRUDFunction(NotificationPop, "notification");
const WithRouterNotification = withRouter(NotificationPopContainer);
export default WithRouterNotification;
