import { Box, Divider, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { studentFileDownloadPath } from "../../../utils/studentFileDownloadUtil";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const EntranceTestData = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  return (
    <Stack
      sx={{
        // display: "grid",
        // gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
        gap: "1rem",
        ["@media (max-width: 775px)"]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box>
        <DataWrapper heading={`Entrance Test`}>
          {student?.registrationData.entranceTest &&
            student?.registrationData.entranceTest.map((item, index) => {
              return (
                <>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Entrance Test:{" "}
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {item?.entranceTest?.label ? item?.entranceTest?.label : item?.entranceTest || "N/A"}
                      </span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="entranceTest"
                        text={item?.entranceTest?.label ? item?.entranceTest?.label : item?.entranceTest || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Entrance Test (Other):{" "}
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {item?.otherEntranceTest ? item?.otherEntranceTest : item?.otherEntranceTest || "N/A"}
                      </span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="otherEntranceTest"
                        text={item?.otherEntranceTest ? item?.otherEntranceTest : item?.otherEntranceTest || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Score: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.entranceTestScore || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="entranceTestScore"
                        text={item?.entranceTestScore || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    <span>
                      Date Of Test:{" "}
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.entranceTestDateOfTest || "N/A"}</span>
                    </span>
                    {appCheck && (
                      <AppCheckActions
                        user={user}
                        setRevertData={setRevertData}
                        allData={data}
                        confirm={confirm}
                        copy={copy}
                        submitted={submitted}
                        data="entranceTestDateOfTest"
                        text={item?.entranceTestDateOfTest || "N/A"}
                      />
                    )}
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      // borderBottom: "1px solid #E0E0E0",
                      pb: "5px",
                      mb: "1rem",
                    }}
                  >
                    Upload Result:{" "}
                    {student.registrationData?.entranceTest ? (
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {student.registrationData?.entranceTest &&
                        student.registrationData?.[`entranceTest.${index}.entranceTestUploadResult`] ? (
                          <Link
                            target="_blank"
                            rel="noopener"
                            download
                            href={studentFileDownloadPath(
                              student?.registeration,
                              student.registrationData?.[`entranceTest.${index}.entranceTestUploadResult`],
                            )}
                          >
                            {" "}
                            {student.registrationData?.[`entranceTest.${index}.entranceTestUploadResult`]}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    ) : (
                      <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                        {appData && appData[`entranceTest.${index}.entranceTestUploadResult`] ? (
                          <Link
                            target="_blank"
                            rel="noopener"
                            download
                            href={studentFileDownloadPath(
                              student?.registeration,
                              appData[`entranceTest.${index}.entranceTestUploadResult`],
                            )}
                          >
                            {" "}
                            {appData[`entranceTest.${index}.entranceTestUploadResult`]}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    )}
                  </Typography>
                  {index < student?.registrationData?.entranceTest?.length - 1 && (
                    <Divider style={{ backgroundColor: "black", height: "2px", marginBottom: "20px" }} />
                  )}
                </>
              );
            })}
        </DataWrapper>
      </Box>
    </Stack>
  );
};

export default EntranceTestData;
