import { Box, Typography } from "@mui/material";
import React from "react";

const DataWrapper = ({ children, heading }) => {
  return (
    <Box
      sx={{
        border: "2px solid #E0E0E0",
        borderRadius: "1rem",
        padding: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        mt: "1rem",
        mb: "2rem",
      }}
    >
      <Typography
        variant="h4"
        color={"primary.main"}
        sx={{
          mt: "-2.2rem",
          backgroundColor: "white",
          ml: "1rem",
          width: "fit-content",
          px: "1rem",
        }}
      >
        {heading}
      </Typography>
      <Box
        sx={{
          ml: "1.5rem",
          ["@media (max-width: 500px)"]: {
            ml: ".5rem",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DataWrapper;
