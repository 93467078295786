import React, { useEffect } from "react";
import { renderFormFields } from "./InputFormMuiContainer";
const InputForm = (props) => {
  let formFieldSchemaCopy = { ...props.formFieldSchema };
  if (props.fields) {
    let fieldKeys = Object.keys(props.fields);
    fieldKeys.map((fKey) => {
      formFieldSchemaCopy[fKey] && (formFieldSchemaCopy[fKey].value = props.fields[fKey]);
    });
  }
  const [fields, changeFields] = React.useState(props.fields ? props.fields : {});
  const [fieldsSchema, changeSchema] = React.useState({ ...formFieldSchemaCopy });

  useEffect(() => {
    changeSchema({ ...props.formFieldSchema });
    changeFields(props.fields ? props.fields : {});
  }, [props.formFieldSchema]);

  useEffect(() => {
    onReset();
  }, [props.resetForm]);

  const handleSave = () => {
    // validate data on save
    let valid = true;
    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        props.formFieldSchema[key] && props.formFieldSchema[key].required && !fields[key] && (valid = false);
      }
    }
    props.handleSave && props.handleSave(fields, valid);
  };
  const handleCancel = () => {
    props.handleClose && props.handleClose();
    changeFields({});
    changeSchema({ ...props.formFieldSchema });
  };
  const handleBack = () => {
    props.handleBack && props.handleBack();
    changeFields({});
    changeSchema({ ...props.formFieldSchema });
  };
  const onReset = () => {
    changeFields({});
    changeSchema({ ...props.formFieldSchema });
  };

  const handleInputChange = (e) => {
    let fields_up = { ...fields };
    let schemaItem = { ...fieldsSchema[e.target.name] };
    let valid = true;
    let invalidMessage = "";
    if (schemaItem.validator) {
      let validator = schemaItem.validator(e.target.value, schemaItem, fieldsSchema);
      valid = validator.valid;
      invalidMessage = validator.invalidMessage;
    }
    if (valid) {
      fields_up[e.target.name] = e.target.value;
      props.config && props.config.getState && props.config.getState(fields_up);
      changeFields(fields_up);
      schemaItem.value = e.target.value;
    } else {
      schemaItem.validationMessage = invalidMessage;
      schemaItem.valid = false;

      changeFields(fields_up);
    }
    fieldsSchema[e.target.name] = schemaItem;
  };

  return renderFormFields(
    fieldsSchema,
    fields,
    {
      handleInputChange: handleInputChange,
      handleSave: handleSave,
      handleCancel: handleCancel,
      handleBack: handleBack,
    },
    props.config,
  );
};

export default InputForm;
