import { Box, Button, Link, Modal } from "@mui/material";
import React from "react";
import { studentFileDownloadPath } from "../../../../../utils/studentFileDownloadUtil";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useState } from "react";
import DocumentViewTable from "./DocumentViewTable";
import Revert from "./Revert";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getLoggedInUser } from "../../../../../utils/loggedInUser";

const DocumentCheck = ({ data, onUpdate, student, fileUpload }) => {

  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const [multiFiles, setMultiFiles] = useState([]);
  const [selectedFileName, setSelectedFile] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const user = getLoggedInUser();

  const openFile = (type, url) => {
    handleOpen();
    setFileUrl(url);
    onUpdate(
      {
        docCheck: {
          [type]: {
            isViewed: true,
            docName: type,
          },
        },
      },
      data._id,
    );
  };

  const openFileForCounselor = (type, url) => {
    handleOpen();
    setFileUrl(url);
  };

  const selectFileCategoryFilesView = (type, file, counselor) => {
    if (!file) return;
    handleOpen();
    setMultiFiles([file]); // Convert single file to an array
    setSelectedFile(file); // Set the selected file
    if (!counselor) {
      onUpdate(
        {
          docCheck: {
            [type]: {
              isViewed: true,
              docName: file,
            },
          },
        },
        data._id,
      );
    }
  };

  // const selectFileCategoryFilesView = (type, file, counselor) => {
  //   if (!file) return;
  //   handleOpen();
  //   setMultiFiles([file]); // Convert single file to an array
  //   setSelectedFile(file); // Set the selected file
  //   if (!counselor) {
  //     const updatedDocCheck = { ...data.docCheck };

  //     // If the type exists in docCheck, add the file to its array
  //     if (updatedDocCheck[type]) {
  //       updatedDocCheck[type].push({
  //         fileName: file,
  //         isViewed: true,
  //       });
  //     } else {
  //       // If the type doesn't exist, create a new array with the file
  //       updatedDocCheck[type] = [{
  //         fileName: file,
  //         isViewed: true,
  //       }];
  //     }

  //     onUpdate(
  //       {
  //         docCheck: updatedDocCheck,
  //       },
  //       data._id,
  //     );
  //   }
  // };

  // const selectFileCategoryFilesView = (type, files, counselor) => {
  //   if (!files) return;
  //   handleOpen();
  //   setMultiFiles(files);
  //   files[0] && setSelectedFile(files[0]);
  //   if (!counselor) {
  //     onUpdate(
  //       {
  //         docCheck: {
  //           [type]: {
  //             isViewed: true,
  //             docName: type,
  //           },
  //         },
  //       },
  //       data._id,
  //     );
  //   }
  // };

  const verify = (verify, field) => {
    onUpdate(
      {
        docCheck: {
          [field]: {
            verify: verify,
            isViewed: true,
            docName: field,
          },
        },
      },
      data._id,
    );
  };

  const [revertData, setRevertData] = useState({});

  const revert = (revert, field) => {
    // onUpdate(
    //   {
    //     docCheck: {
    //       [field]: {
    //         verify: data?.docCheck[field].verify ? true : false,
    //         isViewed: true,
    //         docName: field,
    //         revert: revert,
    //       },
    //     },
    //   },
    //   data._id,
    // );
  };

  let foundIndex = multiFiles && multiFiles.findIndex((m) => m === selectedFileName);
  if (foundIndex !== -1) {
    foundIndex += 1; // Incrementing index by 1
  }

  //   const objectEntries = Object.entries(data?.appData?.documentUpload);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {revertData?.revert && (
        <Revert setRevertData={setRevertData} data={data} student={student} onUpdate={onUpdate} revertData={revertData} />
      )}

      <DocumentViewTable
        data={data?.appData?.documentUpload}
        setSelectedFile={setSelectedFile}
        revert={revert}
        setRevertData={setRevertData}
        stdId={data?.stdId}
        allData={data}
        openFile={openFile} // to be removed
        openFileForCounselor={openFileForCounselor} // to be removed
        verify={verify}
        revertData={revertData}
        user={user}
        onUpdate={onUpdate}
        student={student}
        multiFiles={multiFiles}
        fileUpload={fileUpload}
        selectFileCategoryFilesView={selectFileCategoryFilesView} // replace openFile and openFileForCounselor
      />

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ background: "#fff", width: "80%", height: "80%", }}>
          {/* <div style={{ display: "flex", backgroundColor: "rgb(25,104,173)", padding: "10px" }}>
            <div style={{ color: "white", fontWeight: "500" }}>
              File Name : {selectedFileName}</div>
          </div> */}
          <iframe
            src={`${studentFileDownloadPath(data?.stdId, selectedFileName)}`}
            style={{
              background: "white",
            }}
            frameborder="0"
            height="100%"
            width="100%"
            title="pdf Viewer"
          ></iframe>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={(e) => {
                let foundIndex = multiFiles && multiFiles.findIndex((m) => m === selectedFileName);
                if (foundIndex !== -1 && foundIndex !== 0) setSelectedFile(multiFiles[foundIndex - 1]);
              }}
              disabled={!multiFiles || !multiFiles[0] || multiFiles[0] === selectedFileName}
            >
              Prev
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              variant="contained"
              onClick={(e) => {
                let foundIndex = multiFiles && multiFiles.findIndex((m) => m === selectedFileName);
                if (foundIndex !== -1 && foundIndex !== multiFiles.length - 1) setSelectedFile(multiFiles[foundIndex + 1]);
              }}
              disabled={!multiFiles || multiFiles[multiFiles.length - 1] === selectedFileName}
            >
              Next
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default DocumentCheck;
