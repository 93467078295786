import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EscalateIcon from "../../../Images/EscalateIcon";
import EditIcon from "../../../Images/EditIcon";
import BinIcon from "../../../Images/BinIcon";
import uniImage from "../../../Images/uniImage.jpg";
import ConfirmationModal from "../../../Components/ConfirmationModal";

const ApplicationCard = ({ data, onEdit, onDelete, selectedOption, setShowModal, agree, appId, setAppId, setAgree }) => {
  // console.log("sp",selectedOption);
  // console.log("data", data);
  function getSemesterCode(year, intake) {
    let semesterCode = "";
    if (intake === "Spring") {
      semesterCode = "SP";
    } else if (intake === "Fall") {
      semesterCode = "FA";
    } else if (intake === "Summer") {
      semesterCode = "SU";
    }
    return semesterCode + year?.toString().substr(2, 2);
  }

  // console.log({data});
  // console.log(appId);
  useEffect(() => {
    if (appId && agree == false) {
      setShowModal(true);
    }
  }, [appId]);

  useEffect(() => {
    if (agree) {
      onDelete({ _id: appId });
      setAppId(null);
      setAgree(false);
    }
  }, [agree]);

  return (
    <Box>
      <Card
        sx={{
          width: 300,
          backgroundColor: data?.disable ? "grey" : "primary.main",
          cursor: data?.disable ? "default" : "pointer",
          flex: "0 0 auto",
          p: "14px",
          [`@media (max-width: 455px)`]: {
            width: 300,
          },
          [`@media (max-width: 390px)`]: {
            width: 270,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              backgroundColor: "white",
              width: "fit-content",
              borderRadius: "42px",
              justifyContent: "center",
              alignItems: "center",
              p: ".2rem .8rem 0 .8rem",
              position: "relative",
              zIndex: "99999",
            }}
          >
            {data?.escalation && (
              <Box>
                <EscalateIcon color={"blue"} />
              </Box>
            )}
            {/* <Box
              onClick={data?.disable ? null : () => onEdit(data?.uid)}
              sx={{
                cursor: data?.disable ? "default" : "pointer",
              }}
            >
              <EditIcon color="blue" />
            </Box> */}
            <Box
              onClick={data?.disable ? null : () => setAppId(data?.uid)}
              sx={{
                cursor: data?.disable ? "default" : "pointer",
                position: "relative",
                zIndex: "99",
              }}
            >
              <BinIcon color="red" />
            </Box>
          </Box>
        </Box>

        <Box onClick={data?.disable ? null : () => selectedOption([data?.id])}>
          <Stack direction="row" gap=".7rem" alignItems={"center"}>
            <Stack direction="column" color={"white"}>
              <Typography variant="h6" whiteSpace={"pre-wrap"}>
                {data?.universityName}
              </Typography>
              <Typography variant="subtitle2">{data?.id}</Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              color: "white",
              mt: "1rem",
            }}
          >
            <Stack direction="column">
              <Typography variant="h6">Intended Course</Typography>
              <Typography
                sx={{
                  mt: 1,
                }}
              >
                {data?.intendedCourse}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" mt={3}>
              <Stack direction="column">
                <Typography variant="h6">INTAKE</Typography>
                <Typography
                  sx={{
                    mt: 1,
                  }}
                >
                  {getSemesterCode(data?.year, data?.intake)}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography variant="h6">STATUS</Typography>
                <Typography
                  sx={{
                    mt: 1,
                  }}
                >
                  {data?.status}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ApplicationCard;
