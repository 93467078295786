import React from "react";
import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const TaskDetailCard = ({ studentTasks }) => {
  // const filteredTasks = TaskAccessMethods.filterTasksByUserRole(filterTasks);

  return (
    <>
      {studentTasks.map((task, index) => (
        <Card
          key={index}
          sx={{
            width: 345,
            overflow: "initial",
            [`@media (max-width: 455px)`]: {
              width: 300,
            },
            [`@media (max-width: 390px)`]: {
              width: 270,
            },
          }}
        >
          <CardContent>
            <Stack spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}>
              <Stack>
                <Typography variant="h5" component="div" sx={{ textAlign: "center", fontSize: 14, color: "#1968AD" }}>
                  {task?.studentName}
                </Typography>
              </Stack>
            </Stack>
            {/* {!isSysgen(task.studentId) && ( */}
            <>
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}
                >
                  Reg #: {task?.studentId}
                </Typography>
              </Box>
              <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" color="#1968AD">
                  Branch
                </Typography>
                <span style={{ color: "1968AD", fontSize: "0.8rem" }}> {task?.branch}</span>
              </Stack>
            </>
            {/* )} */}
            <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="#1968AD">
                Pending Tasks
              </Typography>
              <span>{task?.pendingTasks}</span>
            </Stack>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Link to={`/tasks/${task.studentId}`} style={{ textDecoration: "none" }}>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1968AD",
                  width: "75px",
                  height: "28px",
                  marginRight: "30px",
                  marginBottom: "30px",
                  fontSize: "12px",
                  fontWeight: 300,
                  letterSpacing: "1.25px",
                  fontStyle: "normal",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                View
              </Button>
            </Link>
          </Box>
        </Card>
      ))}
    </>
  );
};

export default TaskDetailCard;
