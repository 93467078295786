import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import TabPanel from "./TabPanel";

const Home = () => {
  return (
    <Box
      width="100%"
      sx={{
        pl: 3,
        pr: 3,
        py: 12,
      }}
    >
      <Stack spacing={4} width="100%">
        <Stack direction="row" spacing={3}>
          <Avatar alt="Profile Image" src="" sx={{ width: 120, height: 120 }} />
          <Box>
            <Typography fontSize="32px">Hi</Typography>
            <Typography fontSize="32px">Waleed Rauf</Typography>
          </Box>
        </Stack>
        <Box>
          <TabPanel />
        </Box>
      </Stack>
    </Box>
  );
};

const homeContainer = CRUDFunction(Home, "home");
export default homeContainer;
