import React from "react";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Checkbox, Tooltip } from "@mui/material";

const SubTasksdetails = ({ title, id, handleDeleteSubTask, SubTaskStatus, SubTaskDone }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(237,234,233)",
          width: "400px",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: "rgb(255,255,255)",
          m: " 10px 25px 0px 25px",
        }}
      >
        <Box
          sx={{
            float: "right",
          }}
        >
          <Tooltip title="Delete SubTask" placement="top">
            <DeleteIcon
              onClick={() => handleDeleteSubTask(id)}
              fontSize="medium"
              sx={{
                color: "rgb(121,122,123)",
                cursor: "pointer",
                marginTop: '8px',
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Checkbox
            fontSize="small"
            checked={SubTaskStatus}
            onChange={() => SubTaskDone(id)}
            color="primary"
          />
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              color: "rgb(145,145,146)",
              marginTop: '10px',
              wordBreak: "break-all",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SubTasksdetails;
