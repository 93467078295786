// export function loadRepeater(arrayItem, propObject) {
//     if (!propObject) return []
//     if (!arrayItem) {
//         let singleInstance = {}

//         return singleInstance;
//     }
//     else if (arrayItem) {
//         let toRet = arrayItem.map(item => {
//             let singleItem = {}
//             return singleItem
//         })

//         return toRet
//     }
// }

// function prepareSingleItem(item, propObject) {
//     let loadItem = {};
//     let keys = OBject.keys(propObject);
//     for (let key of keys) {
//         item[key]
//     }
// }

export function repeaterLoader(edit, fieldName, student, data, fieldObj) {
  let toRet = null;
  if (!edit && student?.registrationData && student.registrationData[fieldName] && student.registrationData[fieldName].length > 0) {
    toRet = student.registrationData[fieldName].map((item) => item);
  } else if (edit && data?.appData && data?.appData[fieldName]) {
    toRet = data.appData[fieldName].map((item) => item);
  } else {
    toRet = [fieldObj];
  }
  return toRet;
}
export function studentRepeaterLoaderWithFieldIndexes(edit, fieldName, student, data, fieldObj, fileField) {
  let repeaterRes = repeaterLoader(edit, fieldName, student, data, fieldObj);
  let finalRes = repeaterRes.map((returnedFields, index) => {
    returnedFields[fileField] = student?.registrationData[`${fieldName}.${index}.${fileField}`];
    return returnedFields;
  });
  return finalRes;
}
export function repeaterLoader2(edit, fieldName, student, data, fieldObj) {
  let toRet = null;
  if (!edit && student && student.enquiryData[fieldName] && student.enquiryData[fieldName].length > 0) {
    toRet = student.enquiryData[fieldName].map((item) => item);
  } else if (edit && data?.appData && data?.appData[fieldName]) {
    toRet = data.appData[fieldName].map((item) => item);
  } else {
    toRet = [fieldObj];
  }
  return toRet;
}

export function repeaterDefaultValueIndex(edit, fieldName, propKey, index, student, data) {
  if (!edit && student?.registrationData && student.registrationData[fieldName] && student.registrationData[fieldName][index]) {
    return student.registrationData[fieldName][index][propKey];
  } else if (edit && data?.appData && data.appData[fieldName] && data.appData[fieldName][index]) {
    return data.appData[fieldName][index][propKey];
  }
  return "";
}

export function repeaterDefaultValueIndex2(edit, fieldName, propKey, index, student, data) {
  if (!edit && student?.enquiryData && student.enquiryData[fieldName] && student.enquiryData[fieldName][index]) {
    return student.enquiryData[fieldName][index][propKey];
  } else if (edit && data?.appData && data.appData[fieldName] && data.appData[fieldName][index]) {
    return data.appData[fieldName][index][propKey];
  }
  return "";
}
