import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { guid } from "../../utils/UUID";
import { getLoggedInUser } from "../../utils/loggedInUser";
import ColumnTask from "./ColumnTask";
const StudentTaskDetail = ({ disableNewSection, defaultSections, actions, dbTasks, projectId, users }) => {
  // console.log("dbtasks", dbTasks);
  const [columns, setColumns] = useState([]);
  const [activeColumn, setActiveColumn] = useState("");
  const [tasks, setTasks] = useState(dbTasks);
  const [activeTask, setActiveTask] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [inputTaskValue, setinputTaskValue] = useState("");
  const { onNewTaskCreated, onTaskUpdate, onTaskDeleted } = actions ? actions : {};
  const user = getLoggedInUser();
  // console.log("user", user);
  useEffect(() => {
    if (defaultSections) {
      let dCols = ["Pending", "In Progress", "Completed"];
      let colsAdd = dCols.map((t) => ({ id: t, title: t }));

      setColumns(colsAdd);
    }
  }, defaultSections);

  useEffect(() => {
    if (dbTasks) {
      setTasks(dbTasks);
    }
  }, [dbTasks]);

  function generateId() {
    return guid();
  }

  const InputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      createNewColumn();
      // Hide the component and show the button again
      setShowComponent(false);
    }
  };

  const handleButtonClick = () => {
    setShowComponent(true);
  };

  function createNewColumn() {
    const columnToAdd = {
      id: generateId(),
      title: inputValue,
    };
    setColumns([...columns, columnToAdd]);
    setInputValue("");
  }

  function createTask(columnId) {
    const newTask = {
      id: guid(),
      columnId,
      projectId: projectId ? projectId : "Default",
      content: inputTaskValue,
      isDeleted: false,
      description: "",
      comments: [],
      subTasks: [],
      dueDate: Date,
      isDone: false,
      assignedTo: "",
      createdBy: { id: user._id, name: user.name, email: user.email },
    };
    setTasks([...tasks, newTask]);
    onNewTaskCreated && onNewTaskCreated(newTask, "task");
  }

  const InputTask = (event) => {
    setinputTaskValue(event.target.value);
  };

  const handleDeleteTask = (id) => {
    onTaskDeleted && onTaskDeleted(id);
  };

  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  function onDragStart(event) {
    if (event.active.data.current?.type === "columns") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "tasks") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragOver(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "tasks";
    const isOverATask = over.data.current?.type === "tasks";

    if (!isActiveATask) return;

    // Dropping a Task over Another Task ...

    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);

        if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "columns";

    // Dropping a Task over a Column ...
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);

        tasks[activeIndex].columnId = overId;
        console.log(activeTask);
        onTaskUpdate && onTaskUpdate({ columnId: overId }, activeId);
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }

  function onDragEnd(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "columns";
    if (!isActiveAColumn) return;

    console.log(activeColumn);

    setColumns((columns) => {
      const activeColumnIndex = columns.findIndex((col) => col.id === activeId);
      const overColumnIndex = columns.findIndex((col) => col.id === overId);
      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SortableContext items={columnsId}>
              {columns.map((col) => (
                <ColumnTask
                  key={col.id}
                  columns={col}
                  createTask={createTask}
                  InputTask={InputTask}
                  onTaskUpdate={onTaskUpdate}
                  handleDeleteTask={handleDeleteTask}
                  users={users}
                  tasks={tasks.filter((task) => task.columnId === col.id)}
                />
              ))}
            </SortableContext>
          </Box>

          {!disableNewSection && (
            <Box>
              {!showComponent ? (
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    handleButtonClick();
                  }}
                  startIcon={
                    <AddIcon
                      sx={{
                        color: "rgb(109,110,111)",
                      }}
                    />
                  }
                  sx={{
                    color: "rgb(145,145,146)",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: "rgb(237,234,233)",
                      borderRadius: "6px",
                    },
                  }}
                >
                  Add Section
                </Button>
              ) : (
                <TextField placeholder="New Section .." value={inputValue} onChange={InputChange} onKeyDown={handleKeyPress} size="small" />
              )}
            </Box>
          )}
        </DndContext>
      </Box>
    </>
  );
};

export default StudentTaskDetail;
