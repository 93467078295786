import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Checkbox, Tooltip } from "@mui/material";
const   AppCheckActions = (props) => {
  const handleCopyToClipboard = () => {
    const textToCopy = props.text;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        props.copy(true, props.data);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
      }}
    >
      {props.user.role === "Counselor" && props.allData?.appCheck?.[props.data]?.revert && (
        <Tooltip title={props.allData?.appCheck?.[props.data]?.comment} placement="top">
          <h5>Reverted</h5>
        </Tooltip>
      )}

      {props.user.role !== "Counselor" && (
        <>
          <Tooltip title="Confirm" placement="top">
            <Checkbox
              onChange={(e) => props.confirm(e.target.checked, props.data)}
              defaultChecked={props.allData?.appCheck?.[props.data]?.confirm}
            />
          </Tooltip>
          {props.allData?.appCheck?.[props.data]?.confirm && (
            <>
              <Tooltip title="Copy" placement="top">
                <ContentCopyIcon
                  onClick={handleCopyToClipboard}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </>
          )}

          {props.allData?.appCheck?.[props.data]?.copy && (
            <Tooltip title="Submitted" placement="top">
              <Checkbox
                onChange={(e) => props.submitted(e.target.checked, props.data)}
                defaultChecked={props.allData?.appCheck?.[props.data]?.submitted}
              />
            </Tooltip>
          )}
          {!props.allData?.appCheck?.[props.data]?.confirm && (
            <Tooltip title="Revert" placement="top">
              <button
                disabled={props.allData?.appCheck?.[props.data]?.revert}
                style={{
                  cursor: props.allData?.appCheck?.[props.data]?.revert ? "not-allowed" : "pointer",
                  background: "none",
                  border: "none",
                  outline: "none",
                  padding: "0",
                  transform: props.allData?.appCheck?.[props.data]?.revert ? "rotate(270deg)" : "",
                  opacity: props.allData?.appCheck?.[props.data]?.revert ? ".5" : "1",
                }}
              >
                <ReplayIcon
                  onClick={() =>
                    props.setRevertData({
                      revert: true,
                      name: props.data,
                      value: props.text,
                    })
                  }
                  color="primary"
                />
              </button>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );
};

export default AppCheckActions;
