import React, { useState } from "react";
import TopBarV2 from "../TobBar/TopBarV2";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./nav";
import { styled } from "@mui/material/styles";
import { Main } from "../Common/MainStyle/MainStyle";
import { routes } from "./routes";
import SocketIoClientContainer from "../SocketIo/SocketIO.client";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

// function MainWrapper(props) {
//   return props.enableStyle === false ? props.children : <Main>{props.children}</Main>;
// }
let user = getLoggedInUser();

export default function ContainerPage() {
  const location = useLocation();

  let _routes = routes.map((item) => (
    // <MainWrapper key={item.path} enableStyle={item.enableStyle}>
    // </MainWrapper>

    <Route path={item.path} key={item.path} component={item.component} />
  ));

  return (
    <StyledRoot>
      <SocketIoClientContainer />
      <Router>
        <TopBarV2 />
        <Main>
          <Switch>{_routes}</Switch>
        </Main>
      </Router>
    </StyledRoot>
  );
}
