import React, { useContext, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import SocketIoClient from "../../Components/SocketIo/SocketIO.client";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { SocketContext } from "../../SocketContext";
import { Badge, Button, Tab, Tabs } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SearchIcon from "../../Images/SearchIcon";
import InputAdornment from "@mui/material/InputAdornment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./chatStyle.css";
import useScreenSize from "../../utils/useScreenSIze";
import ArrowLeft from "../../Images/ArrowLeft";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ChatSideBarTop = ({ users, getChatMessages, messageList, setMessageList, props }) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [chatCreatedUserList, setChatCreatedUserList] = useState([]);
  const [onlineUserList, setOnlineUserList] = useState([]);
  const [currentChatUser, setCurrentChatUser] = useState({});
  const [lastMessageInfo, setLastMessageInfo] = useState({});
  const [chatboxsLatestMessages, setchatboxsLatestMessages] = useState([]);
  const [selectedChatBox, setSelectedChatBox] = useState(null);
  const [latestMessage, setlatestMessage] = useState("");
  const [messageReceiver, setmessageReceiver] = useState("");
  const [createChat, setCreateChat] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { socketIOUser } = useContext(SocketContext);
  const [tabValue, setTabValue] = React.useState("team");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  // const toggleSidebar = () => {
  //   // Check if the window width is less than or equal to 900 pixels
  //   if (window.innerWidth <= 900) {
  //     setIsSidebarVisible(!isSidebarVisible);
  //   }
  // };

  // console.log({users});

  // console.log(props);

  const screen = useScreenSize();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messageList]);

  const socket = socketIOUser;

  let user = getLoggedInUser();

  //filteredUsers : it is a array of users without login user.
  const filteredUsers = users.filter((singleUser) => singleUser.email !== user.email);

  const studentArray = filteredUsers.filter((user) => user.role === "Student");
  const teamArray = filteredUsers.filter((user) => user.role !== "Student");

  const handleInputChange = (e) => {
    setCurrentMessage(e.target.value);
  };

  // console.log("Student Array:", studentArray);
  // console.log("Team Array:", teamArray);

  const handleSendMessage = () => {
    if (currentMessage) {
      const messageData = {
        message: currentMessage,
        sender: user.email,
        receiver: messageReceiver,
        status: "new",
        time: new Date(),
        selectedChatBox: selectedChatBox,
      };

      setchatboxsLatestMessages([...chatboxsLatestMessages, messageData]);
      socket.emit("send_message", messageData);
      socket.emit("findChat", messageData);
      setMessageList([...messageList, messageData]);
      setCurrentMessage("");
    }
  };
  //console.log(chatboxsLatestMessages);

  function getAllChatboxsNewMessage(arr) {
    const uniqueItems = {};
    for (const item of arr) {
      const { selectedChatBox, time } = item;
      if (!uniqueItems[selectedChatBox?._id] || new Date(time) > new Date(uniqueItems[selectedChatBox?._id].time)) {
        uniqueItems[selectedChatBox?._id] = { ...item };
      }
    }
    const uniqueItemsArray = Object.values(uniqueItems);
    return uniqueItemsArray;
  }
  const chatboxsNewMessageList = getAllChatboxsNewMessage(chatboxsLatestMessages);
  // console.log("chatboxsNewMessageList", chatboxsNewMessageList);

  //In updatedArray we update lastMessageInfo object with  new values
  const updatedArray = chatboxsNewMessageList.map((item) => {
    const { message, time } = item;

    // Update the lastMessageInfo object with the new values
    const updatedLastMessageInfo = {
      message: message,
      time: time,
    };

    // Return the updated object by merging the existing selectedChatBox with the updated lastMessageInfo
    return {
      ...item.selectedChatBox,
      lastMessageInfo: updatedLastMessageInfo,
    };
  });
  // console.log("Updated Array:", updatedArray);

  const updatedChatboxs = props.chatboxs.map((chatbox) => {
    const matchingItem = updatedArray?.find((item) => item?._id === chatbox?._id);
    // console.log(matchingItem);
    if (matchingItem) {
      return {
        ...chatbox,
        lastMessageInfo: {
          message: matchingItem?.lastMessageInfo?.message,
          time: matchingItem?.lastMessageInfo?.time,
        },
      };
    }

    return chatbox;
  });

  const updatedChatboxsWithSwapUsers = updatedChatboxs.map((chatbox) => {
    const { users } = chatbox;
    if (users[1] === user.email) {
      return { ...chatbox, users: [users[1], users[0]] };
    }
    return chatbox;
  });

  // console.log("Updated Chatboxs", updatedChatboxsWithSwapUsers);

  //  function to compare two objects based on their lastMessageInfo?.time property.
  const compareByTime = (a, b) => {
    const timeA = new Date(a?.lastMessageInfo?.time);
    const timeB = new Date(b?.lastMessageInfo?.time);
    return timeB - timeA;
  };
  // const sortedChatboxes = updatedChatboxs.sort(compareByTime);
  const sortedChatboxes = updatedChatboxsWithSwapUsers.sort(compareByTime);
  const reciverEmails = sortedChatboxes?.map((obj) => obj?.users[1]);

  // const createChatbox = (reciver) => {
  //   if (props.chatInboxs.length > 0) {
  //     props.createChatbox({ users: [user.email, reciver.email] })
  //   }
  // }

  const findChat = (reciver) => {
    // console.log(reciver);
    setCurrentChatUser(reciver);
    // console.log({ setCurrentChatUser });
    setmessageReceiver(reciver.email);
    getChatMessages(user.email, reciver.email);
    socket.emit("findChat", reciver.email);
    setCreateChat(false);
    const selectedChatbox = props.chatboxs.find((chatbox) => chatbox.users[0] === reciver.email || chatbox.users[1] === reciver.email);
    if (selectedChatbox) {
      setSelectedChatBox(selectedChatbox);
    } else {
      // Create a new chatbox and set it as selected

      props
        .createChatbox({
          users: [user.email, reciver.email],
        })
        .then((newChatbox) => {
          setSelectedChatBox(newChatbox.response);
        })
        .catch((error) => {
          console.error("Error creating chatbox:", error);
        });
    }
  };

  // useEffect(() => {
  //   setChatCreatedUserList(reciverEmails)
  // }, [props.chatboxs, props.createChatbox, props.chatInboxs]);

  useEffect(() => {
    socket?.on("receive_message", (data) => {
      // setlatestMessage(data);
      // setchatboxsLatestMessages([...chatboxsLatestMessages, data])

      if (currentChatUser.email === data.sender) {
        setlatestMessage(data);
        setchatboxsLatestMessages([...chatboxsLatestMessages, data]);
      } else {
        setchatboxsLatestMessages([...chatboxsLatestMessages, data]);
      }
    });

    return () => socket?.off("receive_message");
  }, [socket, updatedChatboxsWithSwapUsers]);

  useEffect(() => {
    if (latestMessage) {
      const newMessages = [...messageList, latestMessage];
      setMessageList(newMessages);
    }
  }, [latestMessage]);

  socket?.on("created_chatbox", (chatbox) => {
    // console.log("recieved chatbox", chatbox);
    findChat(chatbox);
  });

  // console.log({ findChat });

  socket?.on("getOnlineUsers", (data) => {
    // console.log("Online users:", data);
    setOnlineUserList(data);
  });

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")?.[0]?.[0]}`,
    };
  }

  // console.log(currentChatUser);
  return (
    <div>
      {/* <Button
        variant="outlined"
        size="small"
        startIcon={<ChevronLeftIcon />}
        onClick={toggleSidebar}
        sx={{
          ml: "10rem",
          [@media (min-width: 900px)]: {
            display: "none",
          },
        }}
      ></Button> */}
      <Grid
        container
        component={Paper}
        sx={{
          height: "80vh",
          position: "fixed",
          marginTop: "1.5rem",
          marginBottom: "2rem",
          // margin:'auto',
          marginLeft: "2rem",
          overflow:'auto',
          // border:'2px solid black',
          boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
          [`@media (max-width: 950px)`]: {
            marginLeft: "1rem",
          },
          [`@media (max-width: 750px)`]: {
            marginLeft: "0rem",
          },
        }}
        className="chatComponent"
      >
        <Grid
          item
          xs={screen.width <= 950 ? 12 : 3}
          sx={{
            borderRight: "1px solid #e0e0e0",
            [`@media (max-width: 950px)`]: {
              display: currentChatUser.email ? "none" : "block !important",
            },
          }}
        >
          <Grid item xs={12} sx={{ padding: "10px" }}>
            <Box sx={{ padding: "25px", borderBottom: "1px solid #e0e0e0" }}>
              <Typography sx={{ fontWeight: "700", fontSize: "28px", color: "rgba(25, 104, 173, 1)" }}>Messages</Typography>
            </Box>

            {!createChat && (
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  textColor="primary"
                  aria-label="secondary tabs example"
                  sx={{ width: "100%" }}
                >
                  <Tab value="team" label="Team" sx={{ width: "50%" }} />

                  <Tab value="student" label="Student" sx={{ width: "50%" }} />
                </Tabs>
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center", gap: "20px", marginTop:'10px' }}>
              {/* searchbar in chatbox */}
              <TextField
                id="outlined-basic-email"
                label="Search..."
                variant="outlined"
                fullWidth
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Fab
                color="primary"
                aria-label="add"
                sx={{
                  minWidth: "40px",
                  width: "40px",
                  height: "40px",
                  minHeight: "40px",
                  transform: createChat ? "rotate(45deg)" : "rotate(0deg)",
                }}
                onClick={() => setCreateChat(!createChat)}
              >
                +
              </Fab>
            </Box>
          </Grid>

          <Divider />
          <Box>

            {!createChat ? (
              <List  sx={{ height: "50vh", overflowY: "auto" }} >
                {tabValue === "team" ? (
                  <>
                    {reciverEmails
                      .filter((receiverEmail) => {
                        const user = teamArray.find((user) => user.email === receiverEmail);
                        return user?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
                      })
                      .map((receiverEmail) => {
                        const user = teamArray.find((user) => user.email === receiverEmail);
                        if (!user) return null;
                        // console.log(user.email);
                        const isOnline = onlineUserList.some((onlineUser) => onlineUser.userId === user.email);
                        // const lastMessageInfo = selectedChatBox?.lastMessageInfo?.message;
                        const lastMessageTime = selectedChatBox?.lastMessageInfo?.time;
                        const formattedDate = new Date(selectedChatBox?.lastMessageInfo?.time).toLocaleTimeString();

                        // console.log("Last Message Info:", lastMessageInfo);
                        return (
                          <ListItem
                            button
                            key={user._id}
                            onClick={() => {
                              findChat(user);
                              // findUser(user);
                            }}
                            sx={{
                              // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
                              background: user.email === currentChatUser.email ? "white" : "white",
                              margin: "0.7rem", 
                              boxShadow:
                                user.email === currentChatUser.email
                                  ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)"
                                  : "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",

                              width: "90%",
                            }}
                          >

                            <ListItemIcon>
                              {isOnline ? (
                                <Badge color="success" overlap="circular" badgeContent=" " variant="dot">
                                  <Avatar
                                    {...stringAvatar(`${user?.name}`)}
                                    sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                                  />
                                </Badge>
                              ) : (
                                
                                <Avatar
                                  {...stringAvatar(`${user?.name}`)}
                                  sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                                />
                                
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={user?.name}
                              // secondary={lastMessageInfo}
                              sx={{ color: user.email === currentChatUser.email ? "  rgba(28, 106, 174, 1)" : "inherit", marginLeft:'10px' }}
                            />

                            {/* <Box sx={{ flex: 1, mr:2 }} />
                            <Box>
                              <Typography variant="body2" color="textSecondary">
                               {formattedDate}
                              </Typography>
                            </Box> */}

                            <Badge badgeContent={0} color="primary"></Badge>
                          </ListItem>
                        );
                      })}
                  </>
                ) : (
                  <>
                
                    {reciverEmails
                      .filter((receiverEmail) => {
                        const user = studentArray.find((user) => user.email === receiverEmail);
                        return user?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
                      })
                      .map((receiverEmail) => {
                        const user = studentArray.find((user) => user.email === receiverEmail);
                        if (!user) return null;
                        // console.log("user array of chatcreatedbox", user);
                        const isOnline = onlineUserList.some((onlineUser) => onlineUser.userId === user.email);
                        return (
                          <ListItem
                            button
                            key={user._id}
                            onClick={() => {
                              findChat(user);
                              // findUser(user);
                            }}
                            sx={{ background: user.email === currentChatUser.email ? "rgba(28, 106, 174, 0.1)" : "inherit" }}
                          >
                            <ListItemIcon>
                              {isOnline ? (
                                <Badge color="success" overlap="circular" badgeContent=" " variant="dot">
                                  <Avatar
                                    {...stringAvatar(`${user?.name}`)}
                                    sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                                  />
                                </Badge>
                              ) : (
                                <Avatar
                                  {...stringAvatar(`${user?.name}`)}
                                  sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={user?.name}
                              sx={{ color: user.email === currentChatUser.email ? "  rgba(28, 106, 174, 1)" : "inherit", marginLeft:'10px' }}
                            />
                            <Badge badgeContent={0} color="primary"></Badge>
                          </ListItem>
                        );
                      })}
                  </>
                )}
              </List>
            ) : (
              <List  sx={{ height: "55vh", overflow: "auto" }}>
              
                {filteredUsers
                  .filter((user) => user?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
                  ?.map((user) => {
                    const isOnline = onlineUserList.some((onlineUser) => onlineUser.userId === user.email);
                    return (
                      <ListItem
                        button
                        key={user._id}
                        onClick={() => findChat(user)}
                        sx={{
                              // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
                              background: user.email === currentChatUser.email ? "white" : "white",
                              margin: "0.7rem", 
                              boxShadow:
                                user.email === currentChatUser.email
                                  ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)"
                                  : "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",

                              width: "90%",
                            }}
                        // sx={{ background: user.email === currentChatUser.email ? "rgba(28, 106, 174, 0.1)" : "inherit" }}
                      >
                        <ListItemIcon>
                          {isOnline ? (
                            <Badge color="success" overlap="circular" badgeContent=" " variant="dot">
                              <Avatar
                                {...stringAvatar(`${user?.name}`)}
                                sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                              />
                            </Badge>
                          ) : (
                            <Avatar
                              {...stringAvatar(`${user?.name}`)}
                              sx={{ color: user.email === currentChatUser.email ? "rgba(28, 106, 174, 1)" : "white" }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={user?.name}
                          sx={{ color: user.email === currentChatUser.email ? " rgba(28, 106, 174, 1)" : "inherit", marginLeft:'10px' }}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </Box>
        </Grid>
        {/* in this alignment of the message will remain same but the messages will be changed by the realtime messages */}
        {/* it will depend whether you want to implement date time stamps in it or not so it will be replaced by date it is already implemented in notifications you can see that */}
        {currentChatUser.email ? (
          <Grid
            item
            xs={window.innerWidth <= 900 ? 12 : 9}
            sx={{
              // overflowY: 'scroll',
              // height: '100%',
              [`@media(max-width: 950px)`]: {
                display: currentChatUser ? "block" : "none",
              },
            }}
          >
            <Box sx={{ boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.05)" }}>
              <ListItem>
                <ArrowBackIosIcon
                  sx={{
                    display: "none",
                    cursor: "pointer",
                    [`@media(max-width: 950px)`]: {
                      display: currentChatUser ? "block" : "none",
                    },
                  }}
                  onClick={() => setCurrentChatUser({})}
                />
                <ListItemIcon>
                  {currentChatUser?.img ? (
                    <Avatar alt={currentChatUser.name} src={currentChatUser.img} />
                  ) : (
                    <Avatar {...stringAvatar(`${currentChatUser?.name}`)} />
                  )}
                </ListItemIcon>
                <Box sx={{marginLeft:'10px'}}>
                  <Typography variant="h6" sx={{ fontSize: "10px", color: "primary.main" }} className="header-message">
                    {currentChatUser.name}
                  </Typography>
                  <Typography sx={{mt:'-5px'}} className="header-message">
                    {currentChatUser.role}
                  </Typography>
                </Box>
              </ListItem>
            </Box>
            <Divider />
            <List sx={{ height:'64vh', overflowY: "auto", padding: "20px", background: "rgb(249, 250, 251)" }} ref={scrollRef}>
              {messageList.map((message, index) => {
                const isSender = user.email !== message.receiver;

                return (
                  <ListItem key={index} sx={{ padding: "0" }} className={isSender ? "senderListItem" : ""}>
                    {isSender ? null : (
                      <ListItemIcon>
                        {currentChatUser?.img ? (
                          <Avatar alt={currentChatUser.name} src={currentChatUser.img} />
                        ) : (
                          <Avatar {...stringAvatar(`${currentChatUser?.name}`)} />
                        )}
                      </ListItemIcon>
                    )}
                    <Grid container sx={{ padding: "0px" }} className={user.email !== message.receiver ? "sender" : "receiver"}>
                      <Grid item xs={12} sx={{ padding: "0px" }}>
                        <ListItemText align="left" primary={message.message} />
                      </Grid>
                      <Grid item xs={12} sx={{ padding: "0px", display: "flex" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            width: "100%",
                            gap: " 0 10px",
                            height: "20px",
                          }}
                        >
                          <p style={{ fontSize: "0.5rem !important" }}>
                            {new Date(message?.time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                          </p>
                          {user.email !== message.receiver ? (
                            <p>
                              {message?.status === "read" ? (
                                <DoneAllIcon sx={{ fontSize: "18px", color: "rgb(0, 136, 255)" }} />
                              ) : (
                                <DoneIcon sx={{ fontSize: "20px", color: "rgba(255, 255, 255, 0.7)" }} />
                              )}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}

            </List>

            <Grid
              container
              sx={{
                padding: "10px",
                boxShadow: "0px -3px 15px 0px rgba(0,0,0,0.05)",
             
                // [@media(max-width: 1031px)]: {
                //   width:"80%",
                // },
              }}
            >
              <Grid item xs={10} sm={11} sx={{ display: 'flex' }}>
                <TextField
                  id="outlined-basic-email"
                  label="Type a Message Here.."
                  className="input-1"
                  fullWidth
                  value={currentMessage}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "rgba(217, 217, 217, 0.87)",
                      borderRadius: "2.5rem",
                    },
                  }}
                // sx={{ width: { xs: "calc(100% - 64px)", sm: "calc(100% - 84px)" } }}
                />
                <Grid xs={2} sm={1} align="right">
                  <Fab color="primary" aria-label="add" onClick={handleSendMessage} sx={{marginLeft:'15px'}}>
                    <SendIcon />
                  </Fab>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        ) : (
          <Grid
            item xs={9}
            sx={{
              [`@media(max-width: 950px)`]: {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <QuestionAnswerIcon sx={{ fontSize: "100px", color: "rgba(246, 139, 30, 0.7)" }} />
              <h2>Success Factor</h2>
              <p>Stay connected with the people who matter most.</p>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ChatSideBarTop;