import React, { useState } from "react";
import { MenuItem, Select, Tab, Tabs, useMediaQuery } from "@mui/material";
import StudentDescription from "./StudentDescription";
import AddTaskDetails from "./AddTaskDetails";
import EmailDetails from "./EmailDetails";

const StudentTask = ({ updateStudent, student }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const isMobile = useMediaQuery("(max-width:768px)");

  const tabStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "grey",
  };

  const activeTabStyle = {
    ...tabStyle,
    color: "rgba(25, 104, 173, 1)",
    borderBottom: "2px solid rgba(25, 104, 173, 1)", // Border style for active tab
  };

  return (
    <>
      <div>
        {isMobile ? (
          <div style={{ backgroundColor: "white", marginTop: "-30px", padding: "25px" }}>
            <Select value={selectedTab} onChange={(event) => setSelectedTab(event.target.value)}>
              <MenuItem value={0}>Create Note</MenuItem>
              {/* <MenuItem value={1}>Add Task</MenuItem> */}
              {/* <MenuItem value={2}>Send Email</MenuItem> */}
            </Select>
          </div>
        ) : (
          <>
            <div style={{ backgroundColor: "white", padding: "20px", marginTop: "33px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  borderBottom: "1px solid rgba(217, 217, 217, 1)",
                }}
              >
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
                  <Tab style={selectedTab === 0 ? activeTabStyle : tabStyle} label="Create Note" />
                  {/* <Tab style={{ fontSize: "16px", fontWeight: '400' }} label="Add Task" /> */}
                  {/* <Tab style={selectedTab === 1 ? activeTabStyle : tabStyle} label="Send Email" disabled /> */}
                </Tabs>
              </div>
            </div>
          </>
        )}

        <div style={{ backgroundColor: "white", padding: "0px 25px 0px 25px" }}>
          {selectedTab === 0 && (
            <div>
              <StudentDescription updateStudent={updateStudent} student={student} />
            </div>
          )}
          {/* {selectedTab === 1 && <div><AddTaskDetails /></div>} */}
          {/* {selectedTab === 1 && (
            <div>
              <EmailDetails />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default StudentTask;
