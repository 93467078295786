import React from "react";

const LeftArrow = ({ color }) => {
  return (
    <svg width="14" height="24" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.467696 17.142L14.8579 31.5275C15.4894 32.1573 16.5124 32.1573 17.1454 31.5275C17.7769 30.8976 17.7769 29.8746 17.1454 29.2448L3.89661 16.0007L17.1438 2.75668C17.7753 2.12684 17.7753 1.10381 17.1438 0.47238C16.5124 -0.15746 15.4878 -0.15746 14.8564 0.47238L0.466102 14.8577C-0.155633 15.481 -0.155633 16.5201 0.467696 17.142Z"
        fill={color}
      />
    </svg>
  );
};

export default LeftArrow;
