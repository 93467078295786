import React from "react";
import { Typography, Box, Stack, Button } from "@mui/material";

export default function RevertData({ student, revert, application, onUpdate }) {
//  console.log({student});
  return (
    <Box sx={{  backgroundColor:"primary.main", p: 2 ,ml:3, width:"fit-content" , borderRadius:"2rem"}}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize="1.5rem" mb={2} sx={{color:"white"}}>
          Revert Status
        </Typography>
        <Button sx={{color:"primary.main",  backgroundColor:"white" , height:"fit-content", width:"fit-content" , "&:hover":{backgroundColor:"#fff !important"}}} size="small" onClick={(e) => onUpdate({ revert: null }, application?._id)}>Resolve</Button>
      </Stack>
      <Typography fontWeight="medium" sx={{color:"white"}}>Assigned To : {revert?.reason}</Typography>
      <Typography fontWeight="light" sx={{color:"white"}}>Comment: {revert?.comment}</Typography>
      <Typography fontWeight="light" sx={{color:"white"}}>User: {revert?.user?.name}</Typography>
    </Box>
  );
}
