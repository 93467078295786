import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import { Typography } from "@mui/material";

const AddTasks = ({ inputTaskValue, InputTask, handleKey }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(237,234,233)",
          borderRadius: "10px",
          marginTop: "25px",
          padding: "10px",
          backgroundColor: "rgb(255,255,255)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <LooksOneOutlinedIcon
            fontSize="small"
            sx={{
              color: "rgb(30,31,33)",
              marginLeft: "5px",
              marginTop: "8px",
            }}
          />
          <TextField
            sx={{
              ml: "5px",
            }}
            autoFocus
            id="standard-size-small"
            placeholder="Add Task .."
            size="small"
            variant="standard"
            value={inputTaskValue}
            onChange={InputTask}
            onKeyDown={handleKey}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              color: "rgb(145,145,146)",
              fontSize: "14px",
              marginLeft: "5px",
              marginTop: "20px",
            }}
          >
            Assignee:
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AddTasks;
