import React from "react";
import { Typography, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Registration",
  "Personal",
  "Education",
  "Testing",
  "Entrance Test",
  // "Objective",
  "Country of Interest",
  "Reference",
  "Employment",
  "Intake",
  "Comments",
  "Appointment",
];

const FinanceForm = () => {
  return (
    <>
      <Box
        sx={{ bgcolor: "white", p: 2, width: "75rem", height: "38rem", borderRadius: ".8rem", overflowY: "scroll", position: "relative" }}
      >
        <Typography fontSize="1.5rem" mb={2}>
          Finance Form
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Stepper alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </>
  );
};

export default FinanceForm;
