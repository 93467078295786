import { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Line from "../../Images/Line";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
// import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import AddIcon from "../../Images/AddIcon";
import SearchIcon from "../../Images/SearchIcon";
import { useLocation } from "react-router-dom";
import { getLoggedInUser } from "../../utils/loggedInUser";

const TopBar = ({ setModal, setQuery, setShowFields, showFields }) => {
  const [boxWidth, setBoxWidth] = useState();

  const handleSearchClick = () => {
    setShowFields(true);

    // console.log("ahsan");
  };
  
 
  const location = useLocation();
  const isProcessingRoute = location.pathname.toLowerCase().includes("processing");
  return (
    <>
      <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgb(25, 104, 173)",
      // width: "fit-content",
      ml: "40px",
      height: "55px",
      borderRadius: "1rem",
      transition: "width 0.3s ease",
      "@media (max-width: 1024px)": {
        ml: "20px",
      },
    }}
  >
    {!(isProcessingRoute) && (
      <IconButton color="inherit" sx={{ margin: "0px 15px 0px 15px" }} onClick={() => setModal(true)}>
        <AddIcon />
        <Typography
          sx={{
            color: "white",
            fontWeight: "300px",
            marginLeft: "10px",
            mb: "2px",
          }}
        >
          {" "}
          Add Student{" "}
        </Typography>
      </IconButton>
    )}
  </Box>
    </>
  );
};

export default TopBar;
