export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";

// Utils Actions
export const CLEAR_ACTIONS_TYPE = "CLEAR_ACTIONS_TYPE";
export const CLEAR_STORE_DATA = "CLEAR_STORE_DATA";
export const SAVE_TRACK_PAGE_NUMBER = "SAVE_TRACK_PAGE_NUMBER";
export const SAVE_WORKPLAN_PAGE_NUMBER = "SAVE_WORKPLAN_PAGE_NUMBER";
export const CLEAR_TRACK_PAGE_NUMBER = "CLEAR_TRACK_PAGE_NUMBER";
export const CLEAR_WORKPLAN_PAGE_NUMBER = "CLEAR_WORKPLAN_PAGE_NUMBER";
// Add Widget Action
export const ADD_NEW_WIDGET = "ADD_NEW_WIDGET";

// LOGIN ACTIONS
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// FORGOT PASSWORD

export const VERIFY_URL_REQUEST = "VERIFY_URL_REQUEST";
export const VERIFY_URL_SUCCESS = "VERIFY_URL_SUCCESS";
export const VERIFY_URL_FAILURE = "VERIFY_URL_FAILURE";

export const EMAIL_RESET_PASSWORD_REQUEST = "EMAIL_RESET_PASSWORD_REQUEST";
export const EMAIL_RESET_PASSWORD_SUCCESS = "EMAIL_RESET_PASSWORD_SUCCESS";
export const EMAIL_RESET_PASSWORD_FAILURE = "EMAIL_RESET_PASSWORD_FAILURE";

// LOGOUT ACTION
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// Attendance Actions
export const ATTENDANCE_CHECKIN_REQUEST = "ATTENDANCE_CHECKIN_REQUEST";
export const ATTENDANCE_CHECKIN_SUCCESS = "ATTENDANCE_CHECKIN_SUCCESS";
export const ATTENDANCE_CHECKIN_FAILURE = "ATTENDANCE_CHECKIN_FAILURE";

export const ATTENDANCE_CHECKOUT_REQUEST = "ATTENDANCE_CHECKOUT_REQUEST";
export const ATTENDANCE_CHECKOUT_SUCCESS = "ATTENDANCE_CHECKOUT_SUCCESS";
export const ATTENDANCE_CHECKOUT_FAILURE = "ATTENDANCE_CHECKOUT_FAILURE";

export const ATTENDANCE_GET_REQUEST = "ATTENDANCE_GET_REQUEST";
export const ATTENDANCE_GET_SUCCESS = "ATTENDANCE_GET_SUCCESS";
export const ATTENDANCE_GET_FAILURE = "ATTENDANCE_GET_FAILURE";

export const ATTENDANCE_SUMMARY_REQUEST = "ATTENDANCE_SUMMARY_REQUEST";
export const ATTENDANCE_SUMMARY_SUCCESS = "ATTENDANCE_SUMMARY_SUCCESS";
export const ATTENDANCE_SUMMARY_FAILURE = "ATTENDANCE_SUMMARY_FAILURE";

// USER ACTION

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_LIST_BY_GROUP_REQUEST = "USER_LIST_BY_GROUP_REQUEST";
export const USER_LIST_BY_GROUP_SUCCESS = "USER_LIST_BY_GROUP_SUCCESS";
export const USER_LIST_BY_GROUP_FAILURE = "USER_LIST_BY_GROUP_FAILURE";

export const USER_BY_EMAIL_REQUEST = "USER_BY_EMAIL_REQUEST";
export const USER_BY_EMAIL_SUCCESS = "USER_BY_EMAIL_SUCCESS";
export const USER_BY_EMAIL_FAILURE = "USER_BY_EMAIL_FAILURE";

export const USER_WITH_DETAIL_REQUEST = "USER_WITH_DETAIL_REQUEST";
export const USER_WITH_DETAIL_SUCCESS = "USER_WITH_DETAIL_SUCCESS";
export const USER_WITH_DETAIL_FAILURE = "USER_WITH_DETAIL_FAILURE";

export const USER_ID_TO_FETCH = "USER_ID_TO_FETCH";

export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAILURE = "PASSWORD_UPDATE_FAILURE";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILURE = "USER_CREATE_FAILURE";

export const USER_GROUP_GET_REQUEST = "USER_GROUP_GET_REQUEST";
export const USER_GROUP_GET_SUCCESS = " USER_GROUP_GET_SUCCESS";
export const USER_GROUP_GET_FAILURE = "USER_GROUP_GET_FAILURE";

export const UPDATE_USERHOURS_REQUEST = "UPDATE_USERHOURS_REQUEST";
export const UPDATE_USERHOURS_SUCCESS = "UPDATE_USERHOURS_SUCCESS";
export const UPDATE_USERHOURS_FAILURE = "UPDATE_USERHOURS_FAILURE";

export const GET_USERHOURS_REQUEST = "GET_USERHOURS_REQUEST";
export const GET_USERHOURS_SUCCESS = "GET_USERHOURS_SUCCESS";
export const GET_USERHOURS_FAILURE = "GET_USERHOURS_FAILURE";

export const ADD_MEMEBERSTEAM_REQUEST = "ADD_MEMEBERSTEAM_REQUEST";
export const ADD_MEMEBERSTEAM_SUCCESS = "ADD_MEMEBERSTEAM_SUCCESS";
export const ADD_MEMEBERSTEAM_FAILURE = "ADD_MEMEBERSTEAM_FAILURE";

export const REMOVE_MEMEBERSTEAM_REQUEST = "REMOVE_MEMEBERSTEAM_REQUEST";
export const REMOVE_MEMEBERSTEAM_SUCCESS = "REMOVE_MEMEBERSTEAM_SUCCESS";
export const REMOVE_MEMEBERSTEAM_FAILURE = "REMOVE_MEMEBERSTEAM_FAILURE";

// export const PERMISSION_LIST_REQUEST = "PERMISSION_LIST_REQUEST";
// export const PERMISSION_LIST_SUCCESS = "PERMISSION_LIST_SUCCESS";
// export const PERMISSION_LIST_FAILURE = "PERMISSION_LIST_FAILURE";

// FILTER TABLE ACTIONS
export const TASKSTATUS_GET_REQUEST = "TASKSTATUS_GET_REQUEST";
export const TASKSTATUS_GET_SUCCESS = "TASKSTATUS_GET_SUCCESS";
export const TASKSTATUS_GET_FAILURE = "TASKSTATUS_GET_FAILURE";

export const MY_RANDOM_ACTION_START = "MY_RANDOM_ACTION_START";
export const MY_RANDOM_ACTION_STOP = "MY_RANDOM_ACTION_STOP";
export const MY_RANDOM_ACTION_END = "MY_RANDOM_ACTION_END";

// IMGS ACTIONS

export const IMG_UPLOAD_REQUEST = "IMG_UPLOAD_REQUEST";
export const IMG_UPLOAD_SUCCESS = "IMG_UPLOAD_SUCCESS";
export const IMG_UPLOAD_FAILURE = "IMG_UPLOAD_FAILURE";
export const IMGS_LOAD_REQUEST = "IMGS_LOAD_REQUEST";
export const IMGS_LOAD_SUCCESS = "IMGS_LOAD_SUCCESS";
export const IMGS_LOAD_FAILURE = "IMGS_LOAD_FAILURE";

// DOCUMENTS ACTIONS

export const DOCUMENT_UPLOAD_REQUEST = "DOCUMENT_UPLOAD_REQUEST";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_FAILURE = "DOCUMENT_UPLOAD_FAILURE";
export const DOCUMENTS_LOAD_REQUEST = "DOCUMENTS_LOAD_REQUEST";
export const DOCUMENTS_LOAD_SUCCESS = "DOCUMENTS_LOAD_SUCCESS";
export const DOCUMENTS_LOAD_FAILURE = "DOCUMENTS_LOAD_FAILURE";

// DIAGNOSTICS ACTIONS

export const DIAGNOSTICS_LISTS_GET_REQUEST = "DIAGNOSTICS_LISTS_GET_REQUEST";
export const DIAGNOSTICS_LISTS_GET_SUCCESS = "DIAGNOSTICS_LISTS_GET_SUCCESS";
export const DIAGNOSTICS_LISTS_GET_FAILURE = "DIAGNOSTICS_LISTS_GET_FAILURE";

export const DIAGNOSTICS_LIST_GET_REQUEST = "DIAGNOSTICS_LIST_GET_REQUEST";
export const DIAGNOSTICS_LIST_GET_SUCCESS = "DIAGNOSTICS_LIST_GET_SUCCESS";
export const DIAGNOSTICS_LIST_GET_FAILURE = "DIAGNOSTICS_LIST_GET_FAILURE";

export const TRAFFICTYPE_LIST_GET_REQUEST = "TRAFFICTYPE_LIST_GET_REQUEST";
export const TRAFFICTYPE_LIST_GET_SUCCESS = "TRAFFICTYPE_LIST_GET_SUCCESS";
export const TRAFFICTYPE_LIST_GET_FAILURE = "TRAFFICTYPE_LIST_GET_FAILURE";

export const SUBDIVISION_LIST_GET_REQUEST = "SUBDIVISION_LIST_GET_REQUEST";
export const SUBDIVISION_LIST_GET_SUCCESS = "SUBDIVISION_LIST_GET_SUCCESS";
export const SUBDIVISION_LIST_GET_FAILURE = "SUBDIVISION_LIST_GET_FAILURE";

export const ASSETTYPE_LIST_GET_REQUEST = "ASSETTYPE_LIST_GET_REQUEST";
export const ASSETTYPE_LIST_GET_SUCCESS = "ASSETTYPE_LIST_GET_SUCCESS";
export const ASSETTYPE_LIST_GET_FAILURE = "ASSETTYPE_LIST_GET_FAILURE";

export const CLASS_LIST_GET_REQUEST = "CLASS_LIST_GET_REQUEST";
export const CLASS_LIST_GET_SUCCESS = "CLASS_LIST_GET_SUCCESS";
export const CLASS_LIST_GET_FAILURE = "CLASS_LIST_GET_FAILURE";

export const TRACKTYPE_LIST_GET_REQUEST = "TRACKTYPE_LIST_GET_REQUEST";
export const TRACKTYPE_LIST_GET_SUCCESS = "TRACKTYPE_LIST_GET_SUCCESS";
export const TRACKTYPE_LIST_GET_FAILURE = "TRACKTYPE_LIST_GET_FAILURE";

export const DYNAMIC_LANGUAGE_GET_REQUEST = "DYNAMIC_LANGUAGE_GET_REQUEST";
export const DYNAMIC_LANGUAGE_GET_SUCCESS = "DYNAMIC_LANGUAGE_GET_SUCCESS";
export const DYNAMIC_LANGUAGE_GET_FAILURE = "DYNAMIC_LANGUAGE_GET_FAILURE";

export const GLOBAL_USER_LOGGING_GET_REQUEST = "GLOBAL_USER_LOGGING_GET_REQUEST";
export const GLOBAL_USER_LOGGING_GET_SUCCESS = "GLOBAL_USER_LOGGING_GET_SUCCESS";
export const GLOBAL_USER_LOGGING_GET_FAILURE = "GLOBAL_USER_LOGGING_GET_FAILURE";

export const SET_GEOLOGGING_GLOBAL_SETTING_REQUEST = "SET_GEOLOGGING_GLOBAL_SETTING_REQUEST";
export const SET_GEOLOGGING_GLOBAL_SETTING_SUCCESS = "SET_GEOLOGGING_GLOBAL_SETTING_SUCCESS";
export const SET_GEOLOGGING_GLOBAL_SETTING_FAILURE = "SET_GEOLOGGING_GLOBAL_SETTING_FAILURE";

// SOD ACTIONS

export const SOD_LIST_GET_REQUEST = "SOD_LIST_GET_REQUEST";
export const SOD_LIST_GET_SUCCESS = "SOD_LIST_GET_SUCCESS";
export const SOD_LIST_GET_FAILURE = "SOD_LIST_GET_FAILURE";

// WORKPLAN TEMPLATE HELPER ACTIONS
export const TEMPLATES_SORT_UPDATE_REQUEST = "TEMPLATES_SORT_UPDATE_REQUEST";
export const TEMPLATES_SORT_UPDATE_SUCCESS = "TEMPLATES_SORT_UPDATE_SUCCESS";
export const TEMPLATES_SORT_UPDATE_FAILURE = "TEMPLATES_SORT_UPDATE_FAILURE";

export const GET_PLAN_USERS_REQUEST = "GET_PLAN_USERS_REQUEST";
export const GET_PLAN_USERS_SUCCESS = "GET_PLAN_USERS_SUCCESS";
export const GET_PLAN_USERS_FAILURE = "GET_PLAN_USERS_FAILURE";

export const TEMPLATES_UPDATE_TEMP_REQUEST = "TEMPLATES_UPDATE_TEMP_REQUEST";
export const TEMPLATES_UPDATE_TEMP_SUCCESS = "TEMPLATES_UPDATE_TEMP_SUCCESS";
export const TEMPLATES_UPDATE_TEMP_FAILURE = "TEMPLATES_UPDATE_TEMP_FAILURE";

// JOURNEY PLAN HELPER ACTION TYPES
export const CREATE_ISSUES_WORKORDER_REQUEST = "CREATE_ISSUES_WORKORDER_REQUEST";
export const CREATE_ISSUES_WORKORDER_SUCCESS = "CREATE_ISSUES_WORKORDER_SUCCESS";
export const CREATE_ISSUES_WORKORDER_FAILURE = "CREATE_ISSUES_WORKORDER_FAILURE";

// ASSET GROUP( TRACKS) HELPER ACTIONS
export const WORKPLAN_FROM_ASSETGROUP_REQUEST = "WORKPLAN_FROM_ASSETGROUP_REQUEST";
export const WORKPLAN_FROM_ASSETGROUP_SUCCESS = "WORKPLAN_FROM_ASSETGROUP_SUCCESS";
export const WORKPLAN_FROM_ASSETGROUP_FAILURE = "WORKPLAN_FROM_ASSETGROUP_FAILURE";

// ASSET HELPER ACTIONS
export const GET_LINE_ASSETS_REQUEST = "GET_LINE_ASSETS_REQUEST";
export const GET_LINE_ASSETS_SUCCESS = "GET_LINE_ASSETS_SUCCESS";
export const GET_LINE_ASSETS_FAILURE = "GET_LINE_ASSETS_FAILURE";

export const GET_LINE_ASSETS_WITH_SELF_REQUEST = "GET_LINE_ASSETS_WITH_SELF_REQUEST";
export const GET_LINE_ASSETS_WITH_SELF_SUCCESS = "GET_LINE_ASSETS_WITH_SELF_SUCCESS";
export const GET_LINE_ASSETS_WITH_SELF_FAILURE = "GET_LINE_ASSETS_FAILURE";

export const GET_ASSETTYPES_ASSETS_REQUEST = "GET_ASSETTYPES_ASSETS_REQUEST";
export const GET_ASSETTYPES_ASSETS_SUCCESS = "GET_ASSETTYPES_ASSETS_SUCCESS";
export const GET_ASSETTYPES_ASSETS_FAILURE = "GET_ASSETTYPES_ASSETS_FAILURE";

export const GET_INSPECTABLE_ASSETS_REQUEST = "GET_INSPECTABLE_ASSETS_REQUEST";
export const GET_INSPECTABLE_ASSETS_SUCCESS = "GET_INSPECTABLE_ASSETS_SUCCESS";
export const GET_INSPECTABLE_ASSETS_FAILURE = "GET_INSPECTABLE_ASSETS_FAILURE";

export const GET_UNASSIGNED_ASSETS_REQUEST = "GET_UNASSIGNED_ASSETS_REQUEST";
export const GET_UNASSIGNED_ASSETS_SUCCESS = "GET_UNASSIGNED_ASSETS_SUCCESS";
export const GET_UNASSIGNED_ASSETS_FAILURE = "GET_UNASSIGNED_ASSETS_FAILURE";

export const GET_ASSET_TREE_REQUEST = "GET_ASSET_TREE_REQUEST";
export const GET_ASSET_TREE_SUCCESS = "GET_ASSET_TREE_SUCCESS";
export const GET_ASSET_TREE_FAILURE = "GET_ASSET_TREE_FAILURE";

export const CREATE_MULTIPLE_ASSETS_REQUEST = "CREATE_MULTIPLE_ASSETS_REQUEST";
export const CREATE_MULTIPLE_ASSETS_SUCCESS = "CREATE_MULTIPLE_ASSETS_SUCCESS";
export const CREATE_MULTIPLE_ASSETS_FAILURE = "CREATE_MULTIPLE_ASSETS_FAILURE";

// RUN HELPER ACTIONS
export const GET_LINE_RUN_REQUEST = "GET_LINE_RUN_REQUEST";
export const GET_LINE_RUN_SUCCESS = "GET_LINE_RUN_SUCCESS";
export const GET_LINE_RUN_FAILURE = "GET_LINE_RUN_FAILURE";

export const GET_RUN_REQUEST = "GET_RUN_REQUEST";
export const GET_RUN_SUCCESS = "GET_RUN_SUCCESS";
export const GET_RUN_FAILURE = "GET_RUN_FAILURE";

// LINE SELECTION ACTIONS

export const SET_SELECTED_LINE = "SET_SELECTED_LINE";
export const CLEAR_SELECTED_LINE = "CLEAR_SELECTED_LINE";

export const GET_MULTIPLE_LINES_DATA_REQUEST = "GET_MULTIPLE_LINES_DATA_REQUEST";
export const GET_MULTIPLE_LINES_DATA_SUCCESS = "GET_MULTIPLE_LINES_DATA_SUCCESS";
export const GET_MULTIPLE_LINES_DATA_FAILURE = "GET_MULTIPLE_LINES_DATA_FAILURE";

// LANGUAGE SELECTION
export const SET_LANGUAGE_CHANGE = "SET_LANGUAGE_CHANGE";

export const ADD_LANGUAGE_CHANGE_REQUEST = "ADD_LANGUAGE_CHANGE_REQUEST";
export const ADD_LANGUAGE_CHANGE_SUCCESS = "ADD_LANGUAGE_CHANGE_SUCCESS";
export const ADD_LANGUAGE_CHANGE_FAILURE = "ADD_LANGUAGE_CHANGE_FAILURE";

export const EDIT_LANGUAGE_CHANGE_REQUEST = "EDIT_LANGUAGE_CHANGE_REQUEST";
export const EDIT_LANGUAGE_CHANGE_SUCCESS = "EDIT_LANGUAGE_CHANGE_SUCCESS";
export const EDIT_LANGUAGE_CHANGE_FAILURE = "EDIT_LANGUAGE_CHANGE_FAILURE";

export const DELETE_LANGUAGE_CHANGE_REQUEST = "DELETE_LANGUAGE_CHANGE_REQUEST";
export const DELETE_LANGUAGE_CHANGE_SUCCESS = "DELETE_LANGUAGE_CHANGE_SUCCESS";
export const DELETE_LANGUAGE_CHANGE_FAILURE = "DELETE_LANGUAGE_CHANGE_FAILURE";

// INSPECTIONS ACTIONS
export const SET_FUTURE_INSPECTION_DETAIL = "SET_FUTURE_INSPECTION_DETAIL";

// DASHBOARD ACTIONS
export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";

//Filter Actions
export const UPDATE_FILTER_STATE = "UPDATE_FILTER_STATE";

// APP SETTINGS ACTIONS
export const GET_APPSETTINS_DATA_REQUEST = "GET_APPSETTINS_DATA_REQUEST";
export const GET_APPSETTINS_DATA_SUCCESS = "GET_APPSETTINS_DATA_SUCCESS";
export const GET_APPSETTINS_DATA_FAILURE = "GET_APPSETTINS_DATA_FAILURE";

export const GET_TESTS_APPFORMS_REQUEST = "GET_TESTS_APPFORMS_REQUEST";
export const GET_TESTS_APPFORMS_SUCCESS = "GET_TESTS_APPFORMS_SUCCESS";
export const GET_TESTS_APPFORMS_FAILURE = "GET_TESTS_APPFORMS_FAILURE";

export const UPDATE_TESTS_APPFORM_REQUEST = "UPDATE_TESTS_APPFORM_REQUEST";
export const UPDATE_TESTS_APPFORM_SUCCESS = "UPDATE_TESTS_APPFORM_SUCCESS";
export const UPDATE_TESTS_APPFORM_FAILURE = "UPDATE_TESTS_APPFORM_FAILURE";

export const DELETE_TESTS_APPFORM_REQUEST = "DELETE_TESTS_APPFORM_REQUEST";
export const DELETE_TESTS_APPFORM_SUCCESS = "DELETE_TESTS_APPFORM_SUCCESS";
export const DELETE_TESTS_APPFORM_FAILURE = "DELETE_TESTS_APPFORM_FAILURE";

export const GET_VERSION_REQUEST = "GET_VERSION_REQUEST";
export const GET_VERSION_SUCCESS = "GET_VERSION_SUCCESS";
export const GET_VERSION_FAILURE = "GET_VERSION_FAILURE";

export const GET_USERS_SIGNATURE_REQUEST = "GET_USERS_SIGNATURE_REQUEST";
export const GET_USERS_SIGNATURE_SUCCESS = "GET_USERS_SIGNATURE_SUCCESS";
export const GET_USERS_SIGNATURE_FAILURE = "GET_USERS_SIGNATURE_FAILURE";
