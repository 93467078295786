
import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider , Button ,Box ,TextField  } from '@mui/material';
import { Reply } from "@mui/icons-material"; 
import { Inbox, Send } from '@mui/icons-material';
import SentSection from './SentSection';



const InboxSection = () => {

  const [showMessages, setShowMessages] = useState(false);
  const [isReplying, setIsReplying] = useState(false); 

  const handleClick = () => {
          setShowMessages(true);
        };


        const handleBackToInbox = () => {
          setShowMessages(false);
        };

        const toggleReply = () => {
          setIsReplying(!isReplying);
        };
      
        
  return (
    <>
      <Paper elevation={2} style={{ padding: '16px' }}>
        <Typography variant="h6">Inbox</Typography>


      </Paper>
              
              {showMessages? (

                
      <Paper elevation={2} style={{ padding: '16px', margin: '16px' }}   >
        <Box sx={{display:"flex" , justifyContent:"space-between" , }}>
       <Button onClick={handleBackToInbox}  variant='contained' >Back</Button>
       <Button variant="contained" onClick={toggleReply} >
              <Reply /> Reply
            </ Button>

            </Box>
            <Box sx={{display:"flex" , flexDirection:"column" , gap:"1rem" , mt:1}}>
        <Typography variant="h6">Message 1</Typography>
        <Typography variant="body1">This is a sample message.</Typography>
        <Typography variant="body1">Whole Message.</Typography>
        </Box>
        {isReplying ? ( 
          <Box mt={2}>
            <TextField
              label="Reply"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          </Box>
        ) : null}
{isReplying && (
            <Button variant="contained" color="primary" sx={{mt:2}}>
              Send
            </Button>
          )}
      </Paper>

      
      
      ):(



<Paper elevation={2} style={{ padding: '16px', margin: '16px' }} onClick={handleClick}>
       
<Typography variant="h6">Message 1</Typography>
<Typography variant="body1">This is a sample message.</Typography>
</Paper>
)}




</>
  );
};

export default InboxSection


