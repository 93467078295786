import { MenuItem, Step, StepButton, Stepper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import DataWrapper from "../StudentProfile/ProfileData/DataWrapper";
import Personal from "./DataDisplay/Personal";
import Qualification from "./DataDisplay/Qualification";
import Experience from "./DataDisplay/Experience";
import Intake from "./DataDisplay/Intake";
import University from "./DataDisplay/University";
import Comments from "./DataDisplay/Comments";

const steps = ["Personal", "Qualification", "Experience", "Intake", "Comments"];

const Overview = ({ singleEnquiry, EmailComment, CallSchedule }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const showNames = {
    0: "Personal",
    1: "Qualification",
    2: "Experience",
    3: "Intake",
    4: "University",
    5: "Comments",
  };

  const data = {
    0: <Personal singleEnquiry={singleEnquiry?.enquiryData} EmailComment={EmailComment} singleEnquiryID={singleEnquiry} CallSchedule={CallSchedule} />,
    1: <Qualification singleEnquiry={singleEnquiry?.enquiryData} />,
    2: <Experience singleEnquiry={singleEnquiry?.enquiryData} />,
    3: <Intake singleEnquiry={singleEnquiry?.enquiryData} />,
    // 4: <University singleEnquiry={singleEnquiry?.enquiryData} />,
    4: <Comments singleEnquiry={singleEnquiry?.enquiryData} />,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <Box
      sx={{
        // display: "grid",
        // gridTemplateColumns: "1fr 6fr",
        display: "flex",
        alignItems: "flex-start",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {data[activeStep]}
      </Box>
    </Box>
  );
};

export default Overview;
