import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const TrafficSource = (props) => {
  const chartData = [50, 30, 10, 10]; // Example chart data percentages
  const labels = ["Pending", "Approved", "Rejected", "Escalated"];

  const options = {
    labels,
    theme: {
      palette: "palette1", // Use the desired palette from your theme
    },
  };

  return (
    <Card sx={{ marginTop: "2rem", height: "94%" }}>
      <CardHeader title="Application Status Summary" />
      <CardContent>
        <ReactApexChart options={options} series={chartData} type="donut" width="70%" />
        <Stack alignItems="center" direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
          {chartData.map((dataPoint, index) => (
            <Stack key={labels[index]} alignItems="center" spacing={1}>
              <Typography variant="h6">{labels[index]}</Typography>
              <Typography variant="subtitle2">{dataPoint}%</Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TrafficSource;
