import React, { useContext, useState } from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import { useEffect } from "react";
import ZForms from "./ZForms";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SubmissionMaster from "./SubmissionMaster";
import submissionTableContainer from "./SubmissionTable";
import Submission from "./Submission";
import AddQuery from "./AddQuery";
import { Box, Modal } from "@mui/material";
import { curdActions } from "../../reduxCURD/actions";
import { SocketContext } from "../../SocketContext";
import DataModal from "./ViewModal/Modal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import GoogleLead from "./GoogleLead";

const Lead = (props) => {
  console.log(props);
  const [selectedTab, setSelectedTab] = useState(0);
  const [proceedData, setProceedData] = useState({});
  const [modal, setModal] = useState(false);
  const [leadDataToShow, setLeadDataToShow] = useState({});
  const [scheduleCallData, setScheduleCallData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { setLeadData, setCreateQuiry } = useContext(SocketContext);
  const [message, setMessage] = useState("");
  const [leadEditData, setleadEditData] = useState({});
  const [edit, setEdit] = useState(false);
  const [leadScheduleCallEdit, setleadScheduleCallEdit] = useState({});
  const [leadDataToCampaign, setleadDataToCampaign] = useState({});

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getLeads = async () => {
    await props.getLeads();
  };

  const getUsers = () => {
    props.getUsers("/allUser");
  };

  useEffect(() => {
    getUsers();
  }, []);

  const createLead = async (data) => {
    // console.log(data);
    await props.createLead(data);
    setMessage("Leads has been created Successfully");
    setSnackbarOpen(true);

    getLeads();
  };

  // console.log("proceedData",proceedData);
  const getOneLead = async (id) => {
    await props.getLead(id);
  };

  const updateLead = async (data, id) => {
    // console.log("data", data);
    // console.log("id", id);
    await props.updateLead(data, id);
  };

  // console.log("ProceedData",proceedData);
  const getProceedData = (data) => {
    setProceedData(data);
    props.getLead(data?.data?._id);
  };

  useEffect(() => {
    getLeads();
  }, []);

  const handleCloseModal = () => {
    setModal(false);
  };

  const createEnquiry = async (data) => {
    // console.log("id",data.infoEmail);
    // setLeadData({
    //   data,
    //   commentSheet: props?.lead?.commentSheet,
    // });

    // setCreateQuiry(true);
    setMessage("Query has been created Successfully");
    setSnackbarOpen(true);
    await props.updateLead(
      {
        LeadMoveToQuery: true,
        isProcess: false,
        isClosed: false,
        enquiryData: data,
        // commentSheet: props?.lead?.commentSheet,
      },
      proceedData?.data?._id,
    );
  };

  const getLead = (data) => {
    // console.log("data", data);
    props.getLead(data?.data?._id);
    setLeadDataToShow(data);
    handleOpen();
  };

  const LeadDataforCampaign = (data) => {
    props.getLead(data?.data?._id);
    setleadDataToCampaign(data);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setScheduleCallData({});
    setOpen(false);
  };

  // const setSchedule = async (data, id, uId) => {
  //   await props.updateLead(
  //     {
  //       scheduleCall: {
  //         [uId]: data,
  //       },
  //     },
  //     id,
  //   );
  // };


  // const CallSchedule = async (id, data, key) => {
  //   await props.updateLead(
  //     {
  //       scheduleCall: {
  //         [key]: {
  //           ...data,
  //         },
  //       },
  //     },
  //     id,
  //   );
  // };

  const CallSchedule = async (data, id, uId) => {
    await props.updateLead(
      {
        scheduleCall: {
          [uId]: data,
        },
      },
      id,
    );
    await props.updateLead(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };

  const setSchedule = async (data, id, uId) => {
    // console.log("data", data); //
    const selectedDateTime = new Date(data.date + "T" + data.time);
    const currentDateTime = new Date();

    // Set seconds and milliseconds to zero for accurate time comparison
    selectedDateTime.setSeconds(0, 0);
    currentDateTime.setSeconds(0, 0);

    // Check if the selected date and time are in the future
    if (selectedDateTime >= currentDateTime) {
      // Proceed with scheduling the call
      await props.updateLead(
        {
          scheduleCall: {
            [uId]: data,
          },
        },
        id,
      );
    } else {
      // Show a message that only current or future dates and times are allowed
      setMessage("Cannot schedule a call for the Past Time");
      setSnackbarOpen(true);
    }
  };

  const editCall = async (value, id, data, key) => {
    console.log("boolean", value, "id", id, "data", data, "key", key);
    setleadScheduleCallEdit({
      ...data,
    });
    // await props.updateLead({
    //   scheduleCall: {
    //     [key]: {
    //       ...data,
    //     },
    //   },
    //   id,
    // });
  };

  const doneCall = async (value, id, data, key, comment) => {
    if (value === false) {
      setScheduleCallData({
        ...data,
        value,
      });
      await props.updateLead(
        {
          scheduleCall: {
            [key]: {
              ...data,
              callDone: value,
            },
          },
        },
        id,
      );
    } else {
      setScheduleCallData({});

      await props.updateLead(
        {
          scheduleCall: {
            [key]: {
              ...data,
              callDone: value,
              scheduleComment: comment,
            },
          },
        },
        id,
      );
      await props.updateLead(
        {
          commentSheet: {
            time: data?.time,
            date: data?.date,
            purpose: data?.mode,
            content: comment,
          },
        },
        id,
      );
    }
  };

  const setComment = (data, id) => {
    props.updateLead(
      {
        comments: data,
      },
      id,
    );
    props.updateLead(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };

  const EmailComment = async (data, id, uId) => {
    await props.updateLead(
      {
        scheduleCall: {
          [uId]: data,
        },
      },
      id,
    );
    await props.updateLead(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };

  const leadEditComment = async (data, id) => {
    await props.updateLead(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };


  const onDelete = async (id) => {
    // console.log("id", id);
    await props
      .deleteLead(id)
      .then((response) => {
        // console.log("Delete response:", response);
      })
      .catch((error) => {
        console.error("Delete error:", error);
      });
    getLeads();
  };

  // const DeleteSchedule = async (id, value, key) => {
  //   console.log("id", id);
  //   await props
  //     .deleteLead({
  //       scheduleCall: {
  //         [key]: {},
  //       },
  //       id,
  //     })
  //     .catch((error) => {
  //       console.error("Delete error:", error);
  //     });
  // };

  useEffect(() => {
    if (props.actionType == "LEADS_CREATE_SUCCESS") {
      setMessage("Lead has been created Successfully");
      setSnackbarOpen(true);
    }
    if (props.actionType == "LEAD_UPDATE_SUCCESS") {
      props.getLeads();
    }
  }, [props.actionType]);

  return (
    <div style={{ marginTop: "70px", display: "flex" }}>
      <div style={{ marginLeft: "2rem", marginRight: "2rem", width: "100vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "1rem",
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
            <Tab label="Manual Leads" />
            <Tab label="Google Leads" />
            <Tab label="Forms" />
            <Tab label="Submissions" />
          </Tabs>
        </div>
        {selectedTab === 0 && (
          <div>
            <SubmissionMaster
              setModal={setModal}
              leadEditData={leadEditData}
              setleadEditData={setleadEditData}
              edit={edit}
              setEdit={setEdit}
              createLead={createLead}
              leads={props.leads}
              getLeads={getLeads}
              updateLead={updateLead}
              getProceedData={getProceedData}
              getLead={getLead}
              users={props.users}
              location={props.location}
              onDelete={onDelete}
              allLeadData={props.lead}
              LeadDataforCampaign={LeadDataforCampaign}
              leadDataToCampaign={leadDataToCampaign}
              leadEditComment={leadEditComment}
            />
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <GoogleLead
              location={props.location}
              getProceedData={getProceedData}
              getLeads={getLeads}
              updateLead={updateLead}
              users={props.users}
              setModal={setModal}
              leads={props.leads}
              getLead={getLead}
              onDelete={onDelete}
            />
          </div>
        )}
        {selectedTab === 2 && (
          <div>
            <ZForms leads={props.leads} loading={props} createLead={createLead} />
          </div>
        )}
        {selectedTab === 3 && (
          <div>
            <Submission props={props} />
          </div>
        )}
      </div>

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            background: "white",
            width: "70rem",
            height: "70%",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <DataModal
            // DeleteSchedule={DeleteSchedule}
            leadScheduleCallEdit={leadScheduleCallEdit}
            setleadScheduleCallEdit={setleadScheduleCallEdit}
            scheduleCallData={scheduleCallData}
            setScheduleCallData={setScheduleCallData}
            editCall={editCall}
            doneCall={doneCall}
            allLeadData={props.lead}
            leadData={leadDataToShow}
            setSchedule={setSchedule}
            setComment={setComment}
            onDelete={onDelete}
            EmailComment={EmailComment}
            CallSchedule={CallSchedule}
          />
        </Box>
      </Modal>

      {modal && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <AddQuery handleCloseModal={handleCloseModal} proceedData={proceedData} createEnquiry={createEnquiry} />
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { getUsers },
};

let variableList = {
  userReducer: {
    users: [],
  },
};

let customReducers = ["userReducer"];

const leadsContainer = CRUDFunction(Lead, "lead", actionOptions, variableList, customReducers);
export default leadsContainer;
