import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TaskCards from "./TaskCards";
// import AddTask from "./AddTask";
import AddTasks from "./AddTasks";

const Column = (props) => {
  const [showTask, setShowTask] = useState(false);

  const ButtonClick = () => {
    setShowTask(true);
  };

  const handleKey = (event) => {
    if (event.key === "Enter") {
      props.createTask(props.columns.id);
      // Hide the component and show the button again
      setShowTask(false);
    }
  };

  const tasksIds = useMemo(() => {
    return props.tasks.map((task) => task.id);
  }, [props.tasks]);

  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: props.columns.id,
    data: {
      type: "columns",
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <>
      <Box
        sx={{
          padding: "10px",
          "&:hover": {
            border: " 1px solid rgb(237,234,233)",
            borderRadius: "6px",
          },
        }}
        ref={setNodeRef}
        style={style}
      >
        <Box
          sx={{
            width: "300px",
            display: "flex",
            flexDirection: "row",
          }}
          {...attributes}
          {...listeners}
        >
          <TextField
            id="filled-hidden-label-normal"
            defaultValue="Untitled Section"
            variant="outlined"
            size="small"
            value={props.columns.title}
          />
          <Tooltip title="Add Task" arrow placement="top">
            <AddIcon
              sx={{
                marginLeft: "22px",
                marginTop: "8px",
                color: "rgb(109,110,111)",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgb(237,234,233)",
                  borderRadius: "6px",
                },
              }}
            />
          </Tooltip>
          <Tooltip title="More Actions" arrow placement="top">
            <MoreHorizIcon
              sx={{
                marginLeft: "8px",
                color: "rgb(109,110,111)",
                marginTop: "8px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgb(237,234,233)",
                  borderRadius: "6px",
                },
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: "rgb(246,244,244)",
            mt: "15px",
          }}
        >
          <SortableContext items={tasksIds}>
            {props.tasks.map((task) => (
              <Box>
                <TaskCards
                  key={task.id}
                  title={task.content}
                  task={task}
                  id={task.id}
                  isDone={props.isDone}
                  assignedTo={task.assignedTo}
                  onTaskUpdate={props.onTaskUpdate}
                  handleDeleteTask={props.handleDeleteTask}
                  users={props.users}
                />
              </Box>
            ))}
          </SortableContext>

          {!showTask ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                fullWidth
                onClick={() => {
                  ButtonClick();
                }}
                startIcon={
                  <AddIcon
                    sx={{
                      color: "rgb(109,110,111)",
                    }}
                  />
                }
                sx={{
                  color: "rgb(145,145,146)",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "rgb(237,234,233)",
                    borderRadius: "6px",
                  },
                }}
              >
                Add Task
              </Button>
            </Box>
          ) : (
            <AddTasks inputTaskValue={props.inputTaskValue} InputTask={props.InputTask} handleKey={handleKey} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Column;
