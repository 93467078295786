import React from 'react'
import { Button,Box } from '@mui/material'
const FinanceTopBar = ({setModal}) => {
  return (
   <>
    <Box sx={{ display:"flex", justifyContent:"flex-end",marginBottom:"10px",marginRight:"40px"}}>
      <Button variant="contained" color="primary" onClick={() => setModal(true)}>
      Create
    </Button>
    </Box>
   </>
  )
}

export default FinanceTopBar