import React from "react";
import { UserForm } from "./UserForm";
import { UserList } from "./UserList";
import { CRUDFunction } from "../../reduxCURD/container";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { curdActions } from "../../reduxCURD/actions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Modal } from "@mui/material";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      selectedUser: null,
      mode: "",
      open: false,
    };

    this.onUserClick = this.onUserClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleUserSave = this.handleUserSave.bind(this);
    this.handleCreateNewUser = this.handleCreateNewUser.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.selfUser = getLoggedInUser();
  }
  componentDidMount() {
    this.props.getUsers();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.actionType === "USER_DELETE_SUCCESS" ||
        this.props.actionType === "USER_UPDATE_SUCCESS" ||
        this.props.actionType === "USER_CREATE_SUCCESS") &&
      this.props.actionType !== prevProps.actionType
    ) {
      this.setState({
        selectedUser: null,
        mode: "",
      });
      this.props.getUsers();
    }
  }
  onUserClick(user) {
    this.setState({
      mode: "edit",
      selectedUser: user,
    });
  }
  handleCancel() {
    this.setState({
      selectedUser: null,
      mode: "",
    });
    this.open = false;
  }

  handleUserSave(user) {
    if (this.state.mode === "edit" && this.state.selectedUser) {
      // update user
      this.props.updateUser(user);
      this.open = false;
    } else {
      // create user
      this.props.createUser(user);
      this.open = false;
    }
  }

  handleCreateNewUser() {
    this.setState({
      selectedUser: null,
      mode: "add",
    });
    this.open = true;
  }

  handleDelete() {
    this.state.selectedUser && this.props.deleteUser({}, this.state.selectedUser._id);
    this.open = false;
  }

  handleOpenModal(value) {
    console.log(value);
  }

  render() {
    return (
      <Box
        sx={{
          pl: 3,
          pr: 3,
          py: 12,
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <UserList users={this.props.users} userClick={this.onUserClick} createNewUser={this.handleCreateNewUser} />

          {/* {this.open ? (
          <Box
            sx={{
              background: "rgba(0, 0, 0, 0.3)",
              position: "fixed",
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          > */}

          {/* <Modal open={this.open} onClose={this.handleCancel} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box>
            <UserForm
              handleSave={this.handleUserSave}
              selectedUser={this.state.selectedUser}
              mode={this.state.mode}
              handleCancel={this.handleCancel}
              selfUser={this.selfUser}
              openModal={this.handleOpenModal}
              handleDelete={sendDeleteMethod(this.handleDelete, this.state.selectedUser)}
            />
          </Box>
        </Modal> */}
          <Box>
            <UserForm
              handleSave={this.handleUserSave}
              selectedUser={this.state.selectedUser}
              mode={this.state.mode}
              handleCancel={this.handleCancel}
              selfUser={this.selfUser}
              openModal={this.handleOpenModal}
              handleDelete={sendDeleteMethod(this.handleDelete, this.state.selectedUser)}
            />
          </Box>
          {/* </Box>
        ) : null} */}

          {/* {this.state.mode === "" ? (
          ""
        ) : (
          <Box
            sx={{
              background: "red",
            }}
          >
            <UserForm
              handleSave={this.handleUserSave}
              selectedUser={this.state.selectedUser}
              mode={this.state.mode}
              handleCancel={this.handleCancel}
              selfUser={this.selfUser}
              handleDelete={sendDeleteMethod(this.handleDelete, this.state.selectedUser)}
            />
          </Box>
        )} */}
        </Box>
      </Box>
    );
  }
}

// let variableList = {
//   userReducer: {
//     users: [],
//     user: {},
//   },
// };
// const getUsers =curdActions.getUsers
// let actionOptions = {
//   create: true,
//   update: true,
//   delete: true,
//   update: true,
//   others: {
//     getUsers
//   },
// };
// let addditionalREducer = ["userReducer"];
const UserContainer = CRUDFunction(Users, "user");
export default UserContainer;

function sendDeleteMethod(deleteMethod, user) {
  let loggedInUser = getLoggedInUser();
  if (loggedInUser && user && loggedInUser.email === user.email) return null;
  return deleteMethod;
}
