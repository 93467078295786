import * as React from "react";
import Box from "@mui/material/Box";
import Chartss from "./DashboardMetrices/Chartss";
//import TrafficSource from "./DashboardMetrices/TrafficSource";
import VerticalChart from "./DashboardMetrices/VerticalChart";
import StudentOverview from "./DashboardMetrices/StudentOverview";
import ApplicationProgress from "./DashboardMetrices/ApplicationProgress";
import Performance from "./DashboardMetrices/Performance";

const Charts = () => {
  return (
    <Box>
      <StudentOverview />
      <Chartss
        chartSeries={[
          { name: "Created", data: [10, 20, 30, 40, 50] },
          { name: "Accepted", data: [5, 15, 25, 35, 45] },
        ]}
      />

      <VerticalChart
        chartSeries={[
          { name: "Series 1", data: [10, 20, 30, 40, 50] },
          { name: "Series 2", data: [5, 15, 25, 35, 45] },
        ]}
      />

      <ApplicationProgress />
      <Performance />
    </Box>
  );
};

export default Charts;
