import React, { useEffect, useState } from "react";
import AddIcon from "../../Images/AddIcon";
import SearchIcon from "../../Images/SearchIcon";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import AddTaskButton from "./AddTaskButton";
import AddProjectModal from "./AddProjectModal";
import { Box, IconButton, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import TaskDropdown from "./TaskDropdown";
import { TaskAccessMethods } from "../../BussinessLogic/Tasks/TaskAccess";
import TaskMenu from "./TaskMenu";
import { isSysgen } from "../../utils/sysGen";

const Tasks = (props) => {
  //controller file for task page in sidebar which is responsible for displaying students task and generic tasks

  console.log(props);

  // All States
  const [personName, setPersonName] = React.useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // State For handling modal of Add project
  const [open1, setOpen1] = useState(false);

  // functions
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setPersonName(event.target.value); // Update the selected user's name
  };

  // functions for filtering task based on student name and
  // Function to filter tasks based on selected user's name (dropdown options)
  //explanation of this function
  //if it will check that if there is no option selected from dropdown then it will show us all users

  const filterTasks = () => {
    if (TaskAccessMethods?.isAdministratorOrManagement() && !personName) {
      return props?.tasks?.filter((task) => task?.studentName?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    } else if (TaskAccessMethods?.isAdministratorOrManagement()) {
      const selectedUserTasks = props?.tasks?.filter((task) => {
        if (isSysgen(task.studentId)) {
          return task?.assignedTo?.name === personName || task?.createdBy?.createdBy?.name === personName;
        } else {
          return task?.createdBy?.assignedTo?.name === personName || task?.createdBy?.createdBy?.name === personName;
        }
      });
      return selectedUserTasks;
    } else {
      return props?.tasks?.filter((task) => task?.studentName?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    }
  };

  // db actions  for fetching all users , student task card and generic task creation
  const getUsers = () => {
    props.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  // const getTaskss = () => {
  //   props.getTasks("/projectTasks/" + "00146-10-0124");
  // };

  const getTasks = () => {
    props.getTasks("/tasksSummary/");
  };

  useEffect(() => {
    getTasks();
  }, []);

  const createTask = async (data) => {
    // console.log(data);
    await props.createTask(data);

    getTasks();
  };

  // useEffect(() => {
  //   getTaskss();
  // }, []);

  // styling related work here
  const isMobile = useMediaQuery("(max-width:768px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 3,
  };

  return (
    <>
      <Box
        sx={{
          px: 6,
          py: 12,
          "@media (max-width: 425px)": {
            px: 0,
          },
        }}
      >
        {isMobile ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                "@media (max-width: 430px)": { display: "flex", justifyContent: "center" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 15px 0px 15px",
                  backgroundColor: "rgb(25, 104, 173)",
                  height: "55px",
                  borderRadius: "1rem",
                  transition: "width 0.3s ease",
                }}
              >
                <IconButton size="large" onClick={handleOpen}>
                  <SearchIcon />
                </IconButton>
                <Box sx={{ color: "white", fontSize: "35px", mt: "-8px" }}>|</Box>
                <IconButton onClick={handleOpen1}>
                  <AddIcon />
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "300px",
                      marginLeft: "8px",
                      mb: "2px",
                    }}
                  >
                    Add Project
                  </Typography>
                </IconButton>
              </Box>
            </Box>
            <Box>
              <TaskMenu filterTasks={filterTasks} />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {TaskAccessMethods.isAdministratorOrManagement() && (
                <TaskDropdown personName={personName} handleChange={handleChange} users={props.users} />
              )}

              <TextField
                id="outlined-basic"
                label="Search Students By Name"
                variant="outlined"
                sx={{
                  width: "420px",
                  "@media (max-width: 1440px)": {
                    width: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    width: "250px",
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Box>
                <AddTaskButton handleOpen1={handleOpen1} />
              </Box>
            </Box>
            <Box>
              <TaskMenu filterTasks={filterTasks} />
            </Box>
          </>
        )}
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {TaskAccessMethods.isAdministratorOrManagement() && (
              <TaskDropdown personName={personName} users={props.users} handleChange={handleChange} />
            )}

            <TextField
              id="outlined-basic"
              label="Search Students by Name"
              fullWidth
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </Box>
      </Modal>

      <Modal open={open1} onClose={handleClose1} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <AddProjectModal createTask={createTask} handleClose1={handleClose1} users={props.users} />
        </Box>
      </Modal>
    </>
  );
};

let variableList = {
  userReducer: {
    users: [],
  },
};
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];

let TasksContainer = CRUDFunction(Tasks, "task", actionOptions, variableList, addditionalREducer, "task");
export default TasksContainer;
