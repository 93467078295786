import React, { useEffect, useState } from "react";
import GmailUI from "./GmailUI";
import { CRUDFunction } from "../../reduxCURD/container";
import { Box, Button, TextField } from "@mui/material";
import DOMPurify from "dompurify";

const Gmail = (props) => {
  console.log(props);

  const [sender, setSender] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachment, setAttachment] = useState("");

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  useEffect(() => {
    props.getGmails();
  }, []);

  const sendSearch = () => {
    if (sender) {
      props.getGmails("?sender=" + sender);
    }

    if (subject) {
      props.getGmails("?subject=" + subject);
    }

    if (body) {
      props.getGmails("?body=" + body);
    }
    if (attachment) {
      props.getGmails("?has=" + attachment);
    }
  };

  return (
    <div>
      <Box>
        <TextField placeholder="Sender..." onChange={(e) => setSender(e.target.value)} />
        <TextField placeholder="Subject..." onChange={(e) => setSubject(e.target.value)} />
        <TextField placeholder="Body..." onChange={(e) => setBody(e.target.value)} />
        <TextField placeholder="Has attachment..." onChange={(e) => setAttachment(e.target.value)} />
        <Button onClick={sendSearch}>Search</Button>
      </Box>
      {props.gmails?.messages?.length > 0 ? (
        <Box>
          {props?.gmails?.messages?.map((message) => {
            return (
              <h4
                onClick={() => props.getGmail(message?.id)}
                key={message?.id}
                style={{
                  cursor: "pointer",
                }}
              >
                {message?.sender?.senderName} - {message?.subject}
              </h4>
            );
          })}
        </Box>
      ) : (
        <h3>Loading...</h3>
      )}

      {props.gmail && <Box dangerouslySetInnerHTML={createMarkup(props?.gmail)}></Box>}

      <GmailUI />
    </div>
  );
};

const GmailContainer = CRUDFunction(Gmail, "gmail");
export default GmailContainer;
