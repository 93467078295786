import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextareaAutosize,
  TextField,
  Box,
  Card, // Import Card component
  CardContent, // Import CardContent component
} from '@mui/material';
import { Inbox, Send } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = ({ open, onClose, onSave }) => {
  const [subject, setSubject] = useState('');

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  return (
    <Card  style={{ display: open ? 'block' : 'none' , position:"fixed" , bottom:"10px" , right:"2rem" }}>
      <CardContent>
        <Box sx={{display:"flex" , justifyContent:"space-between" ,  }}>
        <Typography variant='h5'>New Message</Typography>
        <CloseIcon onClick={onClose} sx={{display:"flex" , justifyContent:"space-between" , cursor:"pointer" }}  />
        </Box>

        <DialogContent>
          <TextField
            label="To"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={handleSubjectChange}
            margin="normal"
          />

          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextareaAutosize
            label="Message"
            variant="outlined"
            fullWidth
            minRows={4}
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={onSave} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </CardContent>
    </Card>
  );
};

export default CustomDialog