import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import { Avatar, Card, CardContent, Stack, SvgIcon, Grid } from "@mui/material";

const StudentOverview = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Students Overview
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="overline">
                    Total Number of Students
                  </Typography>
                  <Typography variant="h4">100</Typography>
                </Stack>
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    height: 56,
                    width: 56,
                  }}
                ></Avatar>
              </Stack>

              <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <SvgIcon color="success" fontSize="small">
                    <ArrowUpIcon />
                  </SvgIcon>
                  <Typography color="success.main" variant="body2">
                    12%
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="caption">
                  Since last month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="overline">
                    Total No of Applications
                  </Typography>
                  <Typography variant="h4">100</Typography>
                </Stack>
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    height: 56,
                    width: 56,
                  }}
                ></Avatar>
              </Stack>

              <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <SvgIcon color="success" fontSize="small">
                    <ArrowUpIcon />
                  </SvgIcon>
                  <Typography color="success.main" variant="body2">
                    12%
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="caption">
                  Since last month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="overline">
                    Income
                  </Typography>
                  <Typography variant="h4">$24k</Typography>
                </Stack>
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    height: 56,
                    width: 56,
                  }}
                >
                  <SvgIcon>
                    <CurrencyDollarIcon />
                  </SvgIcon>
                </Avatar>
              </Stack>

              <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <SvgIcon color="success" fontSize="small">
                    <ArrowUpIcon />
                  </SvgIcon>
                  <Typography color="success.main" variant="body2">
                    12%
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="caption">
                  Since last month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="overline">
                    Acceptance Rate
                  </Typography>
                  <Typography variant="h4">24%</Typography>
                </Stack>
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    height: 56,
                    width: 56,
                  }}
                >
                  <SvgIcon>
                    <CurrencyDollarIcon />
                  </SvgIcon>
                </Avatar>
              </Stack>

              <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <SvgIcon color="success" fontSize="small">
                    <ArrowUpIcon />
                  </SvgIcon>
                  <Typography color="success.main" variant="body2">
                    12%
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="caption">
                  Since last month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentOverview;
