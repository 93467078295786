export const branchesOptions = [
  { text: "All", value: "", level: 0 },
  { text: "SFK", value: "SFK", level: 1 },
  { text: "SFL", value: "SFL", level: 2 },
  { text: "SFI", value: "SFI", level: 3 },
  { text: "SFS", value: "SFS", level: 4 },
  { text: "SFH", value: "SFH", level: 5 },
  { text: "SFIR", value: "SFIR", level: 6 },
  { text: "SFC", value: "SFC", level: 7 },
];
