// all reports that are possible in system, will be used by default
const AllRolesReports = [
  //  Sample Report Object
  {
    // unique for frontend
    id: 1,
    name: "Lead Report",
    // useful for making backend queries based on reportId
    reportId: "LeadR",
    // can be used to show that it is active by default, only 1 or active should be set
    // active :
    // , optional depending on report requirement
    showIntermediate: true,
  },
  {
    id: 2,
    name: "Query Report",
    reportId: "QueryR",
    // active :
    showIntermediate: true,
  },

  {
    id: 3,
    name: "Registration Report",
    reportId: "RegistrationR",
    showIntermediate: true,
  },

  {
    id: 4,
    name: "Processing Report",
    reportId: "ProcessingR",
    showIntermediate: true,
  },
  // {
  //   id: 5,
  //   name: "Intake Report",
  //   reportId: "IntakeR",
  //   showIntermediate: true,
  // },
];

// can be limited allowed report ids
const ApOfficerReports = [];

export const rByRoles = (r) => {
  let roleReports = {
    "Application Officer": ApOfficerReports,
  };
  return roleReports[r] || AllRolesReports;
};
