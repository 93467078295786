import React, { useEffect } from "react";
import api from "./reduxRelated/middleware/api";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import ContainerPage from "./Components/ContainerPage/ContainerPage";
import rootReducer from "./reduxRelated/reducer/index.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { SocketProvider } from "./SocketContext";

import ThemeProvider from "./theme";
let createStoreWithMiddleware = applyMiddleware(thunkMiddleware, api)(createStore);
let store = createStoreWithMiddleware(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

function App() {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <SocketProvider>
      <Provider store={store}>
        <ThemeProvider>
          <BrowserRouter>
            <ContainerPage />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </SocketProvider>
  );
}

export default App;
