import React from 'react'

const SearchIcon = () => {
  return (
    <div>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5416 2.52084C6.1118 2.52084 2.52075 6.11189 2.52075 10.5417C2.52075 14.9715 6.1118 18.5625 10.5416 18.5625C14.9714 18.5625 18.5624 14.9715 18.5624 10.5417C18.5624 6.11189 14.9714 2.52084 10.5416 2.52084ZM1.14575 10.5417C1.14575 5.3525 5.35241 1.14584 10.5416 1.14584C15.7308 1.14584 19.9374 5.3525 19.9374 10.5417C19.9374 15.7309 15.7308 19.9375 10.5416 19.9375C5.35241 19.9375 1.14575 15.7309 1.14575 10.5417Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8471 17.8472C18.1156 17.5787 18.5509 17.5787 18.8194 17.8472L20.6527 19.6805C20.9212 19.949 20.9212 20.3843 20.6527 20.6528C20.3842 20.9213 19.9489 20.9213 19.6804 20.6528L17.8471 18.8195C17.5786 18.551 17.5786 18.1157 17.8471 17.8472Z" fill="white"/>
</svg>

    </div>
  )
}

export default SearchIcon