import React from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Divider, Typography } from "@mui/material";

const Experience = ({ singleEnquiry }) => {
  return (
    <Box>
      <DataWrapper heading={"Experience"}>
        {singleEnquiry?.professionalExperience?.map((item, index) => {
          return (
            <Box>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Company Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.companyName || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Designation: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.designation || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  From: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.from || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  To: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.to || "N/A"}</span>
                </span>
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Main Responsibilities:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.mainResponsibilities || "N/A"}</span>
                </span>
              </Typography>
              {index < singleEnquiry.professionalExperience.length - 1 && (
                <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
              )}
            </Box>
          );
        })}
      </DataWrapper>
    </Box>
  );
};

export default Experience;
