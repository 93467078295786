import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { curdActions } from "../../reduxCURD/actions";
import { CRUDFunction } from "../../reduxCURD/container";
import { useEffect } from "react";
import { guid } from "../../utils/cart";

const SingleForm = (props) => {
  // console.log(props);
  const history = useHistory();
  const { id } = useParams();
  const [points, setPoints] = useState([]);

  useEffect(() => {
    props.getLead(id);
  }, []);

  const handlePoints = () => {
    props?.updateLead(
      {
        points: points,
      },
      id,
    );
    setPoints([]);
    history.goBack();
  };

  // console.log(points);

  // const convertResponsesToArray = (responses) => {
  //   const result = [];
  //   responses.forEach((response) => {
  //     const answers = [];
  //     for (const questionId in response.answers) {
  //       answers.push({
  //         questionId: questionId,
  //         textAnswers: response.answers[questionId].textAnswers,
  //       });
  //     }
  //     result.push({
  //       respondentEmail: response.respondentEmail,
  //       answers: answers,
  //     });
  //   });
  //   return result;
  // };

  // const calculatePoints = () => {
  //   const data = convertResponsesToArray(props.lead.responses);
  //   console.log(data);
  // };

  return (
    <Box
      sx={{
        mt: "100px",
        px: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ mb: 2, backgroundColor: "primary.main", color: "white", "&:hover": { backgroundColor: "primary.main" } }}
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>

        <Button
          sx={{ mb: 2, backgroundColor: "primary.main", color: "white", "&:hover": { backgroundColor: "primary.main" } }}
          onClick={() => handlePoints()}
        >
          Save
        </Button>
      </Box>
      {props.leadActionType == "LEAD_READ_REQUEST" ? (
        <Typography variant="h4" component="div" color="primary.main" my={0}>
          Loading...
        </Typography>
      ) : (
        <Box>
          <Typography variant="h4" sx={{ mb: 2, color: "primary.main" }}>
            {props?.lead?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {props?.lead?.formData?.items?.map((item, index) => {
              return (
                <Paper key={item?.itemId} elevation={3} style={{ padding: "20px" }}>
                  <Box>
                    <Typography variant="h6" component="div" color="primary.main" my={0}>
                      {index + 1}. {item?.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                      }}
                    >
                      {item?.questionItem?.question?.choiceQuestion?.options?.map((option, index) => {
                        const uniqueId = guid();
                        return (
                          <Box
                            key={option?.value}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="subtitle2" color="primary.main" my={1}>
                              {index + 1} - {option?.value}
                            </Typography>

                            <Box>
                              <select
                                name={option?.value}
                                defaultValue={props?.lead?.points?.find((point) => point?.option === option?.value)?.points}
                                onChange={(e) => {
                                  const newPoint = {
                                    id: uniqueId,
                                    points: e?.target?.value,
                                    question: item?.title,
                                    option: e?.target?.name,
                                    questionId: item?.questionItem?.question?.questionId,
                                  };
                                  setPoints([...points, newPoint]);
                                }}
                                style={{
                                  padding: "5px",
                                  outline: "none",
                                }}
                              >
                                <option selected>Choose</option>

                                {Array.from(Array(item?.questionItem?.question?.choiceQuestion?.options?.length).keys()).map((index) => {
                                  return (
                                    <option key={index} value={index + 1}>
                                      {index + 1}
                                    </option>
                                  );
                                })}
                                <option value={"dq"}>DQ</option>
                              </select>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

let variableList = {
  leadReducer: {
    lead: {},
  },
};

const getLead = curdActions.getLead;
const updateLead = curdActions.updateLead;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getLead,
    updateLead,
  },
};
let addditionalREducer = ["leadReducer"];

// export default SingleForm;
const formContainer = CRUDFunction(SingleForm, "singleform", actionOptions, variableList, addditionalREducer, null);
export default formContainer;
