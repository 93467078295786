import React from "react";

const EditIcon = ({ color }) => {
  return (
    <div>
      <svg width="15" height="15" viewBox="0 0 12 12" fill={color} xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_18_5957)">
          <path
            d="M9.49997 6.02307C9.22348 6.02307 9 6.2471 9 6.52304V10.5231C9 10.7986 8.77597 11.023 8.50003 11.023H1.5C1.22397 11.023 1.00003 10.7986 1.00003 10.5231V3.52304C1.00003 3.24756 1.22397 3.02307 1.5 3.02307H5.50003C5.77652 3.02307 6 2.79904 6 2.5231C6 2.24707 5.77652 2.02304 5.50003 2.02304H1.5C0.673004 2.02304 0 2.69604 0 3.52304V10.5231C0 11.3501 0.673004 12.0231 1.5 12.0231H8.50003C9.32703 12.0231 10 11.3501 10 10.5231V6.52304C10 6.24655 9.77646 6.02307 9.49997 6.02307Z"
            fill="#1968AD"
          />
          <path
            d="M4.68806 5.5676C4.65309 5.60257 4.62956 5.64706 4.61958 5.69504L4.2661 7.4631C4.24962 7.54504 4.27562 7.62955 4.33458 7.68906C4.38209 7.73657 4.44609 7.76202 4.51164 7.76202C4.52757 7.76202 4.54414 7.76056 4.56062 7.75708L6.32814 7.40359C6.37712 7.39352 6.42161 7.37009 6.45613 7.33502L10.4121 3.37903L8.64461 1.6116L4.68806 5.5676Z"
            fill="#1968AD"
          />
          <path
            d="M11.6338 0.389099C11.1464 -0.0984192 10.3533 -0.0984192 9.86627 0.389099L9.17432 1.08105L10.9418 2.84857L11.6338 2.15652C11.8698 1.92105 11.9998 1.60703 11.9998 1.27304C11.9998 0.939056 11.8698 0.625031 11.6338 0.389099Z"
            fill="#1968AD"
          />
        </g>
        <defs>
          <clipPath id="clip0_18_5957">
            <rect width="12" height="12" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EditIcon;
