import React from "react";
import googleImage from "../../Images/googleImage.png"
const LoginPage = () => {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_REDIRECT_URL_AUTH}/auth/google`;
  };

  return (
    <div className="">
      <div onClick={handleLogin}>
        <div>
          <img style={{ width: "210px", cursor: "pointer" }} src={googleImage} alt="Google Icon" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
