import React, { useEffect } from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import StudentTaskDetail from "./StudentTaskDetail";
import { Box } from "@mui/material";
import { curdActions } from "../../reduxCURD/actions";
const TaskProfile = (props) => {
  // console.log("props", props);
  // controller file for button action after view in task page in sidebar
  // console.log("props", props);

  // db action for getting tasks for single students or generic task and for fetching all users
  useEffect(() => {
    if (props.actionType === "TASKVIEW_UPDATE_SUCCESS") {
      getProjectTasks();
    }
  }, [props.actionType]);

  useEffect(() => {
    if (props.actionType === "TASKVIEW_DELETE_SUCCESS") {
      getProjectTasks();
    }
  }, [props.actionType]);

  const getProjectTasks = () => {
    props.getTaskviews("/projectTasks/" + props?.match?.params.studentId);
  };

  useEffect(() => {
    getProjectTasks();
  }, []);

  const getUsers = () => {
    props.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box
        sx={{
          mt: "65px",
          padding: "10px",
        }}
      >
        <StudentTaskDetail
          disableNewSection={true}
          defaultSections={true}
          actions={{
            onNewTaskCreated: props.createTaskview,
            onTaskUpdate: props.updateTaskview,
            onTaskDeleted: props.deleteTaskview,
          }}
          projectId={props.match.params.studentId}
          dbTasks={props.taskviews}
          users={props.users}
        />
      </Box>
    </>
  );
};
let variableList = {
  userReducer: {
    users: [],
  },
};
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];

// export default TaskProfile;
let TasksContainer = CRUDFunction(TaskProfile, "taskview", actionOptions, variableList, addditionalREducer, "task");
export default TasksContainer;
