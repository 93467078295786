import { Box } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DataTable from "./DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { CRUDFunction } from "../../reduxCURD/container";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { curdActions } from "../../reduxCURD/actions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { fileUploads } from "../../reduxRelated/actions/fileActions";
import DialogTitle from "@mui/material/DialogTitle";
import StudentForm from "./StudentForm";
import { SocketContext } from "../../SocketContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Enquiry = (props) => {
  console.log("props", props);
  const [sureProceed, setSureProceed] = useState(false);
  const [proceedData, setProceedData] = useState({});
  const [modal, setModal] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [filesToUp, setFilesToUp] = useState(null);
  const [enquiryEditData, setEnquiryEditData] = useState({});

  const { leadData, setLeadData, createQuiry, setCreateQuiry } = useContext(SocketContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // const checkAndCreateEnquiry = useCallback(() => {
  //   const isExist = props?.enquirys?.filter((q) => {
  //     if (q?.enquiryData?.infoEmail === leadData?.data?.infoEmail) {
  //       return q;
  //     }
  //   });

  //   if (createQuiry && isExist?.length === 0) {
  //     props.createEnquiry({
  //       enquiryData: leadData?.data,
  //       commentSheet: leadData?.commentSheet,
  //     });
  //     setMessage("Query has been created Successfully");
  //     setCreateQuiry(false);
  //     setLeadData({});
  //   } else {
  //     setCreateQuiry(false);
  //     setLeadData({});
  //   }
  // }, [createQuiry]);
  // //   console.log(leadData);
  // //  console.log(proceedData);
  // useEffect(checkAndCreateEnquiry, [checkAndCreateEnquiry]);

  const handleClickOpen = () => {
    setSureProceed(true);
  };

  const handleClose = () => {
    setSureProceed(false);
  };

  //  console.log(proceedData);
  const getEnquiries = async () => {
    await props.getEnquirys();
  };
  const onDelete = async (id) => {
    console.log("id", id);
    await props
      .deleteEnquiry(id)
      .then((response) => {
        console.log("Delete response:", response);
      })
      .catch((error) => {
        console.error("Delete error:", error);
      });
    getEnquiries();
  };

  // this function is used for creating query and updating query yaha say hi agey ja rahy yh function into child
  // components
  const createEnquiry = async (data) => {
    if (enquiryEditData?.enquiryData) {
      const { _id } = enquiryEditData;
      editEnquiry({
        enquiryData: data,
        _id,
      });
    } else {
      await props.createEnquiry({
        enquiryData: data,
      });
      setMessage("Query has been created Successfully");
      setSnackbarOpen(true);
    }

    getEnquiries();
  };

  const editEnquiry = async (data, id) => {
    // console.log("the data is", data);
    // console.log("id", id);
    await props.updateEnquiry(data, id);
  };

  const CallSchedule = async (data, id) => {
    await props.updateEnquiry(
      {
        schedule: data,
      },
      id,
    );
    await props.updateEnquiry(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.mode,
          content: data?.content,
        },
      },
      id,
    );
  };

  const setSchedule = async (data, id) => {
    // console.log(data);
    await props.updateEnquiry(
      {
        schedule: data,
      },
      id,
    );
    await props.updateEnquiry(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.mode,
          content: data?.scheduleComment,
        },
      },
      id,
    );
  };

  const setComment = async (data, id) => {
    await props.updateEnquiry(
      {
        comments: data,
      },
      id,
    );
    await props.updateEnquiry(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };

  const EmailComment = async (data, id) => {
    await props.updateEnquiry(
      {
        schedule: data,
      },
      id,
    );
    await props.updateEnquiry(
      {
        commentSheet: {
          time: data?.time,
          date: data?.date,
          purpose: data?.purpose,
          content: data?.content,
        },
      },
      id,
    );
  };

  const getSingleEnquiry = async (id) => {
    await props.getEnquiry(id);
  };

  useEffect(() => {
    getEnquiries();
  }, []);

  useEffect(() => {
    if (props.actionType == "ENQUIRY_CREATE_SUCCESS") {
      setSnackbarOpen(true);
    }
  }, []);

  function generateUniqueId() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().substr(-2);

    return `-${day}-${month}${year}`;
  }

  const proceedToRegistration = async (data, files) => {
    // console.log(data.infoEmail);
    // const data2_id="65929563fe5ca0d1741f2c34";
    // console.log(files);
    const user = getLoggedInUser();
    const branch = user.branch;

    const studentData = {
      registrationData: data,
      registeration: generateUniqueId(),
      counselor: { id: user._id, name: user.name, email: user.email },
      branch: branch,
    };
    setFilesToUp(files);
    let fileKeys = Object.keys(files);
    fileKeys.map((k) => {
      studentData.registrationData[k] = files[k].name;
    });

    await props.createStudent(studentData);

    setSureProceed(false);
    await props.updateEnquiry(
      {
        queryMoveToRegistration: true,
        isProcess: false,
        isClosed: false,
      },
      proceedData?._id,
    );
  };

  //  console.log("pd",proceedData)
  const getProceedData = (data) => {
    setProceedData(data);
    handleClickOpen();
  };

  const uploadFilesOnCreate = () => {
    if (props.studentActionType === "STUDENT_CREATE_SUCCESS") {
      if (filesToUp) {
        if (!props.student?.registeration) console.log("student data not available");
        let fileKeys = Object.keys(filesToUp);
        fileKeys.map((k) => {
          props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.student?.registeration);
        });
        setFilesToUp(null);
      }
      // getStudents();
    }
  };

  useEffect(() => {
    uploadFilesOnCreate();
  }, [props.studentActionType]);

  useEffect(() => {
    if (props.studentActionType === "STUDENT_CREATE_SUCCESS") {
      setMessage("Student has been created Successfully");
      setSnackbarOpen(true);
    }
  }, [props.studentActionType]);

  const getUsers = () => {
    props.getUsers("/allUser");
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box
      sx={{
        mt: "100px",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
      }}
    >
      <Box>
        <Dialog open={sureProceed} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to continue"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            {/* <Button onClick={() => proceedToRegistration(proceedData)} autoFocus>
            Agree
          </Button> */}
            <Button
              onClick={() => {
                setModal(true);
                handleClose();
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <DataTable
          editEnquiry={editEnquiry}
          onDelete={onDelete}
          createEnquiry={createEnquiry}
          enquiryEditData={enquiryEditData}
          setEnquiryEditData={setEnquiryEditData}
          enquires={props?.enquirys}
          setSchedule={setSchedule}
          setComment={setComment}
          getSingleEnquiry={getSingleEnquiry}
          enquiry={props?.enquiry}
          getProceedData={getProceedData}
          users={props.users}
          getEnquiries={getEnquiries}
          updateEnquiry={props.updateEnquiry}
          location={props.location}
          EmailComment={EmailComment}
          CallSchedule={CallSchedule}
        />

        {modal && (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "rgba(0, 0, 0, 0.4)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "1212",
            }}
          >
            <StudentForm
              setModal={setModal}
              proceedData={proceedData}
              onSubmit={proceedToRegistration}
              setStudentData={setStudentData}
              studentData={studentData}
            />
          </Box>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
            {message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
};

let variableList = {
  studentReducer: {
    student: {},
  },
  userReducer: {
    users: [],
  },
};

const createStudent = curdActions.createStudent;
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    createStudent,
    fileUploads,
    getUsers,
  },
};
let addditionalREducer = ["studentReducer", "userReducer"];

// export default SingleForm;
const enquiryContainer = CRUDFunction(Enquiry, "enquiry", actionOptions, variableList, addditionalREducer, null);

// const enquiryContainer = CRUDFunction(Enquiry, "enquiry");
export default enquiryContainer;
