import React, { useEffect, useState } from "react";
import { Container, Paper, Typography, Box, Card, CardHeader, CardContent, IconButton } from "@mui/material";
import { Settings } from "@mui/icons-material";
import MessageDetail from "./MessageDetail";
import DOMPurify from "dompurify";
import GmailFilter from "./GmailFilter";
import { formatDateAndTime } from "../../../../utils/formatData";

const GmailUi = ({ mails, getMail, mail, actionType, onSubmit, settingOpen, setSettingOpen, onMailSend, uploadAttachments }) => {
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleCardClick = (message) => {
    setSelectedMessage(message);
  };

  const handleBackToInbox = () => {
    setSelectedMessage(null);
  };

  useEffect(() => {
    if (selectedMessage) {
      getMail(selectedMessage);
    }
  }, [selectedMessage]);

  const handleSetting = () => {
    setSettingOpen(true);
  };

  const CloseSetting = () => {
    setSettingOpen(false);
  };

  return (
    <Container>
      {selectedMessage ? (
        <MessageDetail
          mail={mail}
          onMailSend={onMailSend}
          message={selectedMessage}
          onBackClick={handleBackToInbox}
          getMail={getMail}
          actionType={actionType}
          uploadAttachments={uploadAttachments}
        />
      ) : (
        <Paper sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Inbox</Typography>
            <IconButton edge="end" aria-label="settings" onClick={handleSetting}>
              <Settings />
            </IconButton>
          </Box>
          {actionType !== "GMAILS_READ_FAILURE" ? (
            <Box>
              {actionType == "GMAILS_READ_SUCCESS" ||
              actionType == "GMAIL_READ_SUCCESS" ||
              actionType == "GMAIL_CREATE_SUCCESS" ||
              actionType == "GMAIL_READ_FAILURE" ? (
                <Box>
                  {mails?.messages?.map((mail) => (
                    <Card
                      key={mail?.id}
                      variant="outlined"
                      sx={{
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                        mb: 1,
                      }}
                      onClick={() => handleCardClick(mail?.id)}
                    >
                      <CardHeader
                        title={
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                m: 0,
                                p: 0,
                              }}
                            >
                              {mail?.sender?.senderName} - {mail?.subject}
                            </Typography>
                            <Typography>{formatDateAndTime(mail?.date)}</Typography>
                          </Box>
                        }
                      />
                      <CardContent
                        sx={{
                          p: 0,
                          px: 3,
                          pt: 1,
                        }}
                      >
                        {/* <Box dangerouslySetInnerHTML={createMarkup(mail?.body)}></Box> */}
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              ) : (
                <h3>Loading...</h3>
              )}
            </Box>
          ) : (
            <h3>Add filter to view mails</h3>
          )}
        </Paper>
      )}

      <GmailFilter onSubmit={onSubmit} open={settingOpen} onClose={CloseSetting} />
    </Container>
  );
};

export default GmailUi;
