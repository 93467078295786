import React from "react";
import { Box, Typography } from "@mui/material";
import { getLoggedInUser } from "../../utils/loggedInUser";

const AccountDetails = () => {
  let user = getLoggedInUser();

  // Get current date and time
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant="h3" sx={{ color: "rgba(25, 104, 173, 1)" }}>
            Hello {user && user?.name} ...
          </Typography>
          <Typography variant="h6" sx={{ color: "rgba(25, 104, 173, 1)" }}>
            {user && user.role}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", color: "rgba(25, 104, 173, 1)" }}>
          {formattedDate}

          <Box sx={{ color: "rgba(25, 104, 173, 1)" }}>{formattedTime}</Box>
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;
