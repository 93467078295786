import React from "react";

import { Typography, Box, Stack, Button } from "@mui/material";
export default function EscalationData({ escalation, application, onUpdate }) {
  return (
    <Box sx={{ backgroundColor:"primary.main", p: 2 , ml:3,borderRight:"1px solid black" , width:"fit-content" , borderRadius:"2rem"}}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize="1.5rem" mb={2} sx={{color:"white" }}>
          Escalation Status
        </Typography>
        <Button sx={{color:"primary.main" , backgroundColor:"white" , height:"fit-content", width:"fit-content" , "&:hover":{backgroundColor:"#fff !important"}}} size="small" onClick={(e) => onUpdate({ escalation: null }, application._id)}>Restore</Button>
      </Stack>
      <Typography fontWeight="medium" sx={{color:"white"}}>Reason/Priority: {escalation?.reason}</Typography>
      <Typography fontWeight="light" sx={{color:"white"}}>Comment: {escalation?.comment}</Typography>
      <Typography fontWeight="light" sx={{color:"white"}}>User: {escalation?.user.name}</Typography>
    </Box>
  );
}
