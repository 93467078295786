import React from "react";

const BinIcon = ({ color }) => {
  return (
    <div>
      <svg width="15" height="15" viewBox="0 0 12 12" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.5 3.5C9.36739 3.5 9.24021 3.55268 9.14645 3.64645C9.05268 3.74021 9 3.86739 9 4V9.5955C8.98566 9.84834 8.87199 10.0853 8.68375 10.2547C8.49551 10.4241 8.24795 10.5123 7.995 10.5H4.005C3.75205 10.5123 3.50449 10.4241 3.31625 10.2547C3.12801 10.0853 3.01434 9.84834 3 9.5955V4C3 3.86739 2.94732 3.74021 2.85355 3.64645C2.75979 3.55268 2.63261 3.5 2.5 3.5C2.36739 3.5 2.24021 3.55268 2.14645 3.64645C2.05268 3.74021 2 3.86739 2 4V9.5955C2.01427 10.1136 2.23329 10.605 2.60909 10.9619C2.98489 11.3189 3.48684 11.5124 4.005 11.5H7.995C8.51316 11.5124 9.01511 11.3189 9.39091 10.9619C9.76671 10.605 9.98573 10.1136 10 9.5955V4C10 3.86739 9.94732 3.74021 9.85355 3.64645C9.75979 3.55268 9.63261 3.5 9.5 3.5Z"
          fill={color}
        />
        <path
          d="M10 2H8V1C8 0.867392 7.94732 0.740215 7.85355 0.646447C7.75979 0.552678 7.63261 0.5 7.5 0.5H4.5C4.36739 0.5 4.24021 0.552678 4.14645 0.646447C4.05268 0.740215 4 0.867392 4 1V2H2C1.86739 2 1.74021 2.05268 1.64645 2.14645C1.55268 2.24021 1.5 2.36739 1.5 2.5C1.5 2.63261 1.55268 2.75979 1.64645 2.85355C1.74021 2.94732 1.86739 3 2 3H10C10.1326 3 10.2598 2.94732 10.3536 2.85355C10.4473 2.75979 10.5 2.63261 10.5 2.5C10.5 2.36739 10.4473 2.24021 10.3536 2.14645C10.2598 2.05268 10.1326 2 10 2ZM5 2V1.5H7V2H5Z"
          fill={color}
        />
        <path
          d="M5.5 8.5V5C5.5 4.86739 5.44732 4.74021 5.35355 4.64645C5.25979 4.55268 5.13261 4.5 5 4.5C4.86739 4.5 4.74021 4.55268 4.64645 4.64645C4.55268 4.74021 4.5 4.86739 4.5 5V8.5C4.5 8.63261 4.55268 8.75979 4.64645 8.85355C4.74021 8.94732 4.86739 9 5 9C5.13261 9 5.25979 8.94732 5.35355 8.85355C5.44732 8.75979 5.5 8.63261 5.5 8.5Z"
          fill={color}
        />
        <path
          d="M7.5 8.5V5C7.5 4.86739 7.44732 4.74021 7.35355 4.64645C7.25979 4.55268 7.13261 4.5 7 4.5C6.86739 4.5 6.74021 4.55268 6.64645 4.64645C6.55268 4.74021 6.5 4.86739 6.5 5V8.5C6.5 8.63261 6.55268 8.75979 6.64645 8.85355C6.74021 8.94732 6.86739 9 7 9C7.13261 9 7.25979 8.94732 7.35355 8.85355C7.44732 8.75979 7.5 8.63261 7.5 8.5Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default BinIcon;
