import { Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { studentFileDownloadPath } from "../../../utils/studentFileDownloadUtil";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const StudentProfileDisplay = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  // console.log("the app data is", appData);
  // console.log("the student data is",student);
  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Student Name"}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <Typography variant="h6">Student Name</Typography> */}
            <Box
              sx={{
                // mr: 2,
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  alignItems: "center",

                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Name Prefix:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.namePrefix?.label || student?.registrationData?.namePrefix || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="namePrefix"
                    text={student?.registrationData?.namePrefix?.label || student?.registrationData?.namePrefix || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  alignItems: "center",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  First Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.firstName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="firstName"
                    text={student?.registrationData?.firstName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Middle Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.middleName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="middleName"
                    text={student?.registrationData?.middleName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Last Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.lastName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="lastName"
                    text={student?.registrationData?.lastName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Date Of Birth: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.dob || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="dob"
                    text={student?.registrationData?.dob || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Email: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.infoEmail || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="infoEmail"
                    text={student?.registrationData?.infoEmail || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Contact Details"}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <Typography variant="h6">Contact Details</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Dialing Code:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.dialingCode?.label || student?.registrationData?.dialingCode || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="dialingCode"
                    text={student?.registrationData?.dialingCode?.label || student?.registrationData?.dialingCode || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Parents Number:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.cellNo || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="cellNo"
                    text={student?.registrationData?.cellNo || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Home No: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.homeNo || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="homeNo"
                    text={student?.registrationData?.homeNo || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Guardian Details"}>
          <Box>
            {/* <Typography variant="h6">Guardian Details</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  First Name :{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianFirstName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianFirstName"
                    text={student?.registrationData?.guardianFirstName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Middle Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianMiddleName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianMiddleName"
                    text={student?.registrationData?.guardianMiddleName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Last Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianLastName || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianLastName"
                    text={student?.registrationData?.guardianLastName || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Profession:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.profession || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="profession"
                    text={student?.registrationData?.profession || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Monthly Income:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.monthlyIncome || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="monthlyIncome"
                    text={student?.registrationData?.monthlyIncome || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianAddress || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianAddress"
                    text={student?.registrationData?.guardianAddress || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Telephone No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.guardianTelephoneNo || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianTelephoneNo"
                    text={student?.registrationData?.guardianTelephoneNo || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Cell No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianCellNo || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianCellNo"
                    text={student?.registrationData?.guardianCellNo || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Alternate No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.guardianAlternateNo || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianAlternateNo"
                    text={student?.registrationData?.guardianAlternateNo || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Email:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.guardianEmail || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="guardianEmail"
                    text={student?.registrationData?.guardianEmail || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Address"}>
          <Box>
            {/* <Typography variant="h6">Address</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address Line 1:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.line1 || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="line1"
                    text={student?.registrationData?.line1 || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Address Line 2:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.line2 || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="line2"
                    text={student?.registrationData?.line2 || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.country?.label || student?.registrationData?.country || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="country"
                    text={student?.registrationData?.country || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  City:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.city?.label || student?.registrationData?.city || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="city"
                    text={student?.registrationData?.city || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Geography And Nationality"}>
          <Box>
            {/* <Typography variant="h6">Geography And Nationality</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Country Of Citizenship:
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.countryOfCitizenship?.label || student?.registrationData?.countryOfCitizenship || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="countryOfCitizenship"
                    text={
                      student?.registrationData?.countryOfCitizenship?.label || student?.registrationData?.countryOfCitizenship || "N/A"
                    }
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Birth City:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.birthCity?.label || student?.registrationData?.birthCity || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="birthCity"
                    text={student?.registrationData?.birthCity?.label || student?.registrationData?.birthCity || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Birth Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.birthCountry?.label || student?.registrationData?.birthCountry || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="birthCountry"
                    text={student?.registrationData?.birthCountry?.label || student?.registrationData?.birthCountry || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Dual Nationality:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.dualNationality?.label || student?.registrationData?.dualNationality || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="dualNationality"
                    text={student?.registrationData?.dualNationality?.label || student?.registrationData?.dualNationality || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Passport No:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.passportNo || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="passportNo"
                    text={student?.registrationData?.passportNo || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Second Country:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.secondCountry?.label || student?.registrationData?.secondCountry || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="secondCountry"
                    text={student?.registrationData?.secondCountry?.label || student?.registrationData?.secondCountry || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Visa Refusal"}>
          <Box>
            {/* <Typography variant="h6">Visa Refusal</Typography> */}
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Date Of Visa Refusal:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.dateOfVisaRefusal || "N/A"}</span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="dateOfVisaRefusal"
                    text={student?.registrationData?.dateOfVisaRefusal || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Reason Of Visa Refusal:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.reasonOfVisaRefusal || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="reasonOfVisaRefusal"
                    text={student?.registrationData?.reasonOfVisaRefusal || "N/A"}
                  />
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Type Of Visa:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.typeOfVisa?.label || student?.registrationData?.typeOfVisa || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="typeOfVisa"
                    text={student?.registrationData?.typeOfVisa?.label || student?.registrationData?.typeOfVisa || "N/A"}
                  />
                )}
              </Typography>
              {student?.registrationData?.typeOfVisa?.label === "Other" && (
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Type Of Visa (Other):{" "}
                    <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.otherTypeOfVisa || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="otherTypeOfVisa"
                      text={student?.registrationData?.otherTypeOfVisa || "N/A"}
                    />
                  )}
                </Typography>
              )}
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                Upload Refusal Letter:{" "}
                {student?.registrationData?.uploadRefusalLetter ? (
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.uploadRefusalLetter ? (
                      <Link
                        target="_blank"
                        rel="noopener"
                        download
                        href={studentFileDownloadPath(student?.registeration, student?.registrationData?.uploadRefusalLetter)}
                      >
                        {student?.registrationData?.uploadRefusalLetter}
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </span>
                ) : (
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {appData?.uploadRefusalLetter ? (
                      <Link
                        target="_blank"
                        rel="noopener"
                        download
                        href={studentFileDownloadPath(student?.registeration, appData?.uploadRefusalLetter)}
                      >
                        {appData?.uploadRefusalLetter}
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </span>
                )}
              </Typography>
              <Typography
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E0E0E0",
                  pb: "5px",
                  mb: "1rem",
                }}
              >
                <span>
                  Visa Country Name:{" "}
                  <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                    {student?.registrationData?.visaCountryName?.label || student?.registrationData?.visaCountryName || "N/A"}
                  </span>
                </span>
                {appCheck && (
                  <AppCheckActions
                    user={user}
                    setRevertData={setRevertData}
                    allData={data}
                    confirm={confirm}
                    copy={copy}
                    submitted={submitted}
                    data="visaCountryName"
                    text={student?.registrationData?.visaCountryName?.label || student?.registrationData?.visaCountryName || "N/A"}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </DataWrapper>
      </Box>
    </Box>
  );
};

export default StudentProfileDisplay;
