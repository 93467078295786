// import React,{useState} from 'react'
// import { Container, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
// import { Mail, Inbox, Send, Drafts, Delete , Report } from '@mui/icons-material';

// const EmailAccount = () => { 
//     const [showMessages, setShowMessages] = useState(false);

//     const handleClick = () => {
//       setShowMessages(true);
//     };
  
//   return (
//     <Container sx={{marginTop:"5rem"}} >
//     <Grid container spacing={2}>
    
//       <Grid item xs={3} sx={{marginLeft:"-15rem"}}>
//         <Paper elevation={2} style={{ padding: '16px' , }}>
//           <Typography variant="h6">Gmail</Typography>
//           <List>
//             <ListItem button>
//               <ListItemIcon>
//                 <Inbox />
//               </ListItemIcon>
//               <ListItemText primary="Inbox" />
//             </ListItem>

//             <ListItem button>
//               <ListItemIcon>
//                 <Send />
//               </ListItemIcon>
//               <ListItemText primary="Sent" />
//             </ListItem>
//             <ListItem button>

//               <ListItemIcon>
//                 <Drafts />
//               </ListItemIcon>
//               <ListItemText primary="Drafts" />
//             </ListItem>
//             <ListItem button>
//               <ListItemIcon>
//                 <Delete />
//               </ListItemIcon>
//               <ListItemText primary="Trash" />
//             </ListItem>

//             <ListItem button>
//               <ListItemIcon>
//                 <Report />
//               </ListItemIcon>
//               <ListItemText primary="Spam" />
//             </ListItem>

//             <ListItem button>
//               <ListItemIcon>
//                 <Delete />
//               </ListItemIcon>
//               <ListItemText primary="Outbox" />
//             </ListItem>
            


//           </List>
         
         
//         </Paper>
//       </Grid>

      
     
//     <Grid item xs={9}>
//       <Paper elevation={2} style={{ padding: '16px' }}>
//         <Typography variant="h6">Inbox</Typography>
//       </Paper>

//       {showMessages ? (
//         <Paper elevation={2} style={{ padding: '16px', margin: '16px' }}>
//           {/* Messages Screen */}
//           <Typography variant="h6">Message 1</Typography>
//           <Typography variant="body1">This is a sample message.</Typography>
//         </Paper>
//       ) : (
//         <Paper
//           elevation={2}
//           style={{ padding: '16px', margin: '16px', cursor: 'pointer' }}
//           onClick={handleClick}
//         >
//           <Typography variant="h6">Messages</Typography>
//         </Paper>
//       )}
//     </Grid>
 
  
//   </Grid>
//   </Container>
//   )
// }

// export default EmailAccount






import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { Inbox, Send } from '@mui/icons-material';
import Sidebar from "./Sidebar"
import InboxSection from "./Inbox"
import SentSection from "./SentSection"
import DraftSection from "./Draft"
import Trash from "./Trash"
import Spam from "./Spam"
import Outbox from "./Outbox"

const GmailUI = () => {
  const [selectedSection, setSelectedSection] = useState(null);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };


  
  return (
    <Box sx={{  marginTop:"5rem" ,  marginLeft:"1rem"  , marginRight:"1rem" }}>
      <Grid container spacing={2}>
         <Grid item xs={isCollapsed ? 0.8 : 1.6}>
         <Sidebar setSelectedSection={setSelectedSection} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} toggleSidebar={toggleSidebar}  />
        </Grid>


        <Grid item xs={10}>
          {selectedSection === 'inbox' ? <InboxSection /> : null}
          {selectedSection === 'sent' ? <SentSection /> : null}
          {selectedSection ==='Drafts' ? <DraftSection /> :null}
          {selectedSection ==="Trash" ? <Trash /> :null}
          {selectedSection ==="Spam" ? <Spam /> :null}
          {selectedSection ==="Outbox" ? <Outbox /> :null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GmailUI;
