import React from "react";
import { Box } from "@mui/material";
import ViewProfile from "../../../ViewProfile";
import { useState } from "react";
import Revert from "./Revert";
import { getLoggedInUser } from "../../../../../utils/loggedInUser";

const ApplicationCheck = ({ student, onUpdate, data }) => {
  const user = getLoggedInUser();
  // console.log(data);

  const confirm = (value, field) => {
    // console.log(value, field);
    onUpdate(
      {
        appCheck: {
          [field]: {
            confirm: value,
            name: field,
          },
        },
      },
      data._id,
    );
  };

  const copy = (value, field) => {
    onUpdate(
      {
        appCheck: {
          [field]: {
            confirm: data?.appCheck?.[field]?.confirm,
            copy: value,
            name: field,
          },
        },
      },
      data._id,
    );
  };

  const submitted = (value, field) => {
    onUpdate(
      {
        appCheck: {
          [field]: {
            confirm: data?.appCheck?.[field]?.confirm,
            copy: true,
            submitted: value,
            name: field,
          },
        },
      },
      data._id,
    );
  };

  const [revertData, setRevertData] = useState({});

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {revertData?.revert && (
        <Revert setRevertData={setRevertData} data={data} student={student} onUpdate={onUpdate} revertData={revertData} appCheck={true} />
      )}

      <ViewProfile
        appCheck={true}
        student={student}
        confirm={confirm}
        copy={copy}
        setRevertData={setRevertData}
        allData={data}
        submitted={submitted}
        user={user}
      />
    </Box>
  );
};

export default ApplicationCheck;
