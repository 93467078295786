import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import ScheduleCallForm from "./ScheduleCallForm";
import { SignalCellularNullTwoTone } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Actions = ({
  leadData,
  leadScheduleCallEdit,
  setleadScheduleCallEdit,
  setSchedule,
  allLeadData,
  doneCall,
  editCall,
  scheduleCallData,
  setScheduleCallData,
  EditCall,
  DeleteSchedule,
}) => {
  // console.log("setleadScheduleCallEdit", leadScheduleCallEdit);
  // console.log("scheduleCallData", scheduleCallData);
  // console.log("setScheduleCallData",setScheduleCallData);
  // console.log("doneCall",doneCall);
  // console.log("setSchedule",setSchedule);
  // console.log("leadData", leadData);
  // console.log("allLeadData", allLeadData);
  const [open, setOpen] = useState(false);
  const [noAttendedClicked, setNoAttentedClicked] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setScheduleCallData({});
  };

  useEffect(() => {
    if (scheduleCallData.value == false) {
      setOpen(true);
    }
  }, [scheduleCallData.value]);

  //   console.log(scheduleCallData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const tableColumns = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time" },
    { id: "mode", label: "Mode" },
    { id: "actions", label: "Attended" },
    // { id: "edit", label: "Actions" },
    // { id: "delete", label: "Delete" },
  ];

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 10,
    p: 4,
  };

  const [text, setText] = React.useState("");

  const handleChange = (event) => {
    setText(event.target.value);
  };

  // console.log(allLeadData?.scheduleCall);

  const [currentLeadData, setCurrentLeadData] = useState({});
  // console.log("setCurrentLeadData", currentLeadData);
  const tableData = allLeadData?.scheduleCall
    ? Object.entries(allLeadData.scheduleCall)
      .filter(([hmm, value]) => value?.email == leadData?.email)
      .map(([key, value]) => ({
        date: value.date,
        time: value.time,
        mode: value.mode,
        actions: (
          <Box>
            {/* <Tooltip title="Yes" placement="top">
                <Checkbox onChange={(e) => doneCall(true, leadData?.data?._id, value, key)} checked={value.callDone} />
              </Tooltip>
              <Tooltip title="No" placement="top">
                <Checkbox
                  color="error"
                  onChange={(e) => doneCall(false, leadData?.data?._id, value, key)}
                  checked={value.callDone == false ? true : false}
                />
              </Tooltip> */}
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
              }}
            >
              {value?.callDone === true || value?.callDone === undefined ? (
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: value.callDone ? "blue" : "black",
                    pointerEvents: value?.callDone ? "none" : "auto",
                  }}
                  // onClick={(e) => doneCall(true, leadData?.data?._id, value, key)}

                  onClick={() => {
                    setCurrentLeadData({
                      bool: true,
                      id: leadData?.data?._id,
                      value,
                      key,
                    });
                    handleOpen3();
                  }}
                >
                  Yes
                </Typography>
              ) : null}

              {value?.callDone === false || value?.callDone === undefined ? (
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: value.callDone === false ? "red" : "black",
                    pointerEvents: value.callDone === false ? "none" : "auto",
                  }}
                  onClick={(e) => doneCall(false, leadData?.data?._id, value, key)}
                >
                  No
                </Typography>
              ) : null}
            </Box>

            <Modal
              open={open3}
              onClose={handleClose3}
              hideBackdrop
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Comment
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    label="Comment"
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      rows: 5,
                    }}
                    value={text}
                    onChange={handleChange}
                  />
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mr: 2,
                    }}
                    // onClick={(e) => doneCall(true, leadData?.data?._id, value, key, text)}
                    onClick={(e) => {
                      doneCall(currentLeadData?.bool, currentLeadData?.id, currentLeadData?.value, currentLeadData?.key, text);
                      handleClose3();
                      setText("");
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleClose3();
                      setText("");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* <Button
                id="basic-button"
                aria-controls={open2 ? "basic-menu" : undefined}
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick}
              >
                {value?.callDone ? value?.callDone : "Choose"}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={(e) => doneCall(e.target.innerText, leadData?.data?._id, value, key)}>Yes</MenuItem>
                <MenuItem onClick={(e) => doneCall(e.target.innerText, leadData?.data?._id, value, key)}>No</MenuItem>
              </Menu> */}
          </Box>
        ),
        edit: (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Tooltip placement="top" title=" Edit">
              <EditIcon
                onClick={() => {
                  // console.log(cellValues?.row?.data);

                  setOpen(true);
                  editCall(false, leadData?.data?._id, value, key);
                }}
                sx={{ color: "rgba(25, 104, 173, 1)", cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
        ),
        // delete: (
        //   <IconButton color="error" onClick={() => DeleteSchedule({ _id: leadData?.data?._id, value, key })}>
        //     <DeleteIcon />
        //   </IconButton>
        // ),
      }))
    : [];

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* <Modal open={open3} onClose={handleClose3} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: ".5rem",
        }}
      >
        {!open && (
          <Button variant="contained" onClick={handleOpen}>
            Schedule Call
          </Button>
        )}
      </Box>
      {open ? (
        <Box>
          {/* <ScheduleForm handleClose={handleClose} setSchedule={setSchedule} singleEnquiry={singleEnquiry} /> */}
          {scheduleCallData || open ? (
            <ScheduleCallForm
              editCall={editCall}
              setScheduleCallData={setScheduleCallData}
              setleadScheduleCallEdit={setleadScheduleCallEdit}
              leadScheduleCallEdit={leadScheduleCallEdit}
              scheduleCallData={scheduleCallData}
              handleClose={handleClose}
              leadData={leadData}
              setSchedule={setSchedule}
            />
          ) : null}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      backgroundColor: "rgba(25, 104, 173, 1)!important",
                      color: "#fff",
                      [`@media(max-width:780px)`]: { fontSize: "0.7rem" },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow key={index}>
                  {tableColumns.map((column) => (
                    <TableCell key={column.id}>{column.id === "delete" ? row[column.id] : row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Actions;
