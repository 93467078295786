import { styled } from "@mui/material/styles";

const APP_BAR_MOBILE = 0;
const APP_BAR_DESKTOP = 0;

export const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: APP_BAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));
