import React from "react";
import PropTypes from "prop-types";

// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Divider } from "@mui/material";

import AccountPopover from "./AccountPopover";
import NotificationPop from "../Notifications/NotificationPop";
import LanguagePopover from "./LanguagePopover";
import { Link, withRouter } from "react-router-dom";
import Iconify from "../Common/iconify/Iconify";
import { bgBlur } from "../../utils/cssStyles";
import Nav from "../ContainerPage/nav";
import { hideTopAndSideBarPath } from "../../utils/topBarSideBarUtil";
import Mynav from "../ContainerPage/mynav";
import { useLocation } from "react-router-dom";
import GetRoute from "./GetRoute";

// ----------------------------------------------------------------------

const NAV_WIDTH = 240;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 72;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  background: "rgb(249, 250, 251)",
  boxShadow: "none",
  [theme.breakpoints.up("xl")]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("xl")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

class TopBarV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { extractedRoute } = this.state;
    let showContent = hideTopAndSideBarPath(this.props.history);
    return !showContent ? (
      <React.Fragment>
        <StyledRoot>
          <StyledToolbar>
            <IconButton
              onClick={() => this.setState({ open: true })}
              sx={{
                mr: 1,
                color: "text.primary",
                display: { xl: "none" },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>

            <Link to="/">
              <Box
                sx={{
                  display: "flex",
                  "@media (max-width: 500px)": {
                    display: "none",
                  },
                }}
              >
                <img src="/assets/Success_Factor_Logo.png" style={{ width: "6rem", marginLeft: "2rem" }} alt="" />
                <Box
                  sx={{
                    marginLeft: "6rem",
                    "@media (max-width: 700px)": {
                      marginLeft: "2rem",
                    },
                    "@media (max-width: 650px)": {
                      display: "none",
                    },
                  }}
                >
                  <GetRoute />
                </Box>
              </Box>
            </Link>

            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
            >
              <NotificationPop />

              <AccountPopover history={this.props.history} />
            </Stack>
          </StyledToolbar>
          <Divider />
        </StyledRoot>
        {/* <Nav openNav={this.state.open} onCloseNav={() => this.setState({ open: false })} /> */}
        <Mynav openNav={this.state.open} onCloseNav={() => this.setState({ open: false })} />
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(TopBarV2);
