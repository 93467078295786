import React from 'react'

const ApprovedIcon = () => {
  return (
    <div>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.1668 2.33331H5.8335C3.90348 2.33331 2.3335 3.9033 2.3335 5.83331V22.1666C2.3335 24.0967 3.90348 25.6666 5.8335 25.6666H22.1668C24.0968 25.6666 25.6668 24.0967 25.6668 22.1666V5.83331C25.6668 3.9033 24.0968 2.33331 22.1668 2.33331Z" fill="#339900"/>
<path d="M12.5418 18.0833C12.2433 18.0833 11.9448 17.9694 11.717 17.7415L8.80029 14.8248C8.34456 14.3691 8.34456 13.6308 8.80029 13.1751C9.25602 12.7194 9.9943 12.7194 10.45 13.1751L12.5418 15.2669L17.5503 10.2584C18.006 9.80269 18.7443 9.80269 19.2 10.2584C19.6558 10.7142 19.6558 11.4524 19.2 11.9082L13.3667 17.7415C13.1388 17.9694 12.8403 18.0833 12.5418 18.0833Z" fill="#E0E0E0"/>
</svg>

    </div>
  )
}

export default ApprovedIcon