import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import { useForm, FormProvider, Controller } from "react-hook-form";

const appointmentForm = {
  date: {
    name: "date",
    type: "date",
    value: "",
    required: true,
  },
  time: {
    name: "time",
    type: "time",
    value: "",
    required: true,
  },
};

const Appointment = ({
  setModal,
  goBack,
  nextStep,
  setStudentData,
  registrationFormFiles,
  control,
  handleSubmit,
  studentData,
  onSubmit,
  isEdit,
  existingStudentData: sData,
}) => {
  const handleCancel = () => {
    setModal(false);
  };
  const onSend = (data) => {
    const studentDataObject = studentData.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    onSubmit({ ...studentDataObject, ...data }, { ...registrationFormFiles });
    nextStep();
  };

  const methods = useForm({
    defaultValues: {
      appointmentDate: "",
      appointmentTime: "",
    },
  });

  return (
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
          <Controller
            control={control}
            defaultValue={isEdit ? sData?.registrationData?.appointmentDate : ""}
            name="appointmentDate"
            render={({ field }) => (
              <TextField
                // error={Boolean(errors.uploadResult)}
                id="outlined-basic"
                type="date"
                variant="outlined"
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Controller
            name="appointmentTime"
            defaultValue={isEdit ? sData?.registrationData?.appointmentTime : ""}
            control={control}
            render={({ field }) => (
              <TextField
                // error={Boolean(errors.uploadResult)}
                id="outlined-basic"
                type="time"
                variant="outlined"
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
              <Button variant="outlined" onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>

      {/* <InputForm
        config={{
          noPaper: true,
          saveText: "Submit",
          cancelButton: true,
          backButton: true,
          headerValue: "Appointment",
        }}
        handleSave={nextStep}
        handleClose={handleCancel}
        handleBack={backStep}
        formFieldSchema={appointmentForm}
      /> */}
    </Box>
  );
};

export default Appointment;
