import { Avatar, Box, Button, Divider, Stack, Typography, MenuItem, Select, IconButton, Menu } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { students } from "../../Data/StudentCardData";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLoggedInUser } from "../../utils/loggedInUser";

const StudentDetails = ({ student, users, updateStudent, setStudentForm, handleOpen, studentTimelineUpdate }) => {
  // console.log(student);
  const loggedInUser = getLoggedInUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (user) => {
    updateStudent && updateStudent({ counselor: { id: user._id, name: user.name, email: user.email } }, student._id);
    // setAnchorEl(null);
    handleClose();
    studentTimelineUpdate("Application assigned to counselor", "Assigned");
  };

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleOptionClick2 = (user) => {
    updateStudent && updateStudent({ appOfficer: { id: user._id, name: user.name, email: user.email } }, student._id);
    handleClose2();
    studentTimelineUpdate("Application assigned to application officer", "Assigned");
  };

  // const { id } = useParams();
  // const oneApplication = students.find((student) => student.regNo === id);
  const options = ["Option 1", "Option 2"];
  let name = "";
  // if (student?.registrationData?.namePrefix) name += " " + student.registrationData.namePrefix.label;
  if (student?.registrationData?.firstName) name += " " + student.registrationData.firstName;
  if (student?.registrationData?.middleName) name += " " + student.registrationData.middleName;
  if (student?.registrationData?.lastName) name += " " + student.registrationData.lastName;

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      alignItems={"center"}
      mx={4}
      sx={{
        backgroundColor: "primary.main",
        p: "25px 30px",
        mb: 6,
        gap: "20px",

        // add media query
        ["@media (max-width: 560px)"]: {
          mx: 0,
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={1} color={"white"}>
        <Avatar alt="Profile Image" src="" sx={{ width: 50, height: 50 }} />
        <Stack direction={"column"}>
          <Typography variant={"h5"}>{name}</Typography>
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: "light",
            }}
          >
            # {student?.registeration}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"column"}>
        <Typography variant={"h5"} sx={{ color: "white" }}>
          Branch
        </Typography>
        <Typography variant={"subtitle2"} sx={{ color: "white", pt: 0.8 }}>
          {student?.registrationData?.branch?.text || student?.registrationData?.branch || student?.branch || "N/A"}
        </Typography>
      </Stack>
      <Stack direction={"column"} color={"white"}>
        <Typography variant={"h5"} sx={{ color: "white" }}>
          Counselor
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant={"subtitle2"} sx={{ color: "white" }}>
            {student?.counselor?.name}
          </Typography>
          <Box
            onClick={handleClick}
            sx={{
              pt: 1,

              cursor: "pointer",
            }}
          >
            <ExpandMoreIcon color="white" />
          </Box>

          <Menu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{ style: { maxHeight: "160px" } }}
          >
            {/* {loadUserOptions((user) => user.role === "Counselor" || user.role === "Mentor", users, handleOptionClick)} */}
            {loadUserOptions((user) => user.role === "Counselor" || user?.email === "sana@successfactor.org", users, handleOptionClick)}
            <MenuItem value={"self-assign"} key={"self-assign"} onClick={() => handleOptionClick(loggedInUser)}>
              Self assign
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
      <Stack direction={"column"} color={"white"}>
        <Typography variant={"h5"} sx={{ color: "white" }}>
          Application Officer
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant={"subtitle2"} sx={{ color: "white" }}>
            {student?.appOfficer?.name}
          </Typography>
          <Box
            onClick={handleClick2}
            sx={{
              cursor: "pointer",
              pt: 1,
            }}
          >
            <ExpandMoreIcon />
          </Box>
          <Menu
            id="icon-menu2"
            anchorEl={anchorEl2?.parentElement}
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
            PaperProps={{ style: { maxHeight: "160px" } }}
          >
            {loadUserOptions((user) => user?.role === "Application Officer", users, handleOptionClick2, "appOfficer")}
            <MenuItem value={"self-assign"} key={"self-assign"} onClick={() => handleOptionClick2(loggedInUser)}>
              Self assign
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        gap={"1rem"}
        sx={{
          "@media (max-width: 430px)": {
            flexDirection: "column !important",
            alignItems: "center !important",
          },
        }}
      >
        {/* <Typography
          onClick={handleOpen}
          variant={"button"}
          sx={{
            border: "1px solid white",
            borderRadius: "50px",
            p: "10px 20px",
            color: "white",
            cursor: "pointer",
          }}
        >
          View Profile
        </Typography> */}
        <Typography
          onClick={() => setStudentForm(true)}
          variant={"button"}
          sx={{
            border: "1px solid white",
            borderRadius: "50px",
            p: "10px 20px",
            color: "white",
            cursor: "pointer",
          }}
        >
          Edit Student
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StudentDetails;

function loadUserOptions(conditionMethod, users, handleOptionClick2, appOfficer) {
  if (!users || !conditionMethod) return null;
  let roleUsers = users.filter((user) => conditionMethod(user));
  let opts = roleUsers.map((user) => (
    <Box>
      <MenuItem value={user._id} key={user._id} onClick={() => handleOptionClick2(user)}>
        {user.name}
      </MenuItem>
    </Box>
  ));
  return opts;
}
