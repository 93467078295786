import React from "react";
import FinanceTable from "./FinanceTable";
import { Box } from "@mui/material";
const Finance = () => {
  return (
    <Box sx={{ width: "100%", pl: 3, pr: 3 }}>
      <FinanceTable />
    </Box>
  );
};

export default Finance;
