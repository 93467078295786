import { Box, FormControl, IconButton, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { guid } from '../../utils/cart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const QueryCampaignModal = ({ handleCampaign, SelectedCampaign, setSelectedCampaign, singleEnquiry, getEnquiries, updateEnquiry }) => {
    console.log(singleEnquiry)

    const [isInputOpen, setisInputOpen] = useState(false);
    const [Title, setTitle] = useState([]);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (singleEnquiry?.AddCampaigns) {
            setTitle(singleEnquiry.AddCampaigns);
        }
    }, [singleEnquiry]);

    const handleInputClick = () => {
        setisInputOpen(!isInputOpen);
    };

    function generateId() {
        return guid();
    }

    function createNewCampaign() {
        const columnToAdd = {
            id: generateId(),
            title: inputValue,
        };
        const updatedTitle = [...Title, columnToAdd];
        setTitle(updatedTitle);
        updateEnquiry({ AddCampaigns: updatedTitle }, singleEnquiry?._id);
        getEnquiries();
        setInputValue("");
    }

    const deleteCampaign = (id) => {
        const updatedTitle = Title.filter((campaign) => campaign.id !== id);
        setTitle(updatedTitle);
        updateEnquiry({ AddCampaigns: updatedTitle }, singleEnquiry?._id);
        getEnquiries();
    };

    const InputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            createNewCampaign();
        }
    };

    return (
        <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Query Campaign
            </Typography>
            <Box sx={{ display: "flex", marginTop: "15px" }}>
                <FormControl sx={{ width: 330 }}>
                    <InputLabel id="demo-multiple-name-label"> Query Campaign</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={SelectedCampaign}
                        onChange={(e) => setSelectedCampaign(e.target.value)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 160,
                                },
                            },
                        }}
                        input={<OutlinedInput label="Query Campaign" />}
                    >
                        {Title.map((x) => (
                            <MenuItem key={x.id}>
                                <ListItemText primary={x.title} onClick={() => handleCampaign(x.title)} />
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteCampaign(x.id)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "rgb(25,104,173)",
                    width: "fit-content",
                    height: "auto",
                    borderRadius: "2rem",
                    marginTop: "5px",
                    marginBottom: "8px",
                    marginLeft: "12px"
                }}>
                    <Tooltip title="Add Campaign" placement='top'>
                        <IconButton onClick={handleInputClick}>
                            <AddCircleIcon sx={{ fill: "white" }} />
                        </IconButton>
                    </Tooltip>
                    {isInputOpen && (
                        <InputBase
                            sx={{ color: "white" }}
                            autoFocus={true}
                            placeholder="Add Campaign"
                            value={inputValue} onChange={InputChange} onKeyDown={handleKeyPress}
                        />
                    )}
                </Box>
            </Box>
        </>
    )
}

export default QueryCampaignModal;
