import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover,Card,CardContent } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// mocks_

import Iconify from "../Common/iconify/Iconify";
import { getLoggedInUser, clearLoggedInUser } from "../../utils/loggedInUser";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover(props) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  let user = getLoggedInUser();
  return (
    <>

<Box>
  <Card sx={{ display: "flex", alignItems: "center", padding: "1rem", position: "relative",borderRadius:"10rem" , height:"3rem"}}>
    <Avatar
      src={user && user.photoURL}
      alt="photoURL"
      sx={{
        mr: "1rem",
        ml:"0.5rem"
      }}
    />
    <Box sx={{ flexGrow: 1,marginRight:"1rem"}}>
      <Typography sx={{ fontSize: "14px", fontWeight: "bold",  }}>
        {user && user.name}
      </Typography>
      <Typography sx={{ fontSize: "12px",color:"rgba(41, 45, 50, 0.44)", }}>
        {user && user.role}
      </Typography>
    </Box>
    <IconButton
      onClick={handleOpen}
      sx={{
        p: 0,
        ...(open && {
          "&:before": {
            zIndex: 1,
            content: "''",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            position: "absolute",
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
          },
        }),
      }}
    >
      <ExpandMoreIcon />
    </IconButton>
  </Card>
</Box>



      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user && user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user && user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={(e) => {
            handleClose();
            clearLoggedInUser();
            props.history.push("/login");
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
