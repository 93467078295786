import { getLoggedInUser } from "../../utils/loggedInUser";
import { isSysgen } from "../../utils/sysGen";

export const TaskAccessMethods = {
  isAdministratorOrManagement: () => {
    const loggedInUser = getLoggedInUser();
    const userRole = loggedInUser ? loggedInUser.role : null;
    return userRole === "Administrator" || userRole === "Management";
  },

  filterTasksByUserRole: (filterTasks) => {
    const loggedInUser = getLoggedInUser();
    const userName = loggedInUser?.name;
    const userEmail = loggedInUser?.email;
    const userRole = loggedInUser?.role;

    const isAdminOrManager = userRole === "Administrator" || userRole === "Management";

    return filterTasks()?.filter((task) => {
      // console.log("task", task);
      var assignedToName = {};
      var assignedToEmail = {};
      if (isAdminOrManager) {
        return true; // Return true to include all tasks for admin and manager
      } else {
        if (isSysgen(task?.studentId)) {
          assignedToName = task?.assignedTo?.name;
          assignedToEmail = task?.assignedTo?.email;
        } else {
          assignedToName = task?.createdBy?.assignedTo?.name;
          assignedToEmail = task?.createdBy?.assignedTo?.email;
        }

        const createdByName = task?.createdBy?.createdBy?.name;
        const createdByEmail = task?.createdBy?.createdBy?.email;

        // Check if the current user's name or email matches either the assignedTo or createdBy fields
        return assignedToName === userName || assignedToEmail === userEmail || createdByName === userName || createdByEmail === userEmail;
      }
    });
  },
};
