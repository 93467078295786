import React, { useEffect, useState } from "react";
import { ReportModuleLogics } from "../../BussinessLogic/Reports/ReportModule";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { getLoggedInUser } from "../../utils/loggedInUser";
import ReportTopBar from "./ReportTopBar";
import ReportFilter from "./ReportFilter";
import QueryReport from "./ReportsComponents/QueryReport";
import RegistrationReport from "./ReportsComponents/RegistrationReport";
import ProcessingReport from "./ReportsComponents/ProcessingReport";
import IntakeReport from "./ReportsComponents/IntakeReport";
import LeadReport from "./ReportsComponents/LeadReport";

const Reports = (props) => {
  // console.log(props);
  // load all possible reports on component first mount from ReportModule based on role.
  let user = getLoggedInUser();
  // If user exists, call the function to fetch reports, otherwise initialize an empty array into state
  const [reports, setReports] = useState(user ? ReportModuleLogics.allowedReports(user?.role) : []);
  const [selectedReport, setSelectedReport] = useState({
    id: 1,
  });

  const [showReport, setShowReport] = useState(false);
  const [reportFilters, setReportFilters] = useState({
    dateRange: { from: "2024-01-01", to: "2024-06-30", today: "" },
    branch1: "",
    user1: "",
  });
  // console.log("reportFilters", reportFilters);
  // db actions for fetching all users

  // console.log("reportFilters", reportFilters);
  const getUsers = () => {
    props.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);
  //db actions for fetching all reports based on first mount
  useEffect(() => {
    const jsonArrayFilters = encodeURIComponent(JSON.stringify(reportFilters));
    const jsonArraySelectedReports = encodeURIComponent(JSON.stringify(selectedReport));
    const url = `/getreport/?dateRange=${jsonArrayFilters}&rId=${jsonArraySelectedReports}`;
    props?.getReport(url);
  }, []);

  return (
    <>
      {/* 1- Report Selection Area View on page open */}
      {!showReport && (
        <>
          {/* Reports top bar for loading all possible reports, passing selected report */}
          <ReportTopBar reports={reports} selectedReport={selectedReport} setSelectedReport={(r) => setSelectedReport(r)} />

          <ReportFilter
            selectedReport={selectedReport}
            users={props?.users}
            filters={reportFilters}
            setReportFilters={(fil) => setReportFilters(fil)}
            getReport={props?.getReport}
          />
        </>
      )}
      {/* Intermediate list of results where user can view each detail report, will be decided based on report requirement, if not report detail screen can be shown*/}
      {/* 2- Report Detail View to show detail report when data is fetched or selected from intermediate list */}
      {selectedReport && loadReportComponent(selectedReport?.id, props?.report)}
    </>
  );
};

const loadReportComponent = (rId, data) => {
  const reportsById = {
    1: <LeadReport data={data} />,
    2: <QueryReport data={data} />,
    3: <RegistrationReport data={data} />,
    4: <ProcessingReport data={data} />,
    5: <IntakeReport data={data} />,
  };
  return reportsById[rId] || <div> No report component found</div>;
};

const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { getUsers },
};

let variableList = {
  userReducer: {
    users: [],
  },
};

let customReducers = ["userReducer"];

const ReportsContainer = CRUDFunction(Reports, "report", actionOptions, variableList, customReducers);
export default ReportsContainer;
