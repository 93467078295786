import { Stack } from "@mui/system";
import React from "react";
import Registration from "./DisplayData/Registration";
import StudentProfile from "./DisplayData/StudentProfile";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EducationData from "./DisplayData/EducationData";
import TestingData from "./DisplayData/TestingData";
import Reference from "./DisplayData/Reference";
import Employment from "./DisplayData/Employment";
import Intake from "./DisplayData/Intake";
import UniversitiesPreference from "./DisplayData/UniversitiesPreference";
import PersonalStatementData from "./DisplayData/PersonalStatementData";
import CountryOfInterest from "./DisplayData/CountryOfInterest";
import EntranceTestData from "./DisplayData/EntranceTestData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DisplayData = ({ data, student }) => {
  const appData = data?.appData;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.2)", px: "2rem", py: "1rem" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Registration" {...a11yProps(0)} />
          <Tab label="Student Profile" {...a11yProps(1)} />
          <Tab label="Intended Course" {...a11yProps(2)} />
          <Tab label="Education" {...a11yProps(3)} />
          <Tab label="Testing" {...a11yProps(4)} />
          <Tab label="Entrance Test" {...a11yProps(5)} />
          <Tab label="Country of Interest" {...a11yProps(6)} />
          <Tab label="Reference" {...a11yProps(7)} />
          <Tab label="Employment" {...a11yProps(8)} />
          <Tab label="Intake" {...a11yProps(9)} />
          <Tab label="Personal Statement" {...a11yProps(10)} />
          <Tab label="Universities Preference" {...a11yProps(11)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Registration appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StudentProfile appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
          sx={{
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.2)",
            px: "2rem",
            py: "1rem",
          }}
        >
          <Typography fontWeight="medium">
            Intended Course: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.courseTitle?.label || "N/A"}</span>
          </Typography>
          <Typography fontWeight="medium">
            Intended Course (Other): <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.otherLevelOfStudy || "N/A"}</span>
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EducationData appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TestingData appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EntranceTestData appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <CountryOfInterest student={student} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Reference appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Employment appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Intake appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <PersonalStatementData appData={appData} student={student} />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <UniversitiesPreference appData={appData} />
      </TabPanel>
    </Box>
  );
};

export default DisplayData;
