import React from 'react'

const UploadIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_19_3593)">
<path d="M14.5291 8.65782V4.87289C14.5291 4.76455 14.479 4.66445 14.4082 4.58526L10.1648 0.129242C10.0856 0.0459289 9.97298 0 9.86052 0H3.13263C1.89056 0 0.898438 1.01288 0.898438 2.25509V14.9771C0.898438 16.2193 1.89056 17.2155 3.13263 17.2155H8.44329C9.44777 18.8829 11.2736 20 13.3536 20C16.5132 20 19.0934 17.4322 19.0934 14.2685C19.0977 11.5048 17.1135 9.19554 14.5291 8.65782ZM10.2774 1.46316L13.1244 4.46014H11.2778C10.7275 4.46014 10.2774 4.00588 10.2774 3.45565V1.46316ZM3.13263 16.3818C2.35321 16.3818 1.73218 15.7565 1.73218 14.9771V2.25509C1.73218 1.4714 2.35321 0.833739 3.13263 0.833739H9.44365V3.45565C9.44365 4.46853 10.2649 5.29388 11.2778 5.29388H13.6954V8.54933C13.5704 8.54521 13.4703 8.5327 13.362 8.5327C11.9072 8.5327 10.5691 9.09132 9.56038 9.96672H4.26651C4.03717 9.96672 3.84964 10.1543 3.84964 10.3834C3.84964 10.6128 4.03717 10.8003 4.26651 10.8003H8.78508C8.48906 11.2172 8.24309 11.634 8.05144 12.0926H4.26651C4.03717 12.0926 3.84964 12.2801 3.84964 12.5094C3.84964 12.7386 4.03717 12.9263 4.26651 12.9263H7.78045C7.67623 13.3432 7.62206 13.8058 7.62206 14.2685C7.62206 15.0187 7.76793 15.7608 8.03054 16.3861H3.13263V16.3818ZM13.3578 19.1705C10.6567 19.1705 8.45992 16.9737 8.45992 14.2726C8.45992 11.5715 10.6525 9.37468 13.3578 9.37468C16.0631 9.37468 18.2556 11.5715 18.2556 14.2726C18.2556 16.9737 16.0589 19.1705 13.3578 19.1705Z" fill="#1968AD"/>
<path d="M4.26648 8.71197H8.48904C8.71838 8.71197 8.90591 8.52429 8.90591 8.2951C8.90591 8.06577 8.71838 7.87823 8.48904 7.87823H4.26648C4.03714 7.87823 3.84961 8.06577 3.84961 8.2951C3.84961 8.52429 4.03714 8.71197 4.26648 8.71197Z" fill="#1968AD"/>
<path d="M13.6538 11.1963C13.5746 11.113 13.4663 11.0629 13.3496 11.0629C13.2328 11.0629 13.1245 11.113 13.0453 11.1963L10.6025 13.8182C10.4441 13.985 10.4567 14.2517 10.6234 14.406C10.7902 14.5644 11.0611 14.5518 11.2194 14.3852L12.9452 12.5386V17.0904C12.9452 17.3198 13.1327 17.5073 13.3621 17.5073C13.5913 17.5073 13.7789 17.3198 13.7789 17.0904V12.5386L15.492 14.3852C15.5755 14.4727 15.6839 14.5186 15.7965 14.5186C15.8964 14.5186 15.9965 14.481 16.0798 14.406C16.2466 14.2476 16.2591 13.985 16.1007 13.8182L13.6538 11.1963Z" fill="#1968AD"/>
</g>
<defs>
<clipPath id="clip0_19_3593">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default UploadIcon