import { Box, Button, Tooltip } from "@mui/material";
import React from "react";
import ApplicationCard from "./ApplicationCard";
import { useRef } from "react";
import { useState } from "react";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import { DataGrid } from "@mui/x-data-grid";
import { RemoveRedEye } from "@mui/icons-material";

const AllApplications = ({ rows, onEdit, onDelete, selectedOption, showCard, pageLocation, selectedTab }) => {
  // console.log({rows});

  const sliderRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const startDragging = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isMouseDown) {
      return;
    }
    const x = e.pageX - sliderRef.current.offsetLeft;
    const scroll = x - startX;
    sliderRef.current.scrollLeft = scrollLeft - scroll;
  };
  const [showModal, setShowModal] = useState(false);
  const [agree, setAgree] = useState(false);
  const [appId, setAppId] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "universityName", headerName: "University Name", width: 200 },
    { field: "intendedCourse", headerName: "Intended Course", width: 200 },
    { field: "intake", headerName: "InTake", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      hide: selectedTab == 5 ? false : true,
      disableClickEventBubbling: true,
      renderCell: (cellValue) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="View application" placement="top">
              <Button
                disabled={cellValue?.row?.disable ? true : false}
                onClick={() => selectedOption([cellValue?.row?.id])}
                sx={{
                  p: 0,
                }}
              >
                <RemoveRedEye
                  fontSize="medium"
                  color={cellValue?.row?.disable ? "disabled" : "primary"}

                />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const formattedRows = rows?.map((x) => {
    return {
      id: x.id, // Unique ID for each row, you can modify this based on your data
      universityName: x.universityName || "N/A", // Replace 'N/A' with a default value if undefined
      intendedCourse: x.intendedCourse || "N/A",
      intake: x.intake || "N/A",
      status: x.status || "N/A",
      disable: pageLocation == "processing" ? false : true,
      // Add other fields as needed, mapping them from your data
    };
  });

  return (
    <>
      {showCard ? (
        <>
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "row",
              width: "100%",
              gap: "1rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              // justifyContent:'center',

              // overflowX: "scroll !important",
              // scrollSnapType: "x mandatory",
              // whiteSpace: "nowrap",
              paddingBottom: ".5rem",
              // "@media (max-width: 1440px)": {
              //   display: 'grid',
              //   gridTemplateColumns: "1fr 1fr",
              //   justifyContent:'center'
              // },

              "@media (max-width: 1600px)": {
                gridTemplateColumns: "1fr 1fr 1fr 1fr ",
                justifyContent: "center",
              },
              "@media (max-width: 1440px)": {
                gridTemplateColumns: "1fr 1fr 1fr ",
                justifyContent: "center",
              },
              "@media (max-width: 1024px)": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr ",
                justifyContent: "center",
              },
              // "@media (max-width: 768px)": {
              //   gridTemplateColumns: "1fr ",
              //   justifyContent: "center",
              // },
              "@media (max-width: 550px)": {
                display: "grid",
                gridTemplateColumns: "1fr",
                justifyContent: "center",
              },
              // "@media (max-width: 375px)": {
              //   display: "grid",
              //   gridTemplateColumns: "1fr",
              //   justifyContent: "center",
              // },
              // style a scrollbar
              // "&::-webkit-scrollbar": {
              //   height: "0.5rem",
              //   width: "0.5rem",
              // },
              // "&::-webkit-scrollbar-track": {
              //   borderRadius: "10px",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   backgroundColor: "primary.main",
              //   borderRadius: "10px",
              // },
            }}
            ref={sliderRef}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
            onMouseMove={handleMouseMove}
          >
            {rows?.map((data) => {
              return (
                <ApplicationCard
                  key={data?.uid}
                  setShowModal={setShowModal}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  data={data}
                  selectedOption={selectedOption}
                  agree={agree}
                  setAppId={setAppId}
                  appId={appId}
                  setAgree={setAgree}
                />
              );
            })}
            <ConfirmationModal
              open={showModal}
              setOpen={setShowModal}
              yes={setAgree}
              title={"Are you sure you want to delete this application"}
              setAppId={setAppId}
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={formattedRows}
                columns={columns}
                headerClassName="customHeader"
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={false}
                sx={{
                  // '& .customHeader .MuiDataGrid-colCell': {
                  //   backgroundColor: 'rgb(25,104,173)', // Change the background color of the header cells
                  //   color: 'white'
                  // },

                  "& .MuiDataGrid-columnHeader": {
                    // backgroundColor: 'rgb(25,104,173)', // Change the background color of the header cells
                    color: "rgb(25,104,173)",
                  },
                  // '& .MuiDataGrid-cell': {
                  //   borderBottom: '1px solid rgb(25,104,173)', // Add a border to all cells
                  //   color: 'black',
                  //   fontSize: '16px'
                  // },
                  // '& .MuiDataGrid-colCell': {
                  //   backgroundColor: 'black', // Add a border to all cells
                  //   color: 'black',
                  //   fontSize: '16px'
                  // },
                  // '& .MuiDataGrid-row': {
                  //   '&:nth-of-type(odd)': {
                  //     backgroundColor: 'rgba(0, 0, 0, 0.05)', // Alternate row color
                  //   },
                  // },
                }}
              />
            </div>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "1rem",
              overflowX: "scroll !important",
              scrollSnapType: "x mandatory",
              whiteSpace: "nowrap",
              paddingBottom: ".5rem",
              // style a scrollbar
              "&::-webkit-scrollbar": {
                height: "0.5rem",
                width: "0.5rem",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary.main",
                borderRadius: "10px",
              },
            }}
            ref={sliderRef}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
            onMouseMove={handleMouseMove}
          >
            {rows?.map((data) => {
              return (
                <ApplicationCard
                  key={data?.uid}
                  setShowModal={setShowModal}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  data={data}
                  selectedOption={selectedOption}
                  agree={agree}
                  setAppId={setAppId}
                  appId={appId}
                  setAgree={setAgree}
                />
              );
            })}
            <ConfirmationModal
              open={showModal}
              setOpen={setShowModal}
              yes={setAgree}
              title={"Are you sure you want to delete this application"}
              setAppId={setAppId}
            />
          </Box> */}
        </>
      )}
    </>
  );
};

export default AllApplications;
