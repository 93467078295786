import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
    display: "none",
});


export const FileInputArea = (props) => {
    return (
        <div
            style={{
                width: "100%",
                minHeight: "30px",
                margin: "25px",
                border: "1px solid #c4c4c4",
                borderRadius: "2px",
                fontSize: "12px",
                background: "#fff",
            }}
        >
            <label htmlFor={props.name}>
                <Input id={props.name} type="file" name={props.name} onChange={props.fileExplore} />
                <Button variant="contained" component="span">
                    {props.uploadLabel ? props.uploadLabel : "Upload File"}
                </Button>
                {props.fileName && <Box style={{ marginTop: "10px" }}>{props.fileName} </Box>}
                {props.fileName && (
                    <span
                        style={{
                            marginLeft: "15px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            maxWidth: "50%",
                            verticalAlign: "middle",
                        }}
                    >
                        {props.fileName}
                    </span>
                )}
            </label>
        </div>



    );
};
