import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, InputBase } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Formbar = ({ createLead, setQuery }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formId, setFormId] = useState("");
  const [copyTo, setCopyTo] = useState(false)
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleOpenModal1 = () => {
    setOpenModals(true);
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal1 = () => {
    setOpenModals(false);
  };


  const handleFormSubmit = () => {
    // Add your logic here to handle form submission
    const leadData = {
      title: formTitle,
      description: formDescription,
      formID: formId,
    };
    
    if (formTitle === "" || formDescription === "" || formId === "") {
      alert("Please fill all the fields!");
      return;
    }



    createLead(leadData);

    // Clear form inputs
    setFormTitle("");
    setFormDescription("");
    setFormId("");

    // Close the modal
    handleCloseModal();
  };


  const handleCopyToClipboard = () => {
    const textToCopy = "google-form@successfactorapp.iam.gserviceaccount.com";
    navigator.clipboard.writeText(textToCopy).then(() => {
      console.log('Text copied to clipboard');
      setCopyTo(true)
    }).catch((error) => {
      console.error('Error copying text to clipboard:', error);
    });
  };



  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      backgroundColor: "primary.main",
      width: "fit-content",
      height: "auto",
      ml: "auto",
      mr: 5,
      borderRadius: "2rem",
      transition: "width 0.3s ease",
      

    }}>





      <IconButton sx={{ margin: "0px 0px 0px 12px" }} onClick={handleOpenModal1}>
        <QuestionMarkIcon sx={{ fill: "white" }} />
      </IconButton>
      <IconButton onClick={handleSearchClick}>
        <SearchIcon sx={{ fill: "white" }} />
      </IconButton>
      {isSearchOpen && (
        <InputBase
          sx={{ ml: 1, flexGrow: 1, color: "white", }}
          autoFocus={true}
          placeholder="Search..."
          onChange={(e) => setQuery(e.target.value)}
        />
      )}

      <IconButton color="inherit" sx={{ margin: "0px 12px 0px 0px", }} onClick={handleOpenModal}>
        <AddIcon fontSize="large" sx={{ fill: "white" }} />
      </IconButton>




      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Form</DialogTitle>
        <DialogContent>
          <TextField
            label="Form Title"
            fullWidth
            value={formTitle}
            onChange={(e) => setFormTitle(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Form Description"
            fullWidth
            value={formDescription}
            onChange={(e) => setFormDescription(e.target.value)}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
          <TextField
            label="Form ID"
            onChange={(e) => setFormId(e.target.value)}
            fullWidth
            value={formId}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleFormSubmit} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openModals} onClose={handleCloseModal1}>
        <DialogContent sx={{ display: "flex", ml: 5 }}>
          <TextField
            sx={{ width: "30rem", color: "blue" }}
            label="Google Service Account Email"
            disabled
            value="google-form@successfactorapp.iam.gserviceaccount.com"

            margin="normal"
            variant="outlined"
          />

          <Button onClick={handleCopyToClipboard}>
            <ContentCopyIcon />
          </Button>
        </DialogContent>


      </Dialog>
    </Box>
  );
};

export default Formbar;
