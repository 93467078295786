import React, { useEffect, useState, useRef } from "react";
import StudentProfile from "./StudentProfile";
import { CRUDFunction } from "../../reduxCURD/container";
import { fileUploads } from "../../reduxRelated/actions/fileActions";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { curdActions } from "../../reduxCURD/actions";
import { Box, Button, Menu, MenuItem, Modal, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Gmail from "./Gmail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Profile = (props) => {
  const [applications, setApplications] = useState([]);
  const [appDetail, setAppDetail] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [studentForm, setStudentForm] = useState(false);

  const [workingSheetForm, setWorkingSheetForm] = useState(false);

  const [editData, setEditData] = useState({});
  // this state is used for editing of student data when we click on student Data
  const [studentData, setStudentData] = useState([]);
  const [filesToUp, setFilesToUp] = useState(null);
  const [edit, setEdit] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [sheetEditData, setSheetEditData] = useState({});
  // console.log("sheetEditData", sheetEditData);
  // console.log("studentData", props.student);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const prevActionType = usePrevious(props.actionType);

  const onEdit = (id) => {
    let singleApplication = props.apppacks?.find((item) => {
      return item?._id === id;
    });
    setEditData(singleApplication);
    setEdit(true);
    setShowForm(true);
  };

  function generateUniqueId(response) {
    const stdId = props.match.params.id.slice(0, 5);
    const year = response?.year;
    const intake = response?.intake?.label;
    const semesterCode = getSemesterCode(year, intake);
    // const branch = response?.branch;
    const branch = props?.student?.registrationData?.branch?.value || props?.student?.registrationData?.branch;
    const uniqueId = `-${stdId}-${semesterCode}-${branch}`;
    return uniqueId;
  }

  function getSemesterCode(year, intake) {
    let semesterCode = "";
    if (intake === "Spring") {
      semesterCode = "SP";
    } else if (intake === "Fall") {
      semesterCode = "FA";
    } else if (intake === "Summer") {
      semesterCode = "SU";
    }
    return semesterCode + year?.toString().substr(2, 2);
  }

  // function generateID() {
  //   return Math.floor(Math.random() * 9000) + 1000;
  // }

  const studentTimeline = (content, status) => {
    const loggedInUser = getLoggedInUser();

    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();

    const currentTime = new Date();

    let hours = currentTime.getHours();
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    const formattedTime = `${hours}:${minutes} ${meridiem}`;

    const formattedDate = `${month}/${day}/${year}`;

    props.updateStudent(
      {
        timeline: {
          content: content,
          date: formattedDate,
          time: formattedTime,
          name: loggedInUser?.name,
          status: status,
        },
      },
      props.student?._id,
    );
  };

  const onSubmit = (appPack, files, multiArrayFiles) => {
    // console.log(appPack);
    const {
      aSlevelForm,
      alevelForm,
      birthCity,
      birthCountry,
      branch,
      cellNo,
      city,
      comments,
      country,
      countryOfCitizenship,
      countryPreference,
      // courseLink,
      courseName,
      courseTitle,
      dateOfVisaRefusal,
      dialingCode,
      dob,
      documentUpload,
      dualNationality,
      englishProficiency,
      entranceTest,
      firstName,
      guardianAddress,
      guardianAlternateNo,
      guardianCellNo,
      guardianEmail,
      guardianFirstName,
      guardianLastName,
      guardianMiddleName,
      guardianTelephoneNo,
      homeNo,
      hsscForm,
      infoEmail,
      institution,
      intake,
      lastName,
      levelOfStudy,
      line1,
      line2,
      middleName,
      monthlyIncome,
      namePrefix,
      olevelForm,
      otherForm,
      passportNo,
      personalStatement,
      pointOfEntry,
      postgraduateCompletionYear,
      postgraduateDegreeTitle,
      postgraduateGrades,
      postgraduateInstitution,
      profession,
      professionalExperience,
      reasonOfVisaRefusal,
      reference,
      regDate,
      regId,
      sccForm,
      secondCountry,
      typeOfVisa,
      undergraduateCompletionYear,
      undergraduateDegreeTitle,
      undergraduateGrades,
      undergraduateInstitution,
      uploadResult,
      visaCountryName,
      visaRefusal,
      year,
      uploadRefusalLetter,

      planOfAction,
      nationalRankings,
      tuitionFee,
      applicationFee,
      university,
      course,
      courseLink,
      otherCourse,
      requirement,
      applicationDeadline,
      additionalRequirement,
      additionalInformation,
      scholarship,
      scholarshipDeadline,
      sessionStartDate,
      srNo,
    } = appPack;
    const newAppPack = {
      countryPreference,
      institution,
      levelOfStudy,
      courseTitle,
      courseName,
      intake,
      year,
      // courseLink,
      pointOfEntry,
      uploadRefusalLetter,
      personalStatement,
      uploadResult,
      comments,
      documentUpload,

      // planOfAction,
      // nationalRankings,
      srNo,
      tuitionFee,
      applicationFee,
      university,
      course,
      courseLink,
      otherCourse,
      // requirement,
      applicationDeadline,
      additionalRequirement,
      additionalInformation,
      scholarship,
      sessionStartDate,
      scholarshipDeadline,
    };

    // console.log(newAppPack);

    const user = getLoggedInUser();
    const uId = generateUniqueId(appPack);

    let appPackFinal = {
      stdId: props.match.params.id,
      workingSheet: {
        ...newAppPack,
        id: uId,
        branch: props?.student?.registrationData?.branch?.value || props?.student?.registrationData?.branch,
      },
      appData: { status: "no" },
      user: { id: user._id, name: user.name, email: user.email },
    };

    let fileKeys = Object.keys(files);
    fileKeys.map((k) => {
      appPackFinal.workingSheet[k] = files[k].name;
      props.fileUploads(files[k], "/uploadsingle?stdId=" + props.match.params.id);
    });
    if (multiArrayFiles) {
      multiArrayFiles.map((mf) => props.fileUploads(mf, "/uploadsingle?stdId=" + props.match.params.id));
    }
    // props.createApppack(appPackFinal);
    // studentTimeline()
    if (sheetEditData?.workingSheet) {
      props.updateApppack(
        {
          workingSheet: { ...newAppPack, id: uId },
        },
        sheetEditData?._id,
      );
      studentTimeline("Working sheet update", "Update");
    } else {
      props.createApppack(appPackFinal);
      studentTimeline("New working sheet started", "Created");
    }
    // sheetEditData?.workingSheet
    //   ? props.updateApppack(
    //       {
    //         workingSheet: { ...newAppPack, id: uId },
    //       },
    //       sheetEditData?._id,
    //     )
    //   : props.createApppack(appPackFinal);
    // props.updateStudent({ timeline: data }, props.student?._id);
  };

  const [confirmMoveToApp, setConfirmMoveToApp] = useState({
    data: {},
    open: false,
  });

  const getProceedData = (data) => {
    setConfirmMoveToApp({
      data: data,
      open: true,
    });
  };

  const moveProceedData = (data) => {
    const user = getLoggedInUser();
    let appPackFinal = {
      stdId: props.match.params.id,
      appData: { ...data.workingSheet },
      user: { id: user._id, name: user.name, email: user.email },
    };
    props.createApppack(appPackFinal);
    studentTimeline("Application created", "Created");
    props.updateStudent({ isProcessing: true, isProcess: false, isClosed: false }, props.student?._id);
    props.updateApppack(
      {
        moveToApplication: true,
      },
      data?._id,
    );
    setConfirmMoveToApp({
      data: {},
      open: false,
    });
  };

  const readStudentsAppPacks = async () => {
    let stdId = props.match.params.id;
    if (stdId) {
      const { response } = await props.getApppacks("?stdId=" + stdId);
      setApplications(response);
    }
  };

  const onUpdate = (updateFields, id, updateFiles, multiArrayFiles) => {
    props.updateApppack(updateFields, id);
    if (updateFiles) {
      Object.keys(updateFiles).map((fk) => props.fileUploads(updateFiles[fk], "/uploadsingle?stdId=" + props.match.params.id));
    }
    if (multiArrayFiles) {
      multiArrayFiles.map((mf) => props.fileUploads(mf, "/uploadsingle?stdId=" + props.match.params.id));
    }
  };

  const onDelete = (id) => {
    // console.log(id);
    props.deleteApppack(id);
  };

  useEffect(() => {
    props.getStudent && props.getStudent("/regId?stdId=" + props.match.params.id);
    // props.getUsers && props.getUsers();
    readStudentsAppPacks();
  }, []);

  const getUsers = () => {
    props.getUsers("/allUser");
  };

  useEffect(() => {
    getUsers();
  }, []);

  const reFetchStudentOnUpdate = () => {
    if (props.userActionType === "STUDENT_UPDATE_SUCCESS") props.getStudent && props.getStudent("/regId?stdId=" + props.match.params.id);
  };

  useEffect(() => {
    reFetchStudentOnUpdate();
  }, [props.userActionType]);

  function usePrevious(actionType) {
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = actionType;
      if (
        prevActionType !== actionType &&
        (props.actionType === "APPPACK_CREATE_SUCCESS" ||
          props.actionType === "APPPACK_UPDATE_SUCCESS" ||
          props.actionType === "APPPACK_DELETE_SUCCESS")
      )
        readStudentsAppPacks();
    }, [props.actionType]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  // console.log(props);

  // final onSubmit when we are edit student
  const studentSubmit = (data, files) => {
    // console.log(files);
    // setFilesToUp(files);

    setFilesToUp(files);
    let fileKeys = Object.keys(files);
    fileKeys.map((k) => {
      data[k] = files[k].name;
    });

    props.updateStudent({ registrationData: data }, props.student?._id);
    studentTimeline("Student update", "Update");
  };

  const uploadFilesOnCreate = () => {
    if (props.studentActionType === "STUDENT_UPDATE_SUCCESS") {
      if (filesToUp) {
        if (!props.match.params.id) console.log("student data not available");
        let fileKeys = Object.keys(filesToUp);
        fileKeys.map((k) => {
          props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.match.params.id);
        });
        setFilesToUp(null);
      }
    }
  };

  useEffect(() => {
    uploadFilesOnCreate();
  }, [props.studentActionType]);

  // snackbar and timeline message
  useEffect(() => {
    if (props.actionType === "APPPACK_CREATE_SUCCESS") {
      setMessage("Application has been created Successfully");
      setSnackbarOpen(true);
    }
  }, [props.actionType]);

  useEffect(() => {
    if (props.actionType === "APPPACK_UPDATE_SUCCESS") {
      setMessage("Application has been updated Successfully");
      setSnackbarOpen(true);
    }
  }, [props.actionType]);

  useEffect(() => {
    if (props.studentActionType === "STUDENT_UPDATE_SUCCESS") {
      setMessage("Student data has been edit successfully");
      setSnackbarOpen(true);
    }
  }, [props.studentActionType]);

  // console.log(props);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleOptionClick2 = (user) => {
    props.updateStudent && props.updateStudent({ appOfficer: { id: user._id, name: user.name, email: user.email } }, props.student._id);
    handleClose2();
    studentTimeline("Application assigned to application officer", "Assigned");
  };
  const loggedInUser = getLoggedInUser();
  
  return (
    <Box
      sx={{
        pl: 3,
        pr: 3,
        py: 12,
        "@media (max-width: 450px)": {
          pl: 0.2,
          pr: 0.2,
          display: "flex",
        },
      }}
    >
      <StudentProfile
        onSubmit={onSubmit}
        studentSubmit={studentSubmit}
        applications={props.apppacks}
        workingSheets={props.apppacks}
        onUpdate={onUpdate}
        appDetail={appDetail}
        setAppDetail={setAppDetail}
        student={props.student}
        onDelete={onDelete}
        onEdit={onEdit}
        setShowForm={setShowForm}
        studentForm={studentForm}
        setStudentForm={setStudentForm}
        showForm={showForm}
        edit={edit}
        setEdit={setEdit}
        editData={editData}
        users={props.users}
        updateStudent={props.updateStudent}
        studentData={studentData}
        setStudentData={setStudentData}
        existingStudentData={props.student}
        fileUpload={props.fileUploads}
        location={props}
        workingSheetForm={workingSheetForm}
        setWorkingSheetForm={setWorkingSheetForm}
        getProceedData={getProceedData}
        sheetEditData={sheetEditData}
        setSheetEditData={setSheetEditData}
        studentTimelineUpdate={studentTimeline}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
          {message}
        </MuiAlert>
      </Snackbar>
      <Box>{/* <Gmail student={props?.student?.registrationData?.infoEmail} /> */}</Box>
      <Box>{/* <Gmail student={props?.student?.registrationData?.infoEmail} /> */}</Box>
      <Box>
        {confirmMoveToApp.open && (
          <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",

                boxShadow: 24,
                p: 4,
                height: "180px",
              }}
            >
              {!props?.student?.appOfficer?.name ? (
                <Box
                  sx={{
                    height: "100%",
                    overflowY: "auto",
                  }}
                >
                  {loadUserOptions((user) => user?.role === "Application Officer", props.users, handleOptionClick2, "appOfficer")}
                  <MenuItem value={"self-assign"} key={"self-assign"} onClick={() => handleOptionClick2(loggedInUser)}>
                    Self assign
                  </MenuItem>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      mb: "1rem",
                      display: "flex",
                      gap: ".2rem",
                    }}
                  >
                    Application assigned to -{" "}
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        color: "primary.main",
                      }}
                    >
                      {props?.student?.appOfficer?.name}
                    </Typography>
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  position: "absolute",
                  bottom: "1rem",
                  right: "1rem",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem", marginTop: "1rem" }}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setConfirmMoveToApp({
                        data: {},
                        open: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={() => moveProceedData(confirmMoveToApp?.data)}>
                    Sure
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </Box>
    </Box>
  );
};
const getStudent = curdActions.getStudent;
const updateStudent = curdActions.updateStudent;
const getUsers = curdActions.getUsers;
const getNotification = curdActions.getNotification;

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { fileUploads, getStudent, getUsers, updateStudent, getNotification },
};

let variableList = {
  studentReducer: {
    // students: [],
    student: {},
  },
  userReducer: {
    users: [],
  },
  notificationReducer: {
    notification: {},
  },
};

let customReducers = ["studentReducer", "userReducer", "notificationReducer"];
const ProfileContainer = CRUDFunction(Profile, "apppack", actionOptions, variableList, customReducers);
export default ProfileContainer;

function loadUserOptions(conditionMethod, users, handleOptionClick2, appOfficer) {
  if (!users || !conditionMethod) return null;
  let roleUsers = users.filter((user) => conditionMethod(user));
  let opts = roleUsers.map((user) => (
    <Box>
      <MenuItem value={user._id} key={user._id} onClick={() => handleOptionClick2(user)}>
        {user.name}
      </MenuItem>
    </Box>
  ));
  return opts;
}
