import React from "react";
import Box from "@mui/material/Box";
import EnhancedTable from "../Common/MUITable/muiTable";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
export const UserList = (props) => {
  // console.log(props)

  const headCols = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "branch",
      label: "Branches",
    },
  ];
  return (
    <Box
    // sx={{
    //   width:'100vw'
    // }}
    >
      <Paper sx={{ mb: 2, backgroundColor: "rgb(249, 250, 251)" }}>
        <Button
          sx={{
            float: "right",
            borderRadius: "2rem",
            margin: "0px 20px 30px 20px",
            "&.MuiButton-contained": { backgroundColor: "primary.main" },
          }}
          variant="contained"
          onClick={props.createNewUser}
        >
          {" "}
          Create User
        </Button>
        <EnhancedTable headCols={headCols} rows={props.users} tableHeading={"Import Orders"} onRowClick={props.userClick} noPaper />
      </Paper>
    </Box>
  );
};
