import React from "react";

const IntakeReport = ({ data }) => {
  // console.log("data", data);

  const ContainterTopStyle = {
    marginLeft: "1.4rem",
    marginRight: "1.6rem",
  };

  const tableStyle = {
    width: "100%",
    // borderCollapse: "collapse",
  };

  const thStyle = {
    padding: "10px 20px",
    textAlign: "center",
  };

  const thTdStyle = {
    padding: "10px 20px",
    textAlign: "center",
  };

  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      thTdStyle: {},
    },
  };
  let totalFA24 = 0;
  let totalSP25 = 0;
  let grandTotalApplications = 0;

  if (data?.reportData) {
    Object.keys(data?.reportData).forEach((branch) => {
      const branchData = data.reportData[branch];
      // console.log(data.reportData[branch]);
      totalFA24 += branchData.FA24Count;
      totalSP25 += branchData.SP25Count;
      grandTotalApplications += branchData.FA24Count + branchData.SP25Count;
    });
  }

  return (
    <>
      <div
        style={{
          fontSize: "28px",
          fontWeight: "700",
          marginLeft: "1.4rem",
          marginBottom: "8px",
        }}
      >
        Intake Report
      </div>

      <div style={ContainterTopStyle}>
        <table style={{ ...tableStyle }}>
          <thead style={{ backgroundColor: "rgb(25, 104, 173)" }}>
            <tr>
              <th style={{ ...thStyle, color: "white" }}> Branch</th>
              <th style={{ ...thStyle, color: "white" }}> FA-24</th>
              <th style={{ ...thStyle, color: "white" }}> SP-25 </th>
              <th style={{ ...thStyle, color: "white" }}> Total Applications </th>
            </tr>
          </thead>
          <tbody>
            {data?.reportData &&
              Object.keys(data.reportData).map((branch) => {
                const branchData = data.reportData[branch];
                const total = branchData.FA24Count + branchData.SP25Count;
                return (
                  <tr key={branch}>
                    <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{branch}</td>
                    <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{branchData.FA24Count}</td>
                    <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{branchData.SP25Count}</td>
                    <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{total || "N/A"}</td>
                  </tr>
                );
              })}

            <tr style={{ backgroundColor: "lightgray", fontWeight: "bold" }}>
              <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>Total</td>
              <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{totalFA24 || "N/A"}</td>
              <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{totalSP25 || "N/A"}</td>
              <td style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>{grandTotalApplications || "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IntakeReport;
