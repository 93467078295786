import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";

const options = [
  { label: "Sender", value: "sender", level: 0 },
  { label: "Body", value: "body", level: 1 },
  { label: "Subject", value: "subject", level: 2 },
  { label: "Mail", value: "mail", level: 3 },
];

const GmailFilter = ({ open, onClose, onSubmit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      mailFilters: [{ filter: "", filterValue: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mailFilters",
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Email Search</DialogTitle>
        <DialogContent>
          {fields.map((item, index) => {
            return (
              <Grid container spacing={2} key={item.id} alignItems={"center"}>
                <Grid item xs={4}>
                  <Controller
                    name={`mailFilters.${index}.filter`}
                    control={control}
                    defaultValue={""}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        onChange={(_, data) => onChange(data)}
                        defaultValue={options[0]?.label}
                        renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Select an option" />}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={"Contains"}
                    // label={selectedOptions[`option${index}`] === "mail" ? "Has" : "Contains"}
                    variant="outlined"
                    margin="normal"
                    defaultValue=""
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    defaultValue={""}
                    control={control}
                    name={`mailFilters.${index}.filterValue`}
                    render={({ field }) => (
                      <TextField label="Type something..." id="outlined-basic" type="text" variant="outlined" {...field} />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button onClick={() => remove(index)} variant="outlined" color="error">
                    Remove Filter
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={() => append({ filter: "", filterValue: "" })}>Add Filter</Button>
            <Box>
              <Button type="submit" color="primary" variant="contained">
                Apply
              </Button>
              <Button onClick={onClose} color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GmailFilter;
