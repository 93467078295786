import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs"; // Import Tabs from MUI
import Tab from "@mui/material/Tab"; // Import Tab from MUI
import React, { useState } from "react";
import LeftArrow from "../../../../Images/LeftArrow";
import Calender from "../../../../Images/Calender";
import AppStatus from "./AppStatus";
import Status from "../Status/Status";
import Gmail from "./Gmail";

const StateMachine = ({ setSingleApplicationData, singleApplicationData, student, onUpdate, updateStudent, studentTimelineUpdate, fileUpload }) => {
  // console.log("sdsd",singleApplicationData)
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        px: 4,

        "@media (max-width: 560px)": {
          px: 0,
        },
      }}
    >
      <AppStatus setSingleApplicationData={setSingleApplicationData} singleApplicationData={singleApplicationData} onUpdate={onUpdate} />

      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="status-tabs"
        sx={{
          "& .MuiTabs-flexContainer": {
            flexDirection: "row",
            justifyContent: "center",
          },
          "& .MuiTab-root": {
            padding: "30px 20px",
          },
        }}
      >
        <Tab sx={{ fontSize: "1.6rem" }} label="State Machine" />
        <Tab sx={{ fontSize: "1.6rem" }} label="Email" />
      </Tabs>

      <Box
        sx={{
          background: "white",
          p: 4,
          boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 720px)": {
            px: 0,
          },
        }}
      >
        {currentTab === 0 && (
          <Status
            studentTimelineUpdate={studentTimelineUpdate}
            data={singleApplicationData}
            fileUpload={fileUpload}
            onUpdate={onUpdate}
            student={student}
            updateStudent={updateStudent}
          />
        )}

        {currentTab === 1 && (
          <div>
            <Gmail onUpdate={onUpdate} singleApplicationData={singleApplicationData} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default StateMachine;
