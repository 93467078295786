import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider ,IconButton, Box } from '@mui/material';
import { Mail, Inbox, Send, Drafts, Delete, Report } from '@mui/icons-material';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const Sidebar = ({ setSelectedSection ,isCollapsed , setIsCollapsed ,toggleSidebar }) => {
 

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

 
  
  return (
    <Paper elevation={2} style={{ padding: '16px' }}>
      <Box sx={{display:"flex" , justifyContent:"space-between"}}>
      <Typography variant="h6" sx={{mt:0.8}}>Gmail</Typography>

      <div onClick={toggleSidebar} style={{ cursor: 'pointer' }} >
      

        {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon sx={{ fill: "black" }} />
              </IconButton>
            ) : (
              <IconButton  onClick={handleDrawerOpen}>
                <ChevronRightIcon sx={{ fill: "black" , }} />
              </IconButton>
            )}
      </div>

      </Box>

      <List style={{ display: isCollapsed ? 'block' : 'block' }}>
        <ListItem button onClick={() => setSelectedSection('inbox')}>
          <ListItemIcon>
            <Inbox />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Inbox" />}
        </ListItem>
        <ListItem button onClick={() => setSelectedSection('sent')}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Sent" />}
        </ListItem>

        <ListItem button onClick={() => setSelectedSection('Drafts')}>
          <ListItemIcon>
            <Drafts />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Drafts" />}
        </ListItem>

        <ListItem button onClick={() => setSelectedSection('Trash')}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Trash" />}
        </ListItem>

        <ListItem button onClick={() => setSelectedSection('Spam')}>
          <ListItemIcon>
            <Report />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Spam" />}
        </ListItem>

        <ListItem button onClick={() => setSelectedSection('Outbox')}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Outbox" />}
        </ListItem>
      </List>
    </Paper>
  );
};

export default Sidebar;
