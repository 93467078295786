import { Checkbox, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const Registration = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  console.log( appData);
  // console.log("the student data is", student);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <DataWrapper heading={"Registration"}>
        <Box
          sx={{
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="medium">
              Branch:{" "}
              <span style={{ fontWeight: "lighter", marginLeft: ".5rem" }}>
                {student?.registrationData?.branch?.text || appData?.branch  || student?.registrationData?.branch || "N/A"}
              </span>
            </Typography>

            {appCheck && (
              <AppCheckActions
                user={user}
                setRevertData={setRevertData}
                allData={data}
                confirm={confirm}
                copy={copy}
                submitted={submitted}
                data="branch"
                student={student}
                text={student?.registrationData?.branch?.text}

              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="medium">
              Counselor:
              <span style={{ fontWeight: "lighter", marginLeft: ".5rem" }}>{student?.counselor?.name || "N/A"}</span>
            </Typography>
            {appCheck && (
              <AppCheckActions
                user={user}
                setRevertData={setRevertData}
                allData={data}
                confirm={confirm}
                copy={copy}
                submitted={submitted}
                data="counselor"
                text={student?.counselor?.name}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="medium">
              RegId:
              <span style={{ fontWeight: "lighter", marginLeft: ".5rem" }}>{student?.registeration || "N/A"}</span>
            </Typography>
            {appCheck && (
              <AppCheckActions
                user={user}
                setRevertData={setRevertData}
                allData={data}
                confirm={confirm}
                copy={copy}
                submitted={submitted}
                data="regId"
                text={student?.registeration}
              />
            )}
          </Box>
        </Box>
      </DataWrapper>
    </Box>
  );
};

export default Registration;
