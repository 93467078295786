import React from 'react'

const DeleteIcon = () => {
  return (
    <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0312 2.34375H14.1016V1.75781C14.1016 0.788555 13.313 0 12.3438 0H7.65625C6.68699 0 5.89844 0.788555 5.89844 1.75781V2.34375H2.96875C1.99949 2.34375 1.21094 3.1323 1.21094 4.10156C1.21094 4.88 1.71973 5.54145 2.42207 5.77191L3.46719 18.3881C3.54246 19.292 4.31191 20 5.21891 20H14.7811C15.6881 20 16.4576 19.292 16.5329 18.3879L17.5779 5.77187C18.2803 5.54145 18.7891 4.88 18.7891 4.10156C18.7891 3.1323 18.0005 2.34375 17.0312 2.34375ZM7.07031 1.75781C7.07031 1.43473 7.33316 1.17188 7.65625 1.17188H12.3438C12.6668 1.17188 12.9297 1.43473 12.9297 1.75781V2.34375H7.07031V1.75781ZM15.365 18.2909C15.3399 18.5921 15.0834 18.8281 14.7811 18.8281H5.21891C4.9166 18.8281 4.66012 18.5921 4.63508 18.2911L3.60523 5.85938H16.3948L15.365 18.2909ZM17.0312 4.6875H2.96875C2.64566 4.6875 2.38281 4.42465 2.38281 4.10156C2.38281 3.77848 2.64566 3.51562 2.96875 3.51562H17.0312C17.3543 3.51562 17.6172 3.77848 17.6172 4.10156C17.6172 4.42465 17.3543 4.6875 17.0312 4.6875Z" fill="#EA0B0B"/>
<path d="M7.65516 17.034L7.06923 7.5809C7.04919 7.25789 6.76962 7.01222 6.44817 7.03234C6.12516 7.05238 5.87958 7.33043 5.89958 7.6534L6.48551 17.1066C6.50477 17.4172 6.76274 17.6562 7.06977 17.6562C7.40911 17.6562 7.67598 17.3705 7.65516 17.034Z" fill="#EA0B0B"/>
<path d="M10 7.03125C9.67641 7.03125 9.41406 7.29359 9.41406 7.61719V17.0703C9.41406 17.3939 9.67641 17.6562 10 17.6562C10.3236 17.6562 10.5859 17.3939 10.5859 17.0703V7.61719C10.5859 7.29359 10.3236 7.03125 10 7.03125Z" fill="#EA0B0B"/>
<path d="M13.5519 7.03239C13.2296 7.01236 12.9508 7.25794 12.9308 7.58095L12.3449 17.0341C12.3249 17.357 12.5705 17.6351 12.8935 17.6551C13.2166 17.6751 13.4945 17.4294 13.5145 17.1066L14.1005 7.65345C14.1205 7.33044 13.8749 7.05239 13.5519 7.03239Z" fill="#EA0B0B"/>
</svg>

    </div>
  )
}

export default DeleteIcon