import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { branchesOptions } from "../../Data/BranchesOptions";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { checkUserRoleForDashboardFilters } from "../../BussinessLogic/Dashboard/filterRole";

const DashBoardFilters = ({ users, DashboardFilters, setDashboardFilters, getDashboards }) => {
  //method used for hiding dashboards filters for user other than admin and Management
  const filterHide = checkUserRoleForDashboardFilters();

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date?.toLocaleDateString("en-US", options);
  };

  const [startDate, setStartDate] = useState(DashboardFilters?.dateRange?.from);
  const [endDate, setEndDate] = useState(DashboardFilters?.dateRange?.to);
  //method for users dropdown
  const DateChange = (ranges) => {
    const startDate = ranges?.selection?.startDate;
    const endDate = ranges?.selection?.endDate;
    setDashboardFilters({
      ...DashboardFilters,
      dateRange: { from: startDate, to: endDate },
    });
    setStartDate(startDate);
    setEndDate(endDate);
    handleClose();
  };
  //method for users dropdown
  const SelectBranch = (newValue) => {
    setDashboardFilters({
      ...DashboardFilters,
      branch1: newValue?.value || "",
    });
  };

  //method for users dropdown
  const handleUserChange = (event, newValue) => {
    const selectedUserName = newValue ? newValue?.name : "";
    setDashboardFilters((prevFilters) => ({
      ...prevFilters,
      user1: selectedUserName,
    }));
  };

  // main method that fetch data from server according to given states
  const FetchData = () => {
    const jsonArrayFilters = encodeURIComponent(JSON?.stringify(DashboardFilters));
    const url = `/?dateRange=${jsonArrayFilters}`;
    getDashboards(url);
  };

  //states and variable used for responsiveness

  const isMobile = useMediaQuery("(max-width:830px)");
  const isScreen = useMediaQuery("(max-width:670px)");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isScreen ? 370 : 630,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box
        sx={{
          pt: 4,
          pb: 2,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "10px",
        }}
      >
        <>
          <FormControl fullWidth variant="outlined" onClick={handleOpen}>
            <InputLabel>From</InputLabel>
            <OutlinedInput
              value={formatDate(startDate)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="From"
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" onClick={handleOpen}>
            <InputLabel>To</InputLabel>
            <OutlinedInput
              value={formatDate(endDate)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="From"
            />
          </FormControl>

          {filterHide && (
            <>
              <Autocomplete
                fullWidth
                options={branchesOptions}
                value={DashboardFilters?.branch1}
                getOptionLabel={(option) => option?.text || option}
                getOptionSelected={(option, value) => option?.value === value}
                onChange={(event, newValue) => {
                  SelectBranch(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Branch" />}
              />

              <Autocomplete
                fullWidth
                options={users}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => <TextField {...params} label="Users" />}
                onChange={handleUserChange}
              />
            </>
          )}

          <Button
            variant="contained"
            onClick={FetchData}
            sx={{
              height: "55px",
              width: isMobile ? "100%" : "400px",
              borderRadius: "10px",
            }}
          >
            Search
          </Button>
        </>
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <DateRangePicker
            onChange={DateChange}
            ranges={[
              {
                startDate: new Date(DashboardFilters.dateRange.from),
                endDate: new Date(DashboardFilters.dateRange.to),
                key: "selection",
              },
            ]}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DashBoardFilters;
