import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import navConfig from "../nav/config";
import { StyledNavItemIcon } from "../nav/nav-section/styles";
import { Link } from "react-router-dom";
import { Avatar, alpha } from "@mui/material";
import { getLoggedInUser } from "../../../utils/loggedInUser";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),

    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SidemenuBar() {
  const location = useLocation();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [isCollapse, setIsCollapse] = React.useState(false);
  const [isCollapse1, setIsCollapse1] = React.useState(false);
  const [isCollapse2, setIsCollapse2] = React.useState(false);
  const [isCollapse3, setIsCollapse3] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };
  const handleCollapse1 = () => {
    setIsCollapse1(!isCollapse1);
  };
  const handleCollapse2 = () => {
    setIsCollapse2(!isCollapse2);
  };
  const handleCollapse3 = () => {
    setIsCollapse3(!isCollapse3);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: open ? "flex-end" : "center",
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const StyledAccount = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 1.5),

    // backgroundColor: alpha(theme.palette.grey[500], 0.12),
    backgroundColor: "primary.light",
  }));

  const navLinks = navConfig.filter(
    (item) => item.name !== "lead" && item.name !== "finance" && item.name !== "user" && item.name !== "chat",
  );
  const leadLinks = navConfig.filter((item) => item.name == "lead");
  const paymentLinks = navConfig.filter((item) => item.name == "finance");
  const settingLinks = navConfig.filter((item) => item.name == "user");
  const chatLinks = navConfig.filter((item) => item.name == "chat");

  let user = getLoggedInUser();

  return (
    <Box sx={{ display: "flex" }}>
      {/* <Toolbar >
        
          
           <IconButton
            
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{

                 marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

        </Toolbar>
      */}

      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "primary.main" }}>
          {open && (
            <Link to="/" style={{ marginRight: "3rem" }}>
              <img src="/assets/Success_Factor_Logo 1.png" style={{ width: "6rem" }} alt="" />
            </Link>
          )}
          <Box
            sx={{
              background: "white",
              borderRadius: "50%",
              width: "1.6rem",
              height: "1.6rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              marginLeft: "0px",
            }}
          >
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon sx={{ fill: "black" }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon sx={{ fill: "black" }} />
              </IconButton>
            )}
          </Box>
          {/* <IconButton onClick={handleDrawerClose} >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>

        <Box>
          <Divider />
        </Box>

        {/* <StyledAccount>
          <Avatar
            src={user && user.photoURL}
            alt="photoURL"
            sx={{
              mr: `${!open && "2rem"} `,
            }}
          />

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {user && user.name}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {user && user.role}
            </Typography>
          </Box>
        </StyledAccount> */}

        <Box sx={{ height: "100%", backgroundColor: "primary.main" }}>
          <List>
            {navLinks.map((text, index) => (
              <Box key={text}>
                {text?.permissions?.includes(user?.role) ? (
                  <Link to={text.path}>
                    <ListItem disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 58,
                          justifyContent: open ? "initial" : "center",
                          borderRadius: "3rem",
                          marginTop: "0.2rem",
                          marginLeft: ".8rem",
                          marginRight: "1rem",
                          "&:hover": {
                            backgroundColor: "rgb(247, 141, 0)",
                          },
                          backgroundColor: location.pathname === text.path ? "rgb(247, 141, 0)" : "inherit",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <StyledNavItemIcon>{text.icon && text.icon}</StyledNavItemIcon>
                        </ListItemIcon>

                        <ListItemText
                          primary={
                            text.title === "Application Processing" && user?.role !== "Application Officer" ? "Processing" : text.title
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ) : null}
              </Box>
            ))}
          </List>

          {["Administrator", "Management"].includes(user?.role) ? (
            <List sx={{ backgroundColor: "primary.main" }}>
              <ListItem disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }} onClick={handleCollapse1}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    borderRadius: "3rem",
                    marginLeft: ".8rem",
                    marginRight: "1rem",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "rgb(247, 141, 0)",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  ></ListItemIcon>
                  {open && (
                    <img
                      src="/assets/icons/navbar/Group 1171275940.svg"
                      style={{ width: "1.3rem", marginLeft: "-0.8rem", marginRight: "1.8rem", fill: "white" }}
                      alt=""
                    />
                  )}
                  <ListItemText primary="Finance" sx={{ opacity: open ? 1 : 0 }} />
                  {isCollapse1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
              </ListItem>
              <Collapse in={isCollapse1} timeout="auto" unmountOnExit>
                {paymentLinks.map((text, index) => (
                  <Link key={text} to={text.path}>
                    <ListItem key={text} disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          borderRadius: "3rem",
                          marginLeft: ".8rem",
                          marginTop: "0.2rem",
                          marginRight: "1rem",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "rgb(247, 141, 0)",
                          },
                          backgroundColor: location.pathname === text.path ? "rgb(247, 141, 0)" : "inherit",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {!open && <StyledNavItemIcon>{text.icon && text.icon}</StyledNavItemIcon>}
                        </ListItemIcon>
                        <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </Collapse>
            </List>
          ) : null}

          {["Administrator", "Management", "Counselor", "Application Officer"].includes(user?.role) ? (
            <List sx={{ backgroundColor: "primary.main" }}>
              <ListItem disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }} onClick={handleCollapse2}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    borderRadius: "3rem",
                    marginLeft: ".8rem",
                    marginRight: "1rem",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "rgb(247, 141, 0)",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  ></ListItemIcon>
                  {open && (
                    <img
                      src="/assets/icons/navbar/Group 1171275938.svg"
                      style={{ width: "1.3rem", marginLeft: "-0.8rem", marginRight: "1.8rem", fill: "white" }}
                      alt=""
                    />
                  )}

                  <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
                  {isCollapse2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
              </ListItem>
              <Collapse in={isCollapse2} timeout="auto" unmountOnExit>
                {settingLinks.map((text, index) => (
                  <Link key={text} to={text.path}>
                    <ListItem key={text} disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          borderRadius: "3rem",
                          marginTop: "0.2rem",
                          marginLeft: ".8rem",
                          marginRight: "1rem",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "rgb(247, 141, 0)",
                          },
                          backgroundColor: location.pathname === text.path ? "rgb(247, 141, 0)" : "inherit",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {!open && <StyledNavItemIcon>{text.icon && text.icon}</StyledNavItemIcon>}
                        </ListItemIcon>
                        <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </Collapse>
            </List>
          ) : null}

          {["Administrator", "Management", "Branch Manager", "Counselor", "Student", "Marketing", "Application Officer"].includes(
            user?.role,
          ) ? (
            <List sx={{ backgroundColor: "primary.main" }}>
              <ListItem disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }} onClick={handleCollapse3}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    borderRadius: "3rem",
                    marginLeft: ".8rem",
                    marginRight: "0rem",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "rgb(247, 141, 0)",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  ></ListItemIcon>
                  {open && (
                    <img
                      src="/assets/icons/navbar/Group 1171275939.svg"
                      style={{ width: "1.3rem", marginLeft: "-0.8rem", marginRight: "1.3rem", fill: "white" }}
                      alt=""
                    />
                  )}
                  <ListItemText primary="Communication" sx={{ opacity: open ? 1 : 0 }} />
                  {isCollapse3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
              </ListItem>
              <Collapse in={isCollapse3} timeout="auto" unmountOnExit>
                {chatLinks.map((text, index) => (
                  <Link key={text} to={text.path}>
                    <ListItem key={text} disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          borderRadius: "3rem",
                          marginTop: "0.2rem",
                          marginLeft: ".8rem",
                          marginRight: "1rem",
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "rgb(247, 141, 0)",
                          },
                          backgroundColor: location.pathname === text.path ? "rgb(247, 141, 0)" : "inherit",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {!open && <StyledNavItemIcon>{text.icon && text.icon}</StyledNavItemIcon>}
                        </ListItemIcon>
                        <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </Collapse>
            </List>
          ) : null}
        </Box>
      </Drawer>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      
       
      </Box> */}
    </Box>
  );
}

{
  /* <List sx={{ backgroundColor: "primary.main" }}>
            <ListItem disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }} onClick={handleCollapse}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: "3rem",
                  marginLeft: ".8rem",
                  marginRight: "1rem",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "rgb(247, 141, 0)",
                  },
                  
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                ></ListItemIcon>

                {open && (
                  <img
                    src="/assets/icons/navbar/Group 1171275936.svg"
                    style={{ width: "1.3rem", marginLeft: "-0.8rem", marginRight: "1.8rem", fill: "white" }}
                    alt=""
                  />
                )}

                <ListItemText primary="Leads" sx={{ opacity: open ? 1 : 0 }} />

                {isCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>

            <Collapse in={isCollapse} timeout="auto" unmountOnExit>
              {leadLinks.map((text, index) => (
                <Link key={text} to={text.path}>
                  <ListItem key={text} disablePadding sx={{ display: "block", color: "rgb(255, 255, 255)" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        borderRadius: "3rem",
                        marginLeft: ".8rem",
                        marginTop:"0.2rem",
                        marginRight: "1rem",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "rgb(247, 141, 0)",
                        },
                        backgroundColor: location.pathname === text.path ? "rgb(247, 141, 0)" : "inherit", 
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {!open && <StyledNavItemIcon>{text.icon && text.icon}</StyledNavItemIcon>}
                      </ListItemIcon>
                      <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </Collapse>
            
          </List> */
}
