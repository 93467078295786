import React from "react";
import { Grid } from "@mui/material";
import PieAndDonutChartComponent from "../../../Components/Common/PieAndDonutChartComponent/PieAndDonutChartComponent";

const ApplicationResponseWidget = ({ appResponseData }) => {
  //console.log("app response data is", appResponseData);
  return (
    <Grid container item xs={12} sm={4} spacing={6}>
      <Grid item xs={12} sm={12} md={12}>
        <PieAndDonutChartComponent
          title={"Application Response Status"}
          pieOptions={{
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: false,
                },
              },
            },
          }}
          subheader=""
          chartData={appResponseData}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationResponseWidget;
