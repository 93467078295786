import React from 'react'

const Line = () => {
  return (
    <div>
        <svg width="2" height="26" viewBox="0 0 2 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L0.999999 25" stroke="white" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
</svg>
    </div>
  )
}

export default Line