import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";

const WorkingSheetGrid = ({ workingSheets, getProceedData, setShowForm, setSheetEditData, location, student }) => {
  const pageLocation = location?.location?.pathname?.split("/")[1];

  // console.log(getProceedData);
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "university",
      headerName: "University",
      width: 300,
      editable: true,
    },
    // {
    //   field: "planOfAction",
    //   headerName: "Plan of action",
    //   width: 150,
    //   editable: true,
    // },
    {
      field: "course",
      headerName: "Course",
      width: 200,
      editable: true,
    },
    {
      field: "applicationDeadline",
      headerName: "Application deadline",
      width: 160,
      editable: true,
    },

    {
      field: "courseLink",
      headerName: "Course Link",
      width: 200,
      editable: false,
    },
    // {
    //   field: "nationalRanking",
    //   headerName: "National ranking",
    //   width: 200,
    //   editable: false,
    // },
    {
      field: "tuitionFee",
      headerName: "Tuition fee",
      width: 150,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Proceed to application" placement="top">
              <Button
                disabled={cellValues?.row?.status == "Done" ? true : false}
                sx={{
                  p: 0,
                }}
              >
                <NavigateNextIcon
                  fontSize="large"
                  color={cellValues?.row?.status == "Done" ? "disabled" : "primary"}
                  onClick={() => getProceedData(cellValues?.row?.data)}

                />
              </Button>
            </Tooltip>
            <Tooltip title="Edit working sheet" placement="top">
              <Button
                disabled={pageLocation == "students" && cellValues?.row?.status == "Done" ? true : false}
                sx={{
                  p: 0,
                }}
              >
                <EditIcon
                  color={pageLocation == "students" && cellValues?.row?.status == "Done" ? "disabled" : "primary"}
                  onClick={() => {
                    setSheetEditData(cellValues?.row?.data);
                    setShowForm(true);
                  }}
                />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const rows = workingSheets
    ?.filter((item) => item?.appData?.status == "no")
    ?.map((item, idx) => {
      return {
        id: idx + 1,
        university: item?.workingSheet?.university?.label,
        course: item?.workingSheet?.course?.label,
        applicationDeadline: item?.workingSheet?.applicationDeadline,
        status: item?.moveToApplication ? "Done" : "In Progress",
        courseLink: item?.workingSheet?.courseLink,
        tuitionFee: item?.workingSheet?.tuitionFee,
        data: item,
      };
    });

  return (
    <Box
      sx={{
        marginLeft: "2rem",
        marginRight: "2rem",
        mt: '20px',
        [`@media (max-width: 425px)`]: {
          margin: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        <Button variant="contained" onClick={() => setShowForm(true)}>
          Start Working Sheet
        </Button>
      </Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default WorkingSheetGrid;
