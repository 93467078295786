import { Alert, Snackbar } from "@mui/material";
import React from "react";

const Notification = ({ vertical = "top", horizontal = "right", open, setOpen, text, variant = "success" }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
        {text || "Your Message"}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
