import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, IconButton, Menu, MenuItem, Step, StepButton, Stepper, Typography } from "@mui/material";
import LeftArrow from "../../Images/LeftArrow";
import { useState } from "react";
import Registration from "./ProfileData/Registration";
import StudentProfileDisplay from "./ProfileData/StudentProfileDisplay";
import EducationData from "./ProfileData/EducationData";
import TestingData from "./ProfileData/TestingData";
import EntranceTestData from "./ProfileData/EntranceTestData";
import CountryOfInterest from "./ProfileData/CountryOfInterest";
import Reference from "./ProfileData/Reference";
import Employment from "./ProfileData/Employment";
import Intake from "./ProfileData/Intake";
import PersonalStatementData from "./ProfileData/PersonalStatementData";
import UniversitiesPreference from "./ProfileData/UniversitiesPreference";
import IntendedCourse from "./ProfileData/IntendedCourse";
import useScreenSize from "../../utils/useScreenSIze";
import { ArrowDownward, KeyboardArrowDown } from "@mui/icons-material";

const ViewProfile = ({
  open,
  handleClose,
  student,
  appCheck,
  confirm,
  allData,
  copy,
  submitted,
  setRevertData,
  user,
  review,
  selectedTab,
}) => {
  // console.log("student", student);
  const [activeStep, setActiveStep] = useState(0);

  const steps = appCheck
    ? [
        "Registration",
        "Student Profile",
        "Intended Course",
        "Education",
        "Testing",
        "Entrance Test",
        "Country of Interest",
        "Reference",
        "Employment",
        "Intake",
        "Universities Preference",
        "Additional",
      ]
    : [
        "Registration",
        "Student Profile",
        "Intended Course",
        "Education",
        "Testing",
        "Entrance Test",
        "Country of Interest",
        "Reference",
        "Employment",
        // "Intake",
        // "Personal Statement",
        // "Universities Preference",
      ];
  const data = {
    0: (
      <Registration
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    1: (
      <StudentProfileDisplay
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    2: (
      <IntendedCourse
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    3: (
      <EducationData
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    4: (
      <TestingData
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    5: (
      <EntranceTestData
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    6: (
      <CountryOfInterest
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    7: (
      <Reference
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    8: (
      <Employment
        student={student}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    9: (
      <Intake
        student={student}
        appData={allData?.appData}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    10: (
      <UniversitiesPreference
        student={student}
        appData={allData?.appData}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
    11: (
      <PersonalStatementData
        student={student}
        appData={allData?.appData}
        appCheck={review ? false : appCheck}
        confirm={confirm}
        data={allData}
        copy={copy}
        submitted={submitted}
        setRevertData={setRevertData}
        user={user}
      />
    ),
  };

  const showNames = {
    0: "Registration",
    1: "Student Profile",
    2: "Intended Course",
    3: "Education",
    4: "Testing",
    5: "Entrance Test",
    6: "Country of Interest",
    7: "Reference",
    8: "Employment",
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const screen = useScreenSize();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {appCheck ? (
        <Box>
          <Box
            sx={{
              p: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              gap: "1rem",
              [`@media (max-width: 1300px)`]: {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.5)",
                pr: "20px",
                [`@media (max-width: 1300px)`]: {
                  borderRight: "none",
                },
              }}
            >
              {screen.width > "1300" ? (
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    id="demo-positioned-button"
                    aria-controls={openMenu ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClick}
                  >
                    {showNames[activeStep]}
                    <span
                      style={{
                        paddingTop: ".6rem",
                      }}
                    >
                      <KeyboardArrowDown />
                    </span>
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {steps?.map((item, index) => {
                      return (
                        <MenuItem key={index} onClick={handleStep(index)}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              )}
            </Box>
            <Box>{data[activeStep]}</Box>
          </Box>
        </Box>
      ) : (
        // <Modal
        //   open={open}
        //   onClose={handleClose}
        //   sx={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     border: "none !important",
        //     outline: "none",
        //   }}
        // >
        <Box
          sx={{
            backgroundColor: "white",
            //   borderRadius: "1rem",
            width: "96.5%",
            height: "90%",
            border: "none",
            marginLeft: "2rem",
            marginRight: "2rem",
            outline: "none",

            overflowY: "auto",
            // style a scrollbar
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              outline: "1px solid slategrey",
              borderRadius: "1rem",
            },

            [`@media (max-width: 425px)`]: {
              margin: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px",
              backgroundColor: "primary.main",

              // borderRadius: "1rem 0rem 0 0",
            }}
          >
            {/* <Box
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                }}
              >
                <LeftArrow color={"white"} />
              </Box> */}
            <Typography variant={"h5"} color="white">
              Student Profile
            </Typography>
            <span></span>
          </Box>
          <Box
            sx={{
              p: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              gap: "1rem",
              [`@media (max-width: 955px)`]: {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.5)",
                pr: "20px",
                [`@media (max-width: 955px)`]: {
                  borderRight: "none",
                },
              }}
            >
              {screen.width > "955" ? (
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    id="demo-positioned-button"
                    aria-controls={openMenu ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClick}
                  >
                    {showNames[activeStep]}
                    <span
                      style={{
                        paddingTop: ".6rem",
                      }}
                    >
                      <KeyboardArrowDown />
                    </span>
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {steps?.map((item, index) => {
                      return (
                        <MenuItem key={index} onClick={handleStep(index)}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              )}
            </Box>
            <Box>{data[activeStep]}</Box>
          </Box>
        </Box>
        // </Modal>
      )}
    </>
  );
};

export default ViewProfile;
