import { Box, Menu, MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import TaskDetailCard from "./TaskDetailCard";
import { TaskAccessMethods } from "../../BussinessLogic/Tasks/TaskAccess";
import { isSysgen } from "../../utils/sysGen";
import GeneralTaskDetail from "./GeneralTaskDetail";

const TaskMenu = ({ filterTasks }) => {
  const [showTask, setshowTask] = useState("Student Tasks");
  const [taskAnchor, settaskAnchor] = useState(null);

  const handleClose = () => {
    settaskAnchor(null);
  };

  const handleClick = (event) => {
    settaskAnchor(event.currentTarget);
  };

  const handleMenuItemClick = (taskType) => {
    setshowTask(taskType);
    handleClose(); // Close the menu when a value is selected
  };

  const filteredTasks = TaskAccessMethods.filterTasksByUserRole(filterTasks);

  const studentTasks = filteredTasks.filter((task) => !isSysgen(task.studentId));
  const generalTasks = filteredTasks.filter((task) => isSysgen(task.studentId));

  // console.log(filteredTasks)

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.1)",
          marginTop: "2rem",
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 3,
            backgroundColor: "rgba(25, 104, 173, 1)",
            "@media (max-width: 580px)": {
              gap: "1rem",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "@media (max-width: 425px)": {
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              },
              "@media (max-width: 375px)": {
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              },
            }}
          >
            <Box
              onClick={handleClick}
              sx={{
                display: "flex",
                cursor: "pointer",
                color: "white",
              }}
            >
              <Typography variant="h4">{showTask}</Typography>
              <ExpandMoreIcon fontSize="large" />
            </Box>
            <Menu id="icon-menu2" anchorEl={taskAnchor?.parentElement} open={Boolean(taskAnchor)} onClose={handleClose}>
              <Box
                sx={{
                  p: 1,
                }}
              >
                <MenuItem onClick={() => handleMenuItemClick("Student Tasks")}>Student Tasks</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("General Tasks")}>General Tasks</MenuItem>
              </Box>
            </Menu>
          </Box>
        </Box>

        {showTask === "Student Tasks" && (
          <>
            <Box
              sx={{
                p: 4,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "3.5rem 1.5rem ",
                "@media (max-width: 425px)": {
                  p: 2,
                },
              }}
            >
              <TaskDetailCard studentTasks={studentTasks} />
            </Box>
          </>
        )}

        {showTask === "General Tasks" && (
          <>
            <Box
              sx={{
                p: 4,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "3.5rem 1.5rem ",
                "@media (max-width: 425px)": {
                  p: 2,
                },
              }}
            >
              <GeneralTaskDetail generalTasks={generalTasks} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TaskMenu;
