import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";

const SubTasks = ({ value, setinputsubTask, onKeyDown }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(237,234,233)",
          width: "400px",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: "rgb(255,255,255)",
          m: " 10px 25px 0px 25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <LooksOneOutlinedIcon
            fontSize="small"
            sx={{
              color: "rgb(30,31,33)",
              marginLeft: "5px",
              marginTop: "8px",
            }}
          />
          <TextField
            sx={{
              ml: "12px",
              wordBreak: "break-all",
            }}
            autoFocus
            fullWidth
            id="standard-size-small"
            placeholder="Add Task .."
            size="small"
            variant="standard"
            value={value}
            onChange={(e) => {
              setinputsubTask(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
        </Box>
      </Box>
    </>
  );
};

export default SubTasks;
