import React from "react";
import Topbar from "./Formbar";
import { Card, CardContent, Typography, Box, Grid, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
// import AppBar from "@mui/material/AppBar";

// import Toolbar from "@mui/material/Toolbar";

// import IconButton from "@mui/material/IconButton";
// import AddIcon from "@mui/icons-material/Add";
// import GridViewIcon from "@mui/icons-material/GridView";
// import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ZForms = ({ leads, createLead, loading }) => {
  // console.log({leads});
  // Sample data
  const formTitle = "Feedback Form";
  const formDescription = "Feedback from students.";
  const creationDate = "May 25, 2023";
  const formStatus = "Active";
  const numberOfSubmissions = 10;
  const lastSubmissionDate = "May 28, 2023";

  const [query, setQuery] = React.useState("");

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  return (
    <>
      <Topbar createLead={createLead} setQuery={setQuery} />

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {leads
          ?.filter((lead) => {
            if (query === "") {
              return lead;
            } else if (lead?.title?.toLowerCase()?.includes(query?.toLowerCase())) {
              return lead;
            } else if (lead?.description?.toLowerCase()?.includes(query.toLowerCase())) {
              return lead;
            }
          })
          ?.map((lead) => {
            if (lead?.manualForm || lead?.googleForm) return;
            return (
              <Link>
                {loading.actionType == "LEADS_READ_REQUEST" ? (
                  <Box sx={{ width: 400, marginTop: "30px", marginLeft: "1.2rem" }}>
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
                    <Skeleton animation="wave" variant="rounded" width={400} height={80} />
                  </Box>
                ) : (
                  <Card
                    sx={{
                      width: 400,
                      height: 230,
                      marginLeft: "1.2rem",
                      [`@media (max-width: 483px)`]: {
                        width: 330,
                      },

                      [`@media (max-width: 416px)`]: {
                        width: 310,
                      },
                      [`@media (max-width: 390px)`]: {
                        width: 290,
                      },
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography variant="h5" component="div" color="primary.main" my={0}>
                            {lead.title}
                          </Typography>
                          <Typography variant="subtitle2" color="primary.main" my={1}>
                            {lead.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" color="primary.main" my={1}>
                            {formatDate(lead.createdAt)}
                          </Typography>
                          <Typography variant="subtitle2" color="primary.main" my={1}>
                            {formStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          marginTop: "10px",
                          bgcolor: "lightGray",
                          width: "23px",
                          height: "23px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: ".9rem",
                          fontWeight: "bold",
                          marginLeft: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        0
                      </Box>
                      <Box my={0}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2" color="primary.main">
                              {formatDate(lead.createdAt)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Link to={`/forms/${lead._id}`} key={lead._id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                <a
                                  style={{
                                    color: "white",
                                    backgroundColor: "#1968AD",
                                    width: "75px",
                                    height: "28px",
                                    marginRight: "30px",
                                    marginBottom: "30px",
                                    fontSize: "12px",
                                    fontWeight: 300,
                                    letterSpacing: "1.25px",
                                    fontStyle: "normal",
                                    textAlign: "center",
                                    paddingTop: "5px",
                                  }}
                                >
                                  View
                                </a>
                              </Box>
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Link>
            );
          })}
      </Box>
    </>
  );
};

export default ZForms;
