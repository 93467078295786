import React, { useState, useEffect } from "react";
import ChatSideBarTop from "./ChatSideBarTop";

const ChatScreen = ({ users, getChatMessages, messageList, setMessageList, props }) => {



  return (
    <>
      <div  >
        <ChatSideBarTop
          users={users
          } messageList={messageList}
          setMessageList={setMessageList}
          getChatMessages={getChatMessages}
          props={props} />
      </div>
    </>
  );
};

export default ChatScreen;
