import React, { useEffect, useState, useRef } from "react";
import ChatScreen from "./ChatScreen";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { getLoggedInUser } from "../../utils/loggedInUser";
const Chat = (props) => {
  //console.log("the props are",props)
  const [messageList, setMessageList] = useState([]);
  const [users, setUsers] = useState([]);

  const prevActionType = usePrevious(props.chatboxActionType);

  // console main jab kisi bhi chatlist main say name pe click karain gain tw yh add kardy ga console main , jis bhi bandy say chat start hui hai

  function usePrevious(actionType) {
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = actionType;
      if (prevActionType !== actionType && props.chatboxActionType === "CHATBOX_CREATE_SUCCESS") {
        props.getChatboxs("/mychatbox");
      }
    }, [props.chatboxActionType]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  // yh saray jin say chat start hugae hai un conversations ko get karaha hai
  useEffect(() => {
    props.getUsers("/allUser");
    let user = getLoggedInUser();
    if (user) {
      props.getChatboxs("/mychatbox");
    }
  }, []);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  useEffect(() => {
    // TODO : "TASK : load the chatInbox messages of the two users in inbox"
    const reversedChatInboxs = props.chatInboxs.slice().reverse();
    setMessageList(reversedChatInboxs);
  }, [props.chatInboxs]);

  useEffect(() => {
    // console.log(props.chatboxs);
  }, [props.chatboxs]);

  const getChatMessages = (first, second) => {
    props.getChatInboxs(`/findMessages/?first=${first}&second=${second}`);
  };

  const createChatbox = (chatbox) => {
    props.createChatbox(chatbox);
  };

  return (
    <>
      <div style={{ marginTop: "70px" }}>
        <ChatScreen
          users={users}
          getChatMessages={getChatMessages}
          messageList={messageList}
          setMessageList={setMessageList}
          props={props}
        />
      </div>
    </>
  );
};
let variableList = {
  userReducer: {
    users: [],
    user: {},
  },
};
// console.log("curdActions are",curdActions);
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];
let moreCruds = [
  {
    name: "chatbox",
    apiName: "chatbox",
  },
];
// export default ChatSideBarTop;
const chatContainer = CRUDFunction(Chat, "chatInbox", actionOptions, variableList, addditionalREducer, null, moreCruds);
export default chatContainer;
// export default Chat
