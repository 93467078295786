import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Button, Box, Typography, Dialog } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import PopDialog from "./Dialog";

const SubmissionTable = (props) => {
  console.log(props);

  const { id } = useParams();
  const [answers, setAnswers] = useState({});
  const [allStudentAns, setAllStudentAns] = useState([]);

  // const answersArray = Object?.entries(answers?.answers)?.map(([key, value]) => ({
  //   questionId: key,
  //   ...value,
  // }));

  // console.log(answers);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    props.getLead(id);
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }
  const leadData = props?.lead?.students?.map((item,index) => {
    return {
      id: index+1,
      email: item?.email,
      points: props.leadActionType == "LEAD_UPDATE_REQUEST" ? "Loading..." : item?.totalPoints,
      createTime: formatDate(item?.createdAt),
      lastSubmittedTime: formatDate(item?.lastSubmittedTime),
      actions: "actions",
    };
  });

  function handleClick(item) {
    const answersArray = Object.entries(item?.answers)?.map(([key, value]) => ({
      questionId: key,
      ...value,
    }));
    setAnswers({ answers: answersArray, respondentEmail: item.respondentEmail });
    // setAnswers({ answers: answersArray, respondentEmail: item.respondentEmail, createTime, lastSubmittedTime, responseId });
    handleClickOpen();
  }

  const convertResponsesToArray = (responses) => {
    const result = [];
    responses?.forEach((response) => {
      const answers = [];
      for (const questionId in response.answers) {
        answers.push({
          questionId: questionId,
          textAnswers: response.answers[questionId].textAnswers,
        });
      }
      result.push({
        respondentEmail: response.respondentEmail,
        answers: answers,
        createTime: response.createTime,
        lastSubmittedTime: response.lastSubmittedTime,
        formId: props.lead.formID,
      });
    });
    return result;
  };

  const pointsMap = {};

  // props.lead?.points.map((point) => {
  //   // pointsMap[point.questionId] = parseInt(point.points);
  //   console.log(point);
  // });

  const pointsByQuestionOption = props.lead?.points?.map((item) => ({
    [item.option]: item.points,
  }));

  const calculateTotalPoints = (pointsArray, responsesArray) => {
    const result = [];

    responsesArray?.forEach((response) => {
      const email = response.respondentEmail;
      const answers = response.answers;
      const createTime = response.createTime;
      const lastSubmittedTime = response.lastSubmittedTime;
      const formID = response.formId;

      let totalPoints = 0;
      let isDQ;

      answers?.forEach((answer) => {
        const option = answer.textAnswers?.answers[0].value;
        // console.log("option", option);

        pointsArray?.forEach((pointsItem) => {
          const pointsKey = Object.keys(pointsItem)[0];
          const pointsValue = pointsItem[pointsKey];

          if (pointsKey == option && !isNaN(pointsValue)) {
            totalPoints += parseInt(pointsValue);
          }
        });
      });

      result.push({ email: email || "N/A", totalPoints, createdAt: createTime, lastSubmittedTime: lastSubmittedTime, formId: formID });
    });

    return result;
  };

  const calculatePoints = () => {
    const data = convertResponsesToArray(props.lead?.responses);

    const totalPoints = calculateTotalPoints(pointsByQuestionOption, data);

    props.updateLead(
      {
        students: totalPoints,
        formId: props.lead.formID,
      },
      id,
    );

    // console.log(totalPoints);
    // console.log(data);
    // console.log(pointsByQuestionOption);
  };

  // console.log(allStudentAns);

  const tableColumns = [
    // { id: "leadName", label: "Lead Name" },
    // { id: "leadScore", label: "Lead Score" },
    // { id: "leadStage", label: "Lead Stage" },
    // { id: "assignedTo", label: "Assigned To" },
    // { id: "lastAction", label: "Last Action" },
    // { id: "origin", label: "Origin" },
    // { id: "city", label: "City" },
    // { id: "contacts", label: "Contacts" },
    { field: 'id', headerName: 'ID', width: 90 },
    { field: "email", headerName: "Emails" ,  width: 90 },
    { field: "points", headerName: "Points" , width: 90 },
    { field: "createTime", headerName: "Create Time" , width: 90},
    { field: "lastSubmittedTime", headerName: "Last Submitted Time" , width: 90 },
    { field: "actions", headerName: "Actions" ,width: 108, disableClickEventBubbling: true, renderCell: (cellValues) => {
      return 
      <Box>
      <Button
        variant="outlined"
        size="small"
        sx={{ mr: 1 }}
        onClick={() => {
          handleClick(cellValues);
        }}
      >
        {" "}
        View
      </Button>
    </Box>
    }
   },
  ];

  const tableData = leadData;

  const history = useHistory();

  return (
    <Box sx={{ marginTop: "100px", mx: "2rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button> */}
        <Button variant="contained" sx={{ mb: 2 }} onClick={calculatePoints}>
          Refresh
        </Button>
      </Box>
      <Typography variant="h4" sx={{ mb: 2, color: "primary.main" }}>
        {props.lead?.title}
      </Typography>
      {props.leadActionType == "LEAD_READ_REQUEST" ? (
        <Typography variant="h4" component="div" color="primary.main" my={0}>
          Loading...
        </Typography>
      ) : (
        <Box sx={{ mx: 2 }}>
          <DataGrid rows={tableData} columns={tableColumns} autoHeight  />
        </Box>
     
        // <TableContainer component={Paper} style={{ marginTop: "20px", marginLeft: "10px", marginRight: "10px" }}>
        //   <Table>
        //     <TableHead>
        //       <TableRow>
        //         {tableColumns.map((column) => (
        //           <TableCell
        //             key={column.id}
        //             sx={{
        //               fontWeight: "bold",
        //               textTransform: "uppercase",
        //               backgroundColor: "rgba(25, 104, 173, 1)!important",
        //               color: "#fff",
        //               [`@media(max-width:780px)`]: { fontSize: "0.7rem" },
        //             }}
        //           >
        //             {column.label}
        //           </TableCell>
        //         ))}
        //       </TableRow>
        //     </TableHead>

        //     <TableBody>
        //       {tableData?.map((row, index) => (
        //         <TableRow key={index}>
        //           {tableColumns.map((column) => (
        //             <TableCell key={column.id}>{row[column.id]}</TableCell>
        //           ))}
        //         </TableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
      )}
      <PopDialog open={open} handleClose={handleClose} answers={answers} />
    </Box>
  );
};

let variableList = {
  leadReducer: {
    lead: {},
  },
};

const getLead = curdActions.getLead;
const updateLead = curdActions.updateLead;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    getLead,
    updateLead,
  },
};
let addditionalREducer = ["leadReducer"];

const submissionTableContainer = CRUDFunction(SubmissionTable, "", actionOptions, variableList, addditionalREducer, null);
export default submissionTableContainer;
