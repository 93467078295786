import React from "react";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import LeftArrow from "../../../../Images/LeftArrow";
import Calender from "../../../../Images/Calender";
import EditIcon from "../../../../Images/EditIcon";
import DeadlineDialog from "./DeadlineDialog";

const AppStatus = ({ setSingleApplicationData, singleApplicationData, onUpdate }) => {
  function formatDate(inputDateStr, time) {
    const inputDate = new Date(inputDateStr);

    const options = {
      weekday: "long", // Display the full day of the week (e.g., "Friday")
      month: "long", // Display the full month name (e.g., "August")
      day: "numeric", // Display the day of the month as a number (e.g., "4")
      year: "numeric", // Display the year as a number (e.g., "2023")
    };

    const timeOptions = {
      hour: "numeric", // Display the hour in 12-hour format (e.g., "11")
      minute: "numeric", // Display the minutes (e.g., "00")
      second: "numeric", // Display the seconds (e.g., "02")
      hour12: true, // Use 12-hour format (true)
      //   timeZoneName: "short", // Display the time zone abbreviation (e.g., "EDT")
    };

    return !time ? inputDate.toLocaleDateString("en-US", options) : inputDate.toLocaleTimeString("en-US", timeOptions);
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = (date) => {
    if (!date) return;

    onUpdate(
      {
        deadline: date,
      },
      singleApplicationData?._id,
    );
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // write a media query with mui syntax
          "@media (max-width: 1520px)": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <Stack direction="row" spacing={2} color={"white"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"}>
          <Box
            sx={{
              cursor: "pointer",
              pr: 2,
            }}
            onClick={() => setSingleApplicationData({})}
          >
            <LeftArrow color={"white"} />
          </Box>
          <Avatar alt="Profile Image" src="" sx={{ width: 70, height: 70 }} />
          <Stack direction="column">
            <Typography variant={"h4"}>{singleApplicationData?.appData?.university?.label}</Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: "light",
                fontSize: ".8rem",
              }}
            >
              # {singleApplicationData?.formId}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" alignItems={"center"}>
          <Typography variant={"h6"} sx={{ color: "white" }}>
            Application Status
          </Typography>
          <Box
            sx={{
              mt: 2,
              backgroundColor: "white",
              color: "black",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              direction={"column"}
              alignItems={"center"}
              sx={{
                p: 2,
                // px: 4,
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid rgba(0, 0, 0, 1)",
                  pr: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "@media (max-width: 424px)": {
                    pr: 0,
                  },
                  "@media (max-width: 722px)": {
                    borderRight: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  Initiated On
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    "@media (max-width: 1150px)": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Calender color={"black"} />
                    <Typography
                      sx={{
                        fontWeight: "light",
                        fontSize: ".8rem",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {formatDate(singleApplicationData?.createdAt)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <HistoryToggleOffIcon
                      fontSize="small"
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "light",
                        fontSize: ".8rem",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {formatDate(singleApplicationData?.createdAt, true)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
            <Stack
              direction={"column"}
              alignItems={"center"}
              sx={{
                p: 2,
                // px: 4,
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid rgba(0, 0, 0, 1)",
                  pr: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "@media (max-width: 424px)": {
                    pr: 0,
                  },
                  "@media (max-width: 1249px)": {
                    borderRight: "none",
                  },
                  "@media (max-width: 1150px)": {
                    borderRight: "1px solid rgba(0, 0, 0, 1) !important",
                  },
                  "@media (max-width: 921px)": {
                    borderRight: "none !important",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  Last Activity
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    "@media (max-width: 1150px)": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Calender color={"black"} />
                    <Typography
                      sx={{
                        fontWeight: "light",
                        fontSize: ".8rem",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {formatDate(singleApplicationData?.updatedAt)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <HistoryToggleOffIcon
                      fontSize="small"
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "light",
                        fontSize: ".8rem",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {formatDate(singleApplicationData?.updatedAt, true)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
            <Stack
              direction={"column"}
              alignItems={"center"}
              sx={{
                p: 2,
                // px: 4,
              }}
            >
              <Box
                sx={{
                  pr: 4,
                  "@media (max-width: 424px)": {
                    pr: 0,
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  Deadline
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleClickOpen}
                  >
                    <EditIcon />
                  </Box>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    "@media (max-width: 1150px)": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Calender color={"black"} />
                    <Typography
                      sx={{
                        fontWeight: "light",
                        fontSize: ".8rem",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {singleApplicationData?.deadline || "....."}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <DeadlineDialog open={open} handleClose={handleClose} submit={submit} />
    </Box>
  );
};

export default AppStatus;
