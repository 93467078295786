export function calculateActiveStep(data, propertyKey, booleanChecksArray) {
  if (!data || !data[propertyKey]) return 0;
  if (data[propertyKey].approved) return 2;
  if (booleanChecksArray.filter((bc) => !data[propertyKey][bc]).length === 0) return 1;
  return 0;
}

export function calculateActiveStepForSubmission(data, propertyKey, booleanChecksArray) {
  if (!data || !data[propertyKey]) return 0;
  if (data[propertyKey].approved) return 3;
  if (data[propertyKey].appCheck) return 2;
  if (data[propertyKey].docCheck) return 1;
  if (booleanChecksArray.filter((bc) => !data[propertyKey][bc]).length === 0) return 4;
  return 0;
}
