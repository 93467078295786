import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import InputForm from "../../../Components/Common/Form/InputForm";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { repeaterDefaultValueIndex, repeaterLoader } from "../../StudentProfile/ApplicationForm/Forms/repeaterLoader";

const EntranceTest = ({
  setModal,
  eControl,
  handleSubmit,
  goBack,
  nextStep,
  setStudentData,
  setRegistrationFormFiles,
  isEdit,
  proceedData: sData,
}) => {
  const handleCancel = () => {
    setModal(false);
  };
  const [file, setFile] = useState("");
  const [showOtherField, setShowOtherField] = useState("");

  const onSubmit = (data) => {
    setStudentData((prevData) => [...prevData, data, { entranceTestUploadResult: file }]);
    nextStep();
  };

  const {
    control,
    // handleSubmit,
    formState: { errors },
  } = useForm(
    
     
       {
          defaultValues: {
            entranceTest: [
              {
                entranceTest: "",
                entranceTestScore: "",
                entranceTestUploadResult: "",
                entranceTestDateOfTest: "",
                otherEntranceTest: "",
              },
            ],
          },
        },
  );
  const entranceTestUploadResult = (e) => {
    setFile(e.target.files[0].name);
    setRegistrationFormFiles((prevData) => {
      return { ...prevData, [e.target.name]: e.target.files[0] };
    });
  };
  const { fields, append } = useFieldArray({
    control,
    name: "entranceTest",
  });

  const addOtherField = (data) => {
    if (data?.label === "Other") {
      setShowOtherField(data?.label);
    } else {
      setShowOtherField("");
    }
  };

  const entranceTestOptions = [
    { label: "SAT", level: 1 },
    { label: "SAT I", level: 2 },
    { label: "GRE General", level: 3 },
    { label: "GRE Subjective", level: 4 },
    { label: "GMAT", level: 5 },
    { label: "LNAT", level: 6 },
    { label: "Other", level: 7 },
  ];

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <Box>
          <Stack>
            {fields.map((field, index) => {
              return (
                <Stack key={field.id} sx={{ borderBottom: "2px solid black", padding: "8px 0", mb:'25px' }} spacing={2}>
                  <Controller
                    defaultValue={""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTest`}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={entranceTestOptions}
                        onChange={(_, data) => (onChange(data?.label), addOtherField(data))}
                        renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Entrance Test" />}
                        {...field}
                      />
                    )}
                  />
                  {showOtherField === "Other" && (
                    <Controller
                      defaultValue={""}
                      control={eControl}
                      name={`entranceTest.${index}.otherEntranceTest`}
                      render={({ field }) => (
                        <TextField
                          id="outlined-basic"
                          type="text"
                          label="Other Entrance Test"
                          variant="outlined"
                          {...field}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  )}
                  <Controller
                    defaultValue={""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestScore`}
                    render={({ field }) => <TextField label="Score" id="outlined-basic" type="text" variant="outlined" {...field} />}
                  />
                  {/* <Controller
                  defaultValue={isEdit ? repeaterDefaultValueIndex(!isEdit, "entranceTest", "entranceTest", index, sData) : ""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestUploadResult`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.entranceTestUploadResult)}
                        helperText="Upload Result"
                        id="outlined-basic"
                        type="file"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  /> */}
                  <TextField
                    type="file"
                    name={`entranceTest.${index}.entranceTestUploadResult`}
                    variant="outlined"
                    onChange={entranceTestUploadResult}
                  />
                  <Controller
                    defaultValue={""}
                    control={eControl}
                    name={`entranceTest.${index}.entranceTestDateOfTest`}
                    render={({ field }) => (
                      <TextField
                        error={Boolean(errors.entranceTestDateOfTest)}
                        helperText="Date Of Test"
                        id="outlined-basic"
                        type="date"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() =>
                append({
                  entranceTest: "",
                  entranceTestScore: "",
                  entranceTestUploadResult: "",
                  entranceTestDateOfTest: "",
                  otherEntranceTest: "",
                })
              }
            >
              Add More
            </Button>
          </Box>
        </Box>
        <Box
          mt={2}
          sx={{
            width: "70%",
            position: "absolute",
            height: "7%",
            bottom: "3%",
          }}
        >
          <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white", zIndex: 1 }}>
            <Button variant="outlined" onClick={goBack}>
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default EntranceTest;
