import React from 'react'

const EscalateIcon = ({color}) => {
  return (
    <svg width="15" height="15" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6 10.8C7.27302 10.8 8.49396 10.2943 9.39414 9.39414C10.2943 8.49396 10.8 7.27302 10.8 6C10.8 4.72696 10.2943 3.50606 9.39414 2.60589C8.49396 1.70571 7.27302 1.2 6 1.2C4.72696 1.2 3.50606 1.70571 2.60589 2.60589C1.70571 3.50606 1.2 4.72696 1.2 6C1.2 7.27302 1.70571 8.49396 2.60589 9.39414C3.50606 10.2943 4.72696 10.8 6 10.8ZM6 9C5.20434 9 4.44129 8.68392 3.87868 8.1213C3.31607 7.55874 3 6.79566 3 6C3 5.20434 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20434 3 6 3C6.79566 3 7.55874 3.31607 8.1213 3.87868C8.68392 4.44129 9 5.20434 9 6C9 6.79566 8.68392 7.55874 8.1213 8.1213C7.55874 8.68392 6.79566 9 6 9Z" fill={color}/>
    </svg>
    
  )
}

export default EscalateIcon