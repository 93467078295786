import { Typography, Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getLoggedInUser } from "../../utils/loggedInUser";

function LocationDisplay() {
  const location = useLocation();
  const history = useHistory();
  const parts = location.pathname.split("/");
  const extractedRoute = parts[1];

  const formattedRoute = extractedRoute === "" ? "DASHBOARD" : extractedRoute.toUpperCase();

  const user = getLoggedInUser();

  useEffect(() => {
    if (["Mentor", "Student", "Application Officer"].includes(user?.role) && location.pathname == "/query") {
      history.push("/");
    }

    if (["Mentor", "Student", "Application Officer"].includes(user?.role) && location.pathname == "/leads") {
      history.push("/");
    }

    if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/students") {
      history.push("/");
    }

    // if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/user") {
    //   history.push("/");
    // }

    // if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/profile") {
    //   history.push("/");
    // }

    // if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/agreements") {
    //   history.push("/");
    // }
    // if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/report") {
    //   history.push("/");
    // }
    // if (["Student", "Marketing"].includes(user?.role) && location.pathname == "/invoice") {
    //   history.push("/");
    // }
  }, [location]);

  return (
    <Box>
      <Typography variant="h4" sx={{ color: "black" }}>
        {formattedRoute}
      </Typography>
    </Box>
  );
}

export default LocationDisplay;
