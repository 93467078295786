import { ControlPointDuplicateRounded } from "@mui/icons-material";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { getLoggedInUser } from "../../../utils/loggedInUser";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { branchesOptions } from "../../../Data/BranchesOptions";

const Registration = ({ setModal, control, handleSubmit, goBack, nextStep, setStudentData, isEdit, existingStudentData: sData }) => {
  // console.log(sData?.branch);
  const [userBranch, setUserBranch] = useState({});
  useEffect(() => {
    const user = getLoggedInUser();
    setUserBranch(user.branch);
    // console.log("jdsjdisj", user.branch);
  }, []);

  // console.log("d", control);
  const methods = useForm({
    defaultValues: {
      regDate: "",
      // branch: "",
      // counselor: "",
    },
  });

  const handleCancel = () => {
    setModal(false);
  };
  const onSubmit = (data) => {
    data.branch = userBranch;
    // console.log("data branch", data.branch);
    setStudentData((prevData) => [...prevData, data]);
    nextStep();
  };

  // const branchesOptions = [
  //   { label: "SFK", id: 1 },
  //   { label: "SFL", id: 2 },
  //   { label: "SFI", id: 3 },
  //   { label: "SFS", id: 4 },
  //   { label: "SFH", id: 5 },
  //   { label: "SFIR", id: 6 },
  //   { label: "SFC", id: 7 },
  // ];
  const counselorOptions = [
    { label: "Fuad Khowaja", id: 1 },
    { label: "Kamila Amin", id: 2 },
    { label: "Ritika Manglani", id: 3 },
    { label: "Umair Azhar", id: 4 },
    { label: "Yumna Zuberi", id: 5 },
    { label: "Ayesha Ghaus", id: 6 },
    { label: "Sana Umair", id: 7 },
    { label: "Usama Akhlaq", id: 8 },
    { label: "Zubaida Abbas", id: 9 },
    { label: "Kanza Iqbal", id: 10 },
    { label: "Ruhma Sallahuddin", id: 11 },
    { label: "Qandeel Shah", id: 12 },
    { label: "Umair Ahmed Sheikhid", level: 13 },
  ];
  const errors = methods.formState.errors;
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
  const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();

  const todayDate = `${year}-${month}-${date}`;

  return (
    <Box mt={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          <Stack spacing={2}>
            <Controller
              name="regDate"
              defaultValue={isEdit ? sData?.registrationData?.regDate : todayDate}
              control={control}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors?.regDate)}
                  helperText={errors?.regDate?.message}
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            {/* {!userBranch ? ( */}
            <Controller
              name="branch"
              defaultValue={isEdit ? sData?.registrationData?.branch || sData?.branch : null}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={branchesOptions}
                  getOptionLabel={(option) => option.text || option} //Added this line
                  getOptionSelected={(option, value) => option?.value === value?.value} //Added this line
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Branch" />}
                  {...field}
                />
              )}
            />
            {/* ) : (
              <TextField
                label="Branch"
                value={userBranch}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            )} */}

            {/* <Controller
              name="branch"
              defaultValue={""}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={branchesOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Branches" />}
                  {...field}
                />
              )}
            /> */}

            {/* <TextField
              label="Branch"
              value={userBranch}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            /> */}

            {/* <Controller
              name="counselor"
              defaultValue={""}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={counselorOptions}
                  onChange={(_, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Counselor" />}
                  {...field}
                />
              )}
            /> */}
          </Stack>
          <Box
            mt={2}
            sx={{
              width: "70%",
              position: "absolute",
              height: "7%",
              bottom: "3%",
            }}
          >
            <Stack spacing={2} direction="row" sx={{ position: "fixed", width: "30%", background: "white" }}>
              <Button variant="outlined" disabled={true} onClick={goBack}>
                Back
              </Button>
              <Button variant="contained" type="submit">
                Next
              </Button>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Registration;
