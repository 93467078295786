export function formatDate(inputDateStr, time) {
  const inputDate = new Date(inputDateStr);

  const options = {
    weekday: "long", // Display the full day of the week (e.g., "Friday")
    month: "long", // Display the full month name (e.g., "August")
    day: "numeric", // Display the day of the month as a number (e.g., "4")
    year: "numeric", // Display the year as a number (e.g., "2023")
  };

  const timeOptions = {
    hour: "numeric", // Display the hour in 12-hour format (e.g., "11")
    minute: "numeric", // Display the minutes (e.g., "00")
    second: "numeric", // Display the seconds (e.g., "02")
    hour12: true, // Use 12-hour format (true)
    //   timeZoneName: "short", // Display the time zone abbreviation (e.g., "EDT")
  };

  return !time ? inputDate.toLocaleDateString("en-US", options) : inputDate.toLocaleTimeString("en-US", timeOptions);
}

export function formatDateAndTime(inputDate) {
  const date = new Date(inputDate);
  const now = new Date();
  const timeDifference = now - date;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (daysDifference === 0) {
    // Within the same day
    const hours = date.getHours();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}, ${formattedTime} (${daysDifference} day${daysDifference === 1 ? "" : "s"} ago)`;
  } else if (daysDifference === 1) {
    // Yesterday
    const yesterdayHours = date.getHours();
    const yesterdayAmPm = yesterdayHours >= 12 ? "PM" : "AM";
    const formattedYesterdayHours = yesterdayHours % 12 || 12;
    const yesterdayMinutes = date.getMinutes();
    const formattedYesterdayMinutes = yesterdayMinutes < 10 ? `0${yesterdayMinutes}` : yesterdayMinutes;
    const formattedYesterdayTime = `${formattedYesterdayHours}:${formattedYesterdayMinutes} ${yesterdayAmPm}`;
    return `Yesterday, ${formattedYesterdayTime} (1 day ago)`;
  } else {
    // More than a day ago
    return `${date.toLocaleString("default", { month: "short" })} ${date.getDate()}, ${date.getFullYear()}, (${daysDifference} day${
      daysDifference === 1 ? "" : "s"
    } ago)`;
  }
}
