import React, { useState, useEffect } from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useLocation, useHistory } from "react-router-dom";
import { isJSON } from "../../utils/isJSON";
import { branchesOptions } from "../../Data/BranchesOptions";
import { Typography, TextField, Button, Container, Grid, Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const GoogleSignUpPage = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [role, setRole] = useState("");
  const [gInfo, setGInfo] = useState(null);
  const [user, setUser] = useState({
    name: "",
    // role: "",
    password: "",
    confirmPassword: "",
    email: "",
    branch: "",
    // add other fields as needed
  });

  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState({
    open:false, 
    variant:"",

  });

  useEffect(() => {
    const googleObject = new URLSearchParams(location.search).get("googleObj");

    let gObjectParse = isJSON(googleObject);
    if (gObjectParse) {
      setGInfo(gObjectParse);
      setUser((prevUser) => ({
        ...prevUser,
        email: gObjectParse.email,
      }));
    }
  }, [location]);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (event) => {
    
    setRole(event.target.value);
  };
  
  const onCreateUser = (e) => {
    e.preventDefault(); // Prevent form submission

    if (user.password !== user.confirmPassword) {
      setMessage("Passwords do not match. Please try again.");
      setSnackbarOpen({open:true, 
        variant:"error",
      });
      return;
    }

    if (!user.email.endsWith("@successfactor.org")) {
      setMessage("Only Users with an email from successfactor.org can register,you will be redirected to login page.");
      setSnackbarOpen(true);
      setTimeout(() => history.push("/login"), 4000);
      return;
    }

    const googleObject = new URLSearchParams(location.search).get("googleObj");
    const finalUserObj = {
      ...user,
      role: role,
      //google: googleObject,
      google: gInfo,
    };

    Object.entries(finalUserObj).forEach(([key, value]) => {
      console.log(`${key}: ${typeof value}`);
    });

    console.log(finalUserObj);
    props.createGuser(finalUserObj, null, "/googleAuthCreate");
    // TODO : will be called when submit is clicked.
    /*  1 : proper user should be made for all fields that are requried in model of user.model.js on server.
    2 : make sure to include email , password, role , google object in the final user object
    3 : call props.createGuser(finalUserObj, null ,"/googleAuthCreate" ) make sure to console.log on server to debug to make sure the object received is correct and same property as parsed in service 
*/
  };

  useEffect(() => {
    if (props.actionType === "GUSER_CREATE_SUCCESS") {
      setMessage("User created successfully. Please login again.");
      setSnackbarOpen({
        open:true,
        variant:"success",
      });
      setTimeout(() => history.push("/login"), 4000);
      // TODO : render a message to show that user is created successfully and reuqire login again with button to redirect the user to login page
    } else if (props.actionType === "GUSER_CREATE_FAILURE") {
      setMessage("User creation failed. Please try again.");
      setSnackbarOpen({
        open:true,
        variant:"error",
      });
      setTimeout(() => history.push("/login"), 4000);
      // TODO : render a message that user creation failed and give button to user to reidrect to login page to try again
    }
  }, [props.actionType, history]);

  const handleSnackbarClose = () => {
    setSnackbarOpen({
      open: false
    });
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Box mt={8} mb={4}>
          <Typography variant="h4" component="h1" align="center" sx={{color:"primary.main"}}>
            New User Sign-up
          </Typography>
        </Box>

        <form onSubmit={onCreateUser}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <TextField fullWidth type="text" name="name" value={user.name} onChange={handleChange} label="Name" required />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select labelId="role-label" id="role" value={role} onChange={handleRoleChange} required>
                  <MenuItem value="Application Officer">Application Officer</MenuItem>
                  <MenuItem value="Counselor">Counselor</MenuItem>
                  <MenuItem value="Marketing">Marketing</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
                label="Password"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                name="confirmPassword"
                value={user.confirmPassword}
                onChange={handleChange}
                label="Confirm Password"
                required
              />
            </Grid>

            {/* Add other fields as needed */}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select labelId="branch-label" id="branch" value={user.branch} onChange={handleChange} name="branch">
                  {branchesOptions.map((branch) => (
                    <MenuItem key={branch.value} value={branch.value}>
                      {branch.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" variant="contained" color="primary">
                Create User
              </Button>
            </Grid>
          </Grid>
        </form>
      
      
        <Snackbar
          open={snackbarOpen.open}
          autoHideDuration={6000}

          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert onClose={handleSnackbarClose} severity={snackbarOpen.variant} variant="filled">
            {message}
          </MuiAlert>
        </Snackbar>
      </Container>
    </div>
  );
};
const GoogleSignUpPageContainer = CRUDFunction(GoogleSignUpPage, "guser", null, null, null, "user");
export default GoogleSignUpPageContainer;
