import React, { useState } from "react";

export const SocketContext = React.createContext();

export const SocketProvider = ({ children }) => {
  const [socketIOUser, setSocketIOUser] = useState(null);
  const [leadData , setLeadData]=useState({
  })
  const [createQuiry, setCreateQuiry] = useState(false)


  return (
    <SocketContext.Provider value={{ socketIOUser, setSocketIOUser, setLeadData , leadData, createQuiry, setCreateQuiry }}>
      {children}
    </SocketContext.Provider>
  );
};