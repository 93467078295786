import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  OutlinedInput,
  Autocomplete,
  Popover,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Modal,
} from "@mui/material";
import { branchesOptions } from "../../Data/BranchesOptions";
import { DateRangePicker } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ReportFilter = ({ filters, setReportFilters, users, selectedReport, getReport }) => {
  // console.log(selectedReport);
  // console.log("users", users);
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const [startDate, setStartDate] = useState(filters.dateRange.from);
  const [endDate, setEndDate] = useState(filters.dateRange.to);

  const DateChange = (ranges) => {
    // console.log("ranges", ranges);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);

    setReportFilters({
      ...filters,
      dateRange: { from: startDate, to: endDate },
    });
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const UsersChange = (field, value) => {
    setReportFilters({
      ...filters,
      [field]: value,
    });
  };

  const BranchChange = (newValue) => {
    // console.log("Selected branch:", newValue);
    setReportFilters({
      ...filters,
      branch1: newValue?.value || "",
    });
  };

  const FetchData = () => {
    const jsonArrayFilters = encodeURIComponent(JSON.stringify(filters));
    const jsonArraySelectedReports = encodeURIComponent(JSON.stringify(selectedReport));
    // console.log(jsonArraySelectedReports);
    const url = `/getreport/?dateRange=${jsonArrayFilters}&rId=${jsonArraySelectedReports}`;
    getReport(url);
  };

  const isMobile = useMediaQuery("(max-width:830px)");
  const isScreen = useMediaQuery("(max-width:670px)");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isScreen ? 370 : 630,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box
      sx={{
        pl: 3,
        pr: 3,
        py: 6,
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "10px",
          }}
        >
          <FormControl
            fullWidth
            variant="outlined"
            // sx={{
            //   width: "300px",
            //   "@media (max-width: 1024px)": {
            //     width: "250px",
            //   },
            // }}
            onClick={handleOpen}
          >
            <InputLabel>From</InputLabel>
            <OutlinedInput
              value={formatDate(startDate)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="From"
            />
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            // sx={{
            //   width: "300px",
            //   "@media (max-width: 1024px)": {
            //     width: "250px",
            //   },
            // }}
            onClick={handleOpen}
          >
            <InputLabel>To</InputLabel>
            <OutlinedInput
              value={formatDate(endDate)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="From"
            />
          </FormControl>

          <Autocomplete
            // sx={{
            //   width: "300px",
            //   "@media (max-width: 1024px)": {
            //     width: "250px",
            //   },
            // }}
            fullWidth
            options={branchesOptions}
            value={filters.branch1}
            getOptionLabel={(option) => option?.text || option}
            getOptionSelected={(option, value) => option?.value === value}
            onChange={(event, newValue) => {
              BranchChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Branch" />}
          />

          <FormControl
            fullWidth
          // sx={{
          //   width: "300px",
          //   "@media (max-width: 1024px)": {
          //     width: "250px",
          //   },
          // }}
          >
            <InputLabel>Users</InputLabel>
            <Select
              value={filters.user1}
              onChange={(event) => UsersChange("user1", event.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              label="Users"
            >
              {users?.map((name, index) => (
                <MenuItem key={index} value={name?.name}>
                  {name?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={FetchData}
            sx={{
              height: "55px",
              width: isMobile ? "100%" : "400px",
              borderRadius: "10px",
            }}
          >
            Search
          </Button>
        </Box>

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <DateRangePicker
              onChange={DateChange}
              ranges={[{ startDate: new Date(filters.dateRange.from), endDate: new Date(filters.dateRange.to), key: "selection" }]}
            />
          </Box>
        </Modal>
      </>
    </Box>
  );
};

export default ReportFilter;
