import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const QueryWidget = ({ queryData }) => {
  // console.log("queryData", queryData);

  const generateChartData = (data) => {
    if (!data) return { series: [], categories: [] };

    const series = [
      {
        name: "In Process Queries",
        data: [],
      },
      {
        name: "Closed Queries",
        data: [],
      },
      {
        name: "Moved To Registration",
        data: [],
      },
    ];

    const categories = [];

    Object?.keys(data)?.forEach((Branch) => {
      const branchData = data[Branch];
      // console.log("branchData", branchData);
      categories.push(Branch);
      // console.log("categories", categories);
      series[0]?.data?.push(branchData?.inProcessCountQuery);
      series[1]?.data?.push(branchData?.closedCountQuery);
      series[2]?.data?.push(branchData?.moveToRegistrationCount);
    });
    return { series, categories };
  };

  const { series, categories } = generateChartData(queryData);

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: "Number of Queries",
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: "top",
    },
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" align="center">
                Query Status
              </Typography>
              <Chart options={chartOptions} series={series} type="bar" height={350} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default QueryWidget;
