import MenuItem from "@mui/material/MenuItem";

export function loadUserOptions(conditionMethod, users, handleOptionClick2) {
  if (!users || !conditionMethod) return null;
  let roleUsers = users.filter((user) => conditionMethod(user));
  let opts = roleUsers.map((user) => (
    <MenuItem value={user._id} key={user._id} onClick={() => handleOptionClick2(user)}>
      {user.name}
    </MenuItem>
  ));
  return opts;
}
