import { Box, Button, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, Tooltip, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LinkIcon from "@mui/icons-material/Link";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';

const GoogleLead = ({ leads, getLead, getProceedData, location, setModal, users, getLeads, updateLead, onDelete }) => {
  const [leadId, setLeadId] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [StatusleadId, setStatusleadId] = useState(-1);
  const [StatusModal, setStatusModal] = useState(false);

  // Modal For Lead Status
  const StatusModalOpen = () => setStatusModal(true);

  const StatusModalClose = () => {
    setStatusModal(false);
    setStatusleadId(-1);
  };

  // For InProcess Status
  const handleProcessLead = async () => {
    await updateLead({ isProcess: true, isClosed: false }, StatusleadId);
    getLeads();
    StatusModalClose();
  };
  // For Closed Status
  const handleCloseLead = async () => {
    await updateLead({ isClosed: true, isProcess: false }, StatusleadId);
    getLeads();
    StatusModalClose();
  };

  // Function For AssigneTo Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLeadId(-1);
  };

  // For Modal Styling
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleOptionClick = async (user) => {
    await updateLead({ assignTo: { id: user._id, name: user.name, email: user.email } }, leadId);
    getLeads();
    handleClose();
  };

  function loadUserOptions(conditionMethod, users, handleOptionClick2) {
    if (!users || !conditionMethod) return null;
    let roleUsers = users.filter((user) => conditionMethod(user));
    let opts = roleUsers.map((user) => (
      <MenuItem value={user._id} key={user._id} onClick={() => handleOptionClick2(user)}>
        {user.name}
      </MenuItem>
    ));
    return opts;
  }

  const tableColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "edit status",
      headerName: "Edit Status",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues?.row?.data.isProcess === true ? "In Process" :
            cellValues?.row?.data.isClosed === true ? "Lead Closed" :
              cellValues?.row?.data.LeadMoveToQuery === true ? "Moved to Query" :
                "Not Started"} placement="top">
            <Button
              disabled={cellValues?.row?.data.LeadMoveToQuery}
              sx={{
                p: 0,
              }}
              onClick={() => {
                StatusModalOpen();
                setStatusleadId(cellValues?.row?.data?._id);
              }}
            >
              <ModeStandbyIcon
                sx={{
                  color:
                    cellValues?.row?.data.isProcess === true
                      ? "Orange"
                      : cellValues?.row?.data.isClosed === true
                      ? "Red"
                      : cellValues?.row?.data.LeadMoveToQuery === true
                      ? "Green"
                      : "Grey",
                }}
              />
            </Button>
          </Tooltip>
        );
      },
    },
    { field: "status", headerName: "Status", width: 150 },
    { field: "owner", headerName: "Owner", width: 150 },
    { field: "fname", headerName: "Name", width: 150 },
    // { field: "mname", headerName: "Age", width: 150 },
    { field: "age", headerName: "Age", width: 150 },
    { field: "email", headerName: "Emails", width: 150 },
    { field: "source", headerName: "Source", width: 150 },
    { field: "mobile", headerName: "Mobile No", width: 150 },
    // { field: "dc", headerName: "City", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    // { field: "hno", headerName: "Current Qualifications", width: 150 },
    { field: "cq", headerName: "Current Qualifications", width: 150 },
    // { field: "namePrefix", headerName: "Current Institution", width: 150 },
    { field: "ci", headerName: "Current Institution", width: 150 },
    // { field: "lname", headerName: "Desired University", width: 150 },
    { field: "du", headerName: "Desired University", width: 150 },
    // { field: "dob", headerName: "Admission Intake", width: 120 },
    { field: "di", headerName: "Admission Intake", width: 120 },
    { field: "specific", headerName: "Specific Query", width: 120 },
    { field: "points", headerName: "Score", width: 110 },
    {
      field: "actions",
      headerName: "Actions",
      width: 190,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="View" placement="top">
              <RemoveRedEyeIcon
                color="primary"
                sx={{ mr: 0.5, cursor: "pointer" }}
                onClick={() => {
                  getLead(cellValues?.row);
                }}
              />
            </Tooltip>

            {cellValues?.row?.data.LeadMoveToQuery === false ?
              <Tooltip title="Proceed to Query" placement="top">
                <NavigateNextIcon
                  fontSize="large"
                  color="primary"
                  sx={{ cursor: "pointer", marginLeft: "10px", marginRight: "-5px" }}
                  onClick={() => {
                    setModal(true);
                    getProceedData(cellValues?.row);
                  }}
                />
              </Tooltip>
              :
              null
            }
            <Box>
              <Tooltip
                title="Assign to"
                onClick={() => {
                  handleOpen();
                  setLeadId(cellValues?.row?.data?._id);
                }}
                placement="top"
              >
                <LinkIcon
                  fontSize="large"
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    marginLeft: "15px",
                    marginTop: "8px"
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const gFormList = leads
    ?.filter((item) => !item?.manualForm && item?.googleForm)
    ?.map((item, index) => {
      const findAnswerByTitle = (title) => {
        const questionItem = item?.googleForm?.items.find((item) => item?.title === title);
        if (questionItem) {
          // console.log("questionItem", questionItem);
          return item?.googleForm?.answers.find((answer) => answer?.questionId === questionItem?.questionItem?.question?.questionId);
        }
        return null;
      };
      const fnameAnswer = findAnswerByTitle("Name");
      const ageAnswer = findAnswerByTitle("Age");
      const mobileNoAnswer = findAnswerByTitle("Contact Number");
      const cityResidentAnswer = findAnswerByTitle("Which city do you reside in?");
      const currentQualifyAnswer = findAnswerByTitle("Current Qualifications");
      const currentInstitutionAnswer = findAnswerByTitle("What institution are you currently studying in?");
      const desiredUniAnswer = findAnswerByTitle("Desired University ?");
      const admissionIntake = findAnswerByTitle("Admission Intake ");
      const specificQuery = findAnswerByTitle("Specific query?");

      const fnameValue = fnameAnswer ? fnameAnswer.textAnswers?.answers[0]?.value : "N/A";
      const ageValue = ageAnswer ? ageAnswer.textAnswers?.answers[0]?.value : "N/A";
      const mobileNoValue = mobileNoAnswer ? mobileNoAnswer.textAnswers?.answers[0]?.value : "N/A";
      const cityResidentValue = cityResidentAnswer ? cityResidentAnswer.textAnswers?.answers[0]?.value : "N/A";
      const currentQualifyValue = currentQualifyAnswer ? currentQualifyAnswer.textAnswers?.answers[0]?.value : "N/A";
      const currentInstitutionValue = currentInstitutionAnswer ? currentInstitutionAnswer.textAnswers?.answers[0]?.value : "N/A";
      const desiredUniValue = desiredUniAnswer ? desiredUniAnswer.textAnswers?.answers[0]?.value : "N/A";
      const admissionIntakeValue = admissionIntake ? admissionIntake.textAnswers?.answers[0]?.value : "N/A";
      const specificQueryValue = specificQuery ? specificQuery.textAnswers?.answers[0]?.value : "N/A";

      const answers = item?.googleForm?.answers.map((answer) => answer.textAnswers?.answers[0]?.value);
      return {
        id: index,
        status: item?.isProcess === true ? "In Process" :
          item?.isClosed === true ? "Lead Closed" :
            item?.LeadMoveToQuery === true ? "Moved to Query" :
              "Not Started" || "N.A",
        owner: item?.assignTo?.name || "N/A",
        ci: currentInstitutionValue,
        email: item?.googleForm?.email,
        points:
          item?.googleForm?.totalPoints < 10
            ? "Low"
            : item?.googleForm?.totalPoints >= 10 && item?.googleForm?.totalPoints < 20
            ? "Medium"
            : item?.googleForm?.totalPoints >= 20
            ? "Hot"
            : "N/A",
        fname: fnameValue,
        age: ageValue,
        source: item?.manualForm ? "Manual" : "Google Form",
        du: desiredUniValue,
        mobile: mobileNoValue,
        city: cityResidentValue,
        cq: currentQualifyValue,
        di: admissionIntakeValue,
        specific: specificQueryValue,
        data: item,
        answers: answers || [],
      };
    });

  // For Handleing Filter Method in Google Leads
  const [filterModel, setFilterModel] = useState({ items: [] });

  const handleCustomFilter = () => {
    const newFilterModel = {
      items: [
        {
          columnField: "email",
          operatorValue: "equals",
          value: location.search.substring(1),
        },
      ],
    };
    setFilterModel(newFilterModel);
  };

  useEffect(() => {
    handleCustomFilter();
  }, [location]);

  return (
    <>
      <Box sx={{ mx: 2, my: 3 }}>
        <DataGrid
          sx={{
            height: `700px`,
          }}
          rows={gFormList}
          columns={tableColumns}
          filterModel={filterModel}
          onFilterModelChange={(x) => setFilterModel(x)}
        />
      </Box>

      {/* Modal For AssigneTo Functionality  */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assign To
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Assigne Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label=" Assigne Name" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
            >
              {loadUserOptions((user) => user.role === "Counselor" || user?.email === "sana@successfactor.org", users, handleOptionClick)}
            </Select>
          </FormControl>
        </Box>
      </Modal>

      {/* Lead Status Modal */}
      <Modal open={StatusModal} onClose={StatusModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Lead Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Lead Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Lead Status" />}
            >
              <MenuItem onClick={handleProcessLead}>In Process</MenuItem>
              <MenuItem onClick={handleCloseLead}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default GoogleLead;
