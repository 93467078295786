import React from "react";

const ApplicationUI = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>Total Applications</div>

          <div>3</div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>Heat Level</div>

          <div style={{ color: "rgba(67, 67, 67, 1)" }}>Red</div>
        </div>

        <div>Task</div>
      </div>
    </div>
  );
};

export default ApplicationUI;
