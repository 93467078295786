import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, StepButton, StepLabel, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import { calculateActiveStep } from "./StatusCaclulator";

const steps = ["Awaiting Student Response", "Response Received"];
// const steps = ["Awaiting Student Response", "Response Received", "Approved"];
const Response = ({ setValue, setDisabledTab, disabledTab, onUpdate, data, student, studentTimelineUpdate }) => {
  const [activeStep, setActiveStep] = useState(calculateActiveStep(data, "response", ["completed"]));
  // const nextStep = () => {
  //   setActiveStep(activeStep + 1);
  // };
  const [decision, setDecision] = useState("");

  const closeApplication = () => {
    const user = getLoggedInUser();
    onUpdate({}, data._id);
  };

  const completed = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        response: {
          studentResponse: {
            response: decision,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    studentTimelineUpdate("Student response", "Submitted");
    // setActiveStep(1);
  };
  const nextTab = () => {
    setDisabledTab((prevData) => ({ ...prevData, tab4: false }));
    setValue(4);
  };
  const approved = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        response: {
          approved: {
            approvedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    // setActiveStep(2);
    // nextTab();
  };

  const handleChange = (event) => {
    const data = event.target.value;
    setDecision(data);
  };
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack
            spacing={2}
            alignItems="flex-start"
            sx={{
              px: 4,
            }}
          >
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Decision</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={decision}
                label="Select Decision"
                onChange={handleChange}
              >
                <MenuItem value="Accept Offer">Accept Offer</MenuItem>
                <MenuItem value="Request Defer">Request Defer</MenuItem>
                <MenuItem value="Reject">Reject</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={completed}
              sx={{
                position: "absolute",
                right: "2%",
                bottom: "0%",
              }}
            >
              Submit
            </Button>
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2} alignItems="flex-start">
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}

            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={approved}>
                Approve
              </Button>
              {/* <Button variant="outlined">Revert</Button> */}
            </Stack>
          </Stack>
        );

      default:
        return;
    }
  };
  const approvedDateObj = new Date(data.response?.approved?.date);
  const approvedDate = approvedDateObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });
  const handleData = (step) => {
    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              px: 10,
              pt: 2,
              "@media (max-width: 950px)": {
                p: "0px !important",
              },
            }}
          >
            {/* <Typography variant="subtitle1">Assigned to: {student?.appOfficer?.name}</Typography> */}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              variant="subtitle1"
            >
              {" "}
              Submitted By
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data?.response?.studentResponse?.submittedBy.name}
              </span>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              variant="subtitle1"
            >
              {" "}
              Student Response
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data?.response?.studentResponse?.response}
              </span>
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="subtitle1"> Approved By: {data?.response?.approved?.approvedBy?.name}</Typography>
            <Typography variant="subtitle1"> Date: {approvedDate}</Typography>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Button variant="contained">Close Application</Button>
          </Box>
        );
      default:
        return "";
    }
  };

  const renderCompleted = (firstObj, secondObj) => {
    return (
      <Box>
        <Typography variant="subtitle1">
          {" "}
          {firstObj.title} {firstObj.value}
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          {secondObj.title} {secondObj.value}
        </Typography>
      </Box>
    );
  };

  const [stepNumber, setStepNumber] = useState(0);
  useEffect(() => {
    setActiveStep(calculateActiveStep(data, "response", ["completed"]));
  }, [{ data }]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, idx) => (
          <Step key={label} disabled={data.stage > 3 ? false : true}>
            <StepLabel>{label}</StepLabel>
            {/* <StepButton onClick={() => setStepNumber(idx)}>{label}</StepButton> */}
          </Step>
        ))}
      </Stepper>
      {data.stage > 3 ? <Box mt={4}>{handleData(stepNumber)}</Box> : <Box mt={4}>{handleSteps(activeStep)}</Box>}
    </Box>
  );
};

export default Response;
