import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useState } from "react";
import ScheduleForm from "./ScheduleForm";

const Schedule = ({ setSchedule, singleEnquiry }) => {
  console.log(singleEnquiry)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const tableColumns = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time" },
    { id: "mode", label: "Mode" },
  ];

  const tableData = singleEnquiry?.schedule?.map((item) => {
    return {
      date: item?.date,
      time: item?.time,
      mode: item?.mode,
    };
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: ".5rem",
        }}
      >
        {!open && (
          <Button variant="contained" onClick={handleOpen}>
            Add Schedule
          </Button>
        )}
      </Box>
      {open ? (
        <Box>
          <ScheduleForm handleClose={handleClose} setSchedule={setSchedule} singleEnquiry={singleEnquiry} />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      backgroundColor: "rgba(25, 104, 173, 1)!important",
                      color: "#fff",
                      [`@media(max-width:780px)`]: { fontSize: "0.7rem" },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow key={index}>
                  {tableColumns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Schedule;
