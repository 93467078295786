import { isJSON } from "./isJSON";
export function getLoggedInUser() {
  let user = localStorage.getItem("user");
  if (user) {
    let loggedInUser = isJSON(user);
    return loggedInUser;
  }
}

export function clearLoggedInUser() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
}
