import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Link,
} from "@mui/material";
import { ArrowBack, Reply } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { formatDateAndTime } from "../../../../utils/formatData";
import { useParams } from "react-router-dom";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";

const MessageDetail = ({ message, onBackClick, mail, actionType, onMailSend, getMail, uploadAttachments }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [showThreads, setShowThreads] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();

  const toggleReply = () => {
    setIsReplying(!isReplying);
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(""))),
  );
  const [convertedContent, setConvertedContent] = useState("");

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  // console.log(mail);

  const openFile = (url) => {
    handleOpen();
    setFileUrl(url);
  };

  const goBack = () => {
    getMail(mail?.id);
    toggleReply();
  };

  return (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ mb: 5 }}>
            <Button variant="contained" onClick={onBackClick}>
              Back to Inbox
            </Button>
          </Box>
          <Box sx={{ mb: 5 }}>
            {isReplying ? (
              <Button variant="contained" onClick={goBack}>
                <ArrowBack /> Back
              </Button>
            ) : (
              <Button variant="contained" onClick={toggleReply}>
                <Reply /> Reply
              </Button>
            )}
          </Box>
        </Box>

        {!isReplying ? (
          <Box>
            {actionType == "GMAIL_READ_SUCCESS" || actionType == "GMAIL_CREATE_SUCCESS" ? (
              <Box>
                <Typography variant="h5">{mail?.subject}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb: 2 }}>
                  <Typography variant="subtitle1">
                    From: {mail?.from?.senderName}{" "}
                    <span
                      style={{
                        fontWeight: "200",
                        fontSize: ".8rem",
                        color: "#9c9c9c",
                      }}
                    >
                      {`<${mail?.from?.senderEmail}>`}
                    </span>
                  </Typography>
                  <Typography variant="subtitle2">Date: {formatDateAndTime(mail?.date)}</Typography>
                </Box>

                <Box
                  dangerouslySetInnerHTML={createMarkup(mail?.body)}
                  sx={{
                    wordWrap: "break-word",
                  }}
                ></Box>
                {mail?.filenames?.length > 0 && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Accordion>
                      <AccordionSummary>
                        <Typography variant="h5">Attachments</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {mail?.filenames?.map((file, index) => {
                          return (
                            <Box key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="subtitle2">{file}</Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: ".5rem",
                                    alignItems: "center",
                                  }}
                                >
                                  <RemoveRedEyeIcon
                                    color="primary"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => openFile(studentFileDownloadPath(id, file))}
                                  />

                                  <Link target="_blank" rel="noopener" download href={studentFileDownloadPath(id, file)}>
                                    <DownloadIcon color="primary" />
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
                <Box
                  sx={{
                    mt: "1rem",
                  }}
                >
                  {mail?.threads?.length > 0 && showThreads == false && <Button onClick={() => setShowThreads(true)}>Show threads</Button>}
                  {showThreads && <Button onClick={() => setShowThreads(false)}>Hide threads</Button>}
                </Box>
                {showThreads && (
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    {mail?.threads?.map((thread) => {
                      return (
                        <Accordion
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                        >
                          <AccordionSummary
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                mr: "auto",
                              }}
                            >
                              {thread?.sender?.senderName}{" "}
                              <span
                                style={{
                                  fontWeight: "200",
                                  fontSize: ".8rem",
                                  color: "#9c9c9c",
                                }}
                              >
                                {`<${thread?.sender?.senderEmail}>`}
                              </span>
                            </Typography>
                            <Typography variant="subtitle2"> {formatDateAndTime(thread?.date)}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              dangerouslySetInnerHTML={createMarkup(thread?.data)}
                              sx={{
                                wordWrap: "break-word",
                              }}
                            ></Box>
                            <Box>
                              {/* {thread?.files?.map((file) => {
                                return <Typography>{file?.filename}</Typography>;
                              })} */}
                              <Accordion>
                                <AccordionSummary>
                                  <Typography variant="h5">Attachments</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {thread?.files?.map((file, index) => {
                                    return (
                                      <Box key={index}>
                                        {file?.filename !== "" && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "1rem",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography variant="subtitle2">{file?.filename}</Typography>

                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: ".5rem",
                                                alignItems: "center",
                                              }}
                                            >
                                              <RemoveRedEyeIcon
                                                color="primary"
                                                sx={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => openFile(studentFileDownloadPath(id, file?.filename))}
                                              />

                                              <Link
                                                target="_blank"
                                                rel="noopener"
                                                download
                                                href={studentFileDownloadPath(id, file?.filename)}
                                              >
                                                <DownloadIcon color="primary" />
                                              </Link>
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                    );
                                  })}
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                )}
              </Box>
            ) : (
              <h3>Loading...</h3>
            )}
          </Box>
        ) : (
          <Box mt={2}>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                options: ["inline", "blockType", "list", "textAlign", "remove", "history"],
                inline: {
                  inDropdown: false,
                  options: ["bold", "italic", "underline", "strikethrough", "monospace"],
                },
                list: { inDropdown: false },
                textAlign: { inDropdown: false },
                link: { inDropdown: false },
                history: { inDropdown: false },
                blockType: {
                  inDropdown: false,
                  options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
                },
              }}
            />
            <Box
              sx={{
                mt: "1rem",
              }}
            >
              <Button
                sx={{
                  zIndex: 2,
                  py: 1,
                }}
                component="label"
                variant="outlined"
                color="inherit"
              >
                Upload Attachments
                <input name="uploadRefusalLetter" type="file" multiple hidden onChange={uploadAttachments} />
              </Button>
            </Box>
          </Box>
        )}

        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {isReplying && (
            <Button
              onClick={() => onMailSend(convertedContent, mail.id, mail.messageId, mail?.subject, mail?.from?.senderEmail)}
              variant="contained"
              color="primary"
            >
              Send
            </Button>
          )}
        </Box>
      </Paper>

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "80%",
            width: "80%",
            background: "white",
          }}
        >
          <iframe src={`${fileUrl}`} frameborder="0" height="100%" width="100%"></iframe>
        </Box>
      </Modal>
    </Container>
  );
};

export default MessageDetail;
