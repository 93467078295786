import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const Reference = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  return (
    <Stack
      sx={{
        // display: "grid",
        // gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
        gap: "1rem",
        ["@media (max-width: 775px)"]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >

      <Box>
        <DataWrapper heading={`Reference`}>
          {student?.registrationData?.reference?.map((item, index) => {
            return (
              <>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Reference Name: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.referenceName || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="referenceName"
                      text={item?.referenceName || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Reference Type: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.referenceType?.label || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="referenceType"
                      text={item?.referenceType?.label || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Reference Institution:{" "}
                    <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.referenceInstitution || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="referenceInstitution"
                      text={item?.referenceInstitution || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Institution Address: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.institutionAddress || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="institutionAddress"
                      text={item?.institutionAddress || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Email Address: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.emailAddress || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="emailAddress"
                      text={item?.emailAddress || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Designation: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.designation || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="designation"
                      text={item?.designation || "N/A"}
                    />
                  )}
                </Typography>
                <Typography
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // borderBottom: "1px solid #E0E0E0",
                    pb: "5px",
                    mb: "1rem",
                  }}
                >
                  <span>
                    Contact No: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.contactNo || "N/A"}</span>
                  </span>
                  {appCheck && (
                    <AppCheckActions
                      user={user}
                      setRevertData={setRevertData}
                      allData={data}
                      confirm={confirm}
                      copy={copy}
                      submitted={submitted}
                      data="contactNo"
                      text={item?.contactNo || "N/A"}
                    />
                  )}
                </Typography>
                {index < student?.registrationData?.reference?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
              </>
            );
          })}
        </DataWrapper>
      </Box>

    </Stack>
  );
};

export default Reference;
