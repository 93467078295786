import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ApplicationCard from "./ApplicationCard";
import { RemoveRedEye } from "@mui/icons-material";

const ClosedApplications = ({ applications, showCard, selectedTab, selectedOption, pageLocation }) => {
  const closedApplications = applications.filter((item) => {
    return item?.closed?.closed;
  });

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "universityName", headerName: "University Name", width: 200 },
    { field: "intendedCourse", headerName: "Intended Course", width: 200 },
    { field: "intake", headerName: "InTake", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      hide: selectedTab == 5 ? false : true,
      disableClickEventBubbling: true,
      renderCell: (cellValue) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="View application" placement="top">
              <Button
                disabled={cellValue?.row?.disable ? true : false}
                onClick={() => selectedOption([cellValue?.row?.id])}
                sx={{
                  p: 0,
                }}
              >
                <RemoveRedEye
                  fontSize="medium"
                  color={cellValue?.row?.disable ? "disabled" : "primary"}

                />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const formattedRows = closedApplications?.map((x) => {
    return {
      id: x?.formId, // Unique ID for each row, you can modify this based on your data
      universityName: x?.appData?.university?.label || "N/A", // Replace 'N/A' with a default value if undefined
      intendedCourse: x?.appData?.course?.label || "N/A",
      intake: x?.appData?.intake?.label || "N/A",
      status: x?.appData?.year || "N/A",
      disable: pageLocation == "processing" ? false : true,
      // Add other fields as needed, mapping them from your data
    };
  });

  // const transformedData = closedApplications?.map((item, index) => ({
  //   id: item?.formId,
  //   universityName: item?.appData?.university?.label,
  //   intendedCourse: item?.appData?.course?.label,
  //   intake: item?.appData?.intake?.label,
  //   year: item?.appData?.year,
  //   delete: "delete",
  // }));

  // const columns = [
  //   { field: "id", headerName: "Application Id", width: 200 },
  //   { field: "universityName", headerName: "University Name", width: 230 },
  //   { field: "intendedCourse", headerName: "Intended course", width: 230 },
  //   { field: "intake", headerName: "Intake", width: 230 },
  // ];
  // const rows = transformedData;

  return (
    <>
      {showCard ? (
        <>
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "row",
              width: "100%",
              gap: "1rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              // justifyContent:'center',

              // overflowX: "scroll !important",
              // scrollSnapType: "x mandatory",
              // whiteSpace: "nowrap",
              paddingBottom: ".5rem",
              // "@media (max-width: 1440px)": {
              //   display: 'grid',
              //   gridTemplateColumns: "1fr 1fr",
              //   justifyContent:'center'
              // },

              "@media (max-width: 1600px)": {
                gridTemplateColumns: "1fr 1fr 1fr",
                justifyContent: "center",
              },
              "@media (max-width: 1440px)": {
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "center",
              },
              "@media (max-width: 1024px)": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr ",
                justifyContent: "center",
              },
              // "@media (max-width: 768px)": {
              //   gridTemplateColumns: "1fr ",
              //   justifyContent: "center",
              // },
              "@media (max-width: 550px)": {
                display: "grid",
                gridTemplateColumns: "1fr",
                justifyContent: "center",
              },
              // "@media (max-width: 375px)": {
              //   display: "grid",
              //   gridTemplateColumns: "1fr",
              //   justifyContent: "center",
              // },
              // style a scrollbar
              // "&::-webkit-scrollbar": {
              //   height: "0.5rem",
              //   width: "0.5rem",
              // },
              // "&::-webkit-scrollbar-track": {
              //   borderRadius: "10px",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   backgroundColor: "primary.main",
              //   borderRadius: "10px",
              // },
            }}
          >
            {formattedRows?.map((item, index) => (
              <ApplicationCard key={index} data={item} showCard={showCard} />
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={formattedRows}
                columns={columns}
                headerClassName="customHeader"
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={false}
                sx={{
                  // '& .customHeader .MuiDataGrid-colCell': {
                  //   // backgroundColor: 'rgb(25,104,173)', // Change the background color of the header cells
                  //   color: 'rgb(25,104,173)'
                  // },

                  "& .MuiDataGrid-columnHeader": {
                    // backgroundColor: 'rgb(25,104,173)', // Change the background color of the header cells
                    color: "rgb(25,104,173)",
                  },
                  // '& .MuiDataGrid-cell': {
                  //   borderBottom: '1px solid rgb(25,104,173)', // Add a border to all cells
                  //   color: 'black',
                  //   fontSize: '16px'
                  // },
                  // '& .MuiDataGrid-colCell': {
                  //   backgroundColor: 'black', // Add a border to all cells
                  //   color: 'black',
                  //   fontSize: '16px'
                  // },
                  // '& .MuiDataGrid-row': {
                  //   '&:nth-of-type(odd)': {
                  //     backgroundColor: 'rgba(0, 0, 0, 0.05)', // Alternate row color
                  //   },
                  // },
                }}
              />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default ClosedApplications;
