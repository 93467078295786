import React from "react";
import { Box, Typography } from "@mui/material";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const CountryOfInterest = ({ student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  return (
    <DataWrapper heading={"Country Of Interest"}>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          First Choice:{" "}
          <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.firstChoice?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="firstChoice"
            text={student?.registrationData?.firstChoice?.label || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Second Choice:{" "}
          <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.secondChoice?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="secondChoice"
            text={student?.registrationData?.secondChoice?.label || "N/A"}
          />
        )}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Third Choice:{" "}
          <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.thirdChoice?.label || "N/A"}</span>
        </span>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="thirdChoice"
            text={student?.registrationData?.thirdChoice?.label || "N/A"}
          />
        )}
      </Typography>
    </DataWrapper>
  );
};

export default CountryOfInterest;
