import { combineReducers } from "redux";

import {ajaxStatusReducer} from "./ajaxStatusReducer.js";
import { commonReducers } from "../../reduxCURD/reducer.js";
const rootReducer = combineReducers({
  ...commonReducers,
  ajaxStatusReducer,
});

export default rootReducer;
