import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import FinanceTopBar from "./FinanceTopBar";
import FinanceForm from "./FinanceForm";
const columns = [
  { field: "financialyear", headerName: "Financial Year", width: 170 },
  { field: "registrationType", headerName: "Registration Type", width: 170 },
  { field: "registrationno", headerName: "Registration No.", width: 170 },
  { field: "registrationdate", headerName: "Registration Date", width: 170 },
  { field: "counselor", headerName: "Counselor", width: 170 },
  { field: "sfbranch", headerName: "SF Branch", width: 170 },
  { field: "dob", headerName: "D.O.B", width: 170 },
  { field: "studentid", headerName: "Student I.D", width: 170 },
  { field: "studentname", headerName: "Student Name", width: 170 },
  { field: "Country", headerName: "Country", width: 170 },
  { field: "Currency", headerName: "Currency", width: 170 },
  { field: "Currency Rate", headerName: "Currency Rate", width: 170 },
  { field: "Invoice No.", headerName: "Invoice No.", width: 170 },
  { field: "Centre", headerName: "Centre", width: 170 },
  { field: "University", headerName: "University", width: 170 },
  { field: "Session", headerName: "Session", width: 170 },
  { field: "Pipeline Status", headerName: "Pipeline Status", width: 170 },
  { field: "Revenue/Non Revenue", headerName: "Revenue/Non Revenue", width: 170 },
  { field: "Study Level", headerName: "Study Level", width: 170 },
  { field: "Course", headerName: "Course", width: 170 },
  { field: "Course Start Date", headerName: "Course Start Date", width: 170 },
  { field: "Course End Date", headerName: "Course End Date", width: 170 },
  { field: "Tuition Fee", headerName: "Tuition Fee", width: 170 },
  { field: "Tuition Fee in PKR", headerName: "Tuition Fee in PKR", width: 170 },
  { field: "Scholarship", headerName: "Scholarship", width: 170 },
  { field: "Initial Deposit", headerName: "Initial Deposit", width: 170 },
  { field: "Rate", headerName: "Rate", width: 170 },
  { field: "Invoice Amount in FC", headerName: "Invoice Amount in FC", width: 170 },
  { field: "Amount Received in FC", headerName: "Amount Received in FC", width: 170 },
  { field: "Bank Charges in FC", headerName: "Bank Charges in FC", width: 170 },
  { field: "Bad Debt in FC", headerName: "Bad Debt in FC", width: 170 },
  { field: "Receivable in FC", headerName: "Receivable in FC", width: 170 },
  { field: "Estimate in PKR", headerName: "Estimate in PKR", width: 170 },
  { field: "Invoice Amount In PKR", headerName: "Invoice Amount In PKR", width: 170 },
  { field: "Amount Received in PKR", headerName: "Amount Received in PKR", width: 170 },
  { field: "Bank Charges in PKR", headerName: "Bank Charges in PKR", width: 170 },
  { field: "Bad Debt in PKR", headerName: "Bad Debt in PKR", width: 170 },
  { field: "Receivable in PKR", headerName: "Receivable in PKR", width: 170 },
  { field: "Gain/Lose", headerName: "Gain/Lose", width: 170 },
  { field: "Gain/Lose %", headerName: "Gain/Lose %", width: 170 },
  { field: "Invoice Status", headerName: "Invoice Status", width: 170 },
  { field: "Invoice Expected", headerName: "Invoice Expected", width: 170 },
  { field: "Comments", headerName: "Comments", width: 170 },
  { field: "Receipts", headerName: "Receipts", width: 170 },
  { field: "Payment Timeline", headerName: "Payment Timeline", width: 170 },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const FinanceTable = () => {
  const [modal, setModal] = useState(false);
  return (
    <Box sx={{ height: 400, width: "100%", marginTop: "90px" }}>
      <FinanceTopBar setModal={setModal} />
      <DataGrid rows={rows} columns={columns} pageSize={5} />

      {modal && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <FinanceForm />
        </Box>
      )}
    </Box>
  );
};

export default FinanceTable;
