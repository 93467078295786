import { Box, Link, Typography } from "@mui/material";
import React from "react";
import DOMPurify from "dompurify";
import { studentFileDownloadPath } from "../../../utils/studentFileDownloadUtil";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const PersonalStatementData = ({ appData, student, confirm, data, copy, submitted, setRevertData, user, appCheck }) => {
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  // console.log(appData);

  return (
    <Box>
      {/* <DataWrapper heading={"Personal Statement"}>
        <Box dangerouslySetInnerHTML={createMarkup(appData?.personalStatement)}></Box>
        {appCheck && (
          <AppCheckActions
            user={user}
            setRevertData={setRevertData}
            allData={data}
            confirm={confirm}
            copy={copy}
            submitted={submitted}
            data="personalStatement"
            text={appData?.personalStatement || "N/A"}
          />
        )}
      </DataWrapper> */}
      <DataWrapper heading={"Additional"}>
        {/* <Box>
          {appData?.personalStatementFile ? (
            <Link
              target="_blank"
              rel="noopener"
              download
              href={studentFileDownloadPath(student?.registeration, appData?.personalStatementFile)}
            >
              {" "}
              {appData?.personalStatementFile}
            </Link>
          ) : (
            "N/A"
          )}
        </Box> */}
        <Typography
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <span>
            Application Deadline: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.applicationDeadline || "N/A"}</span>
          </span>
          {appCheck && (
            <AppCheckActions
              user={user}
              setRevertData={setRevertData}
              allData={data}
              confirm={confirm}
              copy={copy}
              submitted={submitted}
              data="applicationDeadline"
              text={appData?.applicationDeadline || "N/A"}
            />
          )}
        </Typography>
        <Typography
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <span>
            Additional Requirement:{" "}
            <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.additionalRequirement || "N/A"}</span>
          </span>
          {appCheck && (
            <AppCheckActions
              user={user}
              setRevertData={setRevertData}
              allData={data}
              confirm={confirm}
              copy={copy}
              submitted={submitted}
              data="additionalRequirement"
              text={appData?.additionalRequirement || "N/A"}
            />
          )}
        </Typography>
        {/* <Typography
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <span>
            Additional Information:{" "}
            <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.additionalInformation || "N/A"}</span>
          </span>
          {appCheck && (
            <AppCheckActions
              user={user}
              setRevertData={setRevertData}
              allData={data}
              confirm={confirm}
              copy={copy}
              submitted={submitted}
              data="additionalInformation"
              text={appData?.additionalInformation || "N/A"}
            />
          )}
        </Typography> */}
        <Typography
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <span>
            Scholarship: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.scholarship || "N/A"}</span>
          </span>
          {appCheck && (
            <AppCheckActions
              user={user}
              setRevertData={setRevertData}
              allData={data}
              confirm={confirm}
              copy={copy}
              submitted={submitted}
              data="scholarship"
              text={appData?.scholarship || "N/A"}
            />
          )}
        </Typography>
        {/* <Typography
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            pb: "5px",
            mb: "1rem",
          }}
        >
          <span>
            Scholarship Deadline: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{appData?.scholarshipDeadline || "N/A"}</span>
          </span>
          {appCheck && (
            <AppCheckActions
              user={user}
              setRevertData={setRevertData}
              allData={data}
              confirm={confirm}
              copy={copy}
              submitted={submitted}
              data="scholarshipDeadline"
              text={appData?.scholarshipDeadline || "N/A"}
            />
          )}
        </Typography> */}
      </DataWrapper>
    </Box>
  );
};

export default PersonalStatementData;
