export const multiFileAccessorUtil = (obj, fa, sa) => {
  if (!obj || !fa || !sa) return [];
  let fileIndex = 0;
  let safetyStop = 10000;
  let stop = false;
  let result = [];
  while (!stop && fileIndex < safetyStop) {
    let file = obj[`${fa}.${fileIndex}.${sa}`];
    if (!file) stop = true;
    result.push(file);
    fileIndex++;
  }
  return result;
};
