import { Box, Button, Step, StepLabel, Stepper, TextField, Typography, Stack } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider, useFormContext, Controller } from "react-hook-form";

const steps = ["Basic Info", "Portal Details", "Point Of Contact"];

const AgreementForm = ({ setShowForm, setAgreementData, agreementData, setSuccessOpen }) => {
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm({
    defaultValues: {
      universityName: "",
      commencementDate: "",
      expirationDate: "",
      grossNetTuitionFee: "",
      rateApplied: "",
      tierBaseSystemForRates: "",
      bonusIncentiveForAParticularSession: "",
      invoiceDeadlineForASession: "",
      portalUrl: "",
      portalEmail: "",
      portalPassword: "",
      name: "",
      email: "",
      phoneNo: "",
    },
  });

  const BasicInfo = () => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    return (
      <Box>
        <Stack mb={2}>
          <Controller
            control={control}
            name="universityName"
            rules={{
              required: "University Name is required",
            }}
            render={({ field }) => (
              <TextField
                label="University Name"
                error={Boolean(errors.universityName)}
                helperText={errors.universityName?.message}
                id="outlined-basic"
                type="text"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: "50%" }}>
            <Typography>Commencement Date</Typography>
            <Controller
              control={control}
              name="commencementDate"
              rules={{
                required: "Commencement Date is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.commencementDate)}
                  helperText={errors.commencementDate?.message}
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <Typography>Expiration Date</Typography>
            <Controller
              control={control}
              name="expirationDate"
              rules={{
                required: "Expiration Date is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.expirationDate)}
                  helperText={errors.expirationDate?.message}
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2}>
          <Stack sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="grossNetTuitionFee"
              rules={{
                required: "Gross/Net Tuition Fee Date is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.grossNetTuitionFee)}
                  helperText={errors.grossNetTuitionFee?.message}
                  id="outlined-basic"
                  label="Gross/Net Tuition Fee"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="rateApplied"
              rules={{
                required: "Rate Applied is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.rateApplied)}
                  helperText={errors.rateApplied?.message}
                  id="outlined-basic"
                  label="Rate Applied"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2}>
          <Stack sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="tierBaseSystemForRates"
              rules={{
                required: "Tier Base System For Rates is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.tierBaseSystemForRates)}
                  helperText={errors.tierBaseSystemForRates?.message}
                  id="outlined-basic"
                  label="Tier Base System For Rates"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <Controller
              control={control}
              name="bonusIncentiveForAParticularSession"
              rules={{
                required: "Bonus Incentive For A Particular Session is required",
              }}
              render={({ field }) => (
                <TextField
                  error={Boolean(errors.bonusIncentiveForAParticularSession)}
                  helperText={errors.bonusIncentiveForAParticularSession?.message}
                  id="outlined-basic"
                  label="Bonus Incentive For A Particular Session"
                  type="text"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack mt={2}>
          <Controller
            control={control}
            name="invoiceDeadlineForASession"
            rules={{
              required: "Invoice Deadline For A Session is required",
            }}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.invoiceDeadlineForASession)}
                helperText={errors.invoiceDeadlineForASession?.message}
                id="outlined-basic"
                label="Invoice Deadline For A Session"
                type="text"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Stack>
      </Box>
    );
  };
  const PortalDetails = () => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    return (
      <Stack spacing={2}>
        <Controller
          control={control}
          name="portalUrl"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.portalUrl)}
              helperText={errors.portalUrl?.message}
              id="outlined-basic"
              label="Portal Url"
              type="url"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="portalEmail"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.portalEmail)}
              helperText={errors.portalEmail?.message}
              id="outlined-basic"
              label="Portal Email"
              type="email"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="portalPassword"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.portalPassword)}
              helperText={errors.portalPassword?.message}
              id="outlined-basic"
              label="Portal Password"
              type="text"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
      </Stack>
    );
  };

  const PointOfContact = () => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    return (
      <Stack spacing={2}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              id="outlined-basic"
              label="Name"
              type="text"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNo"
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.phoneNo)}
              helperText={errors.phoneNo?.message}
              id="outlined-basic"
              label="Phone Number"
              type="text"
              variant="outlined"
              sx={{ width: "100%" }}
              {...field}
            />
          )}
        />
      </Stack>
    );
  };

  const currentSchema = (step) => {
    switch (step) {
      case 0:
        return <BasicInfo />;
      case 1:
        return <PortalDetails />;
      case 2:
        return <PointOfContact />;
      default:
        return null;
    }
  };
  const generateUniqueId = () => {
    const uniqueId = Math.floor(Math.random() * 9000) + 1000;
    return uniqueId;
  };
  const id = generateUniqueId();
  const onSubmit = (data) => {
    if (activeStep === steps.length - 1) {
      setAgreementData([...agreementData, { ...data, id }]);
      setShowForm(false);
      setSuccessOpen(true);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const onCancel = () => {
    setShowForm(false);
  };

  return (
    <Box sx={{ bgcolor: "white", p: 3, width: "75rem", height: "35rem", borderRadius: "1rem", overflowY: "scroll" }}>
      <Typography fontSize="1.5rem" mb={2}>
        New Agreement
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
            {currentSchema(activeStep)}
            <Stack mt={2} direction="row" spacing={2}>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={() => setActiveStep(activeStep - 1)}>
                  Back
                </Button>
              )}
              <Button variant="contained" type="submit">
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Stack>
          </form>
        </FormProvider>
        {/* <ReusableForm
          schema={currentSchema(activeStep)}
          onSubmit={onSubmit}
          onCancel={onCancel}
          customButtons={() => {
            return (
              <Button variant="outlined" disabled={activeStep < 1} onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Button>
            );
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default AgreementForm;
