import React from "react";
import DataWrapper from "../../StudentProfile/ProfileData/DataWrapper";
import { Box, Typography } from "@mui/material";

const Intake = ({ singleEnquiry }) => {
  return (
    <DataWrapper heading={"Intake"}>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Country Preference:{" "}
          <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.countryPreference?.label || "N/A"}</span>
        </span>
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Intake: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.intake?.label || "N/A"}</span>
        </span>
      </Typography>

      <Typography
        fontWeight="medium"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E0E0E0",
          pb: "5px",
          mb: "1rem",
        }}
      >
        <span>
          Year: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{singleEnquiry?.year || "N/A"}</span>
        </span>
      </Typography>
    </DataWrapper>
  );
};

export default Intake;
