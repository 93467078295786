import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import LockIcon from "@mui/icons-material/Lock";
import Typography from "@mui/material/Typography";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, FormHelperText, Menu, MenuItem } from "@mui/material";
import SubTasks from "./SubTasks";
import moment from "moment";
import SubTasksdetails from "./SubTasksDetails";
import { blue } from "@mui/material/colors";
import { guid } from "../../utils/UUID";
import { getLoggedInUser } from "../../utils/loggedInUser";
import { loadUserOptions } from "../../BussinessLogic/Tasks/TaskAssignTo";
const TaskSidebars = ({ closeSidebar, task, onTaskUpdate, users, handleDeleteTask }) => {
  const [inputsubTask, setinputsubTask] = useState("");
  const [CommentValue, setCommentValue] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [open, setOpen] = useState(false);
  const [changed, setChange] = useState(false);
  const [changeObject, setChangeObject] = useState({});

  /* 
 #Tasks TODO :
 1- when opened, load the information from the task.
 2- all info : comments, due date, assignedTo user should be loaded from task. local state of changed object (this will be prefered over task) can be kept to manage the change.
 3- when any field is changed, the chage state will also be true along with the local state of changed object updated. Update button should also be added to s and enabled when change is true.
 4- on Update, the side bar should also be closed along with calling onTaskUpdate(changeObject, task.id)
 5- Implemented change object and its prefference for title. other fields should be implemented similarly including comments. added update button for implementaion demonstration 
 6- It is expected of frontend developer to design the component to keep in mind which action will be sent to the server for data update and should receive the data from the server in reposne before directly updating the UI for new entries, updates, deletion. Data flow should be from top-down : Server being the top and frontend fetching data and passing to components down the line. only actions such as update, add new data should be sent upward ( such as sending new task to parent to either send it to server or update it in its local state but the child component should receive the updated data, it shoudnt try to update the local state directly and assume the data is added, server might reject the data but user will be shown that data is added. the server call should be linked properly on frontend developer
 */

  const loggedInUser = getLoggedInUser();

  // function createComment() {
  //   const newComment = {
  //     id: guid(),
  //     content: CommentValue,
  //     name: loggedInUser && loggedInUser.name,
  //   };
  //   const updatedTask = { ...task, comments: [...task.comments, newComment] };
  //   setChangeObject(updatedTask);
  //   onTaskUpdate(updatedTask, task.id);
  //   setCommentValue("");
  // }
  function createComment() {
    const newComment = {
      id: guid(),
      content: CommentValue,
      assignee: loggedInUser && loggedInUser.name,
    };
    const updatedComments = [...task.comments, newComment]; // Updated comments array
    const updatedTask = { ...task, comments: updatedComments }; // Create a new task object with updated comments
    setChangeObject({ comments: updatedComments }); // Only set the change object with updated comments
    onTaskUpdate({ comments: updatedComments }, task.id); // Send only the updated comments array to the backend
    setCommentValue("");
  }

  const KeyPressComment = (event) => {
    if (event.key === "Enter") {
      createComment();
    }
  };

  // const handleDeleteComment = (commentId) => {
  //   const updatedTask = {
  //     ...task,
  //     comments: task.comments.filter((comment) => comment.id !== commentId),
  //   };
  //   setChangeObject(updatedTask);
  //   onTaskUpdate(updatedTask, task.id);
  // };
  const handleDeleteComment = (commentId) => {
    const updatedComments = task.comments.filter((comment) => comment.id !== commentId);
    const updatedTask = { ...task, comments: updatedComments };
    setChangeObject({ comments: updatedComments });
    onTaskUpdate({ comments: updatedComments }, task.id);
  };

  // function createSubTask() {
  //   const newSubTask = {
  //     id: guid(),
  //     content: inputsubTask,
  //   };
  //   const updatedTask = { ...task, subTasks: [...task.subTasks, newSubTask] };
  //   setChangeObject(updatedTask);
  //   onTaskUpdate(updatedTask, task.id);
  //   setinputsubTask("");
  // }
  function createSubTask() {
    const newSubTask = {
      id: guid(),
      content: inputsubTask,
      isSubTaskDone: false
    };
    const updatedSubTasks = [...task.subTasks, newSubTask]; // Updated subtasks array
    const updatedTask = { ...task, subTasks: updatedSubTasks }; // Create a new task object with updated subtasks
    setChangeObject({ subTasks: updatedSubTasks }); // Only set the change object with updated subtasks
    onTaskUpdate({ subTasks: updatedSubTasks }, task.id); // Send only the updated subtasks array to the backend
    setinputsubTask("");
  }

  const handleKeySubTask = (event) => {
    if (event.key === "Enter") {
      createSubTask();
      // Hide the component and show the button again
      setShowComponent(false);
    }
  };

  // const handleDeleteSubTask = (SubTaskId) => {
  //   const updatedTask = {
  //     ...task,
  //     subTasks: task.subTasks.filter((SubTask) => SubTask.id !== SubTaskId),
  //   };
  //   setChangeObject(updatedTask);
  //   onTaskUpdate(updatedTask, task.id);
  // };
  const handleDeleteSubTask = (subTaskId) => {
    const updatedSubTasks = task.subTasks.filter((subTask) => subTask.id !== subTaskId);
    const updatedTask = { ...task, subTasks: updatedSubTasks };
    setChangeObject({ subTasks: updatedSubTasks });
    onTaskUpdate({ subTasks: updatedSubTasks }, task.id);
  };

  const handleButtonClick = () => {
    setShowComponent(true);
  };

  const handleOptionClick2 = (user) => {
    const updatedTask = {
      ...task,
      assignedTo: { id: user._id, name: user.name, email: user.email },
    };
    setChangeObject(updatedTask);
    onTaskUpdate(updatedTask, task.id);
    handleClose2();
  };

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleCompleteButtonClick = () => {
    const updatedTask = { isDone: !task.isDone, columnId: task.isDone ? "Pending" : "Completed" };
    onTaskUpdate(updatedTask, task.id);
    closeSidebar();
  };

  const SubTaskDone = (subTaskId) => {
    const updatedSubTasks = task.subTasks.map(subTask => {
      if (subTask.id === subTaskId) {
        return { ...subTask, isSubTaskDone: !subTask.isSubTaskDone };
      }
      return subTask;
    });
    const updatedTask = { ...task, subTasks: updatedSubTasks };
    setChangeObject({ subTasks: updatedSubTasks });
    onTaskUpdate({ subTasks: updatedSubTasks }, task.id);
  };

  return (
    <>
      <Box
        sx={{
          width: 750,
        }}
      >
        <Box
          sx={{
            float: "right",
            color: "rgb(134,134,135)",
            marginRight: "25px",
          }}
        >
          {/* <Tooltip title="Like this Task" arrow>
                <ThumbUpAltOutlinedIcon sx={{ m: "10px" }} />
              </Tooltip>
              <Tooltip title="Attach Files" arrow>
                <AttachFileOutlinedIcon sx={{ m: "10px" }} />
              </Tooltip>
              <Tooltip title="Full Screen" arrow>
                <LaunchOutlinedIcon sx={{ m: "10px" }} />
              </Tooltip>
              <Tooltip title="More" arrow>
                <MoreHorizOutlinedIcon sx={{ m: "10px" }} />
              </Tooltip> */}
          <Tooltip title="Close" placement="top" arrow>
            <KeyboardTabOutlinedIcon onClick={closeSidebar} sx={{ m: "10px" }} />
          </Tooltip>
        </Box>

        <Grid sx={{ m: "25px", display: "flex", flexDirection: "row" }}>
          <Tooltip title={task.isDone === false ? "Mark Done" : "Undo Mark"} placement="top">
            <Button
              startIcon={<DoneIcon sx={{ color: "rgb(134,134,135)" }} />}
              value={task.isDone}
              onClick={handleCompleteButtonClick}
              sx={{
                color: "black",
                textTransform: "capitalize",
                border: "1px solid rgb(207,203,203)",
                fontSize: "small",
              }}
            >
              {task.isDone === true ? " Task Completed" : "Mark as  Complete"}
            </Button>
          </Tooltip>
          <Button
            disabled={!changed}
            sx={{
              color: "black",
              textTransform: "capitalize",
              border: "1px solid rgb(207,203,203)",
              fontSize: "small",
              marginLeft: "15px",
            }}
            onClick={(e) => {
              setOpen(false);
              closeSidebar();
              setChange(false);
              onTaskUpdate(changeObject, task.id);
            }}
          >
            Update
          </Button>
          <Button
            startIcon={<DeleteIcon sx={{ color: "rgb(134,134,135)" }} />}
            onClick={() => handleDeleteTask({ _id: task.id })}
            sx={{
              color: "black",
              textTransform: "capitalize",
              border: "1px solid rgb(207,203,203)",
              fontSize: "small",
              marginLeft: "15px",
            }}
          >
            Delete Task
          </Button>
        </Grid>

        {/* <Grid sx={{ backgroundColor: "rgb(249,248,248)", display: "flex", flexDirection: "row", p: "10px" }}>
          <LockIcon sx={{ fontSize: "medium", ml: "45px", color: "rgb(109,110,111)" }} />
          <Typography variant="body2" gutterBottom sx={{ fontSize: "small", pl: "15px" }}>
            This Task is Private to You ..
          </Typography>
        </Grid> */}

        <TextField
          fullWidth
          variant="outlined"
          defaultValue=""
          onChange={(e) => {
            setChangeObject({ ...changeObject, content: e.target.value });
            setChange(true);
          }}
          value={changeObject.content || task.content || "Task Title"}
          id="fullWidth"
          sx={{
            width: 710,
            maxWidth: "100%",
            margin: "10px",
          }}
        />

        <Grid item>
          <Grid sx={{ m: "25px", display: "flex", flexDirection: "row" }}>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)" }}>
              Assignee
            </Typography>
            <Box sx={{ ml: "100px", mt: "-20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormHelperText>Assignee Name</FormHelperText>
                <Box
                  onClick={handleClick2}
                  sx={{
                    cursor: "pointer",
                    pt: 1,
                  }}
                >
                  <ExpandMoreIcon />
                </Box>
                <Menu id="icon-menu2" anchorEl={anchorEl2?.parentElement} open={Boolean(anchorEl2)} onClose={handleClose2}>
                  <Box sx={{ height: "160px" }}>
                    {loadUserOptions(
                      (user) => user?.role === "Application Officer" || user.role === "Counselor",
                      users,
                      handleOptionClick2,
                      handleClose2,
                    )}
                    <MenuItem value={"self-assign"} key={"self-assign"} onClick={() => handleOptionClick2(loggedInUser)}>
                      Self assign
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>
              <Typography sx={{ color: "black" }}>{task?.assignedTo?.name}</Typography>
            </Box>
          </Grid>

          <Grid sx={{ m: "25px", display: "flex", flexDirection: "row" }}>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)" }}>
              Due Date
            </Typography>

            <TextField
              id="dueDate"
              type="date"
              variant="standard"
              sx={{ ml: "100px", width: 120, mt: "-10px" }}
              value={changeObject.dueDate || (task.dueDate && moment(task.dueDate).format("YYYY-MM-DD")) || ""}
              onChange={(e) => {
                const selectedDate = moment(e.target.value, "YYYY-MM-DD");
                if (selectedDate.isBefore(moment(), "day")) {
                  console.log("Hello");
                } else {
                  const formattedDate = selectedDate.format("YYYY-MM-DD");
                  setChangeObject({ ...changeObject, dueDate: formattedDate });
                }
                setChange(true);
              }}
            />
          </Grid>

          <Grid>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)", marginLeft: "25px" }}>
              Description
            </Typography>

            <TextField
              onChange={(e) => {
                setChangeObject({ ...changeObject, description: e.target.value });
                setChange(true);
              }}
              value={changeObject.description || task.description}
              sx={{ width: "100%", padding: " 10px 25px 10px 20px" }}
              placeholder="Enter text here"
              multiline
              rows={6}
              maxRows={4}
            />

            <Grid>
              <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)", ml: "25px" }}>
                Sub Tasks
              </Typography>

              <Box>
                {task?.subTasks?.map((task) => (
                  <Box>
                    <SubTasksdetails
                      id={task.id}
                      key={task.id}
                      title={task.content}
                      SubTaskStatus={task.isSubTaskDone}
                      handleDeleteSubTask={handleDeleteSubTask}
                      SubTaskDone={SubTaskDone} />
                  </Box>
                ))}
                {!showComponent ? (
                  <Button
                    startIcon={<AddOutlinedIcon sx={{ color: "rgb(134,134,135)" }} />}
                    onClick={() => {
                      handleButtonClick();
                    }}
                    sx={{
                      color: "black",
                      textTransform: "capitalize",
                      border: "1px solid rgb(207,203,203)",
                      fontSize: "small",
                      ml: "25px",
                      mt: "8px",
                    }}
                  >
                    Add SubTask
                  </Button>
                ) : (
                  <SubTasks value={inputsubTask} setinputsubTask={setinputsubTask} onKeyDown={handleKeySubTask} />
                )}
              </Box>
            </Grid>

            <Grid sx={{ backgroundColor: "rgb(249,248,248)", mt: "35px", p: 3 }}>
              <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)", ml: "25px" }}>
                Comments
              </Typography>

              {task?.comments?.map((x) => (
                <>
                  <Box
                    sx={{
                      border: "1px solid rgb(237,234,233)",
                      borderRadius: "10px",
                      marginTop: "15px",
                      backgroundColor: "rgb(255,255,255)",
                    }}
                  >
                    <Box
                      sx={{
                        float: "right",
                        margin: "15px 10px ",
                      }}
                    >
                      <Tooltip title="Delete Comment" placement="top">
                        <DeleteIcon
                          onClick={() => handleDeleteComment(x.id)}
                          fontSize="medium"
                          sx={{
                            color: "rgb(121,122,123)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Box>
                      <Grid sx={{ display: "flex", flexDirection: "row", ml: "25px", mt: "20px", width: "710" }}>
                        <Avatar sx={{ width: 35, height: 35, bgcolor: blue }} src={loggedInUser && loggedInUser.photoURL} alt="photoURL" />
                        <Typography variant="body2" gutterBottom sx={{ fontWeight: "bold", color: "rgb(93,93,95)", mt: "8px", ml: "10px" }}>
                          {x.assignee} ...
                          {/* <span
                            style={{
                              fontSize: ".6rem",
                              fontWeight: "light",
                              ml:'15px'
                            }}
                          >
                            {x.timestamp || "Just Now"}
                          </span> */}
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ fontWeight: "bold", color: "rgb(93,93,95)", ml: "2rem", mt: "8px", mr: "2rem", wordBreak: "break-all" }}
                      >
                        {x.content} .
                      </Typography>
                    </Box>
                  </Box>
                </>
              ))}
            </Grid>
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "rgb(249,248,248)",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                id="fullWidth"
                onChange={(e) => {
                  setCommentValue(e.target.value);
                }}
                value={CommentValue}
                // value={CommentValue}
                // onChange={InputComment}
                onKeyDown={KeyPressComment}
                placeholder="Enter Comments here"
                sx={{
                  width: 710,
                  wordBreak: "break-all",
                  // maxWidth: '100%',
                  margin: "10px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TaskSidebars;
