import React, { useState } from "react";
import { Box, Button, Modal, TextField, InputLabel, MenuItem, FormControl, Select, TextareaAutosize } from "@mui/material";
import { getLoggedInUser } from "../../../../../utils/loggedInUser";

const Revert = ({ student, onUpdate, revertData, data, setRevertData, appCheck }) => {
  console.log(revertData)
  const [reason, setReason] = useState(student?.counselor?.name); // Set the initial value to appOfficer's name
  const [comment, setComment] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const onSubmit = () => {
    let user = getLoggedInUser();
    if (!user) console.log("Logged-in user not found");

    if (appCheck) {
      onUpdate(
        {
          appCheck: {
            revert: revertData?.revert,
            [revertData?.name]: {
              confirm: data?.appCheck[revertData?.name]?.confirm ? true : false,
              submitted: data?.appCheck[revertData?.name]?.submitted ? true : false,
              name: revertData?.name,
              value: revertData?.value,
              revert: revertData?.revert,
              user: { id: user._id, email: user.email, name: user.name },
              assignTo: reason,
              comment,
            },
          },
        },
        data._id,
      );
    } else {
      onUpdate(
        {
          docCheck: {
            [revertData?.docName]: {
              verify: data?.docCheck?.[revertData?.docName]?.verify ? true : false,
              isViewed: data?.docCheck?.[revertData?.docName]?.isViewed ? true : false,
              docName: revertData?.docName,
              revert: revertData?.revert,
              user: { id: user._id, email: user.email, name: user.name },
              assignTo: reason,
              comment,
            },
          },
        },
        data._id,
      );
    }

    setRevertData({});
  };

  return (
    <>
      <Modal open={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 10,
            p: 3,
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: "100%", mb: 5 }}>
            <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reason}
              label="Assigned To"
              onChange={(e) => setReason(e.target.value)}
            >
              {/* <MenuItem value={student.appOfficer?.name}>{student.appOfficer?.name || "N.A"} </MenuItem> */}
              <MenuItem value={student.counselor?.name}>{student.counselor?.name}</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 5 }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Comment"
              variant="outlined"
              required
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5, width: "70%" }}>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Submit
            </Button>
            <Button variant="contained" color="primary" onClick={() => setRevertData({})}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Revert;
