import { Box, MenuItem, Select, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimelineDescription from "./TimelineDescription";
import AsanaBoard from "./Projects/AsanaBoard";
import { CRUDFunction } from "../../../../../reduxCURD/container";

const StudentTimeline = (props) => {
  // console.log("props", props);
  const { student } = props;
  // console.log(props)
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    props?.getStudentTasks("/projectTasks/" + props?.student?.registeration);
  }, []);

  useEffect(() => {
    props.student && props.student.registeration && props.getStudentTasks("/projectTasks/" + props.student.registeration);
  }, [props.student && props.student.registeration]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (props.actionType === "STUDENTTASK_UPDATE_SUCCESS") {
      props?.getStudentTasks("/projectTasks/" + props?.student?.registeration);
    }
  }, [props.actionType]);

  const isMobile = useMediaQuery("(max-width:700px)");

  const tabStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "grey",
  };

  const activeTabStyle = {
    ...tabStyle,
    color: "rgba(25, 104, 173, 1)",
    borderBottom: "2px solid rgba(25, 104, 173, 1)", // Border style for active tab
  };

  return (
    <>
      <div>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "30px",
              padding: "25px",
            }}
          >
            <Select value={selectedTab} onChange={(event) => setSelectedTab(event.target.value)}>
              <MenuItem value={0}>Notes</MenuItem>
              <MenuItem value={1}>Tasks</MenuItem>
            </Select>
          </div>
        ) : (
          <>
            <div style={{ padding: "20px", backgroundColor: "white", marginTop: "35px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  borderBottom: "1px solid rgba(217, 217, 217, 1)",
                }}
              >
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
                  <Tab style={selectedTab === 0 ? activeTabStyle : tabStyle} label="Notes" />
                  <Tab style={selectedTab === 1 ? activeTabStyle : tabStyle} label="Tasks" />
                </Tabs>
              </div>
            </div>
          </>
        )}

        <div>
          {selectedTab === 0 && (
            <Box
              sx={{
                height: "60vh",
                overflowY: "scroll",
                p: 2,
                background: "white",
              }}
            >
              <TimelineDescription student={student} />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box
              sx={{
                height: "60vh",
                // overflowY: "scroll",
                overflowX: "scroll",
                p: 2,
                background: "white",
              }}
            >
              <AsanaBoard
                disableNewSection={true}
                defaultSections={true}
                actions={{
                  onNewTaskCreated: props.createStudentTask,
                  onTaskUpdate: props.updateStudentTask,
                  onTaskDeleted: props.deleteStudentTask,
                }}
                projectId={student && student.registeration}
                dbTasks={props.studentTasks}
                student={student}
                users={props.users}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

let StudentTimelineContainer = CRUDFunction(StudentTimeline, "studentTask", null, null, null, "task");

export default StudentTimelineContainer;
