import React, { useState } from "react";
import { AppBar, Tab, Tabs, Toolbar, Typography, useMediaQuery } from "@mui/material";

const ReportTopBar = ({ reports, setSelectedReport, selectedReport }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const tabStyle = {
    marginLeft: "10px",
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "grey",
  };

  const activeTabStyle = {
    ...tabStyle,
    color: "rgba(25, 104, 173, 1)",
    borderBottom: "2px solid rgba(25, 104, 173, 1)",
  };

  const handleReportSelect = (report) => {
    setSelectedReport(report);
  };

  return (
    <>
      <div
        style={{
          marginLeft: "1.4rem",
          marginRight: "1.6rem",
          marginTop: "90px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "start",
            borderBottom: "1px solid rgba(217, 217, 217, 1)",
          }}
        >
          {reports?.map((report, index) => (
            <>
              <Tabs value={selectedReport} onClick={() => handleReportSelect(report)} aria-label="mui-tabs-example">
                <Tab key={index} style={selectedReport === report ? activeTabStyle : tabStyle} label={report?.name} />
              </Tabs>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReportTopBar;
