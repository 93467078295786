import { Box, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import DataWrapper from "./DataWrapper";
import AppCheckActions from "../TabPanel/Status/Submission/AppCheckActions";

const EducationData = ({ appData, student, appCheck, confirm, data, copy, submitted, setRevertData, user }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <DataWrapper heading={"Postgraduate"}>
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Institution:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.postgraduateInstitution || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="postgraduateInstitution"
                  text={student?.registrationData?.postgraduateInstitution || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Degree Title:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.postgraduateDegreeTitle || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="postgraduateDegreeTitle"
                  text={student?.registrationData?.postgraduateDegreeTitle || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Grades:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.postgraduateGrades || "N/A"}</span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="postgraduateCompletionYear"
                  text={student?.registrationData?.postgraduateCompletionYear || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Completion Year:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.postgraduateCompletionYear || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="postgraduateGrades"
                  text={student?.registrationData?.postgraduateGrades || "N/A"}
                />
              )}
            </Typography>
          </Box>
        </DataWrapper>
        <DataWrapper heading={"Undergraduate"}>
          <Box>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Institution:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.undergraduateInstitution || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="undergraduateInstitution"
                  text={student?.registrationData?.undergraduateInstitution || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Degree Title:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.undergraduateDegreeTitle || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="undergraduateDegreeTitle"
                  text={student?.registrationData?.undergraduateDegreeTitle || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Grades:{" "}
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{student?.registrationData?.undergraduateGrades || "N/A"}</span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="undergraduateGrades"
                  text={student?.registrationData?.undergraduateGrades || "N/A"}
                />
              )}
            </Typography>
            <Typography
              fontWeight="medium"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                pb: "5px",
                mb: "1rem",
              }}
            >
              <span>
                Completion Year:
                <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                  {student?.registrationData?.undergraduateCompletionYear || "N/A"}
                </span>
              </span>
              {appCheck && (
                <AppCheckActions
                  user={user}
                  setRevertData={setRevertData}
                  allData={data}
                  confirm={confirm}
                  copy={copy}
                  submitted={submitted}
                  data="undergraduateCompletionYear"
                  text={student?.registrationData?.undergraduateCompletionYear || "N/A"}
                />
              )}
            </Typography>
          </Box>
        </DataWrapper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width={"100%"}>

          <DataWrapper heading={`A Level`}>
            {student?.registrationData?.alevelForm &&
              student?.registrationData?.alevelForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                        width: "100%",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelInstitution"
                          text={item?.alevelInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelDegreeTitle"
                          text={item?.alevelDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelGrades"
                          text={item?.alevelGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.alevelSubjectList?.label ? item?.alevelSubjectList?.label : item?.alevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelSubjectList"
                          text={item?.alevelSubjectList?.label ? item?.alevelSubjectList?.label : item?.alevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherAlevelSubjectList ? item?.otherAlevelSubjectList : item?.otherAlevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherAlevelSubjectList"
                          text={item?.otherAlevelSubjectList ? item?.otherAlevelSubjectList : item?.otherAlevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelCompletionYear"
                          text={item?.alevelCompletionYear || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.alevelAwardingBody || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="alevelAwardingBody"
                          text={item?.alevelAwardingBody || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.alevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>
        <Stack width={"100%"}>

          <DataWrapper heading={`AS Level`}>
            {student?.registrationData?.aSlevelForm &&
              student?.registrationData.aSlevelForm.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelInstitution"
                          text={item?.aSlevelInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelDegreeTitle"
                          text={item?.aSlevelDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelGrades"
                          text={item?.aSlevelGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.aSlevelSubjectList?.label ? item?.aSlevelSubjectList?.label : item?.aSlevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelSubjectList"
                          text={item?.aSlevelSubjectList?.label ? item?.aSlevelSubjectList?.label : item?.aSlevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherASlevelSubjectList ? item?.otherASlevelSubjectList : item?.otherASlevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherASlevelSubjectList"
                          text={item?.otherASlevelSubjectList ? item?.otherASlevelSubjectList : item?.otherASlevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelCompletionYear"
                          text={item?.aSlevelCompletionYear || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.aSlevelAwardingBody || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="aSlevelAwardingBody"
                          text={item?.aSlevelAwardingBody || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.aSlevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width={"100%"}>

          <DataWrapper heading={`O Level`}>
            {student?.registrationData?.olevelForm &&
              student?.registrationData?.olevelForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelInstitution"
                          text={item?.olevelInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelDegreeTitle"
                          text={item?.olevelDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelGrades"
                          text={item?.olevelGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.olevelSubjectList?.label ? item?.olevelSubjectList?.label : item?.olevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelSubjectList"
                          text={item?.olevelSubjectList?.label ? item?.olevelSubjectList?.label : item?.olevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherOlevelSubjectList ? item?.otherOlevelSubjectList : item?.otherOlevelSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherOlevelSubjectList"
                          text={item?.otherOlevelSubjectList ? item?.otherOlevelSubjectList : item?.otherOlevelSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelCompletionYear"
                          text={item?.olevelCompletionYear || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Awarding Body:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.olevelAwardingBody || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="olevelAwardingBody"
                          text={item?.olevelAwardingBody || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.olevelForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>

        <Stack width={"100%"}>

          <DataWrapper heading={`HSSC`}>
            {student?.registrationData?.hsscForm &&
              student?.registrationData?.hsscForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="hsscInstitution"
                          text={item?.hsscInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="hsscDegreeTitle"
                          text={item?.hsscDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="hsscGrades"
                          text={item?.hsscGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.hsscSubjectList?.label ? item?.hsscSubjectList?.label : item?.hsscSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="hsscSubjectList"
                          text={item?.hsscSubjectList?.label ? item?.hsscSubjectList?.label : item?.hsscSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherHsscSubjectList ? item?.otherHsscSubjectList : item?.otherHsscSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherHsscSubjectList"
                          text={item?.otherHsscSubjectList ? item?.otherHsscSubjectList : item?.otherHsscSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.hsscCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="hsscCompletionYear"
                          text={item?.hsscCompletionYear || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.hsscForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: appCheck ? "1fr" : "1fr 1fr",
          gap: "1rem",
          ["@media (max-width: 775px)"]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack width="100%">

          <DataWrapper heading={`SSC`} >
            {student?.registrationData?.sccForm &&
              student?.registrationData?.sccForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="sccInstitution"
                          text={item?.sccInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="sccDegreeTitle"
                          text={item?.sccDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="sccGrades"
                          text={item?.sccGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.sccSubjectList?.label ? item?.sccSubjectList?.label : item?.sccSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="sccSubjectList"
                          text={item?.sccSubjectList?.label ? item?.sccSubjectList?.label : item?.sccSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherSccSubjectList ? item?.otherSccSubjectList : item?.otherSccSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherSccSubjectList"
                          text={item?.otherSccSubjectList ? item?.otherSccSubjectList : item?.otherSccSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.sccCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherSccSubjectList"
                          text={item?.otherSccSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.sccForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>
        <Stack width="100%">

          <DataWrapper heading={`Other Education`}>
            {student?.registrationData?.otherForm &&
              student?.registrationData?.otherForm?.map((item, index) => {
                return (
                  <>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Institution:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormInstitution || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherFormInstitution"
                          text={item?.otherFormInstitution || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Degree Title:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormDegreeTitle || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherFormDegreeTitle"
                          text={item?.otherFormDegreeTitle || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Grades: <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormGrades || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherFormGrades"
                          text={item?.otherFormGrades || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject:{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherFormSubjectList?.label ? item?.otherFormSubjectList?.label : item?.otherFormSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherFormSubjectList"
                          text={item?.otherFormSubjectList?.label ? item?.otherFormSubjectList?.label : item?.otherFormSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Subject (Other):{" "}
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>
                          {item?.otherOtherFormSubjectList ? item?.otherOtherFormSubjectList : item?.otherOtherFormSubjectList || "N/A"}
                        </span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherOtherFormSubjectList"
                          text={item?.otherOtherFormSubjectList ? item?.otherOtherFormSubjectList : item?.otherOtherFormSubjectList || "N/A"}
                        />
                      )}
                    </Typography>
                    <Typography
                      fontWeight="medium"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: "1px solid #E0E0E0",
                        pb: "5px",
                        mb: "1rem",
                      }}
                    >
                      <span>
                        Completion Year:
                        <span style={{ fontWeight: "lighter", marginLeft: "5px" }}>{item?.otherFormCompletionYear || "N/A"}</span>
                      </span>
                      {appCheck && (
                        <AppCheckActions
                          user={user}
                          setRevertData={setRevertData}
                          allData={data}
                          confirm={confirm}
                          copy={copy}
                          submitted={submitted}
                          data="otherFormCompletionYear"
                          text={item?.otherFormCompletionYear || "N/A"}
                        />
                      )}
                    </Typography>
                    {index < student?.registrationData?.otherForm?.length - 1 && (
                      <Divider style={{ backgroundColor: 'black', height: '2px', marginBottom: '20px' }} />
                    )}
                  </>
                );
              })}
          </DataWrapper>

        </Stack>
      </Box>
    </Box>
  );
};

export default EducationData;
