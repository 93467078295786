import { getLoggedInUser } from "../../utils/loggedInUser";

export const StateMachineAccessMethods = {
  roleAndUserCheck: (role, userEmail) => {
    return StateMachineAccessMethods?.limitedRoleCheck(role) && StateMachineAccessMethods?.matchUserEmailRequired(userEmail);
  },
  //checks if the role of the loggedIn user is admin or manager , then return true
  limitedRoleCheck: (role) => {
    if (!role) throw "Role argument required for this method";
    let user = getLoggedInUser();
    if (!user) return false;
    if (!["Administrator", "Management"]?.includes(user?.role) && user?.role !== role) return false;
    return true;
  },
  //checks if the email of loggedIn user matches
  matchUserEmailRequired: (userEmail) => {
    if (!userEmail) throw "userEmail required for this argument";
    let user = getLoggedInUser();
    if (!user || user?.email !== userEmail) return false;
    return true;
  },
  // checks for app officer in selfAssign
  selfAssignCheck: (student) => {
    if (!student || !student?.appOfficer) return false;
    return StateMachineAccessMethods.matchUserEmailRequired(student?.appOfficer?.email);
  },
};
