import React, { useState } from "react";
import ApprovedIcon from "../../../Images/ApprovedIcon";
import NotApprovedIcon from "../../../Images/NotApprovedIcon";
import UploadIcon from "../../../Images/UploadIcon";
import Downloadicon from "../../../Images/Downloadicon";
import DeleteIcon from "../../../Images/DeleteIcon";
import { Typography, Box, Stack, Grid, Link, Button, Input, Tooltip } from "@mui/material";
import InputForm from "../../../Components/Common/Form/InputForm";
import { studentFileDownloadPath } from "../../../utils/studentFileDownloadUtil";
import styled from "@emotion/styled";
import { CRUDFunction } from "../../../reduxCURD/container";
import ConfirmationModal from "../../../Components/ConfirmationModal";

const DocumentCheckListDisplay = (props) => {
  // console.log({data,student});
  const VisuallyHiddenInput = styled("file")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const documents = props.data?.appData?.documentUpload;
  const appData = props.data?.appData;
  const checkList = [
    {
      id: 1,
      title: "Application Form",
      required: true,
      document: documents?.applicationForm,
      fieldName: "applicationForm",
    },
    {
      id: 2,
      title: "SOP",
      document: documents?.sop,
      fieldName: "sop",
    },
    {
      id: 3,
      title: "Resume",
      required: true,
      document: documents?.resume,
      fieldName: "resume",
    },
    {
      id: 4,
      title: "Academic Chart",
      required: true,
      document: documents?.academicChart,
      fieldName: "academicChart",
    },
    {
      id: 5,
      title: "Master's (Degree / Transcript) or Completion / Hope letter",
      document: documents?.masters,
      fieldName: "masters",
    },
    {
      id: 6,
      title: "Bachelors (Degree / Transcript) or Completion / Hope letter",
      required: true,
      document: documents?.bachelors,
      fieldName: "bachelors",
    },
    {
      id: 7,
      title: "GCE A Levels (Statement of Entry / Certificate) / HSC (Marksheet / Certificate)",
      document: documents?.gceAlevel,
      fieldName: "gceAlevel",
    },
    {
      id: 8,
      title: "GCE O Levels (Statement of Entry / Certificate) / SSC (Marksheet / Certificate)",
      document: documents?.gceOlevel,
      fieldName: "gceOlevel",
    },
    {
      id: 9,
      title: "English Proficiency Testing (IELTS / TOEFL / PTE)",
      required: true,
      document: documents?.testing,
      fieldName: "testing",
    },
    {
      id: 10,
      title: "References (Academic / Job)",
      document: documents?.references,
      fieldName: "references",
    },
    {
      id: 11,
      title: "Passport (Colour copy with 6 monthes validity)",
      document: documents?.passport,
      fieldName: "passport",
    },
    {
      id: 12,
      title: "Work experience / Internship letter in case of gap year in study",
      document: documents?.workExperience,
      fieldName: "workExperience",
    },
    {
      id: 13,
      title: "Extra Curriculum",
      document: documents?.extraCurriculum,
      fieldName: "extraCurriculum",
    },
    {
      id: 14,
      title: "Affidavit of Support",
      document: documents?.affidavit,
      fieldName: "affidavit",
    },
    {
      id: 15,
      title: "Bank Letter / Statement",
      required: true,
      document: documents?.bankLetter,
      fieldName: "bankLetter",
    },

    {
      id: 18,
      title: "Other Documents",
      document: documents?.otherDocuments,
      fieldName: "otherDocuments",
    },
  ];

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thTdStyle = {
    padding: "8px",
    textAlign: "left",
  };

  const trEvenStyle = {
    backgroundColor: "white",
  };

  const trOddStyle = {
    backgroundColor: "white",
  };

  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      thTdStyle: {
        display: "block",
        width: "90%",
        boxSizing: "border-box",
        textAlign: "center",
      },
    },
  };

  const headingContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  };

  const ContainterTopStyle = {
    paddingLeft: "1.5rem",
    paddingRight: "2rem",
  };

  const getStatusIcon = (status) => {
    return status === "Approved" ? <ApprovedIcon /> : <NotApprovedIcon />;
  };

  const uploadFile = (files, fieldName) => {
    // console.log(files);
    if (!appData) return;
    if (files) {
      let upAppData = { ...appData, ...{ documentUpload: { ...documents, [fieldName]: files[0].name } } };
      props.onUpdate({ appData: upAppData }, props.data._id, [files[0]]);
      // Object.keys(files).map((fk) => props.fileUpload(files[fk], "/uploadsingle?stdId=" + props?.student?.registeration));
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [agree, setAgree] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const deleteFile = (file) => {
    setFileToDelete(file);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (fileToDelete) {
      const newObj = { ...documents };
      delete newObj[fileToDelete];
      props.onUpdate(
        {
          appData: {
            ...appData,
            documentUpload: newObj,
          },
        },
        props.data._id,
      );
      setFileToDelete(null);
      setShowModal(false);
    }
  };

  return (
    <div style={ContainterTopStyle}>
      <div style={headingContainerStyle}>
        <h2>Document Checklist</h2>
      </div>
      <ConfirmationModal
        open={showModal}
        setOpen={setShowModal}
        yes={setAgree}
        confirmDelete={confirmDelete}
        title={"Are you sure you want to delete this document"}
      />
      <div style={{ overflowY: "scroll", overflowX: "scroll" }}>
        <table style={tableStyle}>
          <thead>
            <tr style={{ backgroundColor: "rgb(25, 104, 173)" }}>
              <th style={{ ...thTdStyle, color: "white" }}>Documents Name</th>
              <th style={{ ...thTdStyle, color: "white" }}>Status</th>
              <th style={{ ...thTdStyle, color: "white" }}>Size</th>
              <th style={{ ...thTdStyle, color: "white" }}>Action</th>
            </tr>
          </thead>

          <tbody>
            {checkList.map((item, index) => (
              <tr
                key={item.id}
                style={{
                  ...(index % 2 === 0 ? trEvenStyle : trOddStyle),
                  borderBottom: "1px solid #ccc",
                }}
              >
                <td data-label="Documents Name" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                  {item.title}
                </td>
                <td data-label="Status" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                  {item.document ? <ApprovedIcon /> : <NotApprovedIcon />}
                </td>
                <td data-label="Size" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                  {item.size}
                </td>
                <td data-label="Action" style={{ ...thTdStyle, ...mediaQueryStyle.thTdStyle }}>
                  <div style={{ display: "flex", gap: "0.2rem" }}>
                    <Tooltip title="Upload" placement="top">
                      <Box
                        component="label"
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <input type="file" hidden onChange={(e) => uploadFile(e.target.files, item.fieldName)} />
                        <UploadIcon />
                      </Box>
                    </Tooltip>
                    {/* <VisuallyHiddenInput /> */}
                    <Tooltip title="Download" placement="top">
                      <div>
                        <Link
                          target="_blank"
                          rel="noopener"
                          download
                          href={studentFileDownloadPath(props.student?.registeration, item?.document)}
                        >
                          <Downloadicon />
                        </Link>{" "}
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Box
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => deleteFile(item?.fieldName)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DocumentCheckListDisplayContainer = CRUDFunction(DocumentCheckListDisplay, "document");

export default DocumentCheckListDisplayContainer;
